import React, { useEffect, useMemo, useState } from 'react';
import firebase from 'firebase/app';
import { useTranslation } from 'react-i18next';
import { Form, Input, List } from 'semantic-ui-react';
import {
  SessionData,
  GROUP_COLORS,
  CHRISTMAS_GROUP_COLORS,
  SESSION_COLLECTION,
  SESSION_GROUP_SUBCOLLECTION,
  SessionGroupData,
  SESSION_GROUP_LEADERS_SUBCOLLECTION,
  SessionGroupLeaderData,
  GroupData,
} from '@disco/data';
import { LabeledText } from 'libs/ui/src/lib/text/labeled-text';
import { ChronosGroupOptions } from './chronos-groups-options';

interface GroupsProps {
  sessionId: string;
  data: SessionData;
  isSessionArchived: boolean;
}

export function Groups({ sessionId, data, isSessionArchived }: GroupsProps) {
  const { t } = useTranslation();

  const [groupName, setGroupName] = useState('');
  const [groups, setGroups] = useState<SessionGroupData[]>([]);
  const [leaders, setLeaders] = useState<SessionGroupLeaderData[]>([]);

  const groupColors = data?.style === 'christmas' ? CHRISTMAS_GROUP_COLORS : GROUP_COLORS;

  const onAddGroup = (name: string) => {
    if (!data?.groups?.map((group) => group.name)?.includes(name)) {
      firebase
        .firestore()
        .collection(SESSION_COLLECTION)
        .doc(sessionId)
        .set({ groups: [...(data?.groups || []), { name, color: getNextColor() }] }, { merge: true });
    }
  };

  const onRemoveGroup = (name: string) => {
    firebase
      .firestore()
      .collection(SESSION_COLLECTION)
      .doc(sessionId)
      .set({ groups: data?.groups.filter((group) => name !== group.name) }, { merge: true });
  };

  const getNextColor = () =>
    groupColors.find((color) => !data?.groups?.length || !data?.groups?.map((c) => c.color).includes(color)) ||
    groupColors[data?.groups?.length % groupColors.length];

  useEffect(() => {
    return firebase
      .firestore()
      .collection(SESSION_COLLECTION)
      .doc(sessionId)
      .collection(SESSION_GROUP_SUBCOLLECTION)
      .onSnapshot((snapshot) => {
        const list = [];
        snapshot.forEach((doc) => list.push({ id: doc.id, ...doc.data() }));
        setGroups(list);
      });
  }, [sessionId]);

  useEffect(() => {
    if (!data?.gameId?.includes('chronos')) return;

    return firebase
      .firestore()
      .collection(SESSION_COLLECTION)
      .doc(sessionId)
      .collection(SESSION_GROUP_LEADERS_SUBCOLLECTION)
      .onSnapshot((snapshot) => {
        const list = [];
        snapshot.forEach((doc) => list.push({ id: doc.id, ...doc.data() }));
        setLeaders(list);
      });
  }, [data, sessionId]);

  const totalInGroups = useMemo(() => {
    return groups.reduce((prev, curr) => {
      return prev + curr?.count || 0;
    }, 0);
  }, [groups]);

  const getGroupUserCount = (name: string) => groups.find((ele) => ele.id === name)?.count || 0;

  return (
    <Form.Group>
      <Form.Field>
        <LabeledText inline textKey={t('session.totalInGroups')} value={totalInGroups.toString()} top={16} bottom={8} />
      </Form.Field>
      <Form.Field width="16">
        {!isSessionArchived && (
          <Input
            placeholder={t('session.groupName')}
            value={groupName}
            onChange={(e) => setGroupName(e.target.value?.toUpperCase())}
            action={{
              icon: 'plus',
              onClick: () => {
                if (groupName) {
                  onAddGroup(groupName);
                  setGroupName('');
                }
              },
            }}
          />
        )}
        <List style={{ maxHeight: '100%', overflowY: 'scroll', minWidth: 300 }}>
          {data?.groups?.map((group) => (
            <GroupRow key={group.name} {...{ sessionId, group, onRemoveGroup, getGroupUserCount, leaders }} />
          ))}
        </List>
      </Form.Field>
    </Form.Group>
  );
}

interface GroupRowProps {
  sessionId: string;
  group: GroupData;
  onRemoveGroup: (name: string) => void;
  getGroupUserCount: (name: string) => number;
  leaders?: SessionGroupLeaderData[];
}

export const GroupRow = ({ sessionId, group, onRemoveGroup, getGroupUserCount, leaders }: GroupRowProps) => {
  return (
    <List.Item style={{ display: 'flex' }}>
      <List.Icon name="users" style={{ color: group.color }} />
      <List.Content>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <div>{group.name}</div>
          {leaders && !!leaders.length && <ChronosGroupOptions {...{ sessionId, group, leaders }} />}
        </div>
      </List.Content>
      <div style={{ marginRight: '16px' }}>({getGroupUserCount(group.name)})</div>
      <List.Icon name="remove" style={{ cursor: 'pointer' }} onClick={() => onRemoveGroup(group.name)} />
    </List.Item>
  );
};
