import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { usePermission } from '@disco/hooks';
import { Button, Form, Header, Popup, Segment, TextArea } from 'semantic-ui-react';

interface GameData {
  id?: string;
  language?: string;
  companies?: string[];
}

export function Game() {
  const history = useHistory();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [game, setGame] = useState<GameData>();
  const [data, setData] = useState('');
  const [gameSaved, setGameSaved] = useState(false);
  const [gameError, setGameError] = useState(false);

  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection('games')
      .doc(id)
      .onSnapshot((doc) => {
        const game = { ...doc.data(), id: doc.id };
        setGame(game);
        setData(JSON.stringify(game, null, 2));
        setLoading(false);
      });
    return unsubscribe;
  }, []);

  const { id } = useParams<{ id: string }>();

  const onSaveGame = () => {
    try {
      firebase
        .firestore()
        .collection('games')
        .doc(id)
        .set({ ...JSON.parse(data), companies: game.companies })
        .then(() => {
          setGameError(false);
          setGameSaved(true);
        });
    } catch {
      setGameError(true);
    }
  };

  const onShowPreview = () => {
    history.push('/games/' + id + '/preview/0');
  };

  return (
    <Segment basic loading={loading}>
      <Header size="huge">{game?.id}</Header>
      <Form>
        <Form.Field>
          <TextArea onChange={(e) => setData(e.target.value)} style={{ minHeight: 500, resize: 'none' }} value={data} />
        </Form.Field>
        <Form.Field>
          {usePermission('saveGame') && (
            <Popup
              trigger={
                <Button onClick={onSaveGame} negative>
                  {t('games.save')}
                </Button>
              }
              content={gameError ? t('games.saveGameError') : t('games.gameSaved')}
              on="click"
              open={gameSaved || gameError}
              onClose={() => setGameSaved(false)}
              position="top right"
            />
          )}
          <Button onClick={onShowPreview}>{t('games.showPreview')}</Button>
        </Form.Field>
      </Form>
    </Segment>
  );
}

export default Game;
