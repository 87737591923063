import React from 'react';
import { slideWrapper } from '../components/slide';
import { RoundLetters, RoundType } from '@disco/data';
import { PuzzleItems } from '../components/puzzle-items';
import { Content } from '../components/content';
import { SlideImage } from '../components/slide-image';
import { ItemImage } from '../components/puzzle-item';

import styled from 'styled-components';
import { Text } from '../components/text';
import { Colors } from '../constants';

export const TeslaPuzzle2Counting = slideWrapper(
  ({ step, getImg, countingResults, countingResultsLoading }) => {
    const { answers, noteText } = step;

    return (
      <>
        <Content left={75} top={20} yNotCenter>
          <SlideImage width="500px" src={getImg('mapBig')} show={true} />
        </Content>
        <Content left={-5} bottom={0} xNotCenter yNotCenter>
          <SlideImage width="700px" src={getImg('most')} show={true} />
        </Content>

        <Content left={15} bottom={50} yNotCenter>
          <SlideImage width="150px" src={getImg('piwo2')} show={true} />
        </Content>
        <PuzzleItems
          {...{ countingResults, countingResultsLoading, getImg, answers, letters: RoundLetters[4] }}
          smallItem
        >
          <MapWrapper>
            <ItemImage marginTop={10} marginLeft={-80} smallItem={true}>
              <img src={getImg('map')} />
            </ItemImage>
            <Text>{noteText}</Text>
          </MapWrapper>
        </PuzzleItems>
      </>
    );
  },
  { countAnswersTour: RoundType.Tesla }
);
const MapWrapper = styled.div`
  display: flex;
  max-width: 500px;
  align-items: center;
  span {
    color: ${Colors.BLUE};
  }
`;
