import React, { useContext } from 'react';
import firebase from 'firebase/app';
import { useTranslation } from 'react-i18next';
import { Button, Card, Icon, Label } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import { CustomButton, UserDiscoContext, UserDiscoData } from '@disco/ui';
import { LanguagePicker } from './language-picker';
import { emitToast, ToastType } from '../../../../../libs/utils/src/lib/toast';

export const Profile = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { email, roles, companies, name } = useContext<UserDiscoData>(UserDiscoContext);

  const onLogout = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        history.push('/login');
      });
  };

  const onResetPassword = () => {
    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => emitToast({ title: `${t('users.resetLinkSent')} - ${email}` }, ToastType.Success))
      .catch(() => emitToast({ title: t('utils.toastError') }, ToastType.Error));
  };

  return (
    <Card>
      <Card.Content>
        <Card.Header>{t('settings.yourProfile')}</Card.Header>
        <Card.Description>{email}</Card.Description>
        <Card.Description>{name}</Card.Description>
        <Card.Content>
          <Card.Description>
            <div style={{ marginTop: 8, marginBottom: 4 }}>{t('settings.yourRoles')}</div>
            {roles.map((company) => (
              <Label size="tiny" style={{ margin: '2px 8px 2px 0' }} key={company}>
                {company}
              </Label>
            ))}
          </Card.Description>
        </Card.Content>
        <Card.Content>
          <Card.Description>
            <div style={{ marginTop: 8, marginBottom: 4 }}>{t('settings.yourCompanies')}</div>
            {companies.map((company) => (
              <Label size="tiny" style={{ margin: '2px 8px 2px 0' }} key={company}>
                {company}
              </Label>
            ))}
          </Card.Description>
        </Card.Content>
      </Card.Content>
      <Card.Content extra>
        <Card.Content>
          <LanguagePicker />
        </Card.Content>
      </Card.Content>
      <Card.Content extra>
        <CustomButton fluid onMakeAction={onResetPassword}>
          <Icon name="redo" />
          {t('users.resetButton')}
        </CustomButton>
      </Card.Content>
      <Card.Content extra>
        <Button fluid negative onClick={onLogout}>
          {t('settings.logout')}
        </Button>
      </Card.Content>
    </Card>
  );
};
