import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, Modal } from 'semantic-ui-react';
import firebase from 'firebase/app';
import { useForm } from 'react-hook-form';
import { HFSelect } from 'libs/ui/src/lib/form/hf-select';
import { ROLES } from './options';
import { useUsers } from '../../../api/use-users';
import { HFInput } from 'libs/ui/src/lib/form/hf-input';
import { emitToast, ToastType } from 'libs/utils/src/lib/toast';

interface CreateUserProps {
  onClose: () => void;
  open: boolean;
}

export function CreateUserModal({ onClose, open }: CreateUserProps) {
  const { t } = useTranslation();

  const { createUser } = useUsers();

  const [companies, setCompanies] = useState([]);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    const result = await createUser(data);
    onClose();

    if (result.code === 200) {
      emitToast({ title: t('users.userCreated') }, ToastType.Success);
      await firebase.auth().sendPasswordResetEmail(data.email);
    } else {
      emitToast({ title: t('utils.toastError') }, ToastType.Error);
    }
  };

  // set companies
  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection('companies')
      .onSnapshot((snapshot) => {
        const list = [];
        snapshot.forEach((doc) => {
          const { name } = doc.data();
          list.push({ key: doc.id, value: doc.id, text: name });
        });
        setCompanies(list);
      });
    return unsubscribe;
  }, []);

  return (
    <Modal dimmer={'inverted'} size="small" open={open} onClose={onClose}>
      <Modal.Header>{t('users.createModalTitle')}</Modal.Header>
      <Modal.Content>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group widths="equal">
            <HFInput
              {...{
                name: 'email',
                label: t('users.email'),
                required: true,
                errors,
                control,
              }}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <HFInput
              {...{
                name: 'name',
                label: t('users.name'),
                errors,
                control,
              }}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <HFSelect
              {...{
                multiple: true,
                name: 'roles',
                label: t('users.roles'),
                options: ROLES,
                required: true,
                errors,
                control,
              }}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <HFSelect
              {...{
                multiple: true,
                name: 'companies',
                label: t('users.companies'),
                options: companies,
                required: true,
                errors,
                control,
              }}
            />
          </Form.Group>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button positive onClick={handleSubmit(onSubmit)}>
          {t('utils.save')}
        </Button>
        <Button onClick={onClose}>{t('utils.cancel')}</Button>
      </Modal.Actions>
    </Modal>
  );
}
