import styled from 'styled-components';
import { Colors } from '../constants';

export const TextBox = styled.div<any>`
  background: ${(props) => props.theme.boxBackground || Colors.MAIN};
  color: white;
  text-shadow: ${(props) => props.theme.textShadow || 'none'};
  font-size: ${(props) => props.theme.boxFontSize || '3em'};
  line-height: 1.2em;
  padding: 0.7em 2em;
  text-align: center;
`;
