import useSound from 'use-sound';

export const useSoundFx = (sfx, options = { loop: false, volume: 0 }) => {
  const [play, exposedData] = useSound(sfx, {
    ...options,
    id: sfx,
    volume: options.volume ? options.volume : 1,
  });

  return {
    play,
    exposedData,
  };
};
