import { UserCounter } from './user-counter';
import { Title } from './title';
import React from 'react';
import styled from 'styled-components';

const HeaderWithCounter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

interface CustomHeaderProps {
  actions?: any;
  currentStep: any;
  prop: string;
}

export const CustomHeader = ({ actions, currentStep, prop }: CustomHeaderProps) => {
  const { style } = currentStep;
  const isChristmas = style === 'christmas';

  return (
    <HeaderWithCounter>
      <Title align="left" fontColor={currentStep?.color}>
        {currentStep ? currentStep[prop] || '' : ''}
      </Title>
      {actions && <UserCounter actions={actions} margin="0 16px 0 32px" isChristmas={isChristmas} />}
    </HeaderWithCounter>
  );
};
