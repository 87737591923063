import React from 'react';
import { slideWrapper } from '../components/slide';
import { Timer } from '../components/timer';
import { PuzzleItem, PuzzleItemsWrapper } from '../components/puzzle-item';
import { Content } from '../components/content';
import styled from 'styled-components';

const Register = styled.img`
  position: absolute;
  top: 45%;
  left: 35%;
  width: 300px;
  height: auto;
`;

export const PostOfficePuzzle3 = slideWrapper(({ data, step, getImg }) => {
  const { answers } = step;

  return (
    <>
      <Content left={13} top={50}>
        <img src={getImg('rudziki')} />
        <Register src={getImg('register')} />
      </Content>
      <Timer data={data} presentation />
      <PuzzleItemsWrapper>
        <PuzzleItem getImg={getImg} letterWrapper="t1" letter="a" answers={answers} />
        <PuzzleItem getImg={getImg} letterWrapper="t2" letter="b" answers={answers} />
        <PuzzleItem getImg={getImg} letterWrapper="t3" letter="c" answers={answers} />
        <PuzzleItem getImg={getImg} letterWrapper="t4" letter="d" answers={answers} />
      </PuzzleItemsWrapper>
    </>
  );
});
