import React, { useState } from 'react';
import { Button, Form } from 'semantic-ui-react';
import styled from 'styled-components';
import { Wrapper } from '../../../components/Wrapper';
import { saveAction } from '../../../utils/save-action';
import { updateUser, useUserData } from '../../../utils/update-user';
import { useTranslation } from 'react-i18next';
import { useCookies } from '@disco/hooks';
import { useForm } from 'react-hook-form';
import { HFInput } from '../../../../../../../ui/src/lib/form/hf-input';

const FormWrapper = styled.div`
  max-width: 360px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  input {
    width: 100%;
  }
  button {
    width: 100%;
    margin: 0 !important;
  }

  .field {
    width: 100%;
  }
`;

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 2rem;
  width: 100%;

  input {
    width: 100%;
  }
  button {
    width: 100%;
  }
`;

const ShipName = styled.div`
  margin-top: 16px;
`;
export const PlayerInterface = ({ data, uid, ...props }) => {
  const { t } = useTranslation();
  const [shipNamed, setShipNamed] = useState(false);

  const { shipName } = useUserData(data.sessionId, uid);
  const { updateSessionCookie } = useCookies();

  const onGroupSubmit = async ({ groupName }) => {
    //   todo czy mogą być użyte takie same nazwy?
    const actionData = { shipName: groupName };
    await saveAction(actionData, data, uid);
    await updateUser(actionData, data.sessionId, uid);
    updateSessionCookie({ groupName });
    setShipNamed(true);
    const event = new Event('cookie-updated');
    document.dispatchEvent(event);
  };

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty, isSubmitting },
  } = useForm();

  return (
    <Wrapper>
      <Form onSubmit={handleSubmit(onGroupSubmit)}>
        {shipNamed ? (
          <ColumnWrapper>
            <div>{t('fishery.selectNamePickedText')}</div>
            <ShipName>{shipName}</ShipName>
          </ColumnWrapper>
        ) : (
          <FormWrapper>
            <h1>{t('fishery.selectNameTitle')}</h1>

            <HFInput
              {...{
                name: 'groupName',
                defaultValue: '',
                control,
                transform: (e) => e.target.value,
                errors,
   
              }}
            />
            <Button primary loading={isSubmitting}>
              {t('fishery.selectNameButton')}
            </Button>
          </FormWrapper>
        )}
      </Form>
    </Wrapper>
  );
};
