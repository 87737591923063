import React from 'react';
import styled from 'styled-components';

const Box = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
`;

export const Footer = ({ children }) => {
  return <Box>{children}</Box>;
};
