import { UserDiscoData, UserDiscoContext, Permissions, PermissionsContext } from '@disco/ui';
import { useContext } from 'react';

const userHasPermission = (permissionName, permissions, roles, userCompanies) => {
  const { ui, companies } = permissions;
  if (!permissionName) {
    return true;
  }

  if (!ui) return false;
  if (roles.some((role) => ui[permissionName]?.includes(role))) {
    return true;
  }

  if (!companies) return false;
  if (userCompanies.some((userCompany) => companies[permissionName]?.includes(userCompany))) {
    return true;
  }

  return false;
};

export const usePermission = (permissionName) => {
  const { companies: userCompanies, roles } = useContext<UserDiscoData>(UserDiscoContext);
  const permissions = useContext<Permissions>(PermissionsContext);
  return  userHasPermission(permissionName, permissions, roles, userCompanies);
};
