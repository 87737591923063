import React from 'react';
import { slideWrapper } from '../components/slide';
import { TextBox } from '../components/text-box';
import { Text } from '../components/text';
import { Content } from '../components/content';
import { useAnimationSteps } from '@disco/hooks';
import styled from 'styled-components';
import { Rule } from '../components/rule';
import { Colors } from '../constants';

const TimeImage = styled.img<any>`
  opacity: ${(props) => (props.show ? 1 : 0)};
  transition: opacity 0.3s;
  max-height: 100vh;
`;
const TextWrapper = styled.div<any>`
  opacity: ${(props) => (props.show ? 1 : 0)};
  transition: opacity 0.3s;
  margin-top: 64px;
`;

export const Epilog = slideWrapper(({ step, resources, ...props }) => {
  const { title, text1, text2, text3, text4, imageLabels } = step;

  const circles = [text1, text2, text3];
  const animationStep = useAnimationSteps(circles.length + 1);

  return (
    <>
      <Content left={80} top={50}>
        <TimeImage show={true} src={resources[imageLabels?.time]} />
      </Content>
      <Content yNotCenter top={7} left={50}>
        <TextBox>{title}</TextBox>
        <RowContent>
          {circles.map((text, index) => (
            <Circle key={index} show={animationStep - 1 >= index}>
              <TextInside color={Colors.WHITEISH}>{text}</TextInside>
            </Circle>
          ))}
        </RowContent>
        <TextWrapper show={animationStep >= 4}>
          <Text alignCenter big>
            {text4}
          </Text>
        </TextWrapper>
      </Content>
    </>
  );
});

const TextInside = styled(Text)`
  padding: 40px;
  text-align: center;
`;

const RowContent = styled.div`
  margin: 60px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 60px;
`;

const Circle = styled.div<any>`
  opacity: ${(props) => (props.show ? 1 : 0)};
  transition: opacity 0.3s;
  width: 350px;
  height: 350px;
  background: ${Colors.MAIN};
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  outline: 2px solid white;
  outline-offset: -4px;
  align-items: center;
  display: flex;
  justify-content: center;
`;
