import React from 'react';
import styled from 'styled-components';
import { Colors } from '../constants';
import { Text } from './text';

const Dot = styled.div`
  margin-right: 16px;
`;

const RuleElement = styled.div<any>`
  opacity: ${(props) => (props.show ? 1 : 0)};
  width: ${(props) => props.width || '80vw'};
  transition: opacity 0.7s;
  margin: 10px;
  display: flex;

  &&& {
    .red {
      color: ${Colors.RED} !important;
    }
    .blue {
      color: ${Colors.BLUE} !important;
    }
  }
`;

export const Rule = ({ text, width = '80vw', size = 'big', show }) => {
  const props = { [size]: true };
  return (
    <RuleElement width={width} show={show}>
      <Dot>
        <Text {...props}>•</Text>
      </Dot>
      <Text {...props}>{text}</Text>
    </RuleElement>
  );
};
