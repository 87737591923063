import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Text } from '../../components/text';
import { Loader } from 'semantic-ui-react';

const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const TimeScore = ({ spentHours, loading }) => {
  const { t } = useTranslation();

  if (loading || spentHours === undefined) return <TextWrapper />;

  return (
    <TextWrapper>
      <Text big>{`${24 - spentHours} h`}</Text>
      <Text small alignCenter>
        {t('chronos.timeLeft')}
      </Text>
    </TextWrapper>
  );
};

export const startTimeHours = 9;

export const TimeScoreSanta = ({ points, loading }) => {
  const { t } = useTranslation();

  const minutesTotal = points * 15;
  const hours = Math.ceil(minutesTotal / 60);
  const minutesPart = minutesTotal % 60;

  const calculatedHours = startTimeHours - hours;
  const calculatedMinutes = !!minutesPart && 60 - minutesPart;

  if (loading || !points) {
    return <TextWrapper />;
  } else {
    return (
      <TextWrapper>
        <Text big>{`${calculatedHours} h`}</Text>
        {calculatedMinutes && <Text big>{`${calculatedMinutes} min`}</Text>}
        <Text small alignCenter>
          {t('chronos.timeLeftSanta')}
        </Text>
      </TextWrapper>
    );
  }
};
