import { useApi } from '@disco/hooks';
import { useCallback, useEffect, useState } from 'react';
import { ChronosApiEndpoints } from '../../../../../data/src/lib/chronos/api-endpoints';
import { usePresentationCheck } from './use-presentation-check';

interface UseRoundSummaryProps {
  sessionId: string;
  votingStepId: string;
}

export const useSantaFinalCounting = ({ sessionId, votingStepId }: UseRoundSummaryProps) => {
  const callApi = useApi();
  const { actualPresentationScreen } = usePresentationCheck();

  const [result, setResult] = useState<any>();

  useEffect(() => {
    if (actualPresentationScreen) {
      postFinalCalculation().then((data) => {
        setResult(data);
      });
    }
  }, []);

  const postFinalCalculation = useCallback(async () => {
    const { data } = await callApi(ChronosApiEndpoints.postSantaFinal, {
      method: 'POST',
      body: {
        sessionId,
        votingStepId,
      },
    });
    return data;
  }, [callApi, sessionId]);

  return { isSuccess: getIsSuccess(result), groupNameText: getTopGroupNameText(result) };
};

export const getIsSuccess = (result) => result?.winners?.length;

export const getTopGroupNameText = (result) => {
  const resultArray = getIsSuccess(result) ? result?.winners : result?.almost;

  return (
    resultArray &&
    resultArray
      ?.sort((a, b) => (a.score && b.score ? (a.score <= b.score ? -1 : 1) : 0))
      ?.slice(0, 1)
      ?.map((w) => w.groupNickname)
      .join(',')
  );
};

export const getWorstGroupNameText = (result) => {
  return (
    result &&
    result?.worst
      ?.slice(0, 1)
      ?.map((w) => w.groupNickname)
      .join(',')
  );
};
