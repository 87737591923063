import * as React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Text from '../text/text';
import asobi from './asobi.png';

const Logo = styled.div`
  img {
    max-width: 300px;
    height: auto;
  }
  font-size: 16px;
  margin-bottom: 42px;
`;

export const AsobiLogo = () => {
  const { t } = useTranslation();
  return (
    <Logo>
      <img src={asobi} alt="" />
      <Text color={'#3f0d66'}>{t('asobi')}</Text>
    </Logo>
  );
};
