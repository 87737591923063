import { useCookies } from '@disco/hooks';
import { MappedObject } from '../../../../../utils/src/global';

interface ScoringProps {
  groupHoursScore?: MappedObject<number>;
  groups?: { color: string; count: number; name: string }[];
  options?: MappedObject<number>;
  time?: any;
  topGroupResults?: MappedObject<{ firstVoteTime: any; selectedOption: string; total: number }[]>;
  type?: string;
}

export const useSolution = (scoring: ScoringProps) => {
  const { getSessionCookie } = useCookies();

  const groupName = getSessionCookie().groupName;
  const topGroupResults = scoring?.topGroupResults[groupName];

  const getSelectedOption = () => {
    if (!topGroupResults?.length) return;
    else return topGroupResults[0]?.selectedOption;
  };

  return { selectedOption: getSelectedOption(), hoursSpent: scoring?.groupHoursScore[groupName], loading: !scoring };
};
