import { useTranslation } from 'react-i18next';
import firebase from 'firebase/app';
import { Button, Form, Input } from 'semantic-ui-react';
import React, { useState } from 'react';
import { HFInput } from 'libs/ui/src/lib/form/hf-input';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { emitToast, ToastType } from 'libs/utils/src/lib/toast';
import { SESSION_COLLECTION, STEP_SUBCOLLECTION } from '@disco/data';

interface EditStepProps {
  sessionId: string;
  stepId: string;
  currentViewStep: any;
}

const STEP_MINUTES_FIELD_NAME = 'minutes';

export const EditStep = ({ sessionId, stepId, currentViewStep }: EditStepProps) => {
  const { t } = useTranslation();

  const { seconds, question, title, subtitle, listItemsVisible, list, notes } = currentViewStep;

  const [saving, setSaving] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      minutes: (seconds || 0) / 60,
      question,
      title,
      subtitle,
      notes,
      list: (list || []).map((item) => ({ value: item })),
    },
  });

  const { fields } = useFieldArray({
    control,
    name: 'list',
  });

  const onSubmit = async (data) => {
    setSaving(true);

    const { minutes, question, title, subtitle, list, notes } = data;
    const step = { seconds: minutes * 60, question, title, subtitle, notes, list: list.map((item) => item.value) };
    Object.keys(step).forEach((key) => (step[key] === undefined ? delete step[key] : {}));

    await firebase
      .firestore()
      .collection(SESSION_COLLECTION)
      .doc(sessionId)
      .collection(STEP_SUBCOLLECTION)
      .doc(stepId)
      .set(step, { merge: true })
      .catch(() => emitToast({ title: t('utils.toastError') }, ToastType.Error))
      .then(() => emitToast({ title: t('createSession.sessionSaved') }, ToastType.Success));

    setSaving(false);
  };

  return (
    <Form style={{ width: 300 }}>
      <HFInput
        {...{
          label: t('session.notes'),
          name: 'notes',
          defaultValue: notes,
          errors,
          control,
        }}
      />
      {!!seconds && (
        <HFInput
          {...{
            label: t('session.stepMinutes'),
            name: STEP_MINUTES_FIELD_NAME,
            errors,
            control,
            required: true,
          }}
        />
      )}
      {title && (
        <HFInput
          {...{
            label: t('session.stepTitle'),
            name: 'title',
            defaultValue: title,
            errors,
            control,
            required: true,
          }}
        />
      )}
      {subtitle && (
        <HFInput
          {...{
            label: t('session.stepSubtitle'),
            name: 'subtitle',
            defaultValue: subtitle,
            errors,
            control,
            required: true,
          }}
        />
      )}
      {question && (
        <HFInput
          {...{
            label: t('session.stepQuestion'),
            name: 'question',
            defaultValue: question,
            errors,
            control,
            required: true,
          }}
        />
      )}
      {fields.slice(0, listItemsVisible || 0).map((field: any, index) => (
        <Form.Field key={field.id}>
          <label>
            {t('session.stepItem')} {index}
          </label>
          <Controller
            render={({ field }) => <Input {...field} />}
            name={`list.${index}.value`}
            control={control}
            defaultValue={field.value} // make sure to set up defaultValue
          />
        </Form.Field>
      ))}
      <Button onClick={handleSubmit(onSubmit)} positive loading={!!saving} disabled={!!saving}>
        {t('utils.save')}
      </Button>
    </Form>
  );
};

export default EditStep;
