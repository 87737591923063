import { useCallback, useContext } from 'react';
import { callApi } from '@disco/utils';
import { UserDiscoData, UserDiscoContext } from '@disco/ui';
import { emitToast, ToastType } from '../../../../../libs/utils/src/lib/toast';
import { useTranslation } from 'react-i18next';

export const useSteps = () => {
  const { t } = useTranslation();
  const { token } = useContext<UserDiscoData>(UserDiscoContext);

  const resetStepActions = useCallback(async (body) => {
    return await callApi(`/steps/reset`, { token, method: 'delete', body });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const editStepTimerSeconds = useCallback(async (body) => {
    return await callApi(`/steps/timer`, { token, method: 'put', body });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetLeaderToRandom = useCallback(async (body) => {
    return await callApi('/steps/reset-leader', {
      token,
      method: 'POST',
      body,
    })
      .then((resp) => {
        if (resp.code === 200) emitToast({ title: t('utils.toastSuccess') }, ToastType.Success);
        else emitToast({ title: t('utils.toastError') }, ToastType.Error);
      })
      .catch(() => emitToast({ title: t('utils.toastError') }, ToastType.Error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    resetStepActions,
    editStepTimerSeconds,
    resetLeaderToRandom,
  };
};
