import React from 'react';
import { slideWrapper } from '../components/slide';
import { PuzzleItem, PuzzleItemsWrapper } from '../components/puzzle-item';

import { Content } from '../components/content';
import { SlideImage } from '../components/slide-image';

export const TeslaPuzzle1Solution = slideWrapper(({ step, getImg }) => {
  const { answers } = step;

  return (
    <>
      <Content left={4} top={5} xNotCenter yNotCenter>
        <SlideImage width="150px" rotate={'-10deg'} src={getImg('pytajnik')} show={true} />
      </Content>
      <Content left={20} top={14} yNotCenter>
        <SlideImage width="350px" src={getImg('lornetka')} show={true} />
      </Content>
      <PuzzleItemsWrapper>
        <PuzzleItem getImg={getImg} letter="a" answers={answers} />
        <PuzzleItem getImg={getImg} letter="b" answers={answers} />
        <PuzzleItem getImg={getImg} letter="c" answers={answers} />
        <PuzzleItem getImg={getImg} letter="d" answers={answers} />
        <PuzzleItem getImg={getImg} letter="e" answers={answers} />
      </PuzzleItemsWrapper>
    </>
  );
});
