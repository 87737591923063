import React from 'react';
import { slideWrapper } from '../components/slide';
import { Content } from '../components/content';
import styled from 'styled-components';
import { Text } from '../components/text';
import { TextBox } from '../components/text-box';
import { Colors } from '../constants';
import { RoundType } from '@disco/data';
import { useAnimationSteps } from '@disco/hooks';

const TextWrapper = styled.div<any>`
  margin-top: ${(props) => props.marginTop || '16px'};
  width: 100%;

  opacity: ${(props) => (props.show ? 1 : 0)};
  transition: opacity 0.3s;

  span {
    font-weight: bold;
  }

  &&& {
    .red {
      color: ${Colors.RED} !important;
    }
    .blue {
      color: ${Colors.BLUE} !important;
    }
  }
`;

const ItemsWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: 64px;
  justify-content: space-evenly;
  padding: 100px 0 140px 0;
  gap: 8px;
  font-weight: bold;
  background-image: linear-gradient(
    45deg,
    #665b5022 15%,
    #00000000 15%,
    #00000000 50%,
    #665b5022 50%,
    #665b5022 65%,
    #00000000 65%,
    #00000000 100%
  );
  background-size: 14.14px 14.14px;
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: auto;
    max-height: 150px;
  }
`;

export const HypatiaItems = slideWrapper(
  ({ step, resources, getImg, data, ...props }) => {
    const { title, subtitle, firstMissionText, lineText, cameraText, rule } = step;

    const animationStep = useAnimationSteps(3);

    return (
      <>
        <Content width="70%" yNotCenter top={7} left={50}>
          <TextBox>{title}</TextBox>
          <TextWrapper marginTop={'40px'} show={animationStep > 0}>
            <Text medium>{subtitle}</Text>
          </TextWrapper>
          <TextWrapper show={animationStep > 1}>
            <Text medium>{firstMissionText}</Text>
          </TextWrapper>
          <TextWrapper marginTop={'24px'} show={animationStep > 2}>
            <Text medium>{rule}</Text>
          </TextWrapper>
        </Content>
        <Content width="100%" bottom={0} yNotCenter>
          <ItemsWrapper>
            <Item>
              <img alt="line" src={getImg('line')} />
              <Text big>{lineText}</Text>
            </Item>
            <Item>
              <img alt="camera" src={getImg('camera')} />
              <Text big>{cameraText}</Text>
            </Item>
          </ItemsWrapper>
        </Content>
      </>
    );
  },
  { collectItemsTour: RoundType.Hypatia }
);
