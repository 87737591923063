import { useApi } from '@disco/hooks';
import { useCallback, useEffect, useState } from 'react';
import { ChronosApiEndpoints } from '../../../../../data/src/lib/chronos/api-endpoints';
import { getRoundTypeFromStepType } from '../utils';

interface UseRoundSummaryProps {
  sessionId: string;
  type: string;
}

export const useRoundSummary = ({ sessionId, type }: UseRoundSummaryProps) => {
  const callApi = useApi();

  const [summaryData, setSummaryData] = useState([]);

  useEffect(() => {
    getSummaryData().then((data) => {
      const keysSorted = Object.keys(data).sort((a, b) => data[a]?.hours - data[b]?.hours);
      const result = [];
      keysSorted.forEach((key) => {
        result.push({ ...data[key], groupName: key });
      });
      setSummaryData(result);
    });
  }, []);

  const getSummaryData = useCallback(async () => {
    const { data } = await callApi(
      `${ChronosApiEndpoints.getRoundSummary}?` +
        new URLSearchParams({ sessionId, roundType: getRoundTypeFromStepType(type) }),
      {
        method: 'GET',
      }
    );
    return data;
  }, [callApi, sessionId, type]);

  return { summaryData };
};
