import React from 'react';
import { slideWrapper } from '../components/slide';
import styled from 'styled-components';
import { RoundLetters, RoundType } from '@disco/data';
import { PuzzleItems } from '../components/puzzle-items';

const TextImage = styled.img<any>`
  position: absolute;
  left: 2%;
  top: -400px;
  width: 450px;
  transition: top 1s;
`;

export const HypatiaPuzzle2Counting = slideWrapper(
  ({ step, getImg, countingResults, countingResultsLoading }) => {
    const { answers } = step;

    return <PuzzleItems {...{ countingResults, countingResultsLoading, getImg, answers, letters: RoundLetters[3] }} />;
  },
  { countAnswersTour: RoundType.Hypatia }
);
