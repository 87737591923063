import React from 'react';

import { Wrapper } from '../../../components/Wrapper';
import { Interface } from '../../../components/player-interface/Interface';
import { useUserData } from '../../../utils/update-user';

import { useTranslation } from 'react-i18next';

export const PlayerInterface = ({ data, uid, ...props }) => {
  const { t } = useTranslation();

  const { conferance } = data;

  const userData = useUserData(data.sessionId, uid);

  const speaker = false;

  return (
    <Wrapper>
      <Interface
        sessionData={data}
        data={userData}
        content={
          conferance ? (
            <>
              <h1>{t('fishery.conferenceOn')}</h1>
              {speaker && <h2>{t('fishery.conferenceYouHaveBeenChosen')}</h2>}
            </>
          ) : null
        }
      />
    </Wrapper>
  );
};
