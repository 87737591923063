import {
  GroupData,
  SESSION_COLLECTION,
  SESSION_GROUP_LEADERS_SUBCOLLECTION,
  SessionGroupLeaderData,
} from '@disco/data';
import { useSteps } from '../../api/use-steps';
import React, { useState } from 'react';
import firebase from 'firebase/app';
import { emitToast, ToastType } from '../../../../../../libs/utils/src/lib/toast';
import { Button, Form, Icon, Modal, Popup } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { HFInput } from '../../../../../../libs/ui/src/lib/form/hf-input';

interface ChronosGroupOptionsProps {
  sessionId: string;
  group: GroupData;
  leaders: SessionGroupLeaderData[];
}

export const ChronosGroupOptions = ({ sessionId, group, leaders }: ChronosGroupOptionsProps) => {
  const { resetLeaderToRandom } = useSteps();

  const leaderId = leaders.find((ele) => ele.id === group.name)?.leaderId || undefined;
  const nickname = leaders.find((ele) => ele.id === group.name)?.groupNickname || undefined;

  const [modalOpen, setModalOpen] = useState(false);
  const onClose = () => setModalOpen(false);

  const onResetLeader = async () =>
    resetLeaderToRandom({
      groupName: group.name,
      sessionId,
    });

  const onEditNickname = async ({ groupNickname }) => {
    firebase
      .firestore()
      .collection(SESSION_COLLECTION)
      .doc(sessionId)
      .collection(SESSION_GROUP_LEADERS_SUBCOLLECTION)
      .doc(group.name)
      .set({ groupNickname }, { merge: true })
      .then(() => emitToast({ title: 'Nickname changed' }, ToastType.Success))
      .catch((err) => console.log(err));
  };

  const renderDetailsPopup = (
    <>
      <p>Leader: {leaderId}</p>
      <p>Nickname: {nickname}</p>
    </>
  );

  return (
    <>
      <DetailsModal {...{ modalOpen, onClose, leaderId, nickname, onResetLeader, onEditNickname }} />
      <Popup
        on="hover"
        content={renderDetailsPopup}
        trigger={
          <Icon
            style={{ padding: 0, color: leaderId && nickname ? 'green' : leaderId || nickname ? 'yellow' : 'black' }}
            name="list"
          />
        }
      />
      <Popup
        on="hover"
        content="Edit details"
        trigger={<Icon name="edit outline" onClick={() => setModalOpen(true)} />}
      />
    </>
  );
};

interface DetailsModalProps {
  modalOpen: boolean;
  onClose: () => void;
  onResetLeader: () => void;
  onEditNickname: (formData: any) => void;
  leaderId: string;
  nickname: string;
}

function DetailsModal({ modalOpen, onClose, leaderId, nickname, onResetLeader, onEditNickname }: DetailsModalProps) {
  const { t } = useTranslation();

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty, isSubmitting },
  } = useForm();

  return (
    <Modal dimmer={'inverted'} size="small" {...{ open: modalOpen, onClose }}>
      <Modal.Header>Group details</Modal.Header>
      <Modal.Content>
        <div style={{ marginBottom: '32px' }}>
          <label>Leader</label>
          <p>{leaderId}</p>
          <Button onClick={onResetLeader}>Reset to random</Button>
        </div>
        <div>
          <Form onSubmit={handleSubmit(onEditNickname)}>
            <HFInput
              {...{
                name: 'groupNickname',
                label: 'Nickname',
                defaultValue: nickname,
                control,
                placeholder: t('group.nicknamePlaceholder'),
                transform: (e) => e.target.value.toUpperCase(),
                errors,
              }}
            />
            <Button disabled={!isDirty} loading={isSubmitting} fluid>
              {t('utils.save')}
            </Button>
          </Form>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose}>Close</Button>
      </Modal.Actions>
    </Modal>
  );
}
