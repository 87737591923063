import React from 'react';
import { slideWrapper } from '../components/slide';
import { Content } from '../components/content';
import styled from 'styled-components';
import { Text } from '../components/text';
import { TextBox } from '../components/text-box';
import { Colors } from '../constants';
import { RoundType } from '@disco/data';

const TextWrapper = styled.div`
  margin-top: 64px;

  span {
    color: ${Colors.BLUE};
  }
`;

export const LeonardoItemsCollecting = slideWrapper(
  ({ step, resources, getImg, ...props }) => {
    const { title, subtitle, text } = step;

    return (
      <Content width="70%" yNotCenter top={10} left={50}>
        <TextBox>{title}</TextBox>
        <TextWrapper>
          <Text alignCenter gigant>
            {subtitle}
          </Text>
        </TextWrapper>
        <TextWrapper>
          <Text alignCenter big>
            {text}
          </Text>
        </TextWrapper>
      </Content>
    );
  },
  { collectItemsTour: RoundType.Leonardo }
);
