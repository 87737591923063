import React from 'react';
import { slideWrapper } from '../components/slide';
import { PuzzleItem, PuzzleItemsWrapper } from '../components/puzzle-item';
import { Content } from '../components/content';
import { SlideImage } from '../components/slide-image';

export const HypatiaPuzzle3Solution = slideWrapper(({ step, getImg }) => {
  const { answers } = step;

  return (
    <>
      <Content bottom={10} left={-10} yNotCenter xNotCenter>
        <SlideImage width="400px" src={getImg('boat')} show />
      </Content>

      <Content bottom={0} left={-2} yNotCenter xNotCenter>
        <SlideImage width="400px" src={getImg('boatguys')} show />
      </Content>
      <Content bottom={0} left={10} yNotCenter xNotCenter>
        <SlideImage width="300px" src={getImg('guys')} show />
      </Content>
      <PuzzleItemsWrapper>
        <PuzzleItem getImg={getImg} letter="a" answers={answers} />
        <PuzzleItem getImg={getImg} letter="b" answers={answers} />
        <PuzzleItem getImg={getImg} letter="c" answers={answers} />
      </PuzzleItemsWrapper>
    </>
  );
});
