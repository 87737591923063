import { useApi } from '../../generic/hooks/use-api';
import { ChronosApiEndpoints } from '../../../../../data/src/lib/chronos/api-endpoints';
import { useEffect, useState } from 'react';
import { useCookies } from '@disco/hooks';
import { getRoundTypeFromStepType } from '../utils';

export const useGroupInventory = (sessionId: string, currStepType: string, gameId?: string) => {
  const callApi = useApi();

  const [inventoryItems, setInventoryItems] = useState();
  const [spentHours, setSpentHours] = useState();
  const [loading, setLoading] = useState(false);

  const { getSessionCookie } = useCookies();
  const groupName = getSessionCookie().groupName;

  useEffect(() => {
    if (['chronos-hypatia-items', 'chronos-leonardo-items-collecting', 'chronos-tesla-items-collecting'].includes(currStepType)) {
      setTimeout(() => {
        getGroupInventory(sessionId, groupName).then((result) => {
          setInventoryItems(result?.items);
        });
      }, 500);
    }
  }, [currStepType]);

  useEffect(() => {
    setLoading(true);
    getGroupInventory(sessionId, groupName)
      .then((result) => {
        setInventoryItems(result?.items);
        setSpentHours(result?.hours);
      })
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getGroupInventory = async (sessionId: string, groupName: string) => {
    const url = gameId.includes('christmas')
      ? `${ChronosApiEndpoints.getSantaInventory}?` + new URLSearchParams({ sessionId, groupName })
      : `${ChronosApiEndpoints.getInventory}?` +
        new URLSearchParams({ sessionId, groupName, roundType: getRoundTypeFromStepType(currStepType) });

    const { data } = await callApi(url, { method: 'GET' });
    return data;
  };

  return { inventoryItems, spentHours, loading };
};
