import React, { useState } from 'react';

import { Wrapper } from '../../../components/Wrapper';
import { Interface } from '../../../components/player-interface/Interface';
import { useUserData } from '../../../utils/update-user';
import styled from 'styled-components';
import { Button } from '../../../components/player-interface/Button';
import { colorConfig } from '../../../config/colors';
import { useTranslation } from 'react-i18next';
import { useStepAction } from 'libs/steps/src/lib/api/use-step-action';

const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 24px;
  width: 100%;
  padding: 0 16px;
  max-width: 400px;
`;

const Center = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const PlayerInterface = ({ data, uid, ...props }) => {
  const { t } = useTranslation();

  const [selected, setSelected] = useState<string>(null);

  const userData = useUserData(data.sessionId, uid);

  const { postAction } = useStepAction();

  const onSelect = (strategy: string) => () => {
    setSelected(strategy);
    postAction({ data: { selectedOption: String(strategy) }, userId: uid, slideData: data });
  };

  return (
    <Wrapper>
      <Interface
        sessionData={data}
        data={userData}
        content={
          <Box>
            <h1>{t('fishery.howAreYouTitle')}</h1>
            <Button
              selected={selected === '1'}
              onClick={onSelect('1')}
              bgColor={colorConfig.blue.light}
              fontColor={colorConfig.blue.dark}
            >
              <Center>{t('fishery.howAreYouAnwser1')}</Center>
            </Button>
            <Button
              selected={selected === '2'}
              onClick={onSelect('2')}
              bgColor={colorConfig.blue.light}
              fontColor={colorConfig.blue.dark}
            >
              <Center>{t('fishery.howAreYouAnwser2')}</Center>
            </Button>
            <Button
              selected={selected === '3'}
              onClick={onSelect('3')}
              bgColor={colorConfig.blue.light}
              fontColor={colorConfig.blue.dark}
            >
              <Center>{t('fishery.howAreYouAnwser3')}</Center>
            </Button>
          </Box>
        }
      />
    </Wrapper>
  );
};
