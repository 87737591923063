import { ActionData, SessionPublicData } from '@disco/data';
import { useApi } from '../generic/hooks/use-api';
import { useCookies } from '@disco/hooks';

interface PostActionProps extends ActionData {
  slideData: SessionPublicData;
  isGroupSelection?: boolean;
}

export const useStepAction = () => {
  const callApi = useApi();
  const { getSessionCookie } = useCookies();

  const postAction = async (
    { data, userId, slideData, isGroupSelection = false }: PostActionProps,
    withGroup = false
  ) => {
    const { groupName } = getSessionCookie();

    return await callApi('/steps/action', {
      method: 'POST',
      body: {
        data: { ...data, ...(withGroup && { groupName }) },
        userId,
        sessionId: slideData.sessionId,
        stepId: isGroupSelection
          ? slideData?.requiredStep?.stepId || slideData.currentStep.id
          : slideData.currentStep.id,
      },
    });
  };

  return { postAction };
};
