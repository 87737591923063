import React from 'react';
import { slideWrapper } from '../components/slide';
import { TextBox } from '../components/text-box';
import { Content } from '../components/content';
import styled from 'styled-components';
import { Text } from '../components/text';
import { useAnimationSteps } from '@disco/hooks';

const TrainImage = styled.img`
  max-width: 100%;
  height: auto;
`;

const SlideText = styled.div`
  font-size: 60px;
  margin: 0 20px;
`;

const ClockImage = styled.img<any>`
  opacity: ${(props) => (props.show ? 1 : 0)};
  transition: opacity 0.3s;
`;

export const HowToPlay = slideWrapper(({ step, resources, ...props }) => {
  const { title, text, imageLabels } = step;

  const animationStep = useAnimationSteps(1, true);

  return (
    <>
      <Content width="100%" top={33}>
        <TrainImage src={resources[imageLabels?.train]} />
      </Content>
      <Content left={30} top={25}>
        <ClockImage show={animationStep > 0} src={resources[imageLabels?.clock]} />
      </Content>
      <Content yNotCenter top={60}>
        <TextBox>{title}</TextBox>
      </Content>
      <Content yNotCenter y={120} top={60}>
        <SlideText>
          <Text big alignCenter>
            {text}
          </Text>
        </SlideText>
      </Content>
    </>
  );
});
