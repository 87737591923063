import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { UserCounter } from 'libs/steps/src/lib/collectors/components/user-counter';
import { usePresenterActions } from '../../../utils/use-presenter-actions';
import { useConferenceVoteCount } from '../../../utils/use-conference-votes';
import { Dots } from '../../../components/player-interface/Dots';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .label,
  .value {
    font-family: Klavika !important;
    color: #114a94 !important;
  }
`;

const WidgetsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

export const Content = ({ data }) => {
  const { t } = useTranslation();

  const { currentStep } = data;

  const { conferance } = currentStep;

  const { loading } = useConferenceVoteCount(data?.sessionId, data?.currentStep?.id);

  const { actions } = usePresenterActions(data);

  return loading ? (
    <Wrapper>
      <Dots />
    </Wrapper>
  ) : (
    <Wrapper>
      {!conferance && <h2>{t('fishery.doYouWantSpeakNoConference')}</h2>}

      {conferance && (
        <>
          <h1>{t('fishery.doYouWantSpeakAtConferenceTitle')}</h1>
          <WidgetsWrapper>
            <UserCounter actions={actions} />
          </WidgetsWrapper>
        </>
      )}
    </Wrapper>
  );
};
