import React from 'react';
import { SlideImage } from '../components/slide-image';
import { Content } from '../components/content';
import { slideWrapper } from '../components/slide';
import { PuzzleItem, PuzzleItemsWrapper } from '../components/puzzle-item';

export const TeslaPuzzle4Solution = slideWrapper(({ step, getImg }) => {
  const { answers } = step;

  return (
    <>
      <Content left={-2} top={20} xNotCenter yNotCenter>
        <SlideImage width="300px" src={getImg('polowaziomka')} show={true} />
      </Content>
      <Content left={20} top={20} yNotCenter>
        <SlideImage width="150px" rotate={'10deg'} src={getImg('szklanka')} show={true} />
      </Content>
      <PuzzleItemsWrapper>
        <PuzzleItem getImg={getImg} letter="a" answers={answers} />
        <PuzzleItem getImg={getImg} letter="b" answers={answers} />
        <PuzzleItem getImg={getImg} letter="c" answers={answers} />
        <PuzzleItem getImg={getImg} letter="d" answers={answers} />
        <PuzzleItem getImg={getImg} letter="e" answers={answers} />
        <PuzzleItem getImg={getImg} letter="f" answers={answers} />
      </PuzzleItemsWrapper>
    </>
  );
});
