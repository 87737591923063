import React from 'react';
import { Header, Segment } from 'semantic-ui-react';
import styled from 'styled-components';

interface CardProps {
  title: string;
  color?: string;
  headerMargin?: string;
  children: React.ReactNode;
}

const Content = styled.div`
  max-width: 350px;
  margin: 0 auto;
`;

const StyledHeader = styled.h1<{ fontColor: string; margin: string }>`
  &&& {
    color: ${(props) => props.fontColor};
    margin: ${(props) => props.margin};
  }
`;

export function Card({ title, color, children, headerMargin }: CardProps) {
  return (
    <Content>
      <Header as={StyledHeader} margin={headerMargin} fontColor={color} textAlign="center">
        {title}
      </Header>
      <Segment basic>{children}</Segment>
    </Content>
  );
}
