import React, { useCallback, useEffect, useState } from 'react';
import firebase from 'firebase/app';
import { useHistory, useParams } from 'react-router-dom';
import { GameData } from '@disco/data';
import { Button, Dimmer, Icon, Loader } from 'semantic-ui-react';
import { STEP_TYPES } from '@disco/steps';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useResources } from '../../api/use-resources';
import { ScaleWrapper } from '../../components/scale-wrapper';

const TopButtonWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  margin: 2rem;
  z-index: 99999;
`;

const ButtonWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 2rem;
`;

const PREVIEW_GROUPS = [{ name: 'G 1' }, { name: 'G 2' }, { name: 'G 3' }];

export function GamePreview() {
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const { id, stepId: stepIdString } = useParams<{ id: string; stepId: string }>();

  const { resources } = useResources(id);

  const stepId = Number(stepIdString);

  const [loading, setLoading] = useState(true);
  const [game, setGame] = useState<GameData>();
  const [currentStep, setCurrentStep] = useState<any>();

  useEffect(() => {
    return firebase
      .firestore()
      .collection('games')
      .doc(id)
      .onSnapshot((doc) => {
        const game = { ...doc.data(), id: doc.id } as GameData;
        setGame(game);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (game?.language && game?.language !== i18n.language) {
      i18n.changeLanguage(game.language);
    }
  }, [game?.language]);

  useEffect(() => {
    if (!game) return;
    setCurrentStep({
      backgroundColor: game.baseBackgroundColor,
      backgroundImage: game.baseBackgroundImage,
      containBackground: game.containBackground,
      playerInterface: game.playerInterface || null,
      color: game.baseFontColor,
      style: game.style,
      opacity: game.defaultOpacity,
      ...game?.steps[stepId],
    });
  }, [stepId, game]);

  const onShowNext = useCallback(() => {
    history.push('/games/' + id + '/preview/' + (stepId + (game?.steps.length - 1 > stepId ? 1 : 0)));
  }, [id, stepId, game]);

  const onShowPrev = useCallback(() => {
    history.push('/games/' + id + '/preview/' + (stepId + (0 < stepId ? -1 : 0)));
  }, [id, stepId, game]);

  const onBack = () => {
    history.push('/games/' + id);
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.keyCode === 37) {
        onShowPrev();
        return;
      }
      if (e.keyCode == 39) {
        onShowNext();
        return;
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [onShowNext, onShowPrev]);

  const Component = currentStep ? STEP_TYPES[currentStep.type] : () => null;

  return (
    <ScaleWrapper scaleView={game?.scaleView}>
      <Dimmer inverted active={loading}>
        <Loader />
      </Dimmer>
      <Component key={stepId} data={{ currentStep, resources, groups: PREVIEW_GROUPS }} presentation />
      <TopButtonWrapper>
        <Button onClick={onBack} icon="arrow left" />
      </TopButtonWrapper>
      <ButtonWrapper>
        <Button onClick={onShowPrev} disabled={stepId <= 0} icon labelPosition="left">
          {t('games.prev')}
          <Icon name="arrow left" />
        </Button>
        <Button onClick={onShowNext} icon disabled={stepId >= game?.steps.length - 1} labelPosition="right">
          {t('games.next')}
          <Icon name="arrow right" />
        </Button>
      </ButtonWrapper>
    </ScaleWrapper>
  );
}

export default GamePreview;
