import React, { useEffect, useState } from 'react';
import { useApi, useCookies } from '@disco/hooks';
import { slideWrapper } from '../components/slide';
import { Text } from '../components/text';
import styled from 'styled-components';
import { Timer } from '../../generic/timer/timer';
import { Button, Input } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { ChronosApiEndpoints } from '../../../../../data/src/lib/chronos/api-endpoints';
import firebase from 'firebase';
import { GroupFinalProps, SESSION_COLLECTION, SESSION_GROUP_FINAL_SUBCOLLECTION } from '@disco/data';
import { ContentWrapper } from './styled';
import { replaceGroupFinal, replaceResult } from './functions';

export const FinalSelectChronos = slideWrapper(({ step, resources, data: stepData, uid }) => {
  const { t } = useTranslation();
  const callApi = useApi();
  const { getSessionCookie } = useCookies();
  const { groupName } = getSessionCookie();
  const { playerText1, playerLabel1, playerLabel2, groupAnswerSet, playerAnswerSent } = step;

  const {
    sessionId,
    currentStep: { id },
  } = stepData;

  const [bossName, setBossName] = useState('');
  const [year, setYear] = useState('');

  const [groupFinal, setGroupFinal] = useState<GroupFinalProps>({});

  const [submitted, setSubmitted] = useState(false);

  const sendAnswers = async () => {
    setSubmitted(true);
    await callApi(ChronosApiEndpoints.postChronosFinalAnswer, {
      method: 'POST',
      body: {
        bossName,
        year,
        userId: uid,
        groupName,
        sessionId,
        stepId: id,
      },
    }).catch(() => setSubmitted(false));
  };

  useEffect(() => {
    return firebase
      .firestore()
      .collection(SESSION_COLLECTION)
      .doc(sessionId)
      .collection(SESSION_GROUP_FINAL_SUBCOLLECTION)
      .doc(groupName)
      .onSnapshot((doc) => {
        setGroupFinal({ ...doc.data(), id: doc.id });
      });
  }, [groupName, sessionId]);

  useEffect(() => {
    if (
      groupFinal?.bossNameAnswer &&
      groupFinal?.yearAnswer &&
      groupFinal.bossNameAnswer[uid] &&
      groupFinal.yearAnswer[uid]
    ) {
      setSubmitted(true);
      setBossName(groupFinal.bossNameAnswer[uid]);
      setYear(groupFinal.yearAnswer[uid]);
    }
  }, [groupFinal.bossNameAnswer, groupFinal.yearAnswer, uid]);

  // group has accepted answer (2 identical entries)
  if (groupFinal.answerAccepted) {
    return (
      <ContentWrapper>
        <Text>{replaceGroupFinal(groupAnswerSet, groupFinal)}</Text>
      </ContentWrapper>
    );
  }

  if (submitted) {
    return (
      <ContentWrapper>
        <Text>{replaceResult(playerAnswerSent, bossName, year)}</Text>
      </ContentWrapper>
    );
  }

  return (
    <ContentWrapper>
      {stepData.currentStep?.timer && <Timer data={stepData} presentation={false} />}
      <Text medium>{playerText1}</Text>
      <Container>
        <CustomInput value={bossName} onChange={setBossName} label={playerLabel1} maxLength={30} accepted={'string'} />
        <CustomInput value={year} onChange={setYear} label={playerLabel2} maxLength={4} accepted={'number'} />
      </Container>
      <Button disabled={bossName?.length < 1 || year?.length < 1} onClick={sendAnswers} style={{width: '100%'}}>
        {t('util.send')}
      </Button>
    </ContentWrapper>
  );
});

interface CustomInputProps {
  maxLength: number;
  label: string;
  value: string;
  onChange: any;
  accepted: 'number' | 'string';
  placeholder?: string;
}

const CustomInput = ({ maxLength, label, value, placeholder, onChange, accepted }: CustomInputProps) => {
  const strReg = /^[a-zA-Z\s]*$/;
  const numReg = /^[0-9]*$/;

  const customOnChange = (e) => {
    const trimmed = e.target.value.trim();
    if (accepted === 'number' && !numReg.test(trimmed)) return;
    if (accepted === 'string' && !strReg.test(trimmed)) return;

    const val = e.target.value;
    if (maxLength >= val.length) {
      onChange(val?.toUpperCase());
    }
  };

  return (
    <>
      <label>{label}</label>
      <Input fluid {...{ value, placeholder, onChange: customOnChange }} autoComplete={'off'} />
      <LengthCounter>
        {value.length} / {maxLength}
      </LengthCounter>
    </>
  );
};

const LengthCounter = styled.p`
  color: gray;
  font-size: 12px;
`;

const Container = styled.div`
  padding: 16px;
`;
