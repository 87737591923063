import { StepType } from '@disco/data';
import { CollectorsStepType } from '../../../steps/src/lib/collectors/types';
import { ChronosStepType } from '../../../steps/src/lib/chronos/types';
import { FisheryStepType } from '../../../steps/src/lib/fishery/types';

export const isSelectGroupType = (type?: string) => {
  if (!type) return false;

  const existInEnum = (value: string, typeEnum: any) => Object.values(typeEnum)?.includes(value);

  return (
    type.includes(StepType.SELECT_GROUP) &&
    (existInEnum(type, CollectorsStepType) || existInEnum(type, ChronosStepType) || existInEnum(type, FisheryStepType))
  );
};
