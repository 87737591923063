import React from 'react';
import { slideWrapper } from '../components/slide';
import { TextBox } from '../components/text-box';
import { Text } from '../components/text';
import { Content } from '../components/content';
import { useAnimationSteps } from '@disco/hooks';
import styled from 'styled-components';
import { Rule } from '../components/rule';

const TimeImage = styled.img<any>`
  opacity: ${(props) => (props.show ? 1 : 0)};
  transition: opacity 0.3s;
  max-height: 100vh;
`;

const AppearingText = styled(Text)`
  opacity: ${(props) => (props.show ? 1 : 0)};
  transition: opacity 0.3s;
  font-weight: 800;
  margin-top: 60px;
  padding-left: 40px;
`;

const TextBoxWrapper = styled.div`
  margin: 64px;
`;

export const HowToPlayDetails = slideWrapper(({ step, resources, ...props }) => {
  const { title, text1, text2, text3, imageLabels } = step;

  const texts = [text1, text2];
  const animationStep = useAnimationSteps(texts.length + 2);

  return (
    <>
      <Content left={80} top={50}>
        <TimeImage show={animationStep > 0} src={resources[imageLabels?.time]} />
      </Content>
      <Content yNotCenter top={7} left={50}>
        <TextBoxWrapper>
          <TextBox>{title}</TextBox>
        </TextBoxWrapper>
        {texts.map((text, index) => (
          <Rule key={index} size="medium" show={animationStep - 1 > index} text={text} />
        ))}
        <AppearingText huge show={animationStep - 1 > 2}>
          {text3}
        </AppearingText>
      </Content>
    </>
  );
});
