import React from 'react';
import styled from 'styled-components';
import { Text } from './text';
import { Colors } from '../constants';
import { Content } from './content';

export const PuzzleItem = ({
  getImg,
  letter,
  answers,
  playerInterface = false,
  selectionCount = undefined,
  letterWrapper = undefined,
  smallItem = false,
}) => {
  return (
    <Box>
      <TimeWrapper>
        {selectionCount !== undefined && (
          <Count>
            <Text color={Colors.WHITEISH}>{selectionCount}</Text>
          </Count>
        )}
        {answers[letter]?.score !== undefined && <Score src={getImg(String(answers[letter]?.score))} />}
        <img height={100} src={getImg(letter)} />
        {letterWrapper && <LetterWrapper height={160} src={getImg(letterWrapper)} />}
      </TimeWrapper>
      <TextWrapper playerInterface={playerInterface}>
        <Text>{answers[letter]?.text}</Text>
      </TextWrapper>
      {answers[letter]?.item && (
        <ItemImage use={answers[letter]?.use} noDot={answers[letter]?.noDot} smallItem={smallItem}>
          <img src={getImg(answers[letter]?.item)} />
        </ItemImage>
      )}
      {answers[letter]?.item2 && (
        <ItemImage marginTop={50} marginLeft={-80} use={answers[letter]?.use2} smallItem={smallItem}>
          <img src={getImg(answers[letter]?.item2)} />
        </ItemImage>
      )}
    </Box>
  );
};

export const PuzzleItemsWrapper = styled(Content).attrs({ x: -350, xNotCenter: true, top: 50, align: 'flex-start' })``;

export const LetterWrapper = styled.img`
  position: absolute;
  top: -30px;
  left: -35px;
`;

const Count = styled.div`
  position: absolute;
  background-color: ${Colors.BROWN};
  padding: 20px 16px 16px 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  top: -5px;
  left: -20px;
  box-shadow: 0 0 0 2px ${Colors.BROWN};
  border: 1px solid ${Colors.WHITEISH};
`;

const Box = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  height: 130px;

  @media screen and (max-width: 1000px) {
    height: unset;
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin-top: 64px;
  }
`;

const TimeWrapper = styled.div<any>`
  position: relative;
`;

const Score = styled.img`
  transform: translateZ(10px);
  width: 60px;
  height: 60px;
  bottom: -5px;
  right: -25px;
  position: absolute;
`;

const TextWrapper = styled.div<{ playerInterface: boolean }>`
  margin: ${(props) => (props.playerInterface ? '0' : '0 10px 0 40px')};
  width: 800px;

  @media screen and (max-width: 1000px) {
    width: unset;
  }

  span.green {
    color: ${Colors.GREEN};
  }
  span.blue {
    color: ${Colors.BLUE};
  }
  span.red {
    color: ${Colors.RED};
  }
`;

export const ItemImage = styled.div<any>`
  img {
    height: auto;
    max-width: ${(props) => (props.smallItem ? '200px' : '320px')};
  }
  position: relative;

  margin-left: ${(props) => props.marginLeft}px;
  margin-top: ${(props) => props.marginTop}px;

  ::after {
    content: '';
    height: 25px;
    width: 25px;
    background-color: ${(props) => (props.noDot ? 'none' : props.use ? Colors.RED : Colors.BLUE)};
    border-radius: 50%;
    border: 3px solid ${(props) => (props.noDot ? 'none' : 'white')};
    position: absolute;
    bottom: 10%;
    left: 30%;
  }
`;
