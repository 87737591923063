import React from 'react';
import { useParams } from 'react-router-dom';
import Session from '../../components/session';

export function Panel() {
  const { id } = useParams<{ id: string }>();

  return <Session id={id} />;
}

export default Panel;
