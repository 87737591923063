import { LogoElement } from 'libs/ui/src/lib/logo/logo';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Grid, Icon, Menu, Segment, Sidebar } from 'semantic-ui-react';
import { SemanticICONS } from 'semantic-ui-react/dist/commonjs/generic';
import styled from 'styled-components';
import { useUsers } from '../api/use-users';
import asobi from '../../assets/asobi.png';

interface LayoutProps {
  children: React.ReactNode;
  menuItems: { text: string; href: string; icon: SemanticICONS }[];
}

const StyledPusher = styled.div<{ visible: boolean }>`
  && {
    width: ${(props) => (props.visible ? 'calc(100% - 150px)' : '100%')};
    transition: 0.5s ease;

    @media screen and (max-width: 450px) {
      width: 100%;
    }
  }
`;

const AsobiImage = styled.img`
  width: auto;
  height: 100px;
`;

export function Layout({ children, menuItems }: LayoutProps) {
  const location = useLocation();

  const { currentUserRoles } = useUsers();

  return (
    <div style={{ minHeight: '100%' }}>
      <Sidebar.Pushable as={Segment}>
        <Sidebar
          as={Menu}
          animation="push"
          direction="left"
          inverted
          vertical
          icon="labeled"
          visible={true}
          width="thin"
          style={{ backgroundColor: '#3f0d66' }}
        >
          <Menu.Item as={Menu.Header}>
            {currentUserRoles.includes('DEV')
              ? 'DEV'
              : currentUserRoles.includes('ADMIN')
              ? 'ADMIN'
              : currentUserRoles.includes('PRESENTER')
              ? 'PRESENTER'
              : 'VIEWER'}{' '}
            PANEL
          </Menu.Item>
          {menuItems.map(({ text, href, icon }) => (
            <Menu.Item active={href === location.pathname} as={Link} key={text} to={href}>
              <Icon name={icon} />
              {text}
            </Menu.Item>
          ))}
        </Sidebar>

        <Sidebar.Pusher as={StyledPusher} visible={true} style={{ minHeight: '100vh', marginTop: '-2px' }}>
          <Segment basic style={{ maxWidth: '100%', paddingBottom: 0 }}>
            <Grid>
              <Grid.Column style={{ marginLeft: 10 }} floated="left" width="3">
                <AsobiImage src={asobi} alt="" />
              </Grid.Column>
              <Grid.Column width="9" floated="right" textAlign="right" verticalAlign="middle">
                <LogoElement />
              </Grid.Column>
            </Grid>
          </Segment>
          <Segment basic style={{ maxWidth: '100%', marginTop: 0, paddingTop: 0 }}>
            {children}
          </Segment>
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    </div>
  );
}

export default Layout;
