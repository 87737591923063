import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { usePresenterActions } from '../../../utils/use-presenter-actions';
import { Timer } from 'libs/steps/src/lib/generic/timer/timer';
import { UserCounter } from '../../../components/reused-components/user-counter';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .label,
  .value {
    font-family: Klavika !important;
    color: #114a94 !important;
  }
`;

const WidgetsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

const ItemWrapper = styled.span`
  min-width: 140px;
  min-height: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = ({ data }) => {
  const { t } = useTranslation();

  const { actions } = usePresenterActions(data);

  return (
    <Wrapper>
      <h1>{t('fishery.selectStrategy')}</h1>
      <WidgetsWrapper>
        <ItemWrapper>
          <UserCounter actions={actions} />
        </ItemWrapper>
        <ItemWrapper>
          <Timer autoStart data={data} presentation />
        </ItemWrapper>
      </WidgetsWrapper>
    </Wrapper>
  );
};
