import React, { useEffect, useState } from 'react';
import { Card, RangeGroup } from '@disco/ui';
import firebase from 'firebase/app';
import {
  QuestionRangeStep,
  SessionPublicData,
  SESSION_COLLECTION,
  STEP_SUBCOLLECTION,
  ACTION_SUBCOLLECTION,
} from '@disco/data';
import { useCookies } from '@disco/hooks';
import { useTranslation } from 'react-i18next';
import Page from '../components/page';
import styled from 'styled-components';
import { useApi } from '../../generic/hooks/use-api';
import { QuestionRangeCharts } from '../components/charts/question-range-charts';
import { CustomHeader } from '../components/custom-header';

export interface QuestionRangeProps {
  presentation: boolean;
  fetching: boolean;
  uid: string;
  data: SessionPublicData;
  timer?: boolean;
  seconds?: number;
}

const FlexWrapper = styled.div`
  display: flex;
`;

export function QuestionRange({ uid, data, presentation, fetching = true }: QuestionRangeProps) {
  const { t } = useTranslation();
  const { getSessionCookie } = useCookies();

  const callApi = useApi();

  const currentStep = data?.currentStep as QuestionRangeStep;

  const [actions, setActions] = useState([]);
  const [optionSubmitted, setOptionSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const postAction = async (selectedOptions: { [option: string]: number }) => {
    setSubmitting(true);
    Object.entries(selectedOptions).forEach(async ([option, value]) => {
      const actionData = { option, value, groupName: getSessionCookie().groupName };
      const resultAction = await callApi('/steps/action', {
        method: 'POST',
        body: { data: actionData, userId: uid, sessionId: data.sessionId, stepId: data.currentStep.id },
      });
      setOptionSubmitted(true);
      console.log('action result', resultAction);
    });
    setSubmitting(false);
  };

  useEffect(() => {
    setOptionSubmitted(false);
    setSubmitting(false);
  }, [currentStep.id]);

  useEffect(() => {
    if (presentation && fetching) {
      return firebase
        .firestore()
        .collection(SESSION_COLLECTION)
        .doc(data.sessionId)
        .collection(STEP_SUBCOLLECTION)
        .doc(String(data.currentStep.id))
        .collection(ACTION_SUBCOLLECTION)
        .onSnapshot((snapshot) => {
          const list = [];
          snapshot.forEach((doc) => list.push(doc.data()));
          setActions(list);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { maxText, minText, options } = currentStep;

  return (
    <Page {...{ data, presentation }} alignContent={presentation ? 'top' : 'middle'}>
      {presentation ? (
        <>
          <CustomHeader actions={actions} currentStep={currentStep} prop={'question'} />
          <FlexWrapper>
            <div style={{ minHeight: '60vh', minWidth: '100%', display: 'grid', marginTop: 32, position: 'relative' }}>
              <QuestionRangeCharts
                style={currentStep.style}
                options={options}
                actions={actions}
                maxText={maxText}
                minText={minText}
              />
            </div>
          </FlexWrapper>
        </>
      ) : (
        <Card color={currentStep?.color} title={optionSubmitted ? t('question.submitted') : currentStep?.question}>
          {!optionSubmitted && (
            <RangeGroup style={currentStep.style} submitting={submitting} options={options} postAction={postAction} />
          )}
        </Card>
      )}
    </Page>
  );
}

export default QuestionRange;
