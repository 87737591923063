import { useApi } from '../generic/hooks/use-api';

export const useSelectGroup = () => {
  const callApi = useApi();

  const validateGroup = async (sessionId, groupName) => {
    return await callApi('/steps/group/validate', {
      method: 'POST',
      body: { sessionId: sessionId, groupName },
    });
  };

  const pickGroupNickname = async (body) => {
    return await callApi('/steps/group/nickname', {
      method: 'POST',
      body,
    });
  };

  return { validateGroup, pickGroupNickname };
};
