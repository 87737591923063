import { GroupData } from './group';

export interface GameData {
  id?: string;
  name: string;
  language?: string;
  playerInterface?: any;
  style?: string;
  baseFontColor?: string;
  defaultOpacity?: number;
  baseBackgroundImage?: string;
  baseBackgroundColor?: string;
  containBackground?: boolean;
  steps?: any[];
  groups?: GroupData[];
  scaleView?: boolean;
}

export enum StepType {
  SELECT_GROUP = 'select-group',
  QUESTION = 'question',
  PRESENTATION = 'presentation-page',
}
