import { companyColor } from '@disco/data';
import React, { useMemo } from 'react';
import { Grid } from 'semantic-ui-react';
import { SemanticVERTICALALIGNMENTS } from 'semantic-ui-react/dist/commonjs/generic';
import styled, { ThemeProvider } from 'styled-components';
import { Logo } from '../logo/logo';

export interface PageProps {
  children: React.ReactNode;
  backgroundImage?: string;
  backgroundColor?: string;
  color?: string;
  opacity?: number;
  align?: string;
  marginTop?: number;
  padding?: string;
  sessionCode?: string;
  showLogoBanner?: boolean;
  logoAlwaysVisible?: boolean;
  presentation?: boolean;
  contain?: boolean;
  style?: string;
  noWrap?: boolean;
}

const StyledGrid = styled.div<{
  backgroundColor: string;
  backgroundImage: string;
  opacity: number;
  padding: string;
  presentation: boolean;
  contain: boolean;
}>`
  &&& {
    margin: 0;
    width: 100%;
    padding: ${(props) => (props.padding ? props.padding : '4em')};
    position: relative;
    min-height: 100vh;
    background-color: ${(props) => props.backgroundColor};
    ${(props) => props.presentation && 'max-height: 100vh;'}
    ${(props) => props.presentation && 'overflow: hidden;'}
    ::before {
      content: '';
      background-image: url(${(props) => props.backgroundImage});
      opacity: ${(props) => props.opacity || 0.25};
      background-repeat: no-repeat;
      background-size: ${(props) => (props.contain ? 'contain' : 'cover')};
      background-position: ${(props) => (props.contain ? 'center' : 'center top')};
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
    }
    @media screen and (max-width: 1000px) {
      padding: 1em;
    }
  }
`;

const StyledContent = styled.div<{ fontColor: string }>`
  &&& {
    width: 100% !important;
    min-height: 100%;
    box-sizing: border-box;
    position: relative;
    padding: 32px 0 0 0 !important;

    @media screen and (max-width: 1000px) {
      padding: 0 !important;
    }

    div,
    h1,
    h2,
    label {
      color: ${(props) => props.fontColor} !important;
    }

    input:focus,
    textarea:focus {
      border-color: ${companyColor.violet} !important;
    }
  }
`;

export function Page({
  backgroundImage,
  color,
  backgroundColor,
  opacity,
  children,
  align,
  sessionCode,
  padding,
  showLogoBanner,
  logoAlwaysVisible,
  presentation,
  contain,
  style,
  noWrap = false,
}: PageProps) {
  const isChristmas = style === 'christmas';
  const isSanta = style === 'santa';
  const isChronos = style === 'chronos';
  const Wrapper = useMemo(
    () => ((isSanta || presentation) && !noWrap && styledWrappers[style]) || (({ children }) => <>{children}</>),
    [style]
  );

  return (
    <ThemeProvider theme={themes[style] || {}}>
      <Grid
        as={StyledGrid}
        opacity={opacity}
        textAlign="center"
        backgroundImage={(isChronos || isSanta || presentation) && backgroundImage}
        backgroundColor={isChristmas && !presentation ? '#DCD7CD' : backgroundColor}
        verticalAlign={(align as SemanticVERTICALALIGNMENTS) || 'middle'}
        padding={padding}
        contain={contain}
        presentation={presentation}
      >
        <Logo
          alwaysVisible={logoAlwaysVisible}
          sessionCode={sessionCode}
          showLogoBanner={showLogoBanner}
          style={style}
        />
        <Grid.Column as={StyledContent} fontColor={color}>
          <Wrapper>{children}</Wrapper>
        </Grid.Column>
      </Grid>
    </ThemeProvider>
  );
}

const christmasTheme = {
  buttonColor: '#e13636',
  titleLineHeight: '100px',
};

const themes = {
  christmas: christmasTheme,
};

const ChristmasWrapper = ({ children }) => (
  <Wrapper>
    <InnerWrapper>{children}</InnerWrapper>
  </Wrapper>
);

const ChronosWrapper = styled.div`
  .ui.header {
    font-family: Aleo;
  }
  body,
  div,
  p,
  span,
  h1,
  h2,
  h3,
  a {
    font-family: Aleo;
  }
`;

const ChronosSantaWrapper = styled.div`
  .ui.header,
  .ui.statistic > .label,
  .ui.statistic > .value,
  body,
  div,
  p,
  span,
  label,
  h1,
  h2,
  h3,
  a {
    font-family: Aleo;
    color: white !important;
    text-shadow: 2px 2px 20px rgba(0, 0, 0, 0.8), 2px 2px 10px #000000e3;
  }

  max-height: calc(94vh - 2 * 2em);
  overflow-y: auto;
  overflow-x: hidden;
`;

export const styledWrappers = {
  christmas: ChristmasWrapper,
  chronos: ChronosWrapper,
  santa: ChronosSantaWrapper,
};

const Wrapper = styled.div`
  .ui.header,
  .christmas-subtitle {
    font-family: lucien;
    font-size: 7em !important;
    text-transform: none !important;
    font-weight: normal !important;
  }
  .ui.cards {
    margin: 0;
  }
  body,
  div,
  p,
  span,
  h1,
  h2,
  h3,
  a {
    font-family: Exo;
  }
  .ui.card {
    border: none !important;
    background: none !important;
    box-shadow: none !important;

    .image {
      background: none !important;
    }
  }
  margin: 7em auto;
  max-width: 135vh;
  max-height: calc(94vh - 2 * 7em);
  overflow-y: auto;
  overflow-x: hidden;
`;

const InnerWrapper = styled.div`
  zoom: 0.85;
  position: relative;
`;

export default Page;
