import styled from 'styled-components';
import { companyColor } from '@disco/data';

export const ChartWrapper = styled.div<{ fontColor?: string }>`
  &&& {
    width: 100% !important;
    min-height: 100%;
    box-sizing: border-box;
    position: relative;
    padding: 32px 16px;

    @media screen and (max-width: 1000px) {
      padding: 0 !important;
    }

    div,
    h1,
    h2,
    label {
      color: ${(props) => props.fontColor} !important;
    }

    input:focus,
    textarea:focus {
      border-color: ${companyColor.violet} !important;
    }
  }
`;

export const FlexWrapper = styled.div`
  display: flex;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 32px;
`;

export const Legend = styled.div`
  &&& {
    margin-left: 32px;
    text-align: left;
    font-size: 24px;
    line-height: 26px;
    display: inline-block;
  }
`;
