import React from 'react';

import { Wrapper } from '../../../components/Wrapper';
import { Interface } from '../../../components/player-interface/Interface';
import { useUserData } from '../../../utils/update-user';
import { Content } from './Content';
import styled from 'styled-components';
import { Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

export const PlayerInterface = ({ data, uid, context, ...props }) => {
  const { t } = useTranslation();
  const userData = useUserData(data.sessionId, uid);

  const isDynamiteHolder = data?.contextData?.dynamiteUserId === uid;
  return (
    <Wrapper>
      <Interface
        sessionData={data}
        data={userData}
        content={<Content data={data} context={context} />}
        footer={
          isDynamiteHolder ? (
            <DynamiteImage>
              <Box>
                <Icon name="bomb" />
                <p>{t('fishery.redXmultiplier')}</p>
              </Box>
            </DynamiteImage>
          ) : null
        }
      />
    </Wrapper>
  );
};
const Box = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const DynamiteImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
  margin: 2rem;

  i {
    font-size: 4rem;
  }
`;
