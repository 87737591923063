import * as React from 'react';
import styled from 'styled-components';
import { Header } from 'semantic-ui-react';

const StyledTitle = styled.h1<{ fontColor: string; background: string; align: string }>`
  &&& {
    font-size: 74px;
    line-height: ${(props) => (props?.theme?.titleLineHeight ? props.theme.titleLineHeight : '80px')};
    color: ${(props) => props.fontColor};
    text-align: ${(props) => props.align};
    text-transform: uppercase;
    background: ${(props) => props.background};
    padding: 16px ${(props) => (props.background ? 16 : 0)}px;
    margin: 0;
    white-space: pre-wrap;
    display: inline-block;

    @media screen and (max-width: 1000px) {
      font-size: 28px;
      line-height: 34px;
    }
  }
`;

interface TitleProps {
  children: React.ReactNode;
  fontColor?: string;
  background?: string;
  align?: string;
}

export const Title = ({ align, children, fontColor, background }: TitleProps) => (
  <Header as={StyledTitle} background={background} fontColor={fontColor} align={align}>
    {children}
  </Header>
);
