import React from 'react';
import styled from 'styled-components';
import { useEffect, useRef, useState } from 'react';

const Wrapper = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: black;
`;

export function ScaleWrapper({ scaleView, children }) {
  const wrapperRef = useRef(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    function handleResize() {
      if (wrapperRef.current) {
        const { width, height } = wrapperRef.current.getBoundingClientRect();
        const scaleWidth = width / 1280; // Adjust with the natural width of your content
        const scaleHeight = height / 720; // Adjust with the natural height of your content
        if (scaleWidth < scaleHeight) {
          setDimensions({ width, height: width * 0.5625 }); // 0.5625 is 9/16, adjust according to the aspect ratio
        } else {
          setDimensions({ width: height * 1.777777778, height }); // 1.777777778 is 16/9, adjust according to the aspect ratio
        }
      }
    }

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [scaleView]);

  return scaleView ? (
    <Wrapper className="App" ref={wrapperRef}>
      <div
        style={{ backgroundColor: 'white', width: dimensions.width, height: dimensions.height, transformOrigin: '0 0' }}
      >
        {children}
      </div>
    </Wrapper>
  ) : (
    children
  );
}
