import React, { useEffect, useState } from 'react';
import { Page as BasePage } from '@disco/ui';

interface PageProps {
  data: any;
  presentation: any;
  children: any;
  alignContent?: string;
  padding?: string;
  noWrap?: boolean;
}

export function Page({ data, presentation, children, alignContent = '', padding = '', noWrap }: PageProps) {
  const { sessionCode } = data;
  const {
    backgroundImage,
    backgroundColor,
    color,
    opacity,
    align,
    showLogoBanner,
    containBackground,
    style,
  } = data.currentStep;

  const showBackground = style === 'chronos';

  const [backgroundUrl, setBackgroundUrl] = useState(
    (showBackground || presentation) && data?.resources[backgroundImage]
  );

  useEffect(() => {
    setBackgroundUrl(
      (showBackground || presentation || data?.currentStep?.alwaysVisible || style === 'santa') &&
        data?.resources[backgroundImage]
    );
  }, [data, backgroundImage]);

  return (
    <BasePage
      color={color}
      style={style}
      contain={containBackground}
      align={alignContent || align}
      backgroundColor={backgroundColor}
      opacity={opacity}
      backgroundImage={backgroundUrl}
      padding={padding}
      sessionCode={sessionCode}
      showLogoBanner={showLogoBanner}
      presentation={presentation}
      noWrap={noWrap}
    >
      {children}
    </BasePage>
  );
}

export default Page;
