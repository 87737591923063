import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import { Colors } from '../../constants';
import { SelectedInventoryItems } from '../selected-inventory-items';

const Inventory = styled.div`
  --padding: 32px;
  z-index: 100;
  overflow-y: auto;
  position: fixed;
  top: 0;
  left: 0;
  margin: var(--padding);
  background: ${(props) => props.theme.inventoryBackground || '#f6f0e4'};
  border: 2px solid ${(props) => props.theme.borderColor || Colors.BROWN};
  width: calc(100% - 2 * var(--padding));
  height: calc(100% - 2 * var(--padding));
`;

const IconWrapper = styled.div`
  &&& {
    width: 100%;
    display: flex;
    margin-top: 8px;
    margin-right: 4px;
    justify-content: flex-end;
    i.icon {
      color: ${(props) => props.theme.borderColor || Colors.BROWN} !important;
    }
  }
`;

export const InventoryButton = ({ disableItems, inventoryItems, loading, getImg, isSanta }) => {
  const { t } = useTranslation();
  const [inventoryOpen, setInventoryOpen] = useState(false);

  return (
    <>
      <Button disabled={disableItems} onClick={() => setInventoryOpen(true)} compact size="small">
        {isSanta ? t('chronos.itemsAndHints') : t('chronos.items')}
      </Button>
      {inventoryOpen && (
        <Inventory>
          <IconWrapper>
            <Icon size="big" name="close icon" onClick={() => setInventoryOpen(false)} />
          </IconWrapper>
          <SelectedInventoryItems getImg={getImg} inventoryItems={inventoryItems} loading={loading} isSanta={isSanta} />
        </Inventory>
      )}
    </>
  );
};
