import { PuzzleItem, PuzzleItemsWrapper } from './puzzle-item';
import React from 'react';

interface UseCountingResultsDisplayProps {
  countingResults: any;
  countingResultsLoading: boolean;
  getImg: any;
  answers: any;
  letters: string[];
  smallItem?: boolean;
  children?: any;
}

export const PuzzleItems = ({
  countingResults,
  countingResultsLoading,
  getImg,
  answers,
  letters,
  smallItem = false,
  children,
}: UseCountingResultsDisplayProps) => {
  const getCountingResultOption = (option) => (countingResultsLoading ? '?' : countingResults?.options[option] || 0);

  return (
    <PuzzleItemsWrapper>
      {letters?.map((letter) => (
        <PuzzleItem
          key={letter}
          {...{ letter, getImg, answers, selectionCount: getCountingResultOption(letter) }}
          smallItem={smallItem}
        />
      ))}
      {children}
    </PuzzleItemsWrapper>
  );
};
