export const ChronosApiEndpoints = {
  postCollectItemsCounting: '/chronos-game/collect-items/',
  postAnswerCounting: '/chronos-game/count-answers/',
  getSolution: '/chronos-game/solution/',
  getInventory: '/chronos-game/inventory',
  getRoundSummary: '/chronos-game/round-summary',
  postChronosFinalAnswer: '/chronos-game/final',
  postChronosCheckFinalAnswer: '/chronos-game/check/final',
  postSantaAnswerCounting: '/chronos-christmas-game/count-answers/',
  getSantaInventory: '/chronos-christmas-game/inventory/',
  postSantaFinal: '/chronos-christmas-game/final/',
  getSantaFinal: '/chronos-christmas-game/final-solution/',
};
