import React from 'react';
import { slideWrapper } from '../components/slide';
import { Content } from '../components/content';
import styled from 'styled-components';
import { useAnimationSteps } from '@disco/hooks';
import { Timer } from '../components/timer';
import { PuzzleItem, PuzzleItemsWrapper } from '../components/puzzle-item';
import { useConfirmation } from '../../generic/hooks/use-confirmation';

const TextImage = styled.img<any>`
  position: absolute;
  left: 2%;
  top: ${(props) => (props.show ? '100px' : '-400px')};
  width: 450px;
  transition: top 1s;
`;

export const HypatiaPuzzle1 = slideWrapper(({ data, step, getImg }) => {
  const { answers } = step;

  const animationStep = useAnimationSteps(1, true);

  const ConfirmationPopup = useConfirmation(1);

  return (
    <>
      <ConfirmationPopup />
      <TextImage show={animationStep > 0} src={getImg('text1')} />
      <Content xNotCenter yNotCenter left={1} bottom={1}>
        <img height={200} src={getImg('things1')} />
      </Content>
      <Timer data={data} presentation />
      <PuzzleItemsWrapper>
        <PuzzleItem getImg={getImg} letter="a" answers={answers} />
        <PuzzleItem getImg={getImg} letter="b" answers={answers} />
        <PuzzleItem getImg={getImg} letter="c" answers={answers} />
      </PuzzleItemsWrapper>
    </>
  );
});
