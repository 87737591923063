import styled from 'styled-components';

export const SlideImage = styled.img<any>`
  opacity: ${(props) => (props.show ? 1 : 0)};
  width: ${(props) => props.width};
  transform: rotate(${(props) => props.rotate});
  height: auto;

  transition: all 0.5s;
`;
