import React, { useEffect, useState } from 'react';

import { Wrapper } from '../../../components/Wrapper';
import { Interface } from '../../../components/player-interface/Interface';
import { getUserScore, useUserData } from '../../../utils/update-user';
import {
  BlueStrategyBox,
  GreenStrategyBox,
  NoneStrategyBox,
  OrangeStrategyBox,
  RedStrategyBox,
  YellowStrategyBox,
} from '../../../components/player-interface/StrategyBox';
import { Button } from '../../../components/player-interface/Button';
import styled from 'styled-components';
import { colorConfig } from '../../../config/colors';
import { useTranslation } from 'react-i18next';

const StrategyBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
  padding: 0 16px;
  max-width: 400px;
  align-items: center;
  font-size: 1.4rem;
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

const Score = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const PlayerInterface = ({ data, uid }) => {
  const { t } = useTranslation();

  const [selected, setSelected] = useState<string>();

  const userData = useUserData(data.sessionId, uid);
  const userScore = getUserScore(userData, data.currentStep);

  useEffect(() => {
    if (userScore?.strategy) setSelected(userScore.strategy);
  }, [userScore]);

  const strategies = {
    green: <GreenStrategyBox data={data} />,
    red: <RedStrategyBox data={data} />,
    blue: <BlueStrategyBox data={data} />,
    yellow: <YellowStrategyBox data={data} />,
    orange: <OrangeStrategyBox data={data} />,
    none: <NoneStrategyBox />,
  };

  return (
    <Wrapper>
      <Interface
        sessionData={data}
        data={userData}
        content={
          <StrategyBox>
            <ItemWrapper>
              <div>{t('fishery.roundResultSelectedStrategy')}:</div>
              {strategies[selected]}
            </ItemWrapper>
            <ItemWrapper>
              <div>{t('fishery.roundResultYourResult')}:</div>
              <Button bgColor={colorConfig.blue.light} fontColor={colorConfig.blue.dark} selected={false}>
                <Score>{t('fishery.roundResultYourResultScore', { score: userScore?.points })}</Score>
              </Button>
            </ItemWrapper>
          </StrategyBox>
        }
      />
    </Wrapper>
  );
};
