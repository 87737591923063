import React from 'react';
import { slideWrapper } from '../components/slide';
import { Content } from '../components/content';
import styled from 'styled-components';
import { Text } from '../components/text';
import { useAnimationSteps } from '@disco/hooks';
import { Colors } from '../constants';
import { PuzzleItem, PuzzleItemsWrapper } from '../components/puzzle-item';

const Box = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0;
`;

const TextWrapper = styled.div`
  margin: 0 60px;
  width: 700px;

  span {
    color: ${Colors.BLUE};
  }
`;

const TimeWrapper = styled.div<any>`
  position: relative;
  ::after {
    content: '';
    background-image: url(${(props) => props.time});
    background-size: cover;
    width: 60px;
    height: 60px;
    bottom: -5px;
    right: -25px;
    position: absolute;
  }
`;

export const HypatiaPuzzle2Solution = slideWrapper(({ step, getImg }) => {
  const { answers } = step;

  const animationStep = useAnimationSteps(1, true);

  return (
    <>
      <PuzzleItemsWrapper>
        <PuzzleItem getImg={getImg} letter="a" answers={answers} />
        <PuzzleItem getImg={getImg} letter="b" answers={answers} />
        <PuzzleItem getImg={getImg} letter="c" answers={answers} />
      </PuzzleItemsWrapper>
    </>
  );
});
