import React, { useState } from 'react';
import { Form } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import ReactSlider from 'react-slider';
import styled from 'styled-components';
import { companyColor } from '@disco/data';
import { GameButton } from '../../../../steps/src/lib/collectors/components/game-button';
import { MAX_RANGE_VOTE } from '../../../../steps/src/lib/collectors/components/charts/presentation/line-area-chart';

export interface RangeGroupProps {
  postAction: (string) => void;
  options: string[];
  style: string;
  submitting?: boolean;
}

const StyledSlider = styled(ReactSlider)`
  &&& {
    width: 100%;
    height: 25px;
    margin-bottom: 16px;
    div {
      color: #fff !important;
    }
  }
`;

const StyledThumb = styled.div`
  &&& {
    height: 25px;
    line-height: 25px;
    width: 25px;
    text-align: center;
    background-color: ${(props) => (props.theme.buttonColor ? props.theme.buttonColor : companyColor.violet)};
    color: #fff !important;
    border-radius: 4px;
    cursor: grab;
  }
`;

const Thumb = (props, state) => <StyledThumb {...props}>{state.valueNow}</StyledThumb>;

const StyledTrack = styled.div`
  top: 0;
  bottom: 0;
  background: ${(props) => (props.theme.buttonColor ? 'rgb(240, 117, 117)' : '#623688e1')};
  border-radius: 4px;
`;

const Track = (props, state) => <StyledTrack {...props} index={state.index} />;

const ButtonWrapper = styled.div`
  margin-top: 32px;
`;

export function RangeGroup({ postAction, options = [], submitting = false }: RangeGroupProps) {
  const { t } = useTranslation();
  const [values, setValues] = useState(
    options.reduce((prev, option) => {
      prev[option] = 3;
      return prev;
    }, {})
  );

  return (
    <Form onSubmit={() => postAction(values)}>
      {options.map((option, index) => (
        <Form.Field key={index}>
          <label>{option}</label>
          <StyledSlider
            min={0}
            max={MAX_RANGE_VOTE}
            value={values[option]}
            renderTrack={Track}
            renderThumb={Thumb}
            onChange={(value) => setValues((values) => ({ ...values, [option]: value }))}
          />
        </Form.Field>
      ))}
      <ButtonWrapper>
        <GameButton loading={submitting} fluid type="submit">
          {t('send')}
        </GameButton>
      </ButtonWrapper>
    </Form>
  );
}

export default RangeGroup;
