import React from 'react';
import { Colors } from '../constants';
import styled from 'styled-components';
import { Text } from './text';
import { Content } from './content';
import { useTranslation } from 'react-i18next';

interface SantaPresenterSolutionProps {
  countingResults: any;
  countingResultsLoading: boolean;
  getImg: any;
  answers: any;
  letters: string[];
}

export const ItemsWrapper = styled(Content).attrs({ width: '80vw', top: 50, justify: 'center', align: 'center' })``;

export const SantaPresenterSolution = ({
  countingResults,
  countingResultsLoading,
  getImg,
  answers,
  letters,
}: SantaPresenterSolutionProps) => {
  const { t } = useTranslation();
  const getCountingResultOption = (option) => (countingResultsLoading ? '?' : countingResults?.options[option] || 0);

  return (
    <ItemsWrapper>
      <Text big alignCenter>
        {t('chronos.selectedSolution')}
      </Text>
      <Solutions>
        {letters?.map((letter) => (
          <Box key={letter}>
            <SolutionWrapper>
              {getCountingResultOption(letter) !== undefined && (
                <Count>
                  <Text color={Colors.WHITEISH}>{getCountingResultOption(letter)}</Text>
                </Count>
              )}
              <img height={100} src={getImg(letter)} />
            </SolutionWrapper>
          </Box>
        ))}
      </Solutions>
    </ItemsWrapper>
  );
};

const Solutions = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-top: 48px;
`;

const Count = styled.div`
  position: absolute;
  background-color: ${(props) => props.theme.countColor || Colors.BROWN};
  padding: 20px 16px 16px 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  top: -5px;
  left: -20px;
  box-shadow: 0 0 0 2px ${(props) => props.theme.countColor || Colors.BROWN};
  border: 1px solid ${Colors.WHITEISH};
`;

const Box = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0;
  height: 130px;
`;

const SolutionWrapper = styled.div<any>`
  position: relative;
`;
