import React from 'react';
import { slideWrapper } from '../components/slide';
import { Content } from '../components/content';
import styled from 'styled-components';
import { Text } from '../components/text';
import { TextBox } from '../components/text-box';
import { Colors } from '../constants';
import { Timer } from '../components/timer';
import { useConfirmation } from '../../generic/hooks/use-confirmation';

const TextWrapper = styled.div`
  margin-top: 40px;

  span {
    color: ${Colors.BLUE};
  }
`;

const ItemsWrapper = styled.div`
  width: 100%;
  padding: 40px 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: 64px;
  justify-content: space-evenly;
  font-weight: bold;

  background-image: linear-gradient(
    45deg,
    #665b5022 15%,
    #00000000 15%,
    #00000000 50%,
    #665b5022 50%,
    #665b5022 65%,
    #00000000 65%,
    #00000000 100%
  );
  background-size: 14.14px 14.14px;
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  width: 30%;

  img {
    width: 150px;
    height: auto;
    margin-right: 16px;
  }
`;

export const TeslaItems = slideWrapper(({ data, step, resources, getImg, ...props }) => {
  const { title, subtitle, text, items } = step;

  const ConfirmationPopup = useConfirmation(1);

  return (
    <>
      <ConfirmationPopup />
      <Content width="70%" yNotCenter top={7} left={50}>
        <TextBox>{title}</TextBox>
        <TextWrapper>
          <Text alignCenter gigant>
            {subtitle}
          </Text>
        </TextWrapper>
        <TextWrapper>
          <Text alignCenter big>
            {text}
          </Text>
        </TextWrapper>
      </Content>
      <Timer data={data} presentation />
      <Content width="100%" bottom="0" yNotCenter>
        <ItemsWrapper>
          {Object.entries(items).map(([key, text]) => (
            <Item key={key}>
              <img alt={key} src={getImg(key)} />
              <Text big color={Colors.BROWN}>
                {text}
              </Text>
            </Item>
          ))}
        </ItemsWrapper>
      </Content>
    </>
  );
});
