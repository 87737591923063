import { useCookies } from '@disco/hooks';
import { Text } from './text';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Group } from './user-counter';

const TeamWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: bold;
`;

const TeamColor = styled.span<{ color: string }>`
  width: 40px;
  height: 40px;
  margin-bottom: 5px;
  margin-right: 8px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
`;

const GroupWrapper = styled.div`
  margin-left: 32px;
  display: flex;
  align-items: center;
`;

const TeamElementWrapper = styled.div`
  display: flex;
  margin-bottom: 12px;
`;

export const Team = ({ score }) => {
  const { getSessionCookie } = useCookies();
  const [teamName, setTeamName] = useState('');
  const [teamSize, setTeamSize] = useState('');
  const [color, setColor] = useState();

  useEffect(() => {
    const { groupName } = getSessionCookie();
    setTeamName(groupName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const myTeam = score?.groups?.find((group) => group.name === teamName);
    setColor(myTeam?.color);
    setTeamSize(myTeam?.count);
  }, [score?.groups, teamName]);

  return (
    <TeamElementWrapper>
      <TeamWrapper>
        <TeamColor color={color} /> <Text>{teamName}</Text>
      </TeamWrapper>
      <GroupWrapper>
        <Group users={teamSize} />
      </GroupWrapper>
    </TeamElementWrapper>
  );
};
