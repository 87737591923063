import React from 'react';
import { slideWrapper } from '../components/slide';
import { Content } from '../components/content';
import styled from 'styled-components';
import { Text } from '../components/text';
import { SlideImage } from '../components/slide-image';
import { useAnimationSteps } from '@disco/hooks';

const SlideText = styled.div`
  font-size: 60px;
  margin: 0 20px;
`;

export const LeonardoPuzzle1Story = slideWrapper(({ step, resources, getImg, ...props }) => {
  const { text } = step;

  const animationStep = useAnimationSteps(1, true);

  return (
    <>
      <Content x={150} top={12} yNotCenter>
        <SlideImage width="500px" src={getImg('leos')} show={animationStep > 0} />
      </Content>
      <Content x={-250} top={12} yNotCenter>
        <SlideImage width="470px" src={getImg('dickens')} show={animationStep > 0} />
      </Content>
      <Content width="80%" yNotCenter bottom={10}>
        <SlideText>
          <Text big alignCenter>
            {text}
          </Text>
        </SlideText>
      </Content>
    </>
  );
});
