import { toast as toastifyToast, ToastOptions } from 'react-toastify';

export enum ToastType {
  Success = 'success',
  Info = 'info',
  Warning = 'warning',
  Error = 'error',
}

export const emitToast = (
  {
    title,
    subtitle
  }: {
    title: string;
    subtitle?: string;
  },
  type: ToastType,
  config: ToastOptions = {
    position: 'top-center',
    autoClose: 4500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
  }
) => {
  switch (type) {
    case ToastType.Error:
      return toastifyToast.error(title, config);
    case ToastType.Success:
      return toastifyToast.success(title, config);
  }
};
