import React, { useEffect } from 'react';
import { useApi } from '@disco/hooks';
import { PlayerInterface } from './PlayerInterface';
import { InterfaceWrapper } from '../../components/InterfaceWrapper';
import { Wrapper } from '../../components/Wrapper';
import { LoadingAnimation } from '../../components/LoadingAnimation';
import { useRoundLifecycle } from '../../utils/use-round-lifecycle';

export const GenerateNextRound = InterfaceWrapper({
  Interface: PlayerInterface,
  Step: ({ data, ...rest }) => {
    const callApi = useApi();

    const { roundStart } = useRoundLifecycle();

    useEffect(() => {
      let generate = rest?.fetching !== false;

      const requestGeneration = async () => {
        if (generate) {
          await callApi('/session/generate-steps', {
            method: 'POST',
            body: { sessionId: data.sessionId },
          });
          await roundStart(data);
        }
      };

      setTimeout(requestGeneration, 1500);
      return () => {
        generate = false;
      };
    }, []);

    return (
      <Wrapper>
        <LoadingAnimation />
      </Wrapper>
    );
  },
});
