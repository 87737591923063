import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Content } from './components/content';
import { playerInterfaceWrapper } from './player-interface';
import { Text } from '../components/text';
import { useSolution } from './hooks';
import styled from 'styled-components';
import { Colors } from '../constants';
import { Loader } from 'semantic-ui-react';

const OptionWrapper = styled.div<any>`
  display: flex;
  gap: 16px;
  padding: 16px;
  border: 2px solid ${(props) => props.theme.borderColor || Colors.BROWN};
`;

export const PresentSelectedSanta = playerInterfaceWrapper(({ data, getImg }) => {
  const { t } = useTranslation();
  const { scoring, currentStep } = data;
  const { answers } = currentStep;

  const { selectedOption, loading } = useSolution(scoring);

  const Option = useCallback(
    ({ answer, option }) => {
      return option ? (
        <OptionWrapper>
          <img alt={`option-${answer}`} height={70} src={getImg(answer)} />
          <Text small>{(option as any)?.text}</Text>
        </OptionWrapper>
      ) : null;
    },
    [getImg]
  );

  if (loading) return <Loader inverted active />;

  if (!selectedOption)
    return (
      <Content>
        <Text alignCenter>{t('chronos.noAnswer')}</Text>
      </Content>
    );

  return (
    <Content>
      <Text alignCenter>{t('chronos.selectedAnswer')}</Text>{' '}
      <Option answer={selectedOption} option={answers[selectedOption]} />
    </Content>
  );
});
