import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from './text';
import Page from './page';

export const PresenterWrapper = ({ data, presentation, children }) => {
  const { t } = useTranslation();

  return presentation || data?.currentStep?.alwaysVisible ? (
    children
  ) : (
    <Page
      presentation={false}
      data={{
        resources: data.resources,
        currentStep: {
          ...data?.currentStep,
          backgroundColor: data?.currentStep?.style === 'christmas' ? '#DCD7CD' : data?.currentStep?.backgroundColor,
        },
      }}
    >
      <Text color="black">{t('steps.waitForQuestion')}</Text>
    </Page>
  );
};
