import React from 'react';

import { Wrapper } from '../../../components/Wrapper';
import { Interface } from '../../../components/player-interface/Interface';
import { useUserData } from '../../../utils/update-user';

export const PlayerInterface = ({ data, uid, ...props }) => {
  const userData = useUserData(data.sessionId, uid);

  return (
    <Wrapper>
      <Interface sessionData={data} data={userData} content={null} />
    </Wrapper>
  );
};
