import React, {useEffect, useState} from 'react';
import { slideWrapper } from '../components/slide';
import { Content } from '../components/content';
import styled from 'styled-components';
import { Text } from '../components/text';
import { useAnimationSteps } from '@disco/hooks';
import { TextLabel } from '../components/text-label';

const DetailImage = styled.img`
  width: 100px;
  height: auto;
  margin-right: 20px;
`;

const TitleWrapper = styled.div<any>`
  opacity: ${(props) => (props.show ? 1 : 0)};
  display: flex;
  align-items: center;
`;

const HypatiaLabel = styled.img<any>`
  width: 200px;
  height: auto;
  opacity: ${(props) => (props.show ? 1 : 0)};
  transition: opacity 0.6s;
`;

const LabelWrapper = styled.div<any>`
  opacity: ${(props) => (props.show ? 1 : 0)};
  transition: opacity 0.6s;
`;

export const HypatiaInformations = slideWrapper(
  ({ step, resources, ...props }) => {
    const { title, info1, info2, info3, info4, info5, imageLabels } = step;

    const animationStep = useAnimationSteps(1);
    const [showRest, setShowRest] = useState(false)

    useEffect(() => {
      if (animationStep === 1 && !showRest) {
        setTimeout(() => {
          setShowRest(true)
        }, 1000)
      }
    }, [animationStep, showRest])

    return (
      <>
        <Content xNotCenter yNotCenter top={10} left={5}>
          <TitleWrapper show={animationStep > 0}>
            <DetailImage src={resources[imageLabels.detail]} />
            <Text big>{title}</Text>
          </TitleWrapper>
        </Content>
        <Content bottom={5} left={20}>
          <HypatiaLabel show={true} src={resources[imageLabels.hypatia]} />
        </Content>
        <Content top={50} y={-200} left={50} x={-300}>
          <LabelWrapper show={showRest}>
            <TextLabel text={info1} xLength={60} yLength={30} right />
          </LabelWrapper>
        </Content>
        <Content top={50} y={-40} left={50} x={-560}>
          <LabelWrapper show={showRest}>
            <TextLabel text={info2} xLength={60} yLength={220} right />
          </LabelWrapper>
        </Content>
        <Content top={50} y={140} left={50} x={-500}>
          <LabelWrapper show={showRest}>
            <TextLabel text={info3} xLength={60} yLength={110} right />
          </LabelWrapper>
        </Content>
        <Content top={50} y={-200} left={50} x={570}>
          <LabelWrapper show={showRest}>
            <TextLabel text={info4} xLength={60} yLength={300} />
          </LabelWrapper>
        </Content>
        <Content top={50} y={-30} left={50} x={590}>
          <LabelWrapper show={showRest}>
            <TextLabel text={info5} xLength={60} yLength={200} />
          </LabelWrapper>
        </Content>
      </>
    );
  },
  { backgroundSize: '100% 100%' }
);
