import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Button } from '../../../components/player-interface/Button';
import { colorConfig } from '../../../config/colors';
import { usePresenterActions } from '../../../utils/use-presenter-actions';
import { getUserActions } from '@disco/utils';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  font-size: 1.4rem;
  min-width: 250px;
`;

const Center = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const Content = ({ data }) => {
  const { t } = useTranslation();

  const { actions = [] } = usePresenterActions(data);

  const users = getUserActions(actions, 1);

  const uniqueActions = Object.values(users).map((actions) => actions[0]);

  const anwser1 = uniqueActions.filter((action) => action.data?.selectedOption === '1').length;
  const anwser2 = uniqueActions.filter((action) => action.data?.selectedOption === '2').length;
  const anwser3 = uniqueActions.filter((action) => action.data?.selectedOption === '3').length;

  const sum = anwser1 + anwser2 + anwser3;

  const getPercent = (value, sum) => (sum ? ((value / sum) * 100).toFixed(0) : 0);

  return (
    <Wrapper>
      <h1>{t('fishery.howAreYouTitle')}</h1>
      <Wrapper>
        <Button selected={false} bgColor={colorConfig.blue.light} fontColor={colorConfig.blue.dark}>
          <Center>
            {t('fishery.howAreYouAnwser1')} - {getPercent(anwser1, sum)}%
          </Center>
        </Button>
        <Button selected={false} bgColor={colorConfig.blue.light} fontColor={colorConfig.blue.dark}>
          <Center>
            {t('fishery.howAreYouAnwser2')} - {getPercent(anwser2, sum)}%
          </Center>
        </Button>
        <Button selected={false} bgColor={colorConfig.blue.light} fontColor={colorConfig.blue.dark}>
          <Center>
            {t('fishery.howAreYouAnwser3')} - {getPercent(anwser3, sum)}%
          </Center>
        </Button>
      </Wrapper>
    </Wrapper>
  );
};
