import React from 'react';
import { slideWrapper } from '../components/slide';
import { PuzzleItem, PuzzleItemsWrapper } from '../components/puzzle-item';

import { Content } from '../components/content';
import { SlideImage } from '../components/slide-image';

export const LeonardoPuzzle3Solution = slideWrapper(({ step, getImg }) => {
  const { answers } = step;

  return (
    <>
      <Content left={14} top={-15} yNotCenter>
        <SlideImage width="400px" src={getImg('element')} show={true} />
      </Content>
      <Content left={13} top={15} yNotCenter>
        <SlideImage width="300px" src={getImg('stojak')} show={true} />
      </Content>
      <PuzzleItemsWrapper>
        <PuzzleItem getImg={getImg} letter="a" answers={answers} />
        <PuzzleItem getImg={getImg} letter="b" answers={answers} />
        <PuzzleItem getImg={getImg} letter="c" answers={answers} />
        <PuzzleItem getImg={getImg} letter="d" answers={answers} />
        <PuzzleItem getImg={getImg} letter="e" answers={answers} />
      </PuzzleItemsWrapper>
    </>
  );
});
