import { Container as SemanticContainer } from 'semantic-ui-react';
import styled from 'styled-components';

interface ContainerProps {
  margin?: string | number;
  padding?: string | number;
  flex?: string;
}

export const Container = styled(SemanticContainer)<ContainerProps>`
  &&& {
    display: ${(props) => props.flex && 'flex'};
    margin: ${(props) => (props.margin ? (typeof props.margin === 'number' ? `${props.margin}px` : props.margin) : 0)};
    padding: ${(props) =>
      props.padding ? (typeof props.padding === 'number' ? `${props.padding}px` : props.padding) : 0};
  }
`;
