import styled from 'styled-components';
import { Button as SButton } from 'semantic-ui-react';
import { Colors } from '../../constants';

export const Button = styled(SButton)`
  &&& {
    &.ui.button {
      width: 100%;
      background-color: ${(props) => props.theme.buttonColor || Colors.MAIN} !important;
      color: white !important;
    }
  }
`;
