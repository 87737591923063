import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from 'semantic-ui-react';

export const Contact = () => {
  const { t } = useTranslation();

  return (
    <Card>
      <Card.Content>
        <Card.Header>{t('settings.companyContact')}</Card.Header>
        <Card.Description>
          PGS Pracownia Gier Szkoleniowych sp.{'\u00a0'}z{'\u00a0'}o.o.
        </Card.Description>
        <Card.Meta>ul. Puławska 39/2</Card.Meta>
        <Card.Meta>02-508 Warszawa</Card.Meta>
        <Card.Description>email: biuro@pracowniagier.com </Card.Description>
        <Card.Description>tel./fax: +48 22 468 85 52 </Card.Description>
      </Card.Content>
    </Card>
  );
};
