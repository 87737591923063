import { useEffect, useState } from 'react';

export const useAnimationSteps = (stepsCount, autoStart = false) => {
  const [currentAnimationStep, setCurrentAnimationStep] = useState(0);

  useEffect(() => {
    if (autoStart) {
      const timeout = setTimeout(() => setCurrentAnimationStep(1), 1000);
      return () => clearTimeout(timeout);
    }
  }, []);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.keyCode === 39 && currentAnimationStep < stepsCount) {
        e.stopPropagation();
        setCurrentAnimationStep((step) => step + 1);
        return;
      }
      if (e.keyCode === 37 && currentAnimationStep > 0) {
        e.stopPropagation();
        setCurrentAnimationStep((step) => step - 1);
        return;
      }
    };
    document.body.addEventListener('keydown', handleKeyDown);
    return () => document.body.removeEventListener('keydown', handleKeyDown);
  }, [currentAnimationStep]);

  return currentAnimationStep;
};
