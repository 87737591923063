import React from 'react';
import { useTranslation } from 'react-i18next';
import { Content } from '../components/content';
import { playerInterfaceWrapper } from './player-interface';
import { Text } from '../components/text';

export const FollowPresentation = playerInterfaceWrapper(() => {
  const { t } = useTranslation();
  return (
    <Content width="80%" top={45}>
      <Text alignCenter>{t('steps.waitForQuestion')}</Text>
    </Content>
  );
});
