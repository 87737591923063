import React, { FormEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, FormProps, Message } from 'semantic-ui-react';
import { Card } from '../card/card';
import { AsobiLogo } from '../logo/asobi';
import Page from '../page/page';
import Text from '../text/text';

export interface LoginProps {
  error: string | boolean;
  setEmail: (data) => void;
  btnLoading: boolean;
  setBtnLoading: (data) => void;
  setPassword: (data) => void;
  onResetPassword: () => void;
  onSubmit: (event: FormEvent<HTMLFormElement>) => void;
}

export function Login({
  onSubmit,
  error,
  btnLoading,
  setBtnLoading,
  setEmail,
  setPassword,
  onResetPassword,
}: LoginProps) {
  const { t } = useTranslation();
  const [resetPassport, setResetPassword] = useState(false);

  const send = (event: FormEvent<HTMLFormElement>) => {
    setBtnLoading(true);
    if (resetPassport) onResetPassword();
    else onSubmit(event);
  };

  useEffect(() => {
    return function clearLoginData() {
      setPassword('');
    };
  }, [setPassword]);

  return (
    <Page logoAlwaysVisible>
      <Card title="" color="#3f0d66">
        <AsobiLogo />
        <Form size="large" onSubmit={send}>
          <Form.Input placeholder={t('login.email')} onChange={(e) => setEmail(e.target.value)} />
          {!resetPassport && (
            <Form.Input
              placeholder={t('login.password')}
              type="password"
              onChange={(e) => setPassword(e.target.value)}
            />
          )}
          {!resetPassport && error && <Message visible error content={error} />}
          {!resetPassport && (
            <Button
              style={{ background: '#3f0d66', color: 'white' }}
              fluid
              size="large"
              loading={btnLoading}
              disabled={btnLoading}
            >
              {t('login.submit')}
            </Button>
          )}
          {resetPassport && (
            <Button style={{ background: '#3f0d66', color: 'white' }} fluid size="large">
              {t('login.resetPassword')}
            </Button>
          )}
          <Text pointer onClick={() => setResetPassword(!resetPassport)} margin="20px" color={'#3f0d66'}>
            {!resetPassport ? t('login.forgotPassword') : t('login.back')}
          </Text>
        </Form>
      </Card>
    </Page>
  );
}

export default Login;
