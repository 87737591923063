export const GROUPS_COLLECTION = 'groups';

//inside sessions
export const SESSION_GROUP_SUBCOLLECTION = 'session-groups';
export const SESSION_GROUP_LEADERS_SUBCOLLECTION = 'session-group-leaders';
export const SESSION_GROUP_ITEMS_SUBCOLLECTION = 'session-group-items';
export const SESSION_GROUP_FINAL_SUBCOLLECTION = 'session-group-final';

//inside sessions-public
export const GROUP_NICKNAMES_SUBCOLLECTION = 'group-nicknames';

export interface SessionGroupData {
  id: string;
  count: number;
  groupNickname?: string;
}

export interface GroupData {
  name: string;
  color: string;
}

export interface Groups {
  [key: string]: GroupData[];
}

export interface SessionGroupLeaderData {
  id: string;
  leaderId?: string;
  groupNickname?: string;
}
