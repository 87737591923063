import React from 'react';
import { slideWrapper } from '../components/slide';
import { Content } from '../components/content';
import styled from 'styled-components';
import { Text } from '../components/text';
import { useAnimationSteps } from '@disco/hooks';
import { ConditionalSoundButton } from '../components/sound-button';

const Image = styled.img`
  width: 100vw;
  height: auto;
`;

const SlideText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-size: 60px;
  margin: 24px 20px;
  z-index: 999;
`;

const Goat = styled.img<any>`
  opacity: ${(props) => (props.show ? 1 : 0)};
  transition: all 0.5s;
`;

export const PostOfficePuzzle2Story = slideWrapper(({ step, fetching, resources, getImg, ...props }) => {
  const { text1, text2 } = step;

  const isGoat = !!getImg('goat');
  const animationStep = useAnimationSteps(isGoat ? 1 : 0, true);

  return (
    <>
      {fetching && <ConditionalSoundButton getImg={getImg} />}
      <Content width="90%" yNotCenter top={0} left={0}>
        <Image src={getImg('image')} />
        <SlideText>
          <Text medium>{text1}</Text>
          <Text medium>{text2}</Text>
        </SlideText>
      </Content>
      {isGoat && (
        <Content width="90%" yNotCenter top={8} left={65}>
          <Goat show={animationStep} height={550} src={getImg('goat')} />
        </Content>
      )}
    </>
  );
});
