import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Select } from 'semantic-ui-react';
import { useCookies } from '@disco/hooks';

export const languages = [
  { key: 'pl', value: 'pl', text: 'Polski' },
  { key: 'en', value: 'en', text: 'English' },
];

export const LanguagePicker = () => {
  const { i18n } = useTranslation();
  const { setLanguageCookie } = useCookies();

  const currentLanguage = languages.find((ele) => ele.value === i18n.language).value || 'pl';

  const onChange = (_, { value }: { value: string }) => {
    i18n.changeLanguage(value);
    setLanguageCookie(value);
  };

  return <Select style={{ width: '100%' }} options={languages} value={currentLanguage} onChange={onChange} />;
};
