import React, { useEffect, useState } from 'react';

import { Wrapper } from '../../../components/Wrapper';
import { Interface } from '../../../components/player-interface/Interface';
import { useUserData } from '../../../utils/update-user';
import styled from 'styled-components';
import { useStepAction } from '../../../../api/use-step-action';
import { colorConfig } from '../../../config/colors';
import { useTranslation } from 'react-i18next';
import { StrategyBox } from '../../../components/player-interface/StrategyBox';
import { Strategy } from '@disco/data';
import { Icon } from 'semantic-ui-react';

const StrategyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  padding: 0 16px;
  max-width: 400px;
`;

export const PlayerInterface = ({ data, uid, ...props }) => {
  const { t } = useTranslation();

  const [selected, setSelected] = useState<string>('');

  const userData = useUserData(data.sessionId, uid);

  const { postAction } = useStepAction();

  const onSelect = (strategy: string) => () => {
    setSelected(strategy);
    postAction({ data: { selectedOption: strategy }, userId: uid, slideData: data });
  };

  const strategies = data.contextData.strategies;

  const isDynamiteHolder = data?.contextData?.dynamiteUserId === uid;

  const getAddonImage = (strategy: string) => {
    if (isDynamiteHolder && strategy === Strategy.red) {
      return <Icon name="bomb" />;
    }
  };

  const strategiesBox = (
    <StrategyWrapper>
      {strategies.map((strategy) => (
        <StrategyBox
          key={strategy}
          title={t(`fishery.strategy${strategy.charAt(0).toUpperCase() + strategy.slice(1)}Title`)}
          color={colorConfig[strategy]}
          selected={selected === strategy}
          onClick={onSelect(strategy)}
          addonImage={getAddonImage(strategy)}
        />
      ))}
    </StrategyWrapper>
  );

  return (
    <Wrapper>
      <Interface sessionData={data} data={userData} content={strategiesBox} />
    </Wrapper>
  );
};
