import { useApi } from '../../generic/hooks/use-api';
import { RoundType } from '@disco/data';
import { ChronosApiEndpoints } from '../../../../../data/src/lib/chronos/api-endpoints';
import { SlideWrapperOptionProps } from '../components/types';
import { useEffect, useState } from 'react';
import { usePresentationCheck } from './use-presentation-check';

export const useSlideWrapperCounting = (
  options: SlideWrapperOptionProps,
  sessionId: string,
  votingStepId: string,
  currentStepId: string
) => {
  const callApi = useApi();
  const { actualPresentationScreen } = usePresentationCheck();

  const [countingResults, setCountingResults] = useState();
  const [countingResultsLoading, setCountingResultsLoading] = useState(false);
  const updateCountingResult = (result) => {
    setCountingResults(result);
    setCountingResultsLoading(false);
  };

  useEffect(() => {
    if (options) {
      if (actualPresentationScreen) {
        setCountingResultsLoading(true);
        getTourStepMethod(options).then(updateCountingResult);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTourStepMethod = async (options: SlideWrapperOptionProps) => {
    if (options.collectItemsTour && actualPresentationScreen) {
      return collectItemsCounting(options.collectItemsTour);
    }
    if (options.countAnswersTour && actualPresentationScreen) {
      return answerCounting(options.countAnswersTour);
    }
    if (options.countSantaAnswersTour && actualPresentationScreen) {
      return santaAnswerCounting(options.countSantaAnswersTour);
    }
    //todo uncomment if presenter needs to display some solution
    // if (options.showSolutionTour) {
    //   console.log('GET SOLUTION');
    //   return getSolution(options.showSolutionTour);
    // }
  };

  const collectItemsCounting = async (type: RoundType) => {
    const { data } = await callApi(`${ChronosApiEndpoints.postCollectItemsCounting}${type}`, {
      method: 'POST',
      body: {
        sessionId,
        votingStepId,
      },
    });
    return data;
  };

  const answerCounting = async (type: RoundType) => {
    const { data } = await callApi(`${ChronosApiEndpoints.postAnswerCounting}`, {
      method: 'POST',
      body: {
        currentStepId,
        sessionId,
        votingStepId,
      },
    });
    return data;
  };

  const getSolution = async (type: RoundType) => {
    const { data } = await callApi(`${ChronosApiEndpoints.getSolution}${type}`, {
      method: 'POST',
      body: {
        sessionId,
        votingStepId,
      },
    });
    return data;
  };

  const santaAnswerCounting = async (type: RoundType) => {
    const { data } = await callApi(`${ChronosApiEndpoints.postSantaAnswerCounting}`, {
      method: 'POST',
      body: {
        currentStepId,
        sessionId,
        votingStepId,
      },
    });
    return data;
  };

  return { countingResults, countingResultsLoading, santaAnswerCounting };
};
