import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Text } from '../../chronos/components/text';
import { Button } from '../../chronos/player/components/button';

const PopUpBox = styled.div`
  padding: 60px 80px;
  background: #f5e8d1;
  border: 5px solid #665b50;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
`;

const Buttons = styled.div`
  display: flex;
  gap: 40px;
  margin-top: 50px;
`;

export const useConfirmation = (stepsCount) => {
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState(0);

  useEffect(() => {
    if (currentStep > stepsCount) {
      document.body.dispatchEvent(new KeyboardEvent('keydown', { keyCode: 39, bubbles: true }));
    }
  }, [currentStep]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.keyCode === 39 && currentStep < stepsCount) {
        e.stopPropagation();
        setCurrentStep((step) => step + 1);
        return;
      }
      if (e.keyCode === 39 && currentStep === stepsCount) {
        e.stopPropagation();
        return;
      }
      if (e.keyCode === 37 && currentStep > 0) {
        e.stopPropagation();
        setCurrentStep((step) => step - 1);
        return;
      }
      if (e.keyCode === 13 && currentStep == stepsCount) {
        setCurrentStep((step) => step + 1);
        return;
      }
    };
    document.body.addEventListener('keydown', handleKeyDown);
    return () => document.body.removeEventListener('keydown', handleKeyDown);
  }, [currentStep]);

  const ConfirmationPopup = useMemo(
    () => () =>
      currentStep === stepsCount ? (
        <PopUpBox>
          <Text>{t('confirmation.doYou')}</Text>
          <Buttons>
            <Button
              onClick={() => {
                document.body.dispatchEvent(new KeyboardEvent('keydown', { keyCode: 37, bubbles: true }));
              }}
            >
              {t('confirmation.cancel')}
            </Button>
            <Button
              onClick={() => {
                document.body.dispatchEvent(new KeyboardEvent('keydown', { keyCode: 13, bubbles: true }));
              }}
            >
              {t('confirmation.yes')}
            </Button>
          </Buttons>
        </PopUpBox>
      ) : null,
    [currentStep]
  );
  return ConfirmationPopup;
};
