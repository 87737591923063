import React from 'react';
import { slideWrapper } from '../components/slide';
import { PuzzleItem, PuzzleItemsWrapper } from '../components/puzzle-item';
import { Timer } from '../components/timer';
import { Content } from '../components/content';
import { useAnimationSteps } from '@disco/hooks';
import { SlideImage } from '../components/slide-image';
import { useConfirmation } from '../../generic/hooks/use-confirmation';

export const HypatiaPuzzle3 = slideWrapper(({ step, data, getImg, ...props }) => {
  const { answers } = step;

  const animationStep = useAnimationSteps(1, true);

  const ConfirmationPopup = useConfirmation(1);
  return (
    <>
      <ConfirmationPopup />
      <Content bottom={16} left={23} yNotCenter xNotCenter>
        <SlideImage width="50px" src={getImg('man')} show={animationStep < 1} />
      </Content>
      <Content bottom={10} left={-10} yNotCenter xNotCenter>
        <SlideImage width="400px" src={getImg('boat')} show />
      </Content>

      <Content bottom={0} left={-2} yNotCenter xNotCenter>
        <SlideImage width="400px" src={getImg('boatguys')} show />
      </Content>

      <Timer data={data} presentation />
      <PuzzleItemsWrapper>
        <PuzzleItem getImg={getImg} letter="a" answers={answers} />
        <PuzzleItem getImg={getImg} letter="b" answers={answers} smallItem />
        <PuzzleItem getImg={getImg} letter="c" answers={answers} smallItem />
      </PuzzleItemsWrapper>
    </>
  );
});
