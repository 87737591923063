import React from 'react';

import { Wrapper } from '../Wrapper';
import { Logo } from '../Logo';

export const LogoView = () => {
  return (
    <Wrapper>
      <Logo />
    </Wrapper>
  );
};
