import React from 'react';
import { Controller } from 'react-hook-form';
import { Form, Input } from 'semantic-ui-react';
import { Text } from '../text/text';
import { HFNumberProps } from './types';

export const HFNumber = (props: HFNumberProps) => {
  const {
    name,
    label,
    placeholder,
    defaultValue,
    required = false,
    errors = {},
    control,
    width,
    min,
    max
  } = props;

  const onCustomChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    changeFn: (...event: any[]) => void
  ) => {
    const value = parseInt(e.target.value)

    if (isNaN(value)) return changeFn(undefined)

    if (min > value) return changeFn(min)
    if (max < value) return changeFn(max)

    return changeFn(e);
  };

  return (
    <Form.Field required={required} error={!!errors[name]} width={width}>
      <label>{label}</label>
      <Controller
        {...{ name, defaultValue, control }}
        rules={{ required }}
        render={({ field, field: { value, onChange } }) =>
            <Input fluid {...{ ...field, value, placeholder, type: 'number', onChange: (e) => onCustomChange(e, onChange) }} />
        }
      />
      {errors[name]?.message && <Text margin="6px 0 0 0" color='#9f3a38'>{errors[name]?.message}</Text>}
    </Form.Field>
  );
};
