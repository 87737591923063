import React from 'react';
import styled from 'styled-components';
import { ComposedChart, Area, XAxis, YAxis, ResponsiveContainer, Label, Scatter, LabelList } from 'recharts';
import { Text } from '../../text';

const LabelText = styled(Text)`
  &&& {
    font-size: 26px;
  }
`;

export const MAX_RANGE_VOTE = 6;

const labelCustom = (label) => ({ ...props }) => {
  const {
    viewBox: { width, x, y },
  } = props;
  return (
    <g>
      <foreignObject x={x} y={y} dy={16} width={width} height={45}>
        <LabelText>{label}</LabelText>
      </foreignObject>
    </g>
  );
};

const averageLabel = (color) => (props) => {
  const { x, y, width, value } = props;
  const radius = 16;

  return (
    <g>
      <circle cx={x + width / 2} cy={y - radius} r={radius} fill={color} />
      <text x={x + width / 2} y={y - radius + 1} fill="#fff" textAnchor="middle" dominantBaseline="middle">
        {value}
      </text>
    </g>
  );
};

interface Data {
  average?: string;
  value?: number;
  name: number;
  zero?: number;
}

export const LineAreaChart = ({ label, actions, color }) => {
  const filteredActions = actions.filter((action) => action.data.option === label);

  let votesSum = 0;

  const votes = filteredActions.reduce((prev, current) => {
    const value = current.data.value;

    votesSum += value;

    prev[value] = prev[value] ? prev[value] + 1 : 1;
    return prev;
  }, {});

  const data: Data[] = Array(MAX_RANGE_VOTE + 1)
    .fill(0)
    .map((_, index) => ({ name: index, value: votes[index] || 0 }));

  const average = {
    average: (filteredActions.length ? votesSum / filteredActions.length : 0).toFixed(1),
    name: Number(filteredActions.length ? votesSum / filteredActions.length : 0),
    zero: 0,
  };

  const afterAverage = data.findIndex((d) => d.name > average.name);
  data.splice(afterAverage, 0, average);

  return (
    <ResponsiveContainer width="100%" height="100%">
      <ComposedChart
        width={500}
        height={400}
        data={data}
        margin={{
          top: 20,
          right: 70,
          bottom: 30,
          left: 70,
        }}
      >
        <XAxis
          interval={0}
          tickLine={false}
          strokeWidth={3}
          dataKey="name"
          tick={() => null}
          domain={[0, MAX_RANGE_VOTE]}
        >
          <Label offset={0} position="insideBottom" content={labelCustom(label)} />
        </XAxis>
        <YAxis hide tickLine={false} strokeWidth={3} />
        <Area connectNulls={true} type="monotoneX" strokeWidth={3} dataKey="value" fill={color} stroke={color} />
        <Scatter name="blue" strokeWidth={10} dataKey="zero" fill={color}>
          <LabelList offset={0} content={averageLabel(color)} dataKey="average" />
        </Scatter>
      </ComposedChart>
    </ResponsiveContainer>
  );
};
