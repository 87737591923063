import { getUniqueUserActions } from '@disco/utils';
import * as React from 'react';
import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import { Text } from './text';

const UserCounterWrapper = styled.div<{ margin: string; isChristmas: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${(props) => props.margin};
  ${(props) => (props?.isChristmas ? 'position: absolute;\n  top: 0;\n  right: 0;' : '')}

  color: ${(props) => props.theme.textColor};
  text-shadow: ${(props) => props.theme.textShadow || 'none'};
`;

const StyledText = styled(Text)`
  margin-left: 16px;
`;

export const Group = ({ users, margin = '' }) => {
  return (
    <>
      <Icon size="huge" name="group" />
      <StyledText>{users}</StyledText>
    </>
  );
};

export const UserCounter = ({ actions, margin = '', isChristmas = false }) => {
  const users = getUniqueUserActions(actions);

  return (
    <UserCounterWrapper margin={margin} isChristmas={isChristmas}>
      <Group margin={margin} users={users.length} />
    </UserCounterWrapper>
  );
};
