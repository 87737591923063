import React, { useCallback, useState } from 'react';
import { GroupData } from '@disco/data';
import { BarChart, Bar, LabelList, XAxis, YAxis, ResponsiveContainer, Tooltip } from 'recharts';
import { size } from './constants';
import styled from 'styled-components';

interface BarStackedChartProps {
  data: any;
  labels: { [name: string]: string };
  groups: GroupData[];
  options: string[] | { value: string; label: string }[];
  resources: {};
  showTooltip?: boolean;
}

const Label = styled.div`
  text-align: center;
  margin: 0 8px;
  font-size: 20px;
  line-height: 24px;
  font-weight: bold;
`;

const customizedAxisTick = (options) => ({ x, y, payload }) => {
  const option = options.find((option) => typeof option !== 'string' && option.value === payload.value);
  return (
    <g transform={`translate(${Number(x - size.BAR_X_LABEL_MAX_WIDTH / 2 || 0)},${Number(y || 0)})`}>
      <foreignObject x={0} y={0} dy={16} width={size.BAR_X_LABEL_MAX_WIDTH} height={170}>
        <Label>{option?.label || payload.value}</Label>
      </foreignObject>
    </g>
  );
};

let tooltip;
let tooltipValue;

const CustomTooltip = ({ active, payload }) => {
  if (!active || !tooltip) return null;
  for (const bar of payload)
    if (bar.dataKey === tooltip)
      return tooltipValue ? (
        <div style={{ fontWeight: 'bold', background: '#D6D0D0', padding: '8px 12px', borderRadius: 6 }}>
          {bar.name}
          <br />
          {tooltipValue}
        </div>
      ) : null;
  return null;
};

export const BarStackedChart = ({
  data,
  options = [],
  groups = [],
  labels = {},
  resources = {},
  showTooltip = false,
}: BarStackedChartProps) => {
  const customLabel = useCallback(
    (props) => {
      const { x, y, name, value, width } = props;

      return (
        <g>
          <foreignObject
            x={x - 25}
            y={Number(y || 0) - Number((resources[labels[name]] ? width + (value ? 24 : 0) : 30) || 0)}
            width={200}
            height={resources[labels[name]] ? 170 : 30}
          >
            {!!value && <Label>{value}</Label>}
            {resources[labels[name]] && <img src={resources[labels[name]]} height={width - 2} width={width} />}
          </foreignObject>
        </g>
      );
    },
    [resources, labels]
  );

  const [tooltipPosition, setTooltipPosition] = useState();

  return (
    <ResponsiveContainer width="100%" height="100%">
      {data && (
        <BarChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 5,
            left: 5,
            bottom: 95,
          }}
        >
          <XAxis
            tickLine={false}
            strokeWidth={3}
            interval={0}
            dataKey="name"
            type="category"
            tick={customizedAxisTick(options)}
          />
          <YAxis hide type="number" domain={[0, (dataMax) => (dataMax ? dataMax + dataMax * (3 / 4) : 10)]} />
          <Tooltip cursor={false} position={tooltipPosition} content={CustomTooltip} />
          {groups.length ? (
            groups.map((group, index) => {
              return (
                <Bar
                  isAnimationActive={false}
                  maxBarSize={size.BAR_MAX_WIDTH}
                  key={group.name}
                  dataKey={group.name}
                  fill={group.color}
                  stackId="s1"
                  onMouseOver={(data) => {
                    setTooltipPosition(data.tooltipPosition);
                    tooltipValue = data[group.name];
                    tooltip = group.name;
                  }}
                  onMouseLeave={() => {
                    setTooltipPosition(undefined);
                    tooltipValue = null;
                  }}
                >
                  {index === groups.length - 1 ? (
                    <LabelList
                      position="top"
                      style={{ fontSize: '24px', fontWeight: 'bold', lineHeight: '27px' }}
                      content={customLabel}
                    />
                  ) : null}
                </Bar>
              );
            })
          ) : (
            <Bar dataKey="no-groups" fill="red" isAnimationActive={false} maxBarSize={size.BAR_MAX_WIDTH}>
              <LabelList
                dataKey="no-groups"
                position="top"
                content={Object.keys(resources).length && Object.keys(labels).length ? customLabel : undefined}
              />
            </Bar>
          )}
        </BarChart>
      )}
    </ResponsiveContainer>
  );
};
