import firebase from 'firebase/app';
import { GeneratedContextDocumentId, SESSION_COLLECTION, SESSION_GENERATED_CONTEXT_SUBCOLLECTION } from '@disco/data';
import { useEffect, useState } from 'react';

export const useCurrentContext = (sessionId) => {
  const [context, setContext] = useState({});

  useEffect(() => {
    return firebase
      .firestore()
      .collection(SESSION_COLLECTION)
      .doc(sessionId)
      .collection(SESSION_GENERATED_CONTEXT_SUBCOLLECTION)
      .doc(GeneratedContextDocumentId.GENERAL)
      .onSnapshot((snapshot) => {
        setContext(snapshot.data());
      });
  }, [sessionId]);

  return context || {};
};
