import React, { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import styled from 'styled-components';
import { Dimmer, Loader, Segment } from 'semantic-ui-react';
import { Steps } from './steps';
import { SESSION_COLLECTION, SessionData, SessionStatus } from '@disco/data';
import { SessionControls } from './session-controls';
import PinnedControls from './pinned-controls';

interface SessionProps {
  id: string;
}

export interface CurrentStepProps {
  id: string;
  type: string;
  notes?: string;
  actionsAreNotUnique?: boolean;
  seconds?: number;
}

const StepsColumn = styled.div`
  padding-top: 4px;
  max-height: 730px;
  width: 400px;
  margin-bottom: 20px;
  margin-right: 32px;
`;

const StyledContainer = styled.div`
  display: flex;
`;

const StyledSegment = styled(Segment)``;

export function Session({ id: sessionId }: SessionProps) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<SessionData>();
  const [currentViewStep, setCurrentStepView] = useState<CurrentStepProps>();

  useEffect(() => {
    return firebase
      .firestore()
      .collection(SESSION_COLLECTION)
      .doc(sessionId)
      .onSnapshot((doc) => {
        setData(doc.data() as SessionData);
        setLoading(false);
      });
  }, [sessionId]);

  const onScrollToCurrent = () => {
    document.getElementById('step-current').scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  return (
    <StyledSegment viewOnly={data?.status !== SessionStatus.OPEN} basic>
      <Dimmer inverted active={loading}>
        <Loader />
      </Dimmer>
      <StyledContainer>
        <StepsColumn>
          <PinnedControls {...{ sessionId, currentViewStep, data, onScrollToCurrent }} />
          <Steps {...{ sessionId, sessionData: data, setCurrentStepView, currentViewStep, onScrollToCurrent }} />
        </StepsColumn>
        <SessionControls {...{ sessionId, currentViewStep, data }} />
      </StyledContainer>
    </StyledSegment>
  );
}

export default Session;
