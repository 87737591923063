import { useTranslation } from 'react-i18next';
import { useCookies } from '@disco/hooks';
import { PresentationCookie, SESSION_COLLECTION, SessionData, SessionStatus } from '@disco/data';
import { copyToClipboard, generateRandomString, openNewTab } from '@disco/utils';
import { Button, Form, Icon, Popup } from 'semantic-ui-react';
import React, { useState } from 'react';
import styled from 'styled-components';
import { CustomButton } from '@disco/ui';
import { useSessions } from '../api/use-sessions';
import { emitToast, ToastType } from 'libs/utils/src/lib/toast';
import firebase from 'firebase/app';

const StyledGroup = styled.div`
  &&& {
    max-width: 100%;
    padding-bottom: 8px;
    overflow-x: auto;
    display: flex;
    white-space: nowrap;
    display: flex;
    justify-content: space-around;
  }
`;

const StyledArchivedField = styled.div`
  &&& {
    margin-bottom: 1em !important;
    display: flex;
    align-items: flex-end;
  }
`;

interface PinnedControlsProps {
  sessionId: string;
  currentViewStep: any;
  data: SessionData;
  onScrollToCurrent: any;
}

export const PinnedControls = ({ sessionId, currentViewStep, data, onScrollToCurrent }: PinnedControlsProps) => {
  const { t } = useTranslation();
  const { setPresentationCookie } = useCookies();
  const { archiveSession } = useSessions();
  const isSessionArchived = data?.status === SessionStatus.ARCHIVED;
  const [codeCopied, setCodeCopied] = useState(false);

  const onArchiveSession = () => {
    archiveSession(sessionId)
      .then(() => emitToast({ title: t('session.archiveSuccess') }, ToastType.Success))
      .catch(() => emitToast({ title: t('utils.toastError') }, ToastType.Error));
  };

  const onOpenPresentationMode = () => {
    setPresentationCookie({ sessionId, sessionCode: data.sessionCode } as PresentationCookie);
    openNewTab('/sessions/' + sessionId + '/present');
  };

  const generateCode = () => {
    const code = generateRandomString(6);
    firebase.firestore().collection(SESSION_COLLECTION).doc(sessionId).set({ sessionCode: code }, { merge: true });
    return code;
  };

  const onCreateSessionCode = () => {
    if (!data?.sessionCode) {
      const code = generateCode();
      copyToClipboard(code);
    } else {
      copyToClipboard(data.sessionCode);
    }
    setCodeCopied(true);
  };

  return (
    <Form>
      <StyledGroup>
        <Form.Field>
          <label>{data?.name}</label>
          {!isSessionArchived && (
            <Popup
              on="hover"
              content={codeCopied ? t('session.codeCopied') : t('session.clickToCopy')}
              trigger={
                <Button onClick={onCreateSessionCode}>
                  <Icon name="key" />
                  {data?.sessionCode}
                </Button>
              }
            />
          )}
          <Popup
            content={t('session.openPresentationModeTooltip')}
            trigger={
              <CustomButton style={{ backgroundColor: '#3f0d66', color: 'white' }} onClick={onOpenPresentationMode}>
                <Icon name="tv" />
                {t('session.present')}
              </CustomButton>
            }
          />
          <Popup
            content={t('session.slideToCurrentSlide')}
            trigger={<Button icon="crosshairs" onClick={onScrollToCurrent} />}
          />
        </Form.Field>
        <Form.Field as={StyledArchivedField}>
          <Popup
            content={t('session.archive')}
            trigger={
              <div>
                <CustomButton
                  disabled={isSessionArchived}
                  withConfirmation
                  content={t('session.archiveConfirmation')}
                  onMakeAction={onArchiveSession}
                  icon="archive"
                />
              </div>
            }
          />
        </Form.Field>
      </StyledGroup>
    </Form>
  );
};

export default PinnedControls;
