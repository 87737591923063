import { Logo } from 'libs/ui/src/lib/logo/logo';
import React, { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { ThemeProvider } from 'styled-components';
import { Text } from './text';
import { SlideWrapperOptionProps } from './types';
import { useSlideWrapperCounting } from '../hooks/use-slide-wrapper-counting';
import { AspectRatioWrapper } from '../../generic/aspect-ratio-wrapper';
import { ConditionalSoundButton } from './sound-button';

export const Slide = styled.div<any>`
  body,
  div,
  p,
  span,
  h1,
  h2,
  h3,
  a,
  .ui.header {
    font-family: Aleo;
  }

  position: relative;
  min-height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: ${(props) => props.backgroundSize};
  background-position: center top;
  background-image: url(${(props) => props.resources[props.backgroundImage]});

  .ui.statistic > .label,
  .ui.statistic > .value {
    color: ${(props) => props.theme.textColor};
    text-shadow: ${(props) => props.theme.textShadow || 'none'};
  }

  .to-small {
    display: none;
  }

  .content {
    height: 100%;
  }

  ${(props) =>
    props.presentation &&
    `
    .to-small {
      width: 100%;
      justify-content: center;
      height: 100%;
      align-items: center;
      display: none;
    }
    @media screen and (max-width: 1250px) {
      .content {
        display: none;
      }

      .to-small {
        display: flex;
      }
    }
  `}
`;

const MinHeightContainer = styled.div`
  min-height: 100vh;
  display: flex;
  overflow: hidden;
`;

export const slideWrapper = (Content: (any) => JSX.Element, options?: SlideWrapperOptionProps) => ({
  data,
  presentation,
  ...props
}) => {
  const ref = useRef();
  const { t } = useTranslation();
  const { currentStep, resources, sessionId } = data;

  const getImg = (label) => resources[currentStep?.imageLabels?.[label]];

  const { sessionCode } = data;
  const { style, logoAlwaysVisible, showLogoBanner, ...step } = currentStep;

  const { countingResults, countingResultsLoading } = useSlideWrapperCounting(
    options,
    sessionId,
    currentStep?.stepsAfterVoting ? currentStep.id - currentStep?.stepsAfterVoting : currentStep.id,
    currentStep.id
  );

  return (
    <ThemeProvider theme={themes[style]}>
      <MinHeightContainer>
        <Slide backgroundSize={options?.backgroundSize || 'cover'} {...{ resources, presentation, ...step }}>
          <div className="to-small">
            <Text>{t('chronos.screenToSmall')}</Text>
          </div>
          <div ref={ref} className="content">
            <Logo alwaysVisible={logoAlwaysVisible} {...{ sessionCode, showLogoBanner, style }} />
            {getImg('backgroundSound') && <ConditionalSoundButton getImg={getImg} soundKey="backgroundSound" />}
            <Content
              step={currentStep}
              {...{
                data,
                getImg,
                resources,
                presentation,
                countingResults,
                countingResultsLoading,
                fetching: true,
                ...props,
              }}
            />
          </div>
        </Slide>
      </MinHeightContainer>
    </ThemeProvider>
  );
};

export const themes = {
  santa: {
    textShadow: '2px 2px 20px #000000, 2px 2px 10px #000000e3',
    textColor: 'white',
    boxBackground: 'none',
    boxFontSize: '4em',
    countColor: '#456E8B',
    buttonColor: '#91A7BC',
    inventoryBackground: '#91A7BC',
    borderColor: '#456E8B',
  },
  '': {},
  chronos: {},
};
