import { useCallback, useContext } from 'react';
import { callApi } from '@disco/utils';
import { UserDiscoData, UserDiscoContext } from '@disco/ui';

export const useSessions = () => {
  const { token } = useContext<UserDiscoData>(UserDiscoContext);

  const createSession = useCallback(async (body) => {
    return await callApi('/session', { token, method: 'post', body });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const archiveSession = useCallback(async (id) => {
    return await callApi(`/session/${id}`, { token, method: 'delete' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const editSession = useCallback(async (id, body) => {
    return await callApi(`/session/${id}`, { token, method: 'PATCH', body });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPresenters = useCallback(async () => {
    return await callApi(`/users/presenters`, { token });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const editSessionDuration = useCallback(async (id, body) => {
    return await callApi(`/session/duration/${id}`, { token, method: 'PATCH', body });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    createSession,
    archiveSession,
    getPresenters,
    editSession,
    editSessionDuration,
  };
};
