import React from 'react';
import { Wrapper } from '../../../components/Wrapper';
import { VideoStep } from '../../../components/VideoStep';
import { InterfaceWrapper } from '../../../components/InterfaceWrapper';
import { PlayerInterface } from './PlayerInterface';

export const VideoSlide = InterfaceWrapper({
  Interface: PlayerInterface,
  Step: ({ data }) => {
    return (
      <Wrapper>
        <VideoStep data={data} />
      </Wrapper>
    );
  },
});
