import React from 'react';
import { HFElementProps } from './types';
import { Controller } from 'react-hook-form';
import { Form, Input } from 'semantic-ui-react';
import { Text } from '../text/text';
import ReactDatePicker from 'react-datepicker';
import styled from 'styled-components';

const StyledFiled = styled.div`
  .react-datepicker-wrapper {
    width: 100%;
  }
`;

export const HFDatepicker = ({ name, label, defaultValue, required = false, errors = {}, control }: HFElementProps) => {
  return (
    <Form.Field as={StyledFiled} required error={!!errors[name]}>
      <label>{label}</label>
      <Controller
        {...{ name, defaultValue, control }}
        rules={{ required }}
        render={({ field: { value, onChange } }) => (
          <ReactDatePicker
            selected={value}
            onChange={onChange}
            dateFormat="dd/MM/yyyy hh:mm aa"
            showTimeSelect
            timeIntervals={5}
            customInput={<Input icon="calendar alternate" />}
          />
        )}
      />
      {errors[name]?.message && <Text color="#9f3a38">{errors[name]?.message}</Text>}
    </Form.Field>
  );
};
