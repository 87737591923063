import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon, List, ListItem, Loader, Modal } from 'semantic-ui-react';
import { CustomButton, Text } from '@disco/ui';
import { useFirebaseStorage } from 'libs/hooks/src/lib/use-firebase-storage';
import { emitToast, ToastType } from 'libs/utils/src/lib/toast';
import { SinglePagePdf } from 'libs/ui/src/lib/pdf/pdf';
import styled from 'styled-components';

interface InstructionModalProps {
  selectedGame: string;
  open: boolean;
  onClose: () => void;
}

const Buttons = styled.div`
  justify-content: flex-end;
  display: flex;
  flex: 1;
`;

export function InstructionModal({ selectedGame, open, onClose }: InstructionModalProps) {
  const { t } = useTranslation();
  const { getDirectoryDocumentRefs } = useFirebaseStorage();
  const [list, setList] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const { getDocumentByRef } = useFirebaseStorage();
  const [view, setView] = useState(null);
  const [name, setName] = useState('');
  const [viewModalOpen, setViewModalOpen] = useState(false);

  useEffect(() => {
    async function getRefs() {
      const refs = await getDirectoryDocumentRefs('instructions', selectedGame);
      setLoading(false);
      setList(refs);
    }
    if (selectedGame) getRefs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGame]);

  const onDocumentDownloadClick = (ref) => {
    ref
      .getDownloadURL()
      .then((url) => {
        const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = (event) => {
          const blob = xhr.response;
          saveBlob(blob, ref.name);
        };
        xhr.open('GET', url);
        xhr.send();
      })
      .catch((error) => {
        console.error(error);
        emitToast({ title: t('session.resetStep.errorToast') }, ToastType.Error);
      });
  };

  const saveBlob = (blob, fileName) => {
    const a = document.createElement('a');
    a.href = window.URL.createObjectURL(blob);
    a.download = fileName;
    a.dispatchEvent(new MouseEvent('click'));
  };

  const onDocumentViewClick = (ref) => {
    getDocumentByRef(ref).then((url) => {
      setView(url);
      setName(ref.name.split('.').shift());
      setViewModalOpen(true);
    });
  };

  return (
    <>
      <Modal dimmer={'inverted'} size="small" open={open} onClose={onClose}>
        <Modal.Header>
          {t('games.instructions')} - {selectedGame}
        </Modal.Header>
        <Modal.Content>
          {loading ? (
            <Loader />
          ) : (
            <List size="big">
              {list.length > 0 ? (
                list.map((ref, idx) => (
                  <ListItem
                    key={idx}
                    style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                  >
                    <p style={{ margin: 0 }}>{ref.name.split('.').shift()}</p>
                    <Buttons>
                      <CustomButton positive size="tiny" onMakeAction={() => onDocumentViewClick(ref)}>
                        <Icon name="eye" />
                        {t('utils.show')}
                      </CustomButton>
                      <CustomButton positive size="tiny" onMakeAction={() => onDocumentDownloadClick(ref)}>
                        <Icon name="download" />
                        {t('utils.download')}
                      </CustomButton>
                    </Buttons>
                  </ListItem>
                ))
              ) : (
                <Text>{t('games.noInstructionFiles')}</Text>
              )}
            </List>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={onClose}>{t('utils.close')}</Button>
        </Modal.Actions>
      </Modal>
      <Modal scrolling size="large" dimmer="inverted" onClose={() => setViewModalOpen(false)} open={viewModalOpen}>
        <Modal.Header>{name}</Modal.Header>
        <SinglePagePdf pdfUrl={view} />
      </Modal>
    </>
  );
}
