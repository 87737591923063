import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from 'semantic-ui-react';
import { LabeledText } from 'libs/ui/src/lib/text/labeled-text';
import { SessionData } from '../../../models/session';
import { SessionDurationProps } from '@disco/data';
import moment from 'moment';

interface PreviewSessionProps {
  selectedSession: SessionData;
  open: boolean;
  onClose: () => void;
}

export function PreviewSessionModal({ selectedSession, open, onClose }: PreviewSessionProps) {
  const { t } = useTranslation();

  return (
    <Modal dimmer={'inverted'} size="small" open={open} onClose={onClose}>
      <Modal.Header>{selectedSession?.name}</Modal.Header>
      <Modal.Content>
        <LabeledText inline textKey={t('createSession.meetingLink')} value={selectedSession?.meetingLink} />
        <LabeledText inline top={16} textKey={t('sessions.facilitator')} value={selectedSession?.facilitator} />
        <LabeledText inline top={16} textKey={t('createSession.participants')} value={selectedSession?.participants} />
        <LabeledText inline top={16} textKey={t('createSession.notes')} value={selectedSession?.notes} />
        <SessionDuration
          {...{ startAt: selectedSession?.duration?.startAt, endAt: selectedSession?.duration?.endAt }}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose}>{t('sessions.cancel')}</Button>
      </Modal.Actions>
    </Modal>
  );
}

const SessionDuration = ({ startAt, endAt }: SessionDurationProps) => {
  const { t } = useTranslation();
  const noDataText = t('sessions.noDurationData');

  const start = startAt?.toDate?.();
  const end = endAt?.toDate?.();

  const diff = start && end ? moment(end).diff(moment(start), 'minutes') : null;

  return (
    <LabeledText
      inline
      top={16}
      textKey={t('sessions.duration')}
      value={diff ? `${diff} ${t('utils.minutesShort')}` : noDataText}
    />
  );
};
