import React from 'react';
import { slideWrapper } from '../components/slide';
import { Content } from '../components/content';
import styled from 'styled-components';
import { Text } from '../components/text';
import { ConditionalSoundButton } from '../components/sound-button';

const Image = styled.img`
  width: 100vw;
  height: auto;
`;

const SlideText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-size: 60px;
  margin: 32px 20px;
  z-index: 999;
`;

const Krampus = styled.img`
  position: absolute;
  right: 8vw;
  width: 29vw;
`;

const Lamps = styled.img`
  position: absolute;
  top: 0;
  width: 100vw;
  height: auto;
`;

export const LibraryPuzzle7Story = slideWrapper(({ step, fetching, resources, getImg, ...props }) => {
  const { text1, text2 } = step;

  return (
    <>
      {fetching && <ConditionalSoundButton getImg={getImg} />}
      <Content width="90%" yNotCenter top={0} left={0}>
        <Image src={getImg('image')} />
        <Lamps src={getImg('lamps')} />
        <Krampus src={getImg('krampus')} />
        <SlideText>
          <Text medium>{text1}</Text>
          <Text medium>{text2}</Text>
        </SlideText>
      </Content>
    </>
  );
});
