import React from 'react';
import { slideWrapper } from '../components/slide';
import { Content } from '../components/content';
import styled from 'styled-components';
import { useAnimationSteps } from '@disco/hooks';
import { PresentVotingStep, RoundType } from '@disco/data';
import { PuzzleItem, PuzzleItemsWrapper } from '../components/puzzle-item';

const TextImage = styled.img<any>`
  position: absolute;
  left: 2%;
  width: 450px;
  top: 100px;
`;

const AnimatedText = styled.img<any>`
  position: absolute;
  top: 0;
  width: 500px;
  left: 1%;
  clip-path: ${(props) => (props.show ? 'inset(0)' : 'inset(0 0 100% 0)')};
  opacity: ${(props) => (props.show ? '1' : '0')};
  transition: all 1s;
`;

export const HypatiaPuzzle1Solution = slideWrapper(
  ({ step, data, getImg, presentation }) => {
    const { answers } = step;

    const animationStep = useAnimationSteps(1, true);

    const currentStep = data?.currentStep as PresentVotingStep;

    return (
      <>
        <AnimatedText show={animationStep > 0} src={getImg('text2')} />
        <TextImage src={getImg('text1')} />
        <Content xNotCenter yNotCenter left={1} bottom={1}>
          <img height={200} src={getImg('things1')} />
        </Content>
        <PuzzleItemsWrapper>
          <PuzzleItem getImg={getImg} letter="a" answers={answers} />
          <PuzzleItem getImg={getImg} letter="b" answers={answers} />
          <PuzzleItem getImg={getImg} letter="c" answers={answers} />
        </PuzzleItemsWrapper>
      </>
    );
  },
  { showSolutionTour: RoundType.Hypatia }
);
