import styled from 'styled-components';
import backgroud from './background.svg';

export const Wrapper = styled.div`
  background-color: #d6f1fe;
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #114a94;
  background-image: url(${backgroud});
  background-repeat: no-repeat;
  background-size: cover;
  backdrop-filter: saturate(80%);
  letter-spacing: 0.1em;
  padding: 0 10px;

  h1 {
    font-size: 3rem;
  }

  h2 {
    font-size: 2.5rem;
  }

  div:not(.user-counter), h1, h2, p, span {
    font-family: GilmoreFinalPro !important;
    letter-spacing: 0.1em;
  }
`;

export const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
