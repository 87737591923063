import React from 'react';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import styled, { ThemeProvider } from 'styled-components';
import { Slide, themes } from '../components/slide';
import { SelectGroupContent } from './select-group/select-group-content';
import { sessionInfoCookieName } from '@disco/hooks';
import { SessionCookie } from '@disco/data';
import { Colors } from '../constants';
import { InventoryButton } from './components/inventory-button';
import { useGroupInventory } from '../hooks/use-group-inventory';
import { TimeScore, TimeScoreSanta } from './components/time-score';

const ButtonWrapper = styled.div`
  display: flex;
  gap: 16px;
  padding: 16px;
  justify-content: space-between;
  .ui.button {
    background-color: ${(props) => props.theme.buttonColor || Colors.MAIN} !important;
    color: white !important;
  }
  .ui.statistic > .label,
  .ui.statistic > .value {
    color: ${(props) => props.theme.textColor};
    text-shadow: ${(props) => props.theme.textShadow || 'none'};
  }
`;

const ContentWrapper = styled.div`
  padding: 0 8px 16px 8px;
`;

export interface InterfaceOptions {
  disableItems?: boolean;
  disableTime?: boolean;
  disableHints?: boolean;
  hideButtons?: boolean;
  groupRequired?: boolean;
}

export const playerInterfaceWrapper = (Content) => ({ data, playerOptions, presentation, uid, ...props }) => {
  const { t } = useTranslation();

  const cookie = Cookies.get(sessionInfoCookieName);
  const sessionData = cookie ? (JSON.parse(cookie) as SessionCookie) : {};

  const { groupRequired, hideButtons, disableItems }: InterfaceOptions = playerOptions;

  const {
    currentStep,
    currentStep: { type, style, playerInterface },
    resources,
    sessionId,
    gameId,
  } = data;

  const hideButtonsOnChronosCounting = style !== 'santa' && type.includes('-counting');

  const getImg = (label) => resources[playerInterface.imageLabels?.[label]];

  const { inventoryItems, spentHours = 0, loading } = useGroupInventory(sessionId, type, gameId);

  return (
    <ThemeProvider theme={themes[style]}>
      <Slide
        backgroundSize={'cover'}
        backgroundImage={playerInterface.imageLabels.backgroundImage}
        resources={resources}
        presentation={presentation}
      >
        {!groupRequired || sessionData.groupName ? (
          <>
            {!hideButtons && !hideButtonsOnChronosCounting && (
              <ButtonWrapper>
                <InventoryButton {...{ getImg, inventoryItems, loading, disableItems }} isSanta={style === 'santa'} />
                {style !== 'santa' ? (
                  <TimeScore {...{ spentHours, loading }} />
                ) : (
                  <TimeScoreSanta {...{ points: spentHours, loading }} />
                )}
              </ButtonWrapper>
            )}
            <ContentWrapper>
              <Content
                {...{
                  data,
                  uid,
                  getImg,
                  resources,
                  presentation,
                  inventoryItems,
                  isSanta: style === 'santa',
                  step: currentStep,
                  ...props,
                }}
              />
            </ContentWrapper>
          </>
        ) : (
          <SelectGroupContent {...{ data, uid, isSanta: style === 'santa' }} />
        )}
      </Slide>
    </ThemeProvider>
  );
};
