import React from 'react';
import { Redirect, Route } from 'react-router-dom';

export interface PrivateRouteProps {
  component: (...props) => JSX.Element;
  allow: boolean;
  path: string;
  exact: boolean;
  redirect?: string;
}


export const PrivateRoute = ({ component, allow, path, exact, redirect = "/login" }: PrivateRouteProps) => {
  const Component = component;
  return (
    <Route
      path={path}
      exact={exact}
      render={(props) => (allow ? <Component {...props} /> : <Redirect to={redirect} />)}
    />
  );
};

export default PrivateRoute;
