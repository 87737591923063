import React, { useEffect, useState } from 'react';
import { BarStackedChart } from './bar-stacked-chart';
import { GROUP_COLORS } from '@disco/data';

export const QuestionRadioChart = ({ resources, actions, options, imageLabels }: any) => {
  const answersList = [];

  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);

  // console.log({ resources, actions, options, imageLabels });

  useEffect(() => {
    const columns = [];

    actions.forEach((ele) => {
      const key = ele.data.selectedOption + '-option';

      if (!columns.find((column) => column.name === key))
        columns.push({
          name: key,
          color: GROUP_COLORS[columns.length],
        });

      const curr = answersList[ele.data.selectedOption]?.[key];

      answersList[String(ele.data.selectedOption)] = {
        ...answersList[String(ele.data.selectedOption)],
        [key]: curr ? curr + 1 : 1,
      };
    });

    const allColumns = [...columns];

    const data = options?.map((option) => ({
      name: typeof option === 'string' ? option : option.value,
      label: typeof option === 'string' ? option : option.label,
      ...allColumns.reduce((current, column) => {
        current[column.name] = 0;
        return current;
      }, {}),
      ...answersList[typeof option === 'string' ? option : option.value],
    }));

    setColumns(allColumns);
    setData(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actions]);

  // console.log({ columns, data });

  return <BarStackedChart resources={resources} data={data} options={options} groups={columns} labels={imageLabels} />;
};
