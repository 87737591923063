import React from 'react';
import { slideWrapper } from '../components/slide';
import { TextBox } from '../components/text-box';
import { Text } from '../components/text';
import { Content } from '../components/content';
import { useAnimationSteps } from '@disco/hooks';
import styled from 'styled-components';
import { Rule } from '../components/rule';

const TimeImage = styled.img<any>`
  opacity: ${(props) => (props.show ? 1 : 0)};
  transition: opacity 0.3s;
  max-height: 100vh;
`;

const SmallRule = styled.div`
  &&& {
    margin-left: 80px;
    div {
      font-size: 24px !important;
      line-height: 25px !important;
    }
  }
`;

export const HowToPlayFinal = slideWrapper(({ step, resources, ...props }) => {
  const { title, rules, imageLabels } = step;

  const animationStep = useAnimationSteps(rules.length + 1);

  return (
    <>
      <Content left={80} top={50}>
        <TimeImage show={animationStep > 0} src={resources[imageLabels?.time]} />
      </Content>
      <Content yNotCenter top={7} left={50}>
        <TextBox>{title}</TextBox>
      </Content>
      <Content yNotCenter y={140} top={7} left={50}>
        {rules.map(({ text, children }, index) => (
          <>
            <Rule key={index} show={animationStep - 1 > index} text={text} />
            {children?.map((text) => (
              <SmallRule>
                <Rule key={index} show={animationStep - 1 > index} text={text} />
              </SmallRule>
            ))}
          </>
        ))}
      </Content>
    </>
  );
});
