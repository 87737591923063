import React, { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import VisibilitySensor from 'react-visibility-sensor';
import { Button, Card, Dimmer, Loader } from 'semantic-ui-react';
import styled from 'styled-components';
import { STEP_TYPES } from '@disco/steps';
import { useTranslation } from 'react-i18next';
import { SESSION_COLLECTION, SessionData, STEP_SUBCOLLECTION } from '@disco/data';
import { byOrderId, SessionStepData } from '../models/session';
import { useResources } from '../api/use-resources';
import { setNextStep } from '../utils/set-next-step';
import { Text } from '@disco/ui';

interface StepsProps {
  sessionId: string;
  sessionData: SessionData;
  currentViewStep: SessionStepData;
  setCurrentStepView: (any) => void;
  onScrollToCurrent: () => void;
}

const Wrapper = styled.div`
  max-height: calc(100% - 81px);
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 1em;
`;

const CardWrapper = styled.div`
  min-width: 260px;
  display: flex;
  justify-content: center;
`;

const StyledCard = styled(Card)<{ selected: boolean }>`
  &&& {
    overflow: hidden;
    width: 300px;
    min-height: 100%;
    margin-bottom: 24px;
    ${(props) => props.selected && 'box-shadow: 0 0 0 1px #d4d4d5, 0px 0px 1px 4px #db2828, 0 1px 2px 0 #d4d4d5'};
  }
`;

const ComponentPreview = styled.div`
  zoom: 0.3;
  min-height: 700px;
  pointer-events: none;
  overflow: hidden;
`;

export function Steps({ sessionId, sessionData, setCurrentStepView, onScrollToCurrent, currentViewStep }: StepsProps) {
  const { t } = useTranslation();
  const [data, setData] = useState<SessionStepData[]>();

  const { resources } = useResources(sessionData?.gameId);

  useEffect(() => {
    return firebase
      .firestore()
      .collection(SESSION_COLLECTION)
      .doc(sessionId)
      .collection(STEP_SUBCOLLECTION)
      .onSnapshot((snapshot) => {
        const list: SessionStepData[] = [];
        snapshot.forEach((doc) => {
          list.push({ ...doc.data(), id: doc.id });
        });
        list.sort(byOrderId);
        setData(list);
      });
  }, [sessionId]);

  const onSessionStepSelect = (orderId) => {
    setCurrentStepView(data.find((ele) => ele.orderId === orderId));
    setNextStep(sessionId, orderId, sessionData, data, onScrollToCurrent);
  };

  useEffect(() => {
    if (!currentViewStep) return;
    setCurrentStepView(data.find((ele) => ele.orderId === currentViewStep.orderId));
  }, [data]);

  return (
    <Wrapper id="steps-wrapper">
      {data?.map(({ type, orderId, id, ...props }) => {
        const Component = STEP_TYPES[type];
        const isCurrent = sessionData?.currentStepId === id;

        return (
          <VisibilitySensor key={orderId} partialVisibility={true}>
            {({ isVisible }) => (
              <CardWrapper id={isCurrent ? 'step-current' : ''}>
                <div>
                  <Text margin={8} color="#999999">
                    {id}
                  </Text>
                </div>
                <StyledCard selected={isCurrent}>
                  {Component && (
                    <>
                      <Dimmer active={!isVisible} inverted>
                        <Loader />
                      </Dimmer>
                      <ComponentPreview>
                        {isVisible && (
                          <Component
                            data={{ currentStep: props, resources, groups: sessionData?.groups }}
                            presentation
                            fetching={false}
                          />
                        )}
                      </ComponentPreview>
                    </>
                  )}
                  <Card.Content extra>
                    <div className="ui two buttons">
                      <Button
                        basic={!currentViewStep || currentViewStep.id !== id}
                        positive
                        onClick={() => setCurrentStepView({ type, id, orderId, ...props })}
                      >
                        {t('session.viewStep')}
                      </Button>
                      {!isCurrent ? (
                        <Button size="small" basic color="red" onClick={() => onSessionStepSelect(orderId)}>
                          {t('session.setCurrentStep')}
                        </Button>
                      ) : (
                        <Button size="small" disabled basic>
                          {t('session.currentStep')}
                        </Button>
                      )}
                    </div>
                  </Card.Content>
                </StyledCard>
              </CardWrapper>
            )}
          </VisibilitySensor>
        );
      })}
    </Wrapper>
  );
}
