import React, { useState } from 'react';
import { Content } from '../components/content';
import { useStepAction } from '../../../api/use-step-action';
import { Button } from '../components/button';
import { playerInterfaceWrapper } from '../player-interface';
import { Text } from '../../components/text';
import { Timer } from '../../../generic/timer/timer';
import { useTranslation } from 'react-i18next';
import { Loader } from 'semantic-ui-react';
import { Option } from './option';

export const SelectAnswer = playerInterfaceWrapper(({ step, data, uid, getImg, inventoryItems }) => {
  const { t } = useTranslation();
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const [selected, setSelected] = useState(null);
  const [answersSelected, setAnswersSelected] = useState<string[]>([]);
  const [answersSent, setAnswersSent] = useState(0);

  const { postAction } = useStepAction();

  const { subtitle, answers, imageLabels, maxSelections = 1, items, style } = step;
  const answersOptions = answers ? Object.keys(answers)?.sort() : [];

  const isItemStep = step.type.includes('-items')

  const saveSelection = async () => {
    setLoading(true)
    await postAction({ data: { selectedOption: selected }, userId: uid, slideData: data }, true);
    setSubmitted(true);
    setLoading(false)
    incrementSendCount();
  };

  const singleSelect = (answer: string) => setSelected(answer);

  const multiSelect = (answer: string) => {
    incrementSendCount();
    if (answersSelected.includes(answer)) {
      const update = answersSelected.filter((ans) => ans !== answer);
      send(false, answer, update);
    } else {
      if (answersSelected.length === maxSelections) {
        const update = [...answersSelected];
        update[answersSent % maxSelections] = answer;
        send(true, answer, update);
      } else send(true, answer, [...answersSelected, answer]);
    }
  };

  const send = async (changeDir: boolean, answer: string, updatedArray: string[]) => {
    setAnswersSelected(updatedArray);
    await postAction(
      { data: changeDir ? { selectedOption: answer } : { deselectedOption: answer }, userId: uid, slideData: data },
      true
    );
  };

  const incrementSendCount = () => setAnswersSent((prevState) => prevState + 1);

  const checkIfAnswerAvailable = (ansObj) => {
    const check = (reqItem: string, use: number) => {
      if (reqItem) {
        const invItem = inventoryItems?.find((it) => it.item === reqItem);

        if (!invItem || (use > 0 && invItem.count < use)) return false;
      }
      return true;
    };

    if (!inventoryItems) return true;

    return check(ansObj?.item, ansObj?.use || 0) && check(ansObj?.item2, ansObj?.use2 || 0);
  };

  // items select
  if (!answers && items) {
    return (
      <Content>
        {isItemStep && style !== 'santa' && <Timer data={data} />}
        <>
          <Text alignCenter>{subtitle}</Text>
          <Text>{t('chronos.howManySelected', { selected: answersSelected.length, total: maxSelections })}</Text>
          {Object.entries(items).map(([answer, text]: [string, string]) => (
            <Option
              key={answer}
              {...{
                answer,
                text,
                style,
                isItemStep,
                active: answersSelected.includes(answer),
                onClick: () => (maxSelections === 1 ? singleSelect(answer) : multiSelect(answer)),
                src: getImg(answer)
              }}
            />
          ))}
        </>
      </Content>
    );
  }

  // letter answer select
  return (
    <Content>
      {data.currentStep?.timer && <Timer presentation={false} data={data} />}
      {submitted ? (
        <Text>{t('chronos.answerSubmitted')}</Text>
      ) : answers ? (
        <>
          <Text>{t('chronos.selectAnswer')}</Text>
          {answersOptions?.map((answer) => (
            <Option
              key={answer}
              {...{
                answer,
                text: (answers[answer] as any)?.text,
                active: selected === answer,
                style,
                isItemStep,
                onClick: () => (maxSelections === 1 ? singleSelect(answer) : multiSelect(answer)),
                src: getImg(answer),
                srcWrapper: getImg(answers[answer]?.optionWrapper),
                disabled: !checkIfAnswerAvailable(answers[answer]),
              }}
            />
          ))}
          <Button disabled={!selected || loading} onClick={saveSelection}>
            {t('util.send')}
          </Button>
        </>
      ) : (
        <Loader />
      )}
    </Content>
  );
});
