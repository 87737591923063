import { StepType } from 'libs/data/src/lib/game';
import { GroupData } from './group';
import { MappedObject } from '../../../utils/src/global';
import { SessionMeta } from './session';

export const SESSION_COLLECTION = 'sessions';
export const SESSION_USERS_COLLECTION = 'users';
export const SESSION_GENERATED_CONTEXT_SUBCOLLECTION = 'generated-context';
export const SESSION_PUBLIC_COLLECTION = 'sessions-public';
export const STEP_SUBCOLLECTION = 'steps';
export const STEP_VOTING = 'step-voting';
export const ACTION_SUBCOLLECTION = 'actions';
export const GAMES_COLLECTION = 'games';
export const COMPANIES_COLLECTION = 'companies';

export interface SessionData extends SessionMeta {
  id?: string;
  name?: string;
  gameId?: string;
  status?: string;
  style?: string;
  groups?: GroupData[];
  language?: string;
  currentStepId?: string;
  sessionCode?: string;
  requiredStepIsOn?: boolean;
  requiredStep?: StepRequired;
  ownerId?: string;
  companies?: string[];
  duration?: SessionDurationProps;
  overallScore?: MappedObject<OverallGroupScore>;
  finalScore?: FinalScore;
  scaleView?: boolean;
}

export interface ActionData {
  userId: string;
  //firebase timestamp
  time?: any;
  data: {
    selectedOption?: string;
    deselectedOption?: string;
    groupName?: string;
    [key: string]: any;
  };
}

export interface SessionPublicData {
  sessionId?: string;
  sessionCode?: string;
  language?: string;
  groups?: GroupData[];
  gameId?: string;
  status?: string;
  currentStep?: BaseStep | QuestionRadioStep;
  requiredStep?: StepRequired;
  resources?: {};
  timer?: StepTimer;
  scoring?: any;
}

export interface BaseStep extends ContentProps {
  id: number;
  type: string;
  align?: string;
  seconds?: boolean;
  style?: string;
}

export interface QuestionRadioStep extends BaseStep {
  options: string[];
  question: string;
  legend?: boolean;
  maxOptions?: number;
  timer?: boolean;
  isNotLive?: boolean;
  imageLabels?: { [key: string]: string };
  actionsAreNotUnique?: boolean;
}

export interface QuestionTextStep extends BaseStep {
  title: string;
  maxLength: number;
}

export interface QuestionRangeStep extends BaseStep {
  question: string;
  options: string[];
  maxText: string;
  minText: string;
}

export interface PresentVotingStep extends Omit<QuestionRadioStep, 'options'> {
  stepsAfterVoting: number;
  options: { value: string; label: string }[];
}

export interface PresentScoreTableStep extends BaseStep {
  stepsAfterVoting: number;
  showSummary?: boolean;
  airport?: string;
}

export interface ContentProps {
  title: string;
  titleTextAlign?: string;
  color: string;
  buttonColor: string;
}

export enum SessionStatus {
  OPEN = 'open',
  ARCHIVED = 'archived',
  DELETED = 'deleted',
}

export interface StepRequired {
  stepType: StepType;
  stepId: string;
}

export interface StepTimer {
  startAt?: any;
  seconds?: number;
  status?: CountdownStatus;
  endAt?: any;
}

export enum CountdownStatus {
  STARTED = 'STARTED',
  PAUSED = 'PAUSED',
  STOPPED = 'STOPPED',
  COMPLETED = 'COMPLETED',
}

export interface SessionDurationProps {
  startAt?: any;
  endAt?: any;
}

export interface OverallGroupScore {
  hours: number;
  stepTypes: string[];
  hypatiaHours?: number;
  leonardoHours?: number;
  teslaHours?: number;
  groupNickname?: string;
}

export interface FinalScore {
  winners: any[];
  almost: any[];
  worst: any[];
}

export enum GeneratedContextDocumentId {
  GENERAL = 'general',
  NEXT_ROUND = 'next-round',
}
