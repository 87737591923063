import { useEffect, useState } from 'react';
import { callApi } from '@disco/utils';
import firebase from 'firebase/app';
import 'firebase/storage';

export const useGameResources = (gameId, token) => {
  const [resources, setResources] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!gameId || !token) return;

    const storage = firebase.storage().ref();

    (async () => {
      const response = await callApi('/steps/resources', { token, method: 'POST', body: { gameId } });

      if (response?.data) {
        Promise.all(
          response?.data?.map((fileName) =>
            storage
              .child(fileName)
              .getDownloadURL()
              .catch((e) => {
                console.error(e);
                return '';
              })
          )
        ).then((urls) => {
          const fileNames = response?.data;
          const resources = urls.reduce((prev, next, index) => {
            prev[fileNames[index]] = next;
            return prev;
          }, {});
          setResources(resources);
          setLoading(false);
        });
      }
    })();
  }, [gameId, token]);

  return { resources, loading };
};
