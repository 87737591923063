import React from 'react';
import { slideWrapper } from '../components/slide';
import { Content } from '../components/content';
import styled from 'styled-components';
import { Text } from '../components/text';
import { useAnimationSteps } from '@disco/hooks';
import { SlideImage } from '../components/slide-image';

const SlideText = styled.div`
  font-size: 60px;
  margin: 0 20px;
`;

export const HypatiaPuzzle2Story = slideWrapper(({ step, getImg, ...props }) => {
  const { text } = step;

  const animationStep = useAnimationSteps(1, true);

  return (
    <>
      <Content top={20} left={20}>
        <SlideImage rotate="-90deg" src={getImg('paper')} show={animationStep} />
      </Content>
      <Content top={45} left={40}>
        <SlideImage src={getImg('box')} show={true} />
      </Content>

      <Content top={20} left={20}>
        <SlideImage width="500px" rotate="-90deg" src={getImg('birds')} show={animationStep} />
      </Content>
      <Content top={47} left={40}>
        <SlideImage width="1400px" src={getImg('town')} show={true} />
      </Content>
      <Content width="80%" yNotCenter bottom={10}>
        <SlideText>
          <Text big alignCenter>
            {text}
          </Text>
        </SlideText>
      </Content>
    </>
  );
});
