import React, { useEffect, useState } from 'react';
import { Card } from '@disco/ui';
import firebase from 'firebase/app';
import {
  QuestionRadioStep,
  SessionPublicData,
  SESSION_COLLECTION,
  STEP_SUBCOLLECTION,
  ACTION_SUBCOLLECTION,
} from '@disco/data';
import RadioGroup from '../../../../../ui/src/lib/form/radio-group';
import { useCookies } from '@disco/hooks';
import { getUniqueUserActions } from '@disco/utils';
import { useTranslation } from 'react-i18next';
import Page from '../components/page';
import { QuestionRadioChart } from '../components/charts/question-radio';
import styled from 'styled-components';
import { List } from 'semantic-ui-react';
import { useApi } from '../../generic/hooks/use-api';
import { Timer } from '../../generic/timer/timer';
import { CustomHeader } from '../components/custom-header';

export interface QuestionRadioProps {
  presentation: boolean;
  fetching: boolean;
  uid: string;
  data: SessionPublicData;
  timer?: boolean;
  seconds?: number;
}

const FlexWrapper = styled.div`
  display: flex;
`;

const Legend = styled.div`
  &&& {
    margin-left: 32px;
    text-align: left;
    font-size: 24px;
    line-height: 26px;
    display: inline-block;
  }
`;

const TimerWrapper = styled.div`
  min-height: 100px;
`;

export function QuestionRadio({ uid, data, presentation, fetching = true }: QuestionRadioProps) {
  const { t } = useTranslation();
  const { getSessionCookie } = useCookies();

  const callApi = useApi();

  const currentStep = data?.currentStep as QuestionRadioStep;

  const [actions, setActions] = useState([]);
  const [optionSubmitted, setOptionSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const postAction = async (selectedOptions: string) => {
    setSubmitting(true);
    for (let i = 0; i < selectedOptions.length; i++) {
      const actionData = { selectedOption: selectedOptions[i], groupName: getSessionCookie().groupName };
      const resultAction = await callApi('/steps/action', {
        method: 'POST',
        body: { data: actionData, userId: uid, sessionId: data.sessionId, stepId: data.currentStep.id },
      });
      setOptionSubmitted(true);
    }
    setSubmitting(false);
  };

  useEffect(() => {
    setOptionSubmitted(false);
    setSubmitting(false);
  }, [currentStep.id]);

  useEffect(() => {
    if (presentation && fetching) {
      return firebase
        .firestore()
        .collection(SESSION_COLLECTION)
        .doc(data.sessionId)
        .collection(STEP_SUBCOLLECTION)
        .doc(String(data.currentStep.id))
        .collection(ACTION_SUBCOLLECTION)
        .onSnapshot((snapshot) => {
          const list = [];
          snapshot.forEach((doc) => list.push(doc.data()));
          setActions(list);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resources = data?.resources;

  return (
    <Page {...{ data, presentation }} alignContent={presentation ? 'top' : 'middle'}>
      {presentation ? (
        <>
          <CustomHeader actions={actions} currentStep={currentStep} prop={'question'} />
          <TimerWrapper>
            {fetching && currentStep.timer && (
              <Timer
                presentation={presentation}
                data={data}
                disabled={!currentStep.timer}
                autoStart={currentStep.timer}
              />
            )}
          </TimerWrapper>
          <FlexWrapper>
            <div style={{ minHeight: '55vh', minWidth: currentStep?.legend ? '80%' : '100%', display: 'grid' }}>
              <QuestionRadioChart
                resources={resources}
                actions={
                  currentStep.isNotLive
                    ? []
                    : currentStep?.actionsAreNotUnique
                    ? actions
                    : getUniqueUserActions(actions)
                }
                currentViewStep={data.currentStep}
                groups={data?.groups}
              />
            </div>
            {currentStep?.legend && (
              <List as={Legend}>
                {data?.groups?.map((item) => (
                  <List.Item key={item.name}>
                    <List.Icon style={{ color: item.color }} verticalAlign="middle" size="small" name="circle" />
                    <List.Content>{item.name}</List.Content>
                  </List.Item>
                ))}
              </List>
            )}
          </FlexWrapper>
        </>
      ) : (
        <Card color={currentStep?.color} title={optionSubmitted ? t('question.submitted') : currentStep?.question}>
          {!optionSubmitted && (
            <>
              {currentStep.timer && (
                <Timer
                  presentation={presentation}
                  data={data}
                  disabled={!currentStep.timer}
                  autoStart={currentStep.timer}
                />
              )}
              <RadioGroup
                submitting={submitting}
                options={currentStep?.options}
                resources={resources}
                buttonColor={currentStep?.buttonColor}
                currentViewStep={data.currentStep}
                postAction={postAction}
                presentation={presentation}
                maxOptions={currentStep?.maxOptions}
              />
            </>
          )}
        </Card>
      )}
    </Page>
  );
}

export default QuestionRadio;
