import React from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'semantic-ui-react';
import styled from 'styled-components';

interface QuestionTextTableProps {
  actions: { data: { groupName?: string; submittedText?: string } }[];
}

const TableWrapper = styled.div`
  margin-top: 16px;
`;

export const QuestionTextTable = ({ actions }: QuestionTextTableProps) => {
  const { t } = useTranslation();

  return (
    <TableWrapper>
      <Table basic="very" selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{t('steps.groupName')}</Table.HeaderCell>
            <Table.HeaderCell>{t('steps.submittedText')}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {actions.map(({ data: { groupName, submittedText } }) => (
            <Table.Row key={submittedText}>
              <Table.Cell>{groupName}</Table.Cell>
              <Table.Cell>{submittedText}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </TableWrapper>
  );
};
