import React from 'react';
import { slideWrapper } from '../components/slide';
import { PuzzleItem, PuzzleItemsWrapper, ItemImage } from '../components/puzzle-item';
import { Timer } from '../components/timer';

import { Content } from '../components/content';
import { SlideImage } from '../components/slide-image';
import styled from 'styled-components';
import { Text } from '../components/text';
import { Colors } from '../constants';
import { useConfirmation } from '../../generic/hooks/use-confirmation';

export const TeslaPuzzle2 = slideWrapper(({ step, data, getImg, ...props }) => {
  const { answers, noteText } = step;

  const ConfirmationPopup = useConfirmation(1);
  return (
    <>
      <ConfirmationPopup />
      <Timer data={data} presentation />

      <Content left={75} top={20} yNotCenter>
        <SlideImage width="500px" src={getImg('mapBig')} show={true} />
      </Content>
      <Content left={-5} bottom={0} xNotCenter yNotCenter>
        <SlideImage width="700px" src={getImg('most')} show={true} />
      </Content>

      <Content left={15} bottom={50} yNotCenter>
        <SlideImage width="150px" src={getImg('piwo2')} show={true} />
      </Content>
      <PuzzleItemsWrapper>
        <PuzzleItem getImg={getImg} letter="a" answers={answers} smallItem />
        <PuzzleItem getImg={getImg} letter="b" answers={answers} smallItem />
        <PuzzleItem getImg={getImg} letter="c" answers={answers} smallItem />
        <PuzzleItem getImg={getImg} letter="d" answers={answers} smallItem />
        <MapWrapper>
          <ItemImage marginTop={10} marginLeft={-80} smallItem={true}>
            <img src={getImg('map')} />
          </ItemImage>
          <Text>{noteText}</Text>
        </MapWrapper>
      </PuzzleItemsWrapper>
    </>
  );
});

const MapWrapper = styled.div`
  display: flex;
  max-width: 500px;
  align-items: center;
  span {
    color: ${Colors.BLUE};
  }
`;
