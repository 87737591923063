import { MappedObject } from '../../../../utils/src/global';

export interface GroupItemsData {
  hypatiaItems?: MappedObject<ItemData>;
  hypatiaCollected?: any;
  hypatiaUsed?: string[];
  leonardoItems?: MappedObject<ItemData>;
  leonardoCollected?: any;
  leonardoUsed?: string[];
  teslaItems?: MappedObject<ItemData>;
  teslaCollected?: any;
  teslaUsed?: string[];
}

export interface ItemData {
  qty: number;
  used: number;
}

export enum RoundType {
  Hypatia = 'hypatia',
  Leonardo = 'leonardo',
  Tesla = 'tesla',

  //Santa locations
  Office = 'office',
  PostOffice = 'postOffice',
  Barn = 'barn',
  Library = 'library',
  ArtHouse = 'artHouse',
}

export const RoundLetters = {
  2: ['a', 'b'],
  3: ['a', 'b', 'c'],
  4: ['a', 'b', 'c', 'd'],
  5: ['a', 'b', 'c', 'd', 'e'],
  6: ['a', 'b', 'c', 'd', 'e', 'f'],
};

export enum ItemType {
  CAMERA = 'camera',
  LINE = 'line',
  COINS = 'coins',
  DOLLARS = 'dollars',
  TOOLS = 'tools',
  DRONE = 'drone',
  TENT = 'tent',
  COSTUME = 'costume',
  FLASHLIGHT = 'flashlight',
  COMPUTER = 'computer',
  PAINTS = 'paints',
  SUIT = 'suit',
  GUN = 'gun',
  FAKERS = 'fakers',
  WHISKEY = 'whiskey',
  MAP = 'map',
  CROWBAR = 'crowbar',
  ENCYCLOPEDIA = 'encyclopedia',

  //Santa items
  SANTA_HINT_1 = 'santa-hint-1',
  SANTA_HINT_2 = 'santa-hint-2',
  SANTA_HINT_3 = 'santa-hint-3',
  SANTA_HINT_4 = 'santa-hint-4',
  SANTA_HINT_5 = 'santa-hint-5',
  SANTA_HINT_6 = 'santa-hint-6',
  SANTA_HINT_7 = 'santa-hint-7',
  SANTA_HINT_8 = 'santa-hint-8',
  SANTA_OFFICE_YULE_REPORT = 'santa-office-yule-report',
  SANTA_POST_OFFICE_REGISTER = 'santa-post-office-register',
  SANTA_POST_OFFICE_MAIL = 'santa-post-office-mail',
  SANTA_BARN_BOOK = 'santa-barn-book',
  SANTA_BARN_CROWN = 'santa-barn-crown',
  SANTA_BARN_INSTRUMENTS = 'santa-barn-instruments',
  SANTA_LIBRARY_BOOK = 'santa-library-book',
  SANTA_ART_HOUSE_BOOK = 'santa-art-house-book',
}

export interface GroupFinalProps {
  id?: string;
  bossNameAnswer?: any;
  yearAnswer?: any;
  answerAccepted?: boolean;
  answerData?: any;
  duplicatedValues?: {
    bossName: string;
    year: string;
  };
  finalGroupResult?: FinalGroupResultType;
}

export enum FinalGroupResultType {
  NO_ANSWER = 'NO_ANSWER',
  ACCEPTED_INCORRECT = 'ACCEPTED_INCORRECT',
  ONLY_NAME = 'ONLY_NAME',
  ONLY_YEAR = 'ONLY_YEAR',
  WINNER = 'WINNER',
  ALMOST_WINNER = 'ALMOST_WINNER',
  UNKNOWN = 'UNKNOWN',
}
