import React from 'react';
import styled from 'styled-components';
import { FunctionComponent } from 'react';

interface TextProps {
  color?: string;
  display?: string;
  margin?: string | number;
  padding?: string | number;
  dangerouslySetInnerHTML?: { __html: string };
  weight?: number;
  onClick?: (any) => void;
  pointer?: boolean;
  textAlign?: string;
}

const Paragraph = styled.p<TextProps>`
  color: ${(props) => props.color};
  display: ${(props) => (props.display ? props.display : 'block')};
  margin: ${(props) => (props.margin ? (typeof props.margin === 'number' ? `${props.margin}px` : props.margin) : 0)};
  padding: ${(props) =>
    props.padding ? (typeof props.padding === 'number' ? `${props.padding}px` : props.padding) : 0};
  font-weight: ${(props) => (props.weight ? props.weight : 300)};
  cursor: ${(props) => props.pointer && 'pointer'};
  text-align: ${(props) => props.textAlign && props.textAlign};
`;

export const Text: FunctionComponent<TextProps> = ({ children, ...rest }) => {
  return <Paragraph {...rest}>{children}</Paragraph>;
};

export default Text;
