import React from 'react';
import { useTranslation } from 'react-i18next';
import { Content } from '../components/content';
import { playerInterfaceWrapper } from './player-interface';
import { useSolution } from './hooks';
import { PuzzleItem } from '../components/puzzle-item';
import { Text } from '../components/text';
import { Loader } from 'semantic-ui-react';
import styled from 'styled-components';
import { ItemType } from '@disco/data';

export const PresentSolution = playerInterfaceWrapper(({ step, data, getImg, inventoryItems }) => {
  const { t } = useTranslation();
  const { scoring } = data;
  const { answers, type } = step;

  const { selectedOption } = useSolution(scoring);

  let withMapPenalty = false;
  if (
    type === 'chronos-tesla-puzzle-2-solution' &&
    inventoryItems &&
    !inventoryItems.find((ele) => ele.item === ItemType.MAP)
  ) {
    withMapPenalty = true;
  }

  return (
    <div style={{ margin: '32px 8px' }}>
      <Text>{t('chronos.selectedEffect')}</Text>
      {scoring && answers ? (
        <PuzzleItem getImg={getImg} letter={selectedOption} answers={answers} playerInterface />
      ) : (
        <Loader />
      )}
      {answers && withMapPenalty && <Text>{t('chronos.addHourMore')}</Text>}
    </div>
  );
});
