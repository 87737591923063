import React from 'react';
import { usePresenterActions } from '../utils/use-presenter-actions';
import { QuestionRadioChart } from './reused-components/question-radio-chart';
import { getUniqueUserActions } from '@disco/utils';
import { List } from 'semantic-ui-react';
import { QuestionRadioStep } from '@disco/data';
import { UserCounter } from './reused-components/user-counter';
import { ChartWrapper, FlexWrapper, HeaderWrapper, Legend } from './reused-components/presenter-chart-wrappers';

export const ChartPresenterRadioAnswer = ({ data, title, options, isUnique = true }) => {
  const { actions = [] } = usePresenterActions(data);

  const currentStep = data?.currentStep as QuestionRadioStep;
  const resources = data?.resources;

  return (
    <ChartWrapper>
      <HeaderWrapper>
        <h1>{title}</h1>
        <UserCounter actions={actions} />
      </HeaderWrapper>
      <FlexWrapper>
        <div style={{ minHeight: '55vh', minWidth: currentStep?.legend ? '80%' : '100%', display: 'grid' }}>
          <QuestionRadioChart
            resources={resources}
            actions={isUnique ? getUniqueUserActions(actions) : actions}
            options={options}
            imageLabels={currentStep.imageLabels}
          />
        </div>
        {currentStep?.legend && (
          <List as={Legend}>
            {data?.groups?.map((item) => (
              <List.Item key={item.name}>
                <List.Icon style={{ color: item.color }} verticalAlign="middle" size="small" name="circle" />
                <List.Content>{item.name}</List.Content>
              </List.Item>
            ))}
          </List>
        )}
      </FlexWrapper>
    </ChartWrapper>
  );
};
