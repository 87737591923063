import React from 'react';
import { FunctionComponent } from 'react';
import { Text } from '@disco/ui';
import { Icon, SemanticICONS } from 'semantic-ui-react';
import styled from 'styled-components';
import { copyToClipboard } from '@disco/utils';

interface LabeledTextProps {
  textKey: string;
  value: string;
  inline?: boolean;
  top?: number;
  bottom?: number;
  withCopy?: boolean;
  icon?: SemanticICONS;
}

const CustomIcon = styled(Icon)`
  display: inline;
`;

export const LabeledText: FunctionComponent<LabeledTextProps> = ({
  textKey,
  value,
  inline,
  top,
  bottom,
  icon,
  withCopy,
}) => {
  return (
    <div style={{ marginTop: `${top}px` || 0, marginBottom: `${bottom}px` || 0 }}>
      {icon && <CustomIcon name={icon} />}
      <Text
        {...{
          weight: 600,
          display: inline ? 'inline' : undefined,
        }}
      >
        {inline ? `${textKey}: ` : textKey}
        {withCopy && <Icon name="copy" color={'grey'} onClick={copyToClipboard(value)} />}
      </Text>
      <Text
        {...{
          color: 'grey',
          padding: '0 0 0 8px',
          display: inline ? 'inline' : undefined,
        }}
      >
        {value || '-'}
      </Text>
    </div>
  );
};
