import React from 'react';
import { slideWrapper } from '../components/slide';
import { TextBox } from '../components/text-box';
import { Content } from '../components/content';
import { useAnimationSteps } from '@disco/hooks';
import styled from 'styled-components';
import { Rule } from '../components/rule';

const TimeImage = styled.img<any>`
  opacity: ${(props) => (props.show ? 1 : 0)};
  transition: opacity 0.3s;
  max-height: 100vh;
`;

const TextBoxWrapper = styled.div`
  margin: 32px;
  margin-bottom: 48px;
`;

export const HowToPlayRules = slideWrapper(({ step, resources, ...props }) => {
  const { title, rule1, rule2, rule3, rule4, rule5, rule6, imageLabels } = step;

  const rules = [rule1, rule2, rule3, rule4, rule5, rule6].filter((rule) => rule);
  const animationStep = useAnimationSteps(rules.length);

  return (
    <>
      {imageLabels?.time && (
        <Content left={80} top={50}>
          <TimeImage show={animationStep > 0} src={resources[imageLabels?.time]} />
        </Content>
      )}
      <Content yNotCenter top={7} left={50}>
        <TextBoxWrapper>
          <TextBox>{title}</TextBox>
        </TextBoxWrapper>
        {rules.map((rule, index) => rule && <Rule key={index} show={animationStep > index} text={rule} />)}
      </Content>
    </>
  );
});
