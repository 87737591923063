import React, { useState } from 'react';

import { Wrapper } from '../../../components/Wrapper';
import { useTranslation } from 'react-i18next';
import { Card, TextGroup } from '@disco/ui';
import { useStepAction } from '../../../../api/use-step-action';

export const PlayerInterface = ({ data, uid, ...props }) => {
  const { t } = useTranslation();
  const [optionSubmitted, setOptionSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const { postAction } = useStepAction();

  const onPost = (textEntry) => {
    postAction({ data: { submittedText: textEntry }, userId: uid, slideData: data });
    setOptionSubmitted(true);
  };

  return (
    <Wrapper>
      <Card color={'#114a94'} title={optionSubmitted ? t('question.submitted') : t('fishery.thankYou')}>
        {!optionSubmitted && <TextGroup maxLength={255} submitting={submitting} postAction={onPost} />}
      </Card>
    </Wrapper>
  );
};
