import React, { useEffect, useState } from 'react';
import { BaseStep, SessionPublicData } from '@disco/data';
import { useTranslation } from 'react-i18next';
import { useCookies } from '@disco/hooks';
import { Card } from '@disco/ui';
import firebase from 'firebase/app';
import { SESSION_COLLECTION, STEP_SUBCOLLECTION, ACTION_SUBCOLLECTION } from '@disco/data';
import Page from '../components/page';
import { Title } from '../components/title';
import { UserCounter } from '../components/user-counter';
import { Form } from 'semantic-ui-react';
import { HFInput } from '../../../../../ui/src/lib/form/hf-input';
import { useForm } from 'react-hook-form';
import { GameButton } from '../components/game-button';
import { useStepAction } from '../../api/use-step-action';
import { useSelectGroup } from '../../api/use-select-group';

export interface SelectGroupProps {
  uid: string;
  data: SessionPublicData;
  presentation?: boolean;
}

export function SelectGroup({ uid, data, presentation }: SelectGroupProps) {
  const { t } = useTranslation();

  const { updateSessionCookie, getSessionCookie } = useCookies();
  const { postAction } = useStepAction();
  const { validateGroup } = useSelectGroup();

  const { groupName } = getSessionCookie();

  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
  } = useForm();

  const [groupSelected, setGroupSelected] = useState(!!groupName);
  const [submitting, setSubmitting] = useState(false);

  const [actions, setActions] = useState([]);

  const onGroupSubmit = async ({ groupName }) => {
    setSubmitting(true);
    const result = await validateGroup(data.sessionId, groupName);

    if (result.code === 200) {
      await postAction({
        data: { groupName },
        userId: uid,
        slideData: data,
        isGroupSelection: true,
      });
      updateSessionCookie({ groupName });
      setGroupSelected(true);
      const event = new Event('cookie-updated');
      document.dispatchEvent(event);
    } else {
      setError('groupName', { message: t('group.groupNotFound') });
    }
    setSubmitting(false);
  };

  useEffect(() => {
    if (presentation) {
      return firebase
        .firestore()
        .collection(SESSION_COLLECTION)
        .doc(data.sessionId)
        .collection(STEP_SUBCOLLECTION)
        .doc(String(data.currentStep.id))
        .collection(ACTION_SUBCOLLECTION)
        .onSnapshot((snapshot) => {
          const list = [];
          snapshot.forEach((doc) => list.push(doc.data()));
          setActions(list);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const currentStep = data.currentStep as BaseStep;

  return (
    <Page data={data} presentation={presentation}>
      {presentation ? (
        <>
          <Title fontColor={currentStep?.color}>{currentStep?.title}</Title>
          <UserCounter actions={actions} />
        </>
      ) : (
        <Card color={currentStep?.color} title={groupSelected ? t('group.groupSelected') : t('group.header')}>
          {!groupSelected && (
            <Form onSubmit={handleSubmit(onGroupSubmit)}>
              <HFInput
                {...{
                  name: 'groupName',
                  defaultValue: '',
                  control,
                  placeholder: t('group.placeholder'),
                  transform: (e) => e.target.value.toUpperCase(),
                  errors,
                }}
              />
              <GameButton loading={submitting} fluid>
                {t('send')}
              </GameButton>
            </Form>
          )}
        </Card>
      )}
    </Page>
  );
}

export default SelectGroup;
