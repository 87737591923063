import React from 'react';
import { slideWrapper } from '../components/slide';
import { Content } from '../components/content';
import { SlideImage } from '../components/slide-image';
import styled from 'styled-components';
import { Text } from '../components/text';

const SlideText = styled.div`
  font-size: 60px;
  margin: 0 20px;
  z-index: 999;
`;

export const TeslaPuzzle2Story = slideWrapper(({ step, getImg, ...props }) => {
  const { text, animationsCount } = step;

  return (
    <>
      <Content left={50} top={5} yNotCenter>
        <SlideImage width="350px" rotate={'-10deg'} src={getImg('latarnia')} show={true} />
      </Content>
      <Content left={75} top={-5} yNotCenter>
        <SlideImage width="400px" src={getImg('zegarek')} show={true} />
      </Content>
      <Content width="80%" yNotCenter bottom={10}>
        <SlideText>
          <Text big alignCenter>
            {text}
          </Text>
        </SlideText>
      </Content>
    </>
  );
});
