import { Text } from '../../components/text';
import styled from 'styled-components';
import React from 'react';
import { Colors } from '../../constants';

interface OptionProps {
  answer: string;
  text: string;
  onClick: (ans: string) => void;
  active: boolean;
  src: string;
  disabled?: boolean;
  isItemStep?: boolean;
  srcWrapper?: string;
  style?: string;
}

const OptionWrapper = styled.div<any>`
  ${(props) => (props.disabled ? 'background-color: #9797979e;' : props.active ? 'background-color: #8080802b;' : '')}
  width: ${(props) => (props.disabled ? 'calc(100% - 14px)' : '100%')};
  display: flex;
  gap: 16px;
  padding: 16px;
  border: 2px solid
    ${(props) =>
      props.disabled
        ? 'transparent'
        : props.active
        ? props.isChristmas
          ? Colors.BLUE
          : Colors.BEIGE
        : props.isChristmas
        ? Colors.DARK_GREY_DISABLED
        : Colors.BROWN};
  box-shadow: ${(props) => props.disabled && '0 0 5px 5px #9797979e'};
  height: ${(props) => (props.isChristmas || !props.isItemStep ? 'unset' : '100px;')};

  img {
    filter: ${(props) => props.disabled && 'grayscale(1)'};
  }
`;

//todo maybe put span.colors in some global style (TextWrapper in puzzle-item same)
const Item = styled.div<any>`
  display: flex;
  align-items: center;
  filter: ${(props) => props.count === 0 && 'grayscale(100)'};
  img {
    width: 80px;
    @media screen and (max-width: 1000px) {
      width: 65px;
    }
    height: auto;
    margin-right: 16px;
  }

  span.green {
    color: ${Colors.GREEN};
  }
  span.blue {
    color: ${Colors.BLUE};
  }
  span.red {
    color: ${Colors.RED};
  }
`;

const ImgWrapper = styled.div`
  position: relative;
`;

const OptionImgWrapper = styled.img`
  width: 120px !important;
  @media screen and (max-width: 1000px) {
    width: 90px !important;
  }
  position: absolute;
  top: -10px;
  left: -20px;
`;

export const Option = ({
  answer,
  text,
  onClick,
  active,
  src,
  srcWrapper,
  disabled,
  style,
  isItemStep,
}: OptionProps) => {
  return text ? (
    <OptionWrapper
      onClick={disabled ? undefined : onClick}
      {...{ active, disabled }}
      isChristmas={style === 'santa'}
      isItemStep={isItemStep}
    >
      <Item>
        <ImgWrapper>
          <img alt={`option-${answer}`} height={70} src={src} />
          {srcWrapper && <OptionImgWrapper src={srcWrapper} />}
        </ImgWrapper>
        <Text
          shadow={disabled && style === 'santa' ? '2px 2px 10px #000000e3' : null}
          color={disabled ? Colors.DARK_GREY_DISABLED : style === 'santa' ? Colors.BLUE : Colors.BROWN}
          small
        >
          {text}
        </Text>
      </Item>
    </OptionWrapper>
  ) : null;
};
