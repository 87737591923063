import { Button } from 'semantic-ui-react';
import React, { useCallback, useEffect } from 'react';
import { CountdownStatus } from '@disco/data';
import { Icon } from 'semantic-ui-react';

export function TimerControl({ currentStep, changeTimer, state }) {
  const isPaused = state && state.status === CountdownStatus.PAUSED;
  const isRunning = state && state.status === CountdownStatus.STARTED;

  const onStart = async () => {
    await changeTimer(currentStep.seconds, CountdownStatus.STARTED);
  };

  const onRestart = async () => {
    await changeTimer(currentStep.seconds, CountdownStatus.STARTED);
    await changeTimer(currentStep.seconds, CountdownStatus.PAUSED);
  };

  const onPause = async () => {
    await changeTimer(state.seconds, CountdownStatus.PAUSED);
  };

  const onResume = async () => {
    await changeTimer(state.seconds, CountdownStatus.STARTED);
  };

  const onComplete = useCallback(async () => {
    await changeTimer(0, CountdownStatus.COMPLETED);
  }, [changeTimer]);

  useEffect(() => {
    if (state && state.status === CountdownStatus.COMPLETED) {
      onComplete();
    }
  }, [onComplete, state, state?.status]);

  // console.log(state);

  return (
    <div>
      {isPaused && (
        <Button onClick={onResume} icon>
          <Icon name="play" />
        </Button>
      )}

      {isRunning && (
        <Button onClick={onPause} icon>
          <Icon name="stop" />
        </Button>
      )}

      {(isPaused || isRunning) && (
        <Button onClick={onRestart} icon>
          <Icon name={'redo'} />
        </Button>
      )}
      {!(isPaused || isRunning) && (
        <Button onClick={onStart} icon>
          <Icon name={'play'} />
        </Button>
      )}
    </div>
  );
}
