import React, { Component } from 'react';
import { Page } from '@disco/ui';

class ErrorBoundary extends Component<any, any> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  render() {
    const { t } = this.props;

    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <Page>Something went wrong.</Page>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary
