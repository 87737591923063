import React, { useState } from 'react';
import { Button, Form, Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { SemanticCOLORS } from 'semantic-ui-react/dist/commonjs/generic';
import styled from 'styled-components';
import { GameButton } from '../../../../steps/src/lib/collectors/components/game-button';

export interface RadioGroupProps {
  options: string[] | { value: string; label: string }[];
  postAction: (string) => void;
  currentViewStep?: any;
  presentation?: boolean;
  buttonColor?: string;
  resources?: {};
  maxOptions?: number;
  submitting?: boolean;
}

const StyledImages = styled.img`
  max-width: 40px;
  min-height: 30px;
`;

const StyledButton = styled(Button)`
  &&& {
    position: relative;
    display: flex;
    align-items: center;
    padding: 4px 16px 4px 4px;
  }
`;

const StyledLabel = styled.div`
  text-align: left;
  padding: 10px 20px 12px 80px;
  width: 100%;
`;

const IconWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 4px;
`;

export function RadioGroup({
  submitting,
  options = [],
  resources = {},
  currentViewStep,
  presentation = false,
  postAction,
  buttonColor,
  maxOptions = 1,
}: RadioGroupProps) {
  const { t } = useTranslation();

  const [selectedOptions, setSelectedOptions] = useState([]);

  const { imageLabels = {} } = currentViewStep;

  return (
    <Form>
      {(options as Array<any>).map((ele) => {
        const value = typeof ele === 'string' ? ele : ele.value;
        const label = typeof ele === 'string' ? ele : ele.label;
        return (
          <Form.Field key={value}>
            <StyledButton
              fluid
              basic={!selectedOptions.includes(value)}
              color={buttonColor as SemanticCOLORS}
              onClick={() => {
                if (selectedOptions.includes(value)) {
                  setSelectedOptions(selectedOptions.filter((item) => item !== value));
                } else {
                  setSelectedOptions((selected) => [value, ...selected].slice(0, maxOptions));
                }
              }}
            >
              <IconWrapper>
                {!Number(value) && !!Object.keys(imageLabels).length && imageLabels[value] && (
                  <StyledImages src={resources[imageLabels[value]]} alt={value} />
                )}
                <Icon size="large" name={selectedOptions.includes(value) ? 'check circle outline' : 'circle outline'} />
              </IconWrapper>
              {Number(value) && !!Object.keys(imageLabels).length && imageLabels[value] ? (
                <StyledImages style={{ margin: 'auto' }} src={resources[imageLabels[value]]} alt={value} />
              ) : (
                <StyledLabel>{label}</StyledLabel>
              )}
            </StyledButton>
          </Form.Field>
        );
      })}
      {!presentation && (
        <GameButton loading={submitting} fluid onClick={() => postAction(selectedOptions)}>
          {t('send')}
        </GameButton>
      )}
    </Form>
  );
}

export default RadioGroup;
