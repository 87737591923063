import { GroupData } from '@disco/data';
import React from 'react';
import { BarChart, Bar, LabelList, XAxis, YAxis, Legend, ResponsiveContainer } from 'recharts';
import { size } from './constants';

interface BarChartPresentationProps {
  data: any;
  groups: GroupData[];
}

export const BarChartPresentation = ({ data, groups = [] }: BarChartPresentationProps) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 5,
          left: 5,
          bottom: 5,
        }}
      >
        <XAxis dataKey="name" />
        <YAxis hide type="number" domain={[0, 'dataMax + 1']} />
        <Legend />
        {groups.map((group) => (
          <Bar
            isAnimationActive={false}
            maxBarSize={size.BAR_MAX_WIDTH}
            key={group.name}
            dataKey={group.name}
            fill={group.color}
          >
            <LabelList dataKey={group.name} position="top" />
          </Bar>
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};
