export { HypatiaInformations } from './hypatia-informations';
export { HypatiaPuzzle1Story } from './hypatia-puzzle-1-story';
export { HypatiaPuzzle1 } from './hypatia-puzzle-1';
export { HypatiaPuzzle1Counting } from './hypatia-puzzle-1-counting';
export { HypatiaPuzzle1Solution } from './hypatia-puzzle-1-solution';
export { HypatiaPuzzle2Story } from './hypatia-puzzle-2-story';
export { HypatiaPuzzle2 } from './hypatia-puzzle-2';
export { HypatiaPuzzle2Counting } from './hypatia-puzzle-2-counting';
export { HypatiaPuzzle2Solution } from './hypatia-puzzle-2-solution';
export { HypatiaPuzzle3 } from './hypatia-puzzle-3';
export { HypatiaPuzzle3Counting } from './hypatia-puzzle-3-counting';
export { HypatiaPuzzle3Solution } from './hypatia-puzzle-3-solution';
export { HypatiaPuzzle3Story } from './hypatia-puzzle-3-story';
export { HypatiaItems } from './hypatia-items';
