import { UserCounter } from 'libs/steps/src/lib/collectors/components/user-counter';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { usePresenterActions } from '../../../utils/use-presenter-actions';
import { GROUP_COLORS } from '@disco/data';
import { ChartWrapper, HeaderWrapper } from '../../../components/reused-components/presenter-chart-wrappers';

export const Content = ({ data }) => {
  const { t } = useTranslation();

  const { actions } = usePresenterActions(data);

  return (
    <ChartWrapper>
      <HeaderWrapper>
        <h1>{t('fishery.selectYourMood')}</h1>
        <UserCounter actions={actions} />
      </HeaderWrapper>
      <FlexWrapper>
        {actions.map((action, index) => (
          <BorderBox key={action.data.submittedText + index} borderColor={GROUP_COLORS[index % GROUP_COLORS.length]}>
            {action.data.submittedText}
          </BorderBox>
        ))}
      </FlexWrapper>
    </ChartWrapper>
  );
};

const FlexWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 32px;
`;

const BorderBox = styled.div<{ borderColor: string }>`
  border-radius: 12px;
  border: 2px solid ${(props) => props.borderColor};
  padding: 24px;
  font-size: 28px;
  line-height: 32px;
  font-weight: bold;
  margin: 8px;
  justify-content: center;
  align-items: center;
  display: flex;
`;
