import Cookies from 'js-cookie';
import { useCallback } from 'react';
import { LanguageCookie, PresentationCookie, SessionCookie } from '@disco/data';

export const sessionInfoCookieName = 'session-info';
const presentationInfoCookieName = 'presentation-info';
const langCookieName = 'lang';

export const useCookies = () => {
  const setSessionCookie = useCallback((info) => {
    Cookies.set(sessionInfoCookieName, JSON.stringify(info) as SessionCookie, { expires: 1 });
  }, []);

  const getSessionCookie = useCallback(() => {
    const cookie = Cookies.get(sessionInfoCookieName);
    return cookie ? (JSON.parse(cookie) as SessionCookie) : {};
  }, []);

  const updateSessionCookie = useCallback(
    (info) => {
      const obj = getSessionCookie();
      Cookies.set(sessionInfoCookieName, { ...obj, ...info }, { expires: 1 });
    },
    [getSessionCookie]
  );

  //TODO refactor to generic
  const setPresentationCookie = useCallback((info) => {
    Cookies.set(presentationInfoCookieName, JSON.stringify(info) as PresentationCookie, { expires: 1 });
  }, []);

  const getPresentationCookie = useCallback(() => {
    const cookie = Cookies.get(presentationInfoCookieName);
    return cookie ? (JSON.parse(cookie) as PresentationCookie) : {};
  }, []);

  const setLanguageCookie = useCallback((info) => {
    Cookies.set(langCookieName, JSON.stringify(info) as LanguageCookie, { expires: 1 });
  }, []);

  const getLanguageCookie = useCallback(() => {
    const cookie = Cookies.get(langCookieName);
    return cookie ? (JSON.parse(cookie) as LanguageCookie) : {};
  }, []);

  return {
    setSessionCookie,
    getSessionCookie,
    updateSessionCookie,
    setPresentationCookie,
    getPresentationCookie,
    setLanguageCookie,
    getLanguageCookie,
  };
};
