import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { UserCounter } from 'libs/steps/src/lib/collectors/components/user-counter';
import { usePresenterActions } from '../../../utils/use-presenter-actions';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .label,
  .value {
    font-family: Klavika !important;
    color: #114a94 !important;
  }
`;

const WidgetsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

export const Content = ({ data }) => {
  const { t } = useTranslation();

  const { actions } = usePresenterActions(data);

  return (
    <Wrapper>
      <h1>{t('fishery.referendumVotingTitle')}</h1>
      <WidgetsWrapper>
        <UserCounter actions={actions} />
      </WidgetsWrapper>
    </Wrapper>
  );
};
