import React from 'react';
import { slideWrapper } from '../components/slide';
import { Timer } from '../components/timer';
import { Content } from '../components/content';
import { PuzzleItem, PuzzleItemsWrapper } from '../components/puzzle-item';
import { useAnimationSteps } from '@disco/hooks';
import { SlideImage } from '../components/slide-image';
import { useConfirmation } from '../../generic/hooks/use-confirmation';

export const LeonardoPuzzle1 = slideWrapper(({ data, step, getImg }) => {
  const { answers } = step;

  const animationStep = useAnimationSteps(1, true);

  const ConfirmationPopup = useConfirmation(1);
  return (
    <>
      <ConfirmationPopup />
      <Timer data={data} presentation />

      <Content left={12} top={10} yNotCenter>
        <SlideImage width="400px" src={getImg('glasses')} show={animationStep} />
      </Content>
      <Content left={10} bottom={0} yNotCenter>
        <SlideImage width="400px" src={getImg('mona')} show={animationStep} />
      </Content>
      <PuzzleItemsWrapper>
        <PuzzleItem getImg={getImg} letter="a" answers={answers} />
        <PuzzleItem getImg={getImg} letter="b" answers={answers} smallItem />
        <PuzzleItem getImg={getImg} letter="c" answers={answers} smallItem />
        <PuzzleItem getImg={getImg} letter="d" answers={answers} smallItem />
        <PuzzleItem getImg={getImg} letter="e" answers={answers} smallItem />
      </PuzzleItemsWrapper>
    </>
  );
});
