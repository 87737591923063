import firebase from 'firebase/app';
import {
  GeneratedContextDocumentId,
  GeneratedSectionContextFishery,
  getAvailableStrategies,
  RoundType,
  SESSION_COLLECTION,
  SESSION_GENERATED_CONTEXT_SUBCOLLECTION,
  SESSION_PUBLIC_COLLECTION,
  SessionStatus,
  STEP_VOTING,
  Strategy,
} from '@disco/data';

export const setNextStep = async (sessionId, orderId, sessionData, steps, onSuccess = () => null) => {
  firebase
    .firestore()
    .collection(SESSION_COLLECTION)
    .doc(sessionId)
    .set(
      {
        currentStepId: orderId?.toString(),
        requiredStepIsOn:
          !!sessionData.requiredStepIsOn || String(sessionData?.requiredStep?.stepId) === String(orderId),
      },
      { merge: true }
    )
    .then(onSuccess);
  if (sessionData.status === SessionStatus.OPEN) {
    const currentStep = steps.find((ele) => ele.orderId === orderId);

    const scoring = currentStep?.type ? await getChronosScoringForPublicSession(sessionId, currentStep) : undefined;
    const contextData = ['lowisko', 'lowisko-en'].includes(sessionData.gameId)
      ? await getFisheryContextData(sessionId)
      : {};

    await firebase
      .firestore()
      .collection(SESSION_PUBLIC_COLLECTION)
      .doc(sessionData.sessionCode)
      .set({
        requiredStep: sessionData.requiredStepIsOn ? sessionData.requiredStep : null,
        currentStep,
        sessionId,
        gameId: sessionData.gameId,
        language: sessionData.language || 'pl',
        ...(scoring ? { scoring } : {}),
        contextData,
      });
  }
};

const getChronosScoringForPublicSession = async (sessionId, currentStep) => {
  if (
    (currentStep.type.startsWith(`chronos-${RoundType.Hypatia}-puzzle`) ||
      currentStep.type.startsWith(`chronos-${RoundType.Leonardo}-puzzle`) ||
      currentStep.type.startsWith(`chronos-${RoundType.Tesla}-puzzle`)) &&
    currentStep.type.endsWith('solution')
  ) {
    const scoreRef = await firebase
      .firestore()
      .collection(SESSION_COLLECTION)
      .doc(sessionId)
      .collection(STEP_VOTING)
      .doc((Number(currentStep.id) - Number(currentStep.stepsAfterVoting)).toString())
      .get();
    return scoreRef.exists && scoreRef.data();
  }

  return undefined;
};

const getFisheryContextData = async (sessionId: string) => {
  const context = await firebase
    .firestore()
    .collection(SESSION_COLLECTION)
    .doc(sessionId)
    .collection(SESSION_GENERATED_CONTEXT_SUBCOLLECTION)
    .doc(GeneratedContextDocumentId.GENERAL)
    .get();
  const contextData = context.exists && { ...(context.data() as GeneratedSectionContextFishery) };
  return {
    stocking: contextData.stocking,
    roundNumber: contextData.roundNumber,
    strategies: getAvailableStrategies(contextData.roundNumber).filter((strategy) => strategy !== Strategy.none),
    dynamiteUserId: contextData.dynamiteUserId,
  };
};
