import React from 'react';
import styled from 'styled-components';
import { Colors } from '../constants';

const StyledText = styled.div<any>`
  margin-bottom: ${(props) => props.marginBottom}px;
  margin-top: ${(props) => props.marginTop}px;
  color: ${(props) => props.color || props.theme.textColor || Colors.TEXT};
  text-shadow: ${(props) => (props?.shadow ? props.shadow : props.theme.textShadow || 'none')};
  text-align: ${(props) => props.alignCenter && 'center'};
  font-weight: ${(props) => props.isBold && 'bold'};
  font-size: ${(props) =>
    props.gigant
      ? '60px'
      : props.huge
      ? '46px'
      : props.big
      ? '38px'
      : props.medium
      ? '32px'
      : props.small
      ? '20px'
      : '26px'};
  line-height: ${(props) =>
    props.gigant
      ? '64px'
      : props.huge
      ? '50px'
      : props.big
      ? '41px'
      : props.medium
      ? '37px'
      : props.small
      ? '22px'
      : '28px'};
`;

export const Text = ({ children, ...props }) => (
  <StyledText {...props} dangerouslySetInnerHTML={{ __html: children }} />
);
