import { GROUP_COLORS, CHRISTMAS_GROUP_COLORS } from '@disco/data';
import React from 'react';
import styled from 'styled-components';
import { Text } from '../text';
import { LineAreaChart } from './presentation/line-area-chart';

interface QuestionRangeChartsProps {
  actions: { data: { groupName?: string; submittedText?: string } }[];
  minText: string;
  maxText: string;
  options: string[];
  style: string;
}

const ChartsWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const TextLeft = styled.div`
  transform: translateX(calc(-50% + 25px)) rotate(-90deg);
`;

const TextRight = styled.div`
  transform: translateX(calc(50% - 25px)) rotate(-90deg);
`;

export const QuestionRangeCharts = ({ actions, style, minText, maxText, options }: QuestionRangeChartsProps) => {
  return (
    <>
      {options.map((option, index) => (
        <LineAreaChart
          key={option}
          actions={actions}
          label={option}
          color={style == 'christmas' ? CHRISTMAS_GROUP_COLORS[index] : GROUP_COLORS[index]}
        />
      ))}
      <ChartsWrapper>
        <TextLeft>
          <Text>{minText}</Text>
        </TextLeft>
        <TextRight>
          <Text>{maxText}</Text>
        </TextRight>
      </ChartsWrapper>
    </>
  );
};
