import { getUniqueUserActions } from '@disco/utils';
import * as React from 'react';
import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';

const Text = styled.div<{
  color?: string;
  align?: string;
  marginTop?: number;
  marginBottom?: number;
  isBold?: boolean;
}>`
  &&& {
    font-size: 48px;
    line-height: 64px;
    color: ${(props) => props.color};
    text-align: ${(props) => props.align};
    margin-top: ${(props) => props.marginTop}px;
    margin-bottom: ${(props) => props.marginBottom}px;
    font-weight: ${(props) => props.isBold && 'bold'};

    @media screen and (max-width: 1000px) {
      font-size: 24px;
      line-height: 27px;
    }
  }
`;

const UserCounterWrapper = styled.div<{ margin: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${(props) => props.margin};
  color: ${(props) => props.theme.textColor};
  text-shadow: ${(props) => props.theme.textShadow || 'none'};
`;

const StyledText = styled(Text)`
  margin-left: 16px;
`;

export const Group = ({ users, margin = '' }) => {
  return (
    <>
      <Icon size="huge" name="group" />
      <StyledText>{users}</StyledText>
    </>
  );
};

export const UserCounter = ({ actions, margin = '' }) => {
  const users = getUniqueUserActions(actions);

  return (
    <UserCounterWrapper margin={margin}>
      <Group margin={margin} users={users.length} />
    </UserCounterWrapper>
  );
};
