import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from 'semantic-ui-react';

export const Subscription = ({ id, until }) => {
  const { t } = useTranslation();

  return (
    <Card>
      <Card.Content>
        <Card.Header>{id}</Card.Header>
      </Card.Content>
      <Card.Content>
        <Card.Description>
          <div style={{ marginBottom: 4 }}>{t('settings.accessUntil')}:</div>
          {until}
        </Card.Description>
      </Card.Content>
    </Card>
  );
};
