import React from 'react';
import { slideWrapper } from '../components/slide';
import styled from 'styled-components';
import { Content } from '../components/content';
import { SlideImage } from '../components/slide-image';
import { RoundLetters, RoundType } from '@disco/data';
import { PuzzleItems } from '../components/puzzle-items';

export const LeonardoPuzzle1Counting = slideWrapper(
  ({ step, getImg, countingResults, countingResultsLoading }) => {
    const { answers } = step;

    return (
      <>
        <Content left={12} top={10} yNotCenter>
          <SlideImage width="400px" src={getImg('glasses')} show={true} />
        </Content>
        <Content left={14} bottom={0} yNotCenter>
          <SlideImage width="400px" src={getImg('mona')} show={true} />
        </Content>
        <PuzzleItems
          {...{ countingResults, countingResultsLoading, getImg, answers, letters: RoundLetters[5] }}
          smallItem
        />
      </>
    );
  },
  { countAnswersTour: RoundType.Leonardo }
);
