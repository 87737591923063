import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Wrapper } from '../../../components/Wrapper';
import { Interface } from '../../../components/player-interface/Interface';
import { useUserData } from '../../../utils/update-user';

import { useStepAction } from '../../../../api/use-step-action';
import { Button } from '../../../components/player-interface/Button';
import { colorConfig } from '../../../config/colors';
import { useTranslation } from 'react-i18next';

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  width: 100%;
  padding: 0 16px;
  max-width: 400px;
  text-align: center;

  button {
    justify-content: center;
  }
`;

export const PlayerInterface = ({ data, uid, ...props }) => {
  const { t } = useTranslation();

  const { conferance } = data;

  const [selected, setSelected] = useState<boolean>(null);

  const userData = useUserData(data.sessionId, uid);

  const { postAction } = useStepAction();

  const onSelect = (strategy: boolean) => () => {
    setSelected(strategy);
    postAction({ data: { selectedOption: String(strategy) }, userId: uid, slideData: data });
  };

  return (
    <Wrapper>
      <Interface
        sessionData={data}
        data={userData}
        content={
          conferance ? (
            <ButtonsWrapper>
              <h1>{t('fishery.doYouWantSpeakAtConferenceQuestion')}</h1>
              <Button
                selected={selected === true}
                onClick={onSelect(true)}
                bgColor={colorConfig.orange.light}
                fontColor={colorConfig.orange.dark}
              >
                {t('fishery.doYouWantConferenceYes')}
              </Button>
              <Button
                selected={selected === false}
                onClick={onSelect(false)}
                bgColor={colorConfig.blue.light}
                fontColor={colorConfig.blue.dark}
              >
                {t('fishery.doYouWantConferenceNo')}
              </Button>
            </ButtonsWrapper>
          ) : undefined
        }
      />
    </Wrapper>
  );
};
