import React from 'react';

import { Wrapper } from '../../../components/Wrapper';
import { useUserData } from '../../../utils/update-user';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Text = styled.div`
  text-align: center;
  font-size: 2rem;
`;

export const PlayerInterface = ({ data, uid, ...props }) => {
  const { t } = useTranslation();

  const userData = useUserData(data.sessionId, uid);

  return (
    <Wrapper>
      <h1>{`${t('fishery.summaryYourResults')}: ${userData.overallPoints}`}</h1>
    </Wrapper>
  );
};
