export enum CollectorsStepType {
  QUESTION_RADIO = 'question-radio',
  QUESTION_TEXT = 'question-text',
  QUESTION_RANGE = 'question-range',
  SELECT_GROUP = 'select-group',
  PRESENT = 'present',
  PRESENT_TIMER = 'present-timer',
  PRESENT_VOTING = 'present-voting',
  PRESENT_SCORE_TABLE = 'present-score-table',
}
