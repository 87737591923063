import { useEffect, useState } from 'react';
import { useApi } from '../../generic/hooks/use-api';

export const useCollectorsScore = (currentStep, sessionData, fetching) => {
  const [scoreData, setScoreData] = useState<any>(null);
  const [loading, setLoading] = useState(true);

  const callApi = useApi();

  useEffect(() => {
    (async () => {
      if (!fetching || !currentStep.id) return;
      if (!currentStep.showSummary && currentStep.airport && currentStep.stepsAfterVoting) {
        const response = await callApi('/collectors-game/score/' + currentStep.airport, {
          method: 'post',
          body: {
            sessionId: sessionData.sessionId,
            votingStepId: String(Number(currentStep.id) - Number(currentStep.stepsAfterVoting)),
          },
        });
        setScoreData(response.data);
      }
      if (currentStep.showSummary) {
        const response = await callApi('/collectors-game/score/summary', {
          method: 'post',
          body: { sessionId: sessionData.sessionId, currentStepId: currentStep.orderId },
        });
        setScoreData(response.data);
      }
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep]);

  return { scoreData, loading };
};
