import { Role } from '../../../models/user';

export const ROLES: Option[] = [
  { key: 'presenter', value: Role.PRESENTER.toString(), text: Role.PRESENTER.toString() },
  { key: 'admin', value: Role.ADMIN.toString(), text: Role.ADMIN.toString() },
  { key: 'dev', value: Role.DEV.toString(), text: Role.DEV.toString() },
];

export interface Option {
  key: string;
  value: string;
  text?: string;
  data?: any;
}
