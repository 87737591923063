import React from 'react';
import { useTranslation } from 'react-i18next';

import { Wrapper } from '../../../components/Wrapper';
import { LoadingAnimation } from '../../../components/LoadingAnimation';

export const PlayerInterface = ({ data, uid, ...props }) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <LoadingAnimation />
    </Wrapper>
  );
};
