import React from 'react';
import { slideWrapper } from '../components/slide';
import styled from 'styled-components';
import { Content } from '../components/content';
import { Text } from '../components/text';
import { SlideImage } from '../components/slide-image';

const SlideText = styled.div`
  font-size: 60px;
  margin: 0 20px;
`;

export const LeonardoPuzzle4Story = slideWrapper(({ step, getImg, ...props }) => {
  const { text, animationsCount } = step;

  return (
    <>
      <Content left={80} top={40} yNotCenter>
        <SlideImage width="700px" src={getImg('pioro')} show={true} />
      </Content>
      <Content left={14} top={0} yNotCenter>
        <SlideImage width="1120px" src={getImg('budynek')} show={true} />
      </Content>
      <Content left={50} top={-2} yNotCenter>
        <SlideImage width="300px" rotate={'-30deg'} src={getImg('herb')} show={true} />
      </Content>
      <Content left={80} top={30} yNotCenter>
        <SlideImage width="400px" src={getImg('baba')} show={true} />
      </Content>
      <Content width="80%" yNotCenter bottom={10}>
        <SlideText>
          <Text big alignCenter>
            {text}
          </Text>
        </SlideText>
      </Content>
    </>
  );
});
