import * as enCommon from './locales/en.json';
import * as plCommon from './locales/pl.json';

export * from './lib/context/user';
export * from './lib/context/permissions';
export * from './lib/login/login';
export * from './lib/page/page';
export * from './lib/card/card';
export * from './lib/button/custom-button';
export * from './lib/button/confirm-modal';
export * from './lib/private-route/private-route';
export * from './lib/text/text';
export * from './lib/container/container';
export * from './lib/form/text-group';
export * from './lib/form/range-group';
export * from './lib/logo/asobi';

export { enCommon, plCommon };
