import React, { useCallback, useEffect, useState } from 'react';
import firebase from 'firebase/app';

import { useTranslation } from 'react-i18next';
import {
  GeneratedContextDocumentId,
  SESSION_COLLECTION,
  SESSION_GENERATED_CONTEXT_SUBCOLLECTION,
  SESSION_PUBLIC_COLLECTION,
  SessionStatus,
  STEP_SUBCOLLECTION,
} from '@disco/data';
import { RoundBlock } from './RoundBlock';
import { RoundSettings, RoundSummary, RoundWrapper } from './RoundWrapper';
import { Loader, Popup } from 'semantic-ui-react';
import { CustomButton } from '@disco/ui';

interface RoundConfig {
  roundNumber: number;
}

export const FisheryControls = ({ sessionId, sessionData }) => {
  const { t } = useTranslation();
  const [nextRoundConfig, setNextRoundConfig] = useState<RoundConfig>();
  const [currentRoundContext, setCurrentRoundContext] = useState<RoundConfig>();

  const onConfig = useCallback(
    (id, setConfig) => {
      return firebase
        .firestore()
        .collection(SESSION_COLLECTION)
        .doc(sessionId)
        .collection(SESSION_GENERATED_CONTEXT_SUBCOLLECTION)
        .doc(id)
        .onSnapshot((doc) => {
          setConfig(doc.data() as RoundConfig);
        });
    },
    [sessionId]
  );

  useEffect(() => {
    return onConfig(GeneratedContextDocumentId.NEXT_ROUND, setNextRoundConfig);
  }, [onConfig]);

  useEffect(() => {
    return onConfig(GeneratedContextDocumentId.GENERAL, setCurrentRoundContext);
  }, [onConfig]);

  const updateNextRoundConfig = useCallback(
    (id, config) => {
      firebase
        .firestore()
        .collection(SESSION_COLLECTION)
        .doc(sessionId)
        .collection(SESSION_GENERATED_CONTEXT_SUBCOLLECTION)
        .doc(id)
        .set(config, { merge: true });
    },
    [sessionId]
  );

  const updateFieldNextConfig = useCallback(
    (key: string, value: string) => {
      updateNextRoundConfig(GeneratedContextDocumentId.NEXT_ROUND, {
        [key]: value,
      });
    },
    [updateNextRoundConfig]
  );

  const updateFieldCurrentConfig = useCallback(
    (key: string, value: string) => {
      updateNextRoundConfig(GeneratedContextDocumentId.GENERAL, {
        [key]: value,
      });
    },
    [updateNextRoundConfig]
  );

  const currentRound = currentRoundContext?.roundNumber;

  if (!currentRoundContext) {
    return <Loader />;
  }

  const onEndGame = async () => {
    if (sessionData.status === SessionStatus.OPEN) {
      const orderId = String(sessionData?.endOrderId);
      const stepRef = await firebase
        .firestore()
        .collection(SESSION_COLLECTION)
        .doc(sessionId)
        .collection(STEP_SUBCOLLECTION)
        .doc(orderId)
        .get();
      const stepData = stepRef.exists && stepRef.data();

      await firebase
        .firestore()
        .collection(SESSION_COLLECTION)
        .doc(sessionId)
        .set(
          {
            currentStepId: orderId,
            requiredStepIsOn: !!sessionData.requiredStepIsOn || String(sessionData?.requiredStep?.stepId) === orderId,
          },
          { merge: true }
        );
      await firebase
        .firestore()
        .collection(SESSION_PUBLIC_COLLECTION)
        .doc(sessionData.sessionCode)
        .set({
          requiredStep: sessionData.requiredStepIsOn ? sessionData.requiredStep : null,
          currentStep: stepData,
          sessionId,
          gameId: sessionData.gameId,
          language: sessionData.language || 'pl',
        });
    }
  };

  return (
    <RoundWrapper>
      <RoundSummary>
        <h2>{t('fisheryAdmin.currentRound')}</h2>
        {currentRound > 0 ? (
          <RoundBlock
            currentRound={currentRound}
            currentSlide
            config={currentRoundContext || {}}
            onChangeValue={updateFieldCurrentConfig}
          />
        ) : (
          <RoundSettings>
            <h3>{t('fisheryAdmin.gameStart')}</h3>
          </RoundSettings>
        )}
      </RoundSummary>
      <RoundSummary>
        <h2>{t('fisheryAdmin.nextRound')}</h2>
        <RoundBlock
          currentRound={currentRound + 1}
          currentSlide={false}
          config={nextRoundConfig || {}}
          onChangeValue={updateFieldNextConfig}
        />
      </RoundSummary>
      <RoundSettings style={{ maxWidth: '80px' }}>
        <Popup
          content={t('fisheryAdmin.endButton')}
          trigger={
            <div>
              <CustomButton
                withConfirmation
                onMakeAction={onEndGame}
                content={t('fisheryAdmin.endButtonConfirmation')}
                icon="stop"
              />
            </div>
          }
        />
      </RoundSettings>
    </RoundWrapper>
  );
};
