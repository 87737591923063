import React, { useCallback, useEffect } from 'react';
import { slideWrapper } from '../components/slide';
import { Text } from '../components/text';
import { useApi } from '@disco/hooks';
import { ChronosApiEndpoints } from '../../../../../data/src/lib/chronos/api-endpoints';
import { ContentWrapper } from './styled';
import { Content } from '../components/content';

export const FinalSolutionChronos = slideWrapper(({ step, resources, data }) => {
  const { text1 } = step;
  const callApi = useApi();

  const { sessionId } = data;

  const checkAnswers = useCallback(async () => {
    await callApi(ChronosApiEndpoints.postChronosCheckFinalAnswer, {
      method: 'POST',
      body: {
        sessionId,
      },
    });
  }, [callApi, sessionId]);

  useEffect(() => {
    checkAnswers();
  }, []);

  return (
    <Content width="90%" top={16}>
      <Text alignCenter huge>
        {text1}
      </Text>
    </Content>
  );
});
