import React from 'react';
import { slideWrapper } from '../components/slide';
import { Content } from '../components/content';
import { SlideImage } from '../components/slide-image';
import styled from 'styled-components';
import { Text } from '../components/text';

const SlideText = styled.div`
  font-size: 60px;
  margin: 0 20px;
  z-index: 999;
`;

export const TeslaPuzzle3Story = slideWrapper(({ step, getImg, ...props }) => {
  const { text, animationsCount } = step;

  return (
    <>
      <Content left={75} top={5} yNotCenter>
        <SlideImage width="500px" src={getImg('zdjecie')} show={true} />
      </Content>
      <Content left={50} top={25} yNotCenter>
        <SlideImage width="1100px" src={getImg('maszyna')} show={true} />
      </Content>
      <Content left={25} top={5} yNotCenter>
        <SlideImage width="650px" src={getImg('zdjecia')} show={true} />
      </Content>
      <Content width="80%" yNotCenter bottom={10}>
        <SlideText>
          <Text alignCenter>{text}</Text>
        </SlideText>
      </Content>
    </>
  );
});
