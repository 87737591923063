import React, { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import { useTranslation } from 'react-i18next';
import { Button, Dimmer, Header, Loader, Segment, Table } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import { GAMES_COLLECTION } from '@disco/data';
import { InstructionModal } from '../components/modals/instruction/instruction';
import { useUsers } from '../api/use-users';
import { usePermission } from '@disco/hooks';

enum GameModalTypes {
  INSTRUCTIONS,
}

export function Games() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const { currentUserCompanies } = useUsers();

  const history = useHistory();

  const gameDetailViewPermission = usePermission('gameDetailView');

  const [modalType, setModalType] = useState<GameModalTypes>(null);
  const [selectedGame, setSelectedGame] = useState(null);

  useEffect(() => {
    const gamesRef = firebase
      .firestore()
      .collection(GAMES_COLLECTION)
      .where('companies', 'array-contains-any', currentUserCompanies);

    const unsubscribe = gamesRef.onSnapshot((snapshot) => {
      const list = [];
      snapshot.forEach((doc) => list.push({ ...doc.data(), id: doc.id }));
      setData(list);
      setLoading(false);
    });
    return unsubscribe;
  }, []);

  const onSelectRow = (id) => {
    if (!gameDetailViewPermission) return;
    history.push('/games/' + id);
  };

  const onOpenInstructions = (e: any, id: string) => {
    e.stopPropagation();
    setModalType(GameModalTypes.INSTRUCTIONS);
    setSelectedGame(id);
  };

  const onClose = () => setModalType(null);

  return (
    <Segment basic>
      <Header size="huge">{t('games.title')}</Header>
      <Dimmer inverted active={loading}>
        <Loader />
      </Dimmer>
      <Table basic="very" selectable={gameDetailViewPermission} textAlign="left">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell style={{ paddingLeft: '.5em' }} singleLine>
              {t('games.id')}
            </Table.HeaderCell>
            <Table.HeaderCell>{t('games.language')}</Table.HeaderCell>
            <Table.HeaderCell>{t('games.instructions')}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {data.map(({ id, language }) => (
            <Table.Row key={id} style={{ cursor: 'pointer' }} onClick={() => onSelectRow(id)}>
              <Table.Cell style={{ paddingLeft: '.5em' }}>{id}</Table.Cell>
              <Table.Cell>{language}</Table.Cell>
              <Table.Cell>
                <Button icon="folder open" size="tiny" onClick={(e) => onOpenInstructions(e, id)} />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      <InstructionModal {...{ selectedGame, onClose, open: modalType === GameModalTypes.INSTRUCTIONS }} />
    </Segment>
  );
}

export default Games;
