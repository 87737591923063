import firebase from 'firebase/app';
import { SESSION_COLLECTION, SESSION_USERS_COLLECTION, Strategy } from '@disco/data';
import { useEffect, useState } from 'react';

export const updateUser = async (data, sessionId, uid) => {
  await firebase
    .firestore()
    .collection(SESSION_COLLECTION)
    .doc(sessionId)
    .collection(SESSION_USERS_COLLECTION)
    .doc(uid)
    .set(data, { merge: true });
};

export const useUserData = (sessionId, uid): FisheryUserData => {
  const [userData, setUserData] = useState<FisheryUserData>({});

  useEffect(() => {
    return firebase
      .firestore()
      .collection(SESSION_COLLECTION)
      .doc(sessionId)
      .collection(SESSION_USERS_COLLECTION)
      .doc(uid)
      .onSnapshot((snapshot) => {
        setUserData(snapshot.data() as FisheryUserData);
      });
  }, [sessionId, uid]);

  return userData || {};
};

export const getUserScore = (userData: FisheryUserData = {}, currentStep: any = {}): StepPoints => {
  if (!Object.keys(userData).length || !currentStep?.orderId || !userData.stepPoints)
    return { strategy: Strategy.none, points: '-' };
  return userData?.stepPoints[Number(currentStep?.orderId) - Number(currentStep?.stepsAfterVoting || 0)];
};

export interface FisheryUserData {
  shipName?: string;
  stepPoints?: { [key: number]: StepPoints };
  overallPoints?: number;
}

export interface StepPoints {
  strategy: string;
  points: string;
}
