import React from 'react';
import { slideWrapper } from '../components/slide';
import { Content } from '../components/content';
import { SlideImage } from '../components/slide-image';
import { PuzzleItem, PuzzleItemsWrapper } from '../components/puzzle-item';

export const TeslaPuzzle2Solution = slideWrapper(({ step, getImg }) => {
  const { answers } = step;

  return (
    <>
      <Content left={-5} bottom={0} xNotCenter yNotCenter>
        <SlideImage width="700px" src={getImg('most')} show={true} />
      </Content>

      <Content left={15} bottom={50} yNotCenter>
        <SlideImage width="150px" src={getImg('piwo2')} show={true} />
      </Content>
      <Content left={7} bottom={50} yNotCenter>
        <SlideImage width="150px" src={getImg('piwo1')} show={true} />
      </Content>
      <PuzzleItemsWrapper>
        <PuzzleItem getImg={getImg} letter="a" answers={answers} />
        <PuzzleItem getImg={getImg} letter="b" answers={answers} />
        <PuzzleItem getImg={getImg} letter="c" answers={answers} />
        <PuzzleItem getImg={getImg} letter="d" answers={answers} />
      </PuzzleItemsWrapper>
    </>
  );
});
