import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Text } from '../components/text';
import { Colors } from '../constants';
import { Loader } from 'semantic-ui-react';

const InventoryWrapper = styled.div`
  padding: 16px;
  box-sizing: border-box;
  gap: 16px;
  display: flex;
  flex-direction: column;
`;

const Item = styled.div<any>`
  display: flex;
  align-items: center;
  margin-top: 16px;
  filter: ${(props) => props.count === 0 && 'grayscale(100)'};
  text-decoration: ${(props) => props.count === 0 && 'line-through'};
  img {
    width: 100px;
    height: auto;
    margin-right: 16px;
  }
  p {
    color: ${(props) => props.theme.textColor || Colors.BLUE};
  }
`;

export const SelectedInventoryItems = ({ inventoryItems = [], loading, getImg, isSanta }) => {
  const { t } = useTranslation();

  if (loading) return <Loader active />;

  if (!inventoryItems.length)
    return (
      <InventoryWrapper>
        <Text alignCenter>{t('chronos.noItemsYet')}</Text>
      </InventoryWrapper>
    );

  function partition(array, filter) {
    const pass = [],
      fail = [];
    array.forEach((e, idx, arr) => (filter(e, idx, arr) ? pass : fail).push(e));
    return [pass, fail];
  }

  if (isSanta) {
    const [hints, items] = partition(inventoryItems, ({ item }) => item.includes('hint'));
    return (
      <InventoryWrapper>
        {!!hints?.length && (
          <div>
            <Text alignCenter>{t('chronos.collectedHints')}</Text>
            {hints?.map(({ item }) => (
              <Item key={item}>
                <Text small>{`${t(`chronos.${item}`)}`}</Text>
              </Item>
            ))}
          </div>
        )}
        {!!items?.length && (
          <div>
            <Text alignCenter>{t('chronos.collectedItems')}</Text>
            {items?.map(({ item, count }) => (
              <Item key={item} count={count}>
                {getImg(item) && <img alt={item} src={getImg(item)} />}
                <Text small>{`${t(`chronos.${item}`)} ${count > 1 ? `(${count})` : ''}`}</Text>
              </Item>
            ))}
          </div>
        )}
      </InventoryWrapper>
    );
  } else {
    return (
      <InventoryWrapper>
        <Text alignCenter style={{ marginTop: '32px' }}>
          {t('chronos.missionItems')}
        </Text>
        {inventoryItems
          .filter((ele) => ele.item !== 'undefined')
          .map(({ item, count }) => (
            <Item key={item} count={count}>
              {getImg(item) && <img alt={item} src={getImg(item)} />}
              <Text small>{`${t(`chronos.${item}`)} ${count > 1 ? `(${count})` : ''}`}</Text>
            </Item>
          ))}
      </InventoryWrapper>
    );
  }
};
