import { useTranslation } from 'react-i18next';
import { Form } from 'semantic-ui-react';
import { HFSelect } from 'libs/ui/src/lib/form/hf-select';
import { HFInput } from 'libs/ui/src/lib/form/hf-input';
import { HFDatepicker } from 'libs/ui/src/lib/form/hf-datepicker';
import { HFNumber } from 'libs/ui/src/lib/form/hf-number';
import React from 'react';
import { DEFAULT_VARIANT, InitialGroupList } from './initial-groups';

export interface Option {
  key: string;
  value: string;
  text?: string;
  data?: any;
}

export const SessionForm = ({
  handleSubmit,
  errors,
  control,
  variantKey,
  groupVariants,
  companies,
  games = [],
  presenters = [],
  edit = false,
}) => {
  const { t } = useTranslation();

  return (
    <Form onSubmit={handleSubmit}>
      {!edit && (
        <Form.Group widths="equal">
          <HFSelect
            {...{
              name: 'gameId',
              label: t('createSession.type'),
              options: games.map((p) => ({ text: p, value: p, key: p })),
              required: true,
              errors,
              control,
            }}
          />
        </Form.Group>
      )}
      {companies?.length > 1 && (
        <Form.Group widths="equal">
          <HFSelect
            {...{
              multiple: true,
              name: 'companies',
              label: t('users.companies'),
              options: companies,
              required: true,
              errors,
              control,
            }}
          />
        </Form.Group>
      )}
      {groupVariants.length > 0 && (
        <Form.Group>
          <HFSelect
            {...{
              width: "13",
              name: 'variantKey',
              label: t('createSession.initialGroupSet'),
              options: groupVariants,
              defaultValue: DEFAULT_VARIANT,
              required: true,
              errors,
              control,
            }}
          />
          <Form.Field width="3">
            <label>{'\u00A0'}</label>
            <InitialGroupList {...{ variants: groupVariants, variantKey }} />
          </Form.Field>
        </Form.Group>
      )}
      <Form.Group widths="equal">
        <HFInput
          {...{
            name: 'name',
            label: t('createSession.sessionName'),
            placeholder: t('createSession.sessionNamePlaceholder'),
            defaultValue: '',
            required: true,
            errors,
            control,
            width: 7,
          }}
        />
        <HFInput
          {...{
            name: 'sessionCode',
            label: t('createSession.sessionCode'),
            defaultValue: '',
            required: true,
            errors,
            disabled: edit,
            control,
            width: 4,
            icon: 'key',
            transform: (e) => e.target.value.toUpperCase(),
          }}
        />
      </Form.Group>
      <Form.Group widths="equal">
        <HFDatepicker
          {...{
            name: 'plannedDate',
            label: t('createSession.plannedDateTime'),
            required: true,
            errors,
            control,
          }}
        />
        <HFInput
          {...{
            name: 'participants',
            label: t('createSession.participants'),
            defaultValue: '',
            errors,
            control,
          }}
        />
        <HFInput
          {...{
            name: 'meetingLink',
            label: t('createSession.meetingLink'),
            defaultValue: '',
            errors,
            control,
          }}
        />
      </Form.Group>
      <Form.Group widths="equal">
        <HFSelect
          {...{
            name: 'facilitator',
            label: t('createSession.facilitator'),
            options: presenters.map((p) => ({ text: p, value: p, key: p })),
            required: true,
            errors,
            control,
          }}
        />
        <HFNumber
          {...{
            name: 'groupSize',
            label: t('createSession.groupSize'),
            defaultValue: '',
            errors,
            control,
            min: 0,
            max: 100,
          }}
        />
        <HFInput
          {...{
            name: 'trainingObjective',
            label: t('createSession.trainingObjective'),
            defaultValue: '',
            errors,
            control,
          }}
        />
      </Form.Group>
      <HFInput
        {...{
          name: 'notes',
          label: t('createSession.notes'),
          defaultValue: '',
          errors,
          control,
          textarea: true,
        }}
      />
    </Form>
  );
};
