import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import styled from 'styled-components';
import { Button, Label } from 'semantic-ui-react';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const SinglePagePdf = ({ pdfUrl }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  return (
    <DocumentWrapper>
      <Document
        file={pdfUrl}
        loading={<LoadingWrapper>Ładowanie...</LoadingWrapper>}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        <Page width={1020} pageNumber={pageNumber} />
      </Document>
      {numPages > 1 && (
        <>
          <NavPageWrapper>
            <Button circular icon="chevron left" type="button" disabled={pageNumber <= 1} onClick={previousPage} />
            <Button circular icon="chevron right" type="button" disabled={pageNumber >= numPages} onClick={nextPage} />
          </NavPageWrapper>
          <NavPageWrapper style={{ marginBottom: 16}}>
            <Label basic>
              Strona {pageNumber || (numPages ? 1 : '--')} z {numPages || '--'}
            </Label>
          </NavPageWrapper>
        </>
      )}
    </DocumentWrapper>
  );
};

const DocumentWrapper = styled.div`
  max-height: 80vh;
  overflow: auto;
`;

const NavPageWrapper = styled.div`
  margin-top: 8px;
  display: flex;
  justify-content: center;
`;

const LoadingWrapper = styled.div`
  margin: 16px;
  display: flex;
  justify-content: center;
  font-weight: 700;
`;
