import React, { useEffect, useState } from 'react';
import { slideWrapper } from '../components/slide';
import { useCookies } from '@disco/hooks';
import firebase from 'firebase';
import {
  FinalGroupResultType,
  GroupFinalProps,
  SESSION_COLLECTION,
  SESSION_GROUP_FINAL_SUBCOLLECTION,
} from '@disco/data';
import { Loader } from 'semantic-ui-react';
import { ContentWrapper } from './styled';
import { replaceGroupFinal } from './functions';
import { Text } from '../components/text';

export const FinalSolutionChronosPresent = slideWrapper(({ step, resources, data, ...props }) => {
  const {
    textNoAnswer,
    textDoubleWrong,
    textBossCorrect,
    textYearCorrect,
    textDoubleCorrectFirst,
    textDoubleCorrect,
  } = step;

  const { sessionId } = data;
  const { getSessionCookie } = useCookies();
  const { groupName } = getSessionCookie();

  const [groupFinal, setGroupFinal] = useState<GroupFinalProps>({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    return firebase
      .firestore()
      .collection(SESSION_COLLECTION)
      .doc(sessionId)
      .collection(SESSION_GROUP_FINAL_SUBCOLLECTION)
      .doc(groupName)
      .onSnapshot((doc) => {
        setGroupFinal({ ...doc.data(), id: doc.id });
        setLoading(false);
      });
  }, [groupName, sessionId]);

  const getFinalText = () => {
    switch (groupFinal.finalGroupResult) {
      case FinalGroupResultType.NO_ANSWER:
        return textNoAnswer;
      case FinalGroupResultType.ACCEPTED_INCORRECT:
        return textDoubleWrong;
      case FinalGroupResultType.ONLY_NAME:
        return textBossCorrect;
      case FinalGroupResultType.ONLY_YEAR:
        return textYearCorrect;
      case FinalGroupResultType.WINNER:
        return textDoubleCorrectFirst;
      case FinalGroupResultType.ALMOST_WINNER:
        return textDoubleCorrect;
      default:
        return '';
    }
  };

  if (loading) return <Loader active />;

  return (
    <ContentWrapper>
      <Text>{replaceGroupFinal(getFinalText() || textNoAnswer, groupFinal)}</Text>
    </ContentWrapper>
  );
});
