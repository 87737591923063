import React from 'react';
import { slideWrapper } from '../components/slide';
import { PuzzleItem, PuzzleItemsWrapper } from '../components/puzzle-item';

import { SlideImage } from '../components/slide-image';
import { Content } from '../components/content';

export const TeslaPuzzle5Solution = slideWrapper(({ step, getImg }) => {
  const { answers } = step;

  return (
    <>
      <Content left={13} top={14} xNotCenter yNotCenter>
        <SlideImage width="250px" src={getImg('nuty')} show={true} />
      </Content>
      <Content left={10} bottom={4} yNotCenter>
        <SlideImage width="400px" src={getImg('pocztowka')} show={true} />
      </Content>
      <PuzzleItemsWrapper>
        <PuzzleItem getImg={getImg} letter="a" answers={answers} />
        <PuzzleItem getImg={getImg} letter="b" answers={answers} />
        <PuzzleItem getImg={getImg} letter="c" answers={answers} />
        <PuzzleItem getImg={getImg} letter="d" answers={answers} />
        <PuzzleItem getImg={getImg} letter="e" answers={answers} />
      </PuzzleItemsWrapper>
    </>
  );
});
