import { slideWrapper } from './slide';
import React from 'react';
import { TextBox } from './text-box';
import { Content } from './content';
import { useRoundSummary } from '../hooks/use-round-summary';
import { DisplayScore } from './display-score';
import firebase from 'firebase/app';
import { useEffect } from 'react';
import { SESSION_PUBLIC_COLLECTION } from '@disco/data';

export const RoundSummary = slideWrapper(({ data, step, getImg }) => {
  const { title, type } = step;
  const { sessionId, sessionCode } = data;

  const { summaryData } = useRoundSummary({ sessionId, type });

  const getRound = () => {
    if (step.type.includes('hypatia')) return 'hypatia';
    else if (step.type.includes('leonardo')) return 'leonardo';
    else if (step.type.includes('tesla')) return 'tesla';
  };

  const groups = summaryData?.length
    ? summaryData.map((ele) => ({
        groupName: ele.groupName,
        text: ele?.groupNickname || ele.groupName,
        score: ele[`${getRound()}Hours`],
      }))
    : [];

  useEffect(() => {
    if (!summaryData.length) return;
    firebase
      .firestore()
      .collection(SESSION_PUBLIC_COLLECTION)
      .doc(sessionCode)
      .set({ groupScores: groups, maxHours: 24 }, { merge: true });
  }, [summaryData]);

  return (
    <>
      <Content top={16}>
        <TextBox>{title}</TextBox>
      </Content>
      <Content width="100%" top={55}>
        <DisplayScore {...{ groups, getImg }} />
      </Content>
    </>
  );
});
