import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Wrapper } from '../../../components/Wrapper';
import { InterfaceWrapper } from '../../../components/InterfaceWrapper';
import { PlayerInterface } from './PlayerInterface';

import styled from 'styled-components';
import { ScoreTable } from '../../../components/ScoreTable';
import { useCurrentContext } from '../../../utils/use-current-context';
import firebase from 'firebase';
import { SESSION_COLLECTION, SESSION_USERS_COLLECTION } from '@disco/data';

const Box = styled.div`
  gap: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h1,
  h2 {
    text-align: center;
  }
  img {
    margin-left: -100px;
  }
`;

const StockingBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h2 {
    margin-top: 0;
  }
`;

const Boxes = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 24px;
`;

export const StartingStocking = InterfaceWrapper({
  Interface: PlayerInterface,
  Step: ({ data }) => {
    const { t } = useTranslation();
    const [userCount, setUserCount] = useState({});

    const context = useCurrentContext(data?.sessionId);

    useEffect(() => {
      getUserCount();
    }, []);

    const getUserCount = async () => {
      const sessionRef = firebase
        .firestore()
        .collection(SESSION_COLLECTION)
        .doc(data?.sessionId)
        .collection(SESSION_USERS_COLLECTION);

      const snapshot = await sessionRef.get();
      setUserCount(snapshot.size);
    };

    return (
      <Wrapper>
        <Boxes>
          <Box>
            <ScoreTable context={context} userCount={userCount} />
          </Box>
          <StockingBox>
            <h1>{t('fishery.fishStockingTitle')} 6</h1>
            <h2>(0-12)</h2>
          </StockingBox>
        </Boxes>
      </Wrapper>
    );
  },
});
