import React from 'react';
import { slideWrapper } from '../components/slide';
import { PuzzleItem, PuzzleItemsWrapper } from '../components/puzzle-item';
import { Timer } from '../components/timer';

import { SlideImage } from '../components/slide-image';
import { Content } from '../components/content';
import { useConfirmation } from '../../generic/hooks/use-confirmation';

export const TeslaPuzzle5 = slideWrapper(({ step, data, getImg, ...props }) => {
  const { answers } = step;

  const ConfirmationPopup = useConfirmation(1);
  return (
    <>
      <ConfirmationPopup />
      <Timer data={data} presentation />

      <Content left={15} top={14} xNotCenter yNotCenter>
        <SlideImage width="250px" src={getImg('lupa')} show={true} />
      </Content>
      <Content left={10} bottom={4} yNotCenter>
        <SlideImage width="400px" src={getImg('pocztowka')} show={true} />
      </Content>
      <PuzzleItemsWrapper>
        <PuzzleItem getImg={getImg} letter="a" answers={answers} smallItem />
        <PuzzleItem getImg={getImg} letter="b" answers={answers} smallItem />
        <PuzzleItem getImg={getImg} letter="c" answers={answers} smallItem />
        <PuzzleItem getImg={getImg} letter="d" answers={answers} smallItem />
        <PuzzleItem getImg={getImg} letter="e" answers={answers} smallItem />
      </PuzzleItemsWrapper>
    </>
  );
});
