import React from 'react';
import { slideWrapper } from '../components/slide';
import { Content } from '../components/content';
import { useAnimationSteps } from '@disco/hooks';
import { SlideImage } from '../components/slide-image';
import styled from 'styled-components';
import { Text } from '../components/text';

const SlideText = styled.div`
  font-size: 60px;
  margin: 0 20px;
`;

export const LeonardoPuzzle3Story = slideWrapper(({ step, getImg, ...props }) => {
  const { text, animationsCount } = step;

  const animationStep = useAnimationSteps(1, true);
  return (
    <>
      <Content left={50} top={40} yNotCenter>
        <SlideImage width="550px" src={getImg('machine')} show={animationStep} />
      </Content>
      <Content left={90} top={0} yNotCenter>
        <SlideImage width="450px" src={getImg('oldman')} show={true} />
      </Content>
      <Content width="80%" yNotCenter bottom={10}>
        <SlideText>
          <Text big alignCenter>
            {text}
          </Text>
        </SlideText>
      </Content>
    </>
  );
});
