import React from 'react';
import { slideWrapper } from '../components/slide';
import { TextBox } from '../components/text-box';
import { Text } from '../components/text';
import { Content } from '../components/content';
import styled from 'styled-components';
import { Timer } from '../../generic/timer/timer';
import { useConfirmation } from '../../generic/hooks/use-confirmation';

const TimeImage = styled.img<any>`
  opacity: ${(props) => (props.show ? 1 : 0)};
  transition: opacity 0.3s;
  max-height: 100vh;
`;

const TextWrapper = styled.div`
  margin-top: 40px;
`;

export const Final = slideWrapper(({ step, resources, data, ...props }) => {
  const { title, subtitle, text1, text2, imageLabels } = step;

  const ConfirmationPopup = useConfirmation(1);
  return (
    <>
      <ConfirmationPopup />
      <Content left={80} top={50}>
        <TimeImage show src={resources[imageLabels?.time]} />
      </Content>
      <Content yNotCenter top={7}>
        <TextBox>{title}</TextBox>
      </Content>
      <Content width="100%" yNotCenter y={140} top={20}>
        {data.currentStep?.timer && <Timer data={data} presentation={true} />}
        <TextWrapper>
          <Text huge>{subtitle}</Text>
        </TextWrapper>
        <TextWrapper>
          <Text big>{`• ${text1}`}</Text>
        </TextWrapper>
        <TextWrapper>
          <Text big>{`• ${text2}`}</Text>
        </TextWrapper>
      </Content>
    </>
  );
});
