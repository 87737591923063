import React from 'react';
import { slideWrapper } from '../components/slide';
import { Content } from '../components/content';
import { TextBox } from '../components/text-box';
import { useRoundSummary } from '../hooks/use-round-summary';
import styled from 'styled-components';
import { Text } from '../components/text';
import { startTimeHours } from '../player/components/time-score';
import { SantaImage } from './final-story';

export const SantaScoreTable = slideWrapper(
  ({ step, data, resources, getImg, countingResults, countingResultsLoading }) => {
    const { title, type } = step;
    const { sessionId } = data;

    const { summaryData = [] } = useRoundSummary({ sessionId, type });

    const isFinalTopSanta = type === 'chronos-santa-final-santa-top';

    const sand =
      getImg('sand') ||
      'https://firebasestorage.googleapis.com/v0/b/asobi-7cc09.appspot.com/o/chronos-santa%2Fsand-time.svg?alt=media&token=42002133-5d63-4cfb-9686-02c658f38568';

    return (
      <>
        <Content width="80%" yNotCenter top={10}>
          <TextBox>{title}</TextBox>
          <ScoreWrapper>
            {summaryData
              .sort((a, b) => a.hours - b.hours)
              .slice(0, 8)
              .map(({ groupName, groupNickname, hours }) => (
                <Score key={groupName}>
                  <TextWrapper>
                    <Text alignCenter>{groupNickname || groupName}</Text>
                  </TextWrapper>
                  {Array(startTimeHours - Math.ceil(hours / 4))
                    .fill(0)
                    .map((_, idx) => (
                      <FullSand key={`${groupName}BigSand${idx}`} src={sand} />
                    ))}
                  <PartSandWrapper>
                    {Array(hours % 4 !== 0 ? 4 - (hours % 4) : 0)
                      .fill(0)
                      .map((_, idx) => (
                        <PartSand key={`${groupName}sand${idx}`} src={sand} />
                      ))}
                  </PartSandWrapper>
                </Score>
              ))}
          </ScoreWrapper>
        </Content>
        {isFinalTopSanta && (
          <Content xNotCenter left={70} top={50}>
            <SantaImage show src={getImg('santa')} />
          </Content>
        )}
      </>
    );
  }
);

const TextWrapper = styled.div`
  width: 250px;
  justify-content: center;
  display: flex;
  p {
    max-width: 250px;
    text-overflow: ellipsis;
  }
`;

const Score = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

const FullSand = styled.img`
  height: 60px;
  width: auto;
`;

const PartSand = styled.img`
  height: 28px;
  width: auto;
`;

const PartSandWrapper = styled.div`
  width: 60px;
  height: 50px;
  display: flex;
  gap: 2px;
  flex-wrap: wrap-reverse;
  align-items: baseline;
`;

const ScoreWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
