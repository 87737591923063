import React, { useState } from 'react';

import { Wrapper } from '../../../components/Wrapper';
import { Card, RangeGroup } from '@disco/ui';
import { getOptionsGrade } from './Content';
import { useStepAction } from '../../../../api/use-step-action';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export const PlayerInterface = ({ data, uid, ...props }) => {
  const { t } = useTranslation();

  const [optionSubmitted, setOptionSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const { postAction } = useStepAction();

  const onPost = (values) => {
    Object.entries(values).forEach(([key, option]) => {
      postAction({ data: { option: key, value: option }, userId: uid, slideData: data });
    });
    setOptionSubmitted(true);
  };

  return (
    <Wrapper>
      <Card color={'#114a94'} title={optionSubmitted ? t('question.submitted') : t('fishery.thankYou')}>
        {!optionSubmitted && (
          <WrapperTextSize style={{ fontSize: '32px !important' }}>
            <RangeGroup style={'whatever'} submitting={submitting} options={getOptionsGrade(t)} postAction={onPost} />
          </WrapperTextSize>
        )}
      </Card>
    </Wrapper>
  );
};

const WrapperTextSize = styled.div`
  &&& label {
    font-size: 22px !important;
  }
`;
