export enum ChronosStepType {
  GAME_TITLE = 'chronos-game-title',
  SELECT_GROUP = 'chronos-select-group',
  SELECT_GROUP_NICKNAME = 'chronos-select-group-nickname',
  CHRONOS_VIDEO_STEP = 'chronos-video-step',
  HOW_TO_PLAY = 'chronos-how-to-play',
  HOW_TO_PLAY_RULES = 'chronos-how-to-play-rules',
  HOW_TO_PLAY_GAME = 'chronos-how-to-play-game',
  HOW_TO_PLAY_DETAILS = 'chronos-how-to-play-details',

  HYPATIA_ITEMS = 'chronos-hypatia-items',
  HYPATIA_INFORMATIONS = 'chronos-hypatia-informations',
  HYPATIA_STORY_1 = 'chronos-hypatia-puzzle-1-story',
  HYPATIA_PUZZLE_1 = 'chronos-hypatia-puzzle-1',
  HYPATIA_PUZZLE_1_COUNTING = 'chronos-hypatia-puzzle-1-counting',
  HYPATIA_SOLUTION_1 = 'chronos-hypatia-puzzle-1-solution',
  HYPATIA_STORY_2 = 'chronos-hypatia-puzzle-2-story',
  HYPATIA_PUZZLE_2 = 'chronos-hypatia-puzzle-2',
  HYPATIA_PUZZLE_2_COUNTING = 'chronos-hypatia-puzzle-2-counting',
  HYPATIA_SOLUTION_2 = 'chronos-hypatia-puzzle-2-solution',
  HYPATIA_PUZZLE_3 = 'chronos-hypatia-puzzle-3',
  HYPATIA_PUZZLE_3_COUNTING = 'chronos-hypatia-puzzle-3-counting',
  HYPATIA_STORY_3 = 'chronos-hypatia-puzzle-3-story',
  HYPATIA_SOLUTION_3 = 'chronos-hypatia-puzzle-3-solution',
  HYPATIA_ROUND_SUMMARY = 'chronos-hypatia-round-summary',

  LEONARDO_ITEMS = 'chronos-leonardo-items',
  LEONARDO_ITEMS_COLLECTING = 'chronos-leonardo-items-collecting',
  LEONARDO_INFORMATIONS = 'chronos-leonardo-informations',
  LEONARDO_STORY_1 = 'chronos-leonardo-puzzle-1-story',
  LEONARDO_PUZZLE_1 = 'chronos-leonardo-puzzle-1',
  LEONARDO_PUZZLE_1_COUNTING = 'chronos-leonardo-puzzle-1-counting',
  LEONARDO_SOLUTION_1 = 'chronos-leonardo-puzzle-1-solution',
  LEONARDO_STORY_2 = 'chronos-leonardo-puzzle-2-story',
  LEONARDO_PUZZLE_2 = 'chronos-leonardo-puzzle-2',
  LEONARDO_PUZZLE_2_COUNTING = 'chronos-leonardo-puzzle-2-counting',
  LEONARDO_SOLUTION_2 = 'chronos-leonardo-puzzle-2-solution',
  LEONARDO_STORY_3 = 'chronos-leonardo-puzzle-3-story',
  LEONARDO_PUZZLE_3 = 'chronos-leonardo-puzzle-3',
  LEONARDO_PUZZLE_3_COUNTING = 'chronos-leonardo-puzzle-3-counting',
  LEONARDO_SOLUTION_3 = 'chronos-leonardo-puzzle-3-solution',
  LEONARDO_STORY_4 = 'chronos-leonardo-puzzle-4-story',
  LEONARDO_PUZZLE_4 = 'chronos-leonardo-puzzle-4',
  LEONARDO_PUZZLE_4_COUNTING = 'chronos-leonardo-puzzle-4-counting',
  LEONARDO_SOLUTION_4 = 'chronos-leonardo-puzzle-4-solution',
  LEONARDO_ROUND_SUMMARY = 'chronos-leonardo-round-summary',
  LEONARDO_GAME_SUMMARY = 'chronos-leonardo-game-summary',

  TESLA_ITEMS = 'chronos-tesla-items',
  TESLA_ITEMS_COLLECTING = 'chronos-tesla-items-collecting',
  TESLA_INFORMATIONS = 'chronos-tesla-informations',
  TESLA_STORY_1 = 'chronos-tesla-puzzle-1-story',
  TESLA_PUZZLE_1 = 'chronos-tesla-puzzle-1',
  TESLA_PUZZLE_1_COUNTING = 'chronos-tesla-puzzle-1-counting',
  TESLA_SOLUTION_1 = 'chronos-tesla-puzzle-1-solution',
  TESLA_STORY_2 = 'chronos-tesla-puzzle-2-story',
  TESLA_PUZZLE_2 = 'chronos-tesla-puzzle-2',
  TESLA_PUZZLE_2_COUNTING = 'chronos-tesla-puzzle-2-counting',
  TESLA_SOLUTION_2 = 'chronos-tesla-puzzle-2-solution',
  TESLA_STORY_3 = 'chronos-tesla-puzzle-3-story',
  TESLA_PUZZLE_3 = 'chronos-tesla-puzzle-3',
  TESLA_PUZZLE_3_COUNTING = 'chronos-tesla-puzzle-3-counting',
  TESLA_SOLUTION_3 = 'chronos-tesla-puzzle-3-solution',
  TESLA_STORY_4 = 'chronos-tesla-puzzle-4-story',
  TESLA_PUZZLE_4 = 'chronos-tesla-puzzle-4',
  TESLA_PUZZLE_4_COUNTING = 'chronos-tesla-puzzle-4-counting',
  TESLA_SOLUTION_4 = 'chronos-tesla-puzzle-4-solution',
  TESLA_STORY_5 = 'chronos-tesla-puzzle-5-story',
  TESLA_PUZZLE_5 = 'chronos-tesla-puzzle-5',
  TESLA_PUZZLE_5_COUNTING = 'chronos-tesla-puzzle-5-counting',
  TESLA_SOLUTION_5 = 'chronos-tesla-puzzle-5-solution',
  TESLA_ROUND_SUMMARY = 'chronos-tesla-round-summary',
  TESLA_GAME_SUMMARY = 'chronos-tesla-game-summary',

  EPILOG = 'chronos-epilog',
  HOW_TO_PLAY_FINAL = 'chronos-how-to-play-final',
  FINAL = 'chronos-final',
  FINAL_SOLUTION = 'chronos-final-solution',
  FINAL_SCORES = 'chronos-final-scores',

  SANTA_INTRO = 'chronos-santa-intro',
  SANTA_HOW_TO_PLAY = 'chronos-santa-how-to-play',
  SANTA_YOUR_CHOICE = 'chronos-santa-your-choice',
  SANTA_SCORE_TABLE = 'chronos-santa-score-table',

  OFFICE_STORY_1 = 'chronos-santa-office-1-story',
  OFFICE_PUZZLE_1 = 'chronos-santa-office-1-puzzle',
  OFFICE_EFFECT_1 = 'chronos-santa-office-1-effect',

  POST_OFFICE_STORY_1 = 'chronos-santa-post-office-1-story',
  POST_OFFICE_STORY_2 = 'chronos-santa-post-office-2-story',
  POST_OFFICE_STORY_3 = 'chronos-santa-post-office-3-story',
  POST_OFFICE_STORY_4 = 'chronos-santa-post-office-4-story',
  POST_OFFICE_STORY_5 = 'chronos-santa-post-office-5-story',
  POST_OFFICE_PUZZLE_1 = 'chronos-santa-post-office-1-puzzle',
  POST_OFFICE_EFFECT_1 = 'chronos-santa-post-office-1-effect',
  POST_OFFICE_PUZZLE_2 = 'chronos-santa-post-office-2-puzzle',
  POST_OFFICE_EFFECT_2 = 'chronos-santa-post-office-2-effect',

  BARN_STORY_1 = 'chronos-santa-barn-1-story',
  BARN_STORY_2 = 'chronos-santa-barn-2-story',
  BARN_PUZZLE_1 = 'chronos-santa-barn-1-puzzle',
  BARN_EFFECT_1 = 'chronos-santa-barn-1-effect',
  BARN_STORY_3 = 'chronos-santa-barn-3-story',
  BARN_PUZZLE_2 = 'chronos-santa-barn-2-puzzle',
  BARN_EFFECT_2 = 'chronos-santa-barn-2-effect',
  BARN_STORY_4 = 'chronos-santa-barn-4-story',
  BARN_PUZZLE_3 = 'chronos-santa-barn-3-puzzle',
  BARN_EFFECT_3 = 'chronos-santa-barn-3-effect',
  BARN_STORY_5 = 'chronos-santa-barn-5-story',

  LIBRARY_STORY_1 = 'chronos-santa-library-1-story',
  LIBRARY_STORY_2 = 'chronos-santa-library-2-story',
  LIBRARY_PUZZLE_1 = 'chronos-santa-library-1-puzzle',
  LIBRARY_EFFECT_1 = 'chronos-santa-library-1-effect',
  LIBRARY_STORY_3 = 'chronos-santa-library-3-story',
  LIBRARY_PUZZLE_2 = 'chronos-santa-library-2-puzzle',
  LIBRARY_EFFECT_2 = 'chronos-santa-library-2-effect',
  LIBRARY_STORY_4 = 'chronos-santa-library-4-story',
  LIBRARY_ROUND_SUMMARY = 'chronos-santa-library-round-summary',
  LIBRARY_STORY_5 = 'chronos-santa-library-5-story',
  LIBRARY_YOUR_CHOICE = 'chronos-santa-library-your-choice',

  ART_HOUSE_STORY_1 = 'chronos-santa-art-house-1-story',
  ART_HOUSE_PUZZLE_1 = 'chronos-santa-art-house-1-puzzle',
  ART_HOUSE_EFFECT_1 = 'chronos-santa-art-house-1-effect',
  ART_HOUSE_STORY_2 = 'chronos-santa-art-house-2-story',
  ART_HOUSE_PUZZLE_2 = 'chronos-santa-art-house-2-puzzle',
  ART_HOUSE_EFFECT_2 = 'chronos-santa-art-house-2-effect',
  ART_HOUSE_STORY_3 = 'chronos-santa-art-house-3-story',
  ART_HOUSE_ROUND_SUMMARY = 'chronos-santa-art-house-round-summary',
  ART_HOUSE_YOUR_CHOICE = 'chronos-santa-art-house-your-choice',

  WHERE_SANTA = 'chronos-santa-where-santa',
  FINAL_SANTA_PUZZLE = 'chronos-santa-final-santa-puzzle',
  FINAL_SANTA_STORY_1 = 'chronos-santa-final-santa-1-story',
  FINAL_SANTA_STORY_2 = 'chronos-santa-final-santa-2-story',
  FINAL_SANTA_TOP = 'chronos-santa-final-santa-top',
  FINAL_SANTA_STORY_3 = 'chronos-santa-final-santa-3-story',
  FINAL_THANKS = 'chronos-santa-final-thanks',

  CHARACTER_DESCRIPTION = 'chronos-santa-character-description',
}
