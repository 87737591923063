import React, { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import { useTranslation } from 'react-i18next';
import { getUniqueUserActions } from '@disco/utils';
import { ACTION_SUBCOLLECTION, SESSION_COLLECTION, SessionData, STEP_SUBCOLLECTION, SessionStatus } from '@disco/data';
import { CustomButton } from '@disco/ui';
import styled from 'styled-components';
import { Form, Icon, Menu, Tab } from 'semantic-ui-react';
import { Groups } from './groups/groups';
import { LabeledText } from 'libs/ui/src/lib/text/labeled-text';
import { CurrentStepProps } from './session';
import { emitToast, ToastType } from 'libs/utils/src/lib/toast';
import { useSteps } from '../api/use-steps';
import EditStep from './edit-step';
import { CHART_MAP } from '../../../../../libs/steps/src/lib/collectors/step-types';
import { getControls, hasGroupTab } from './controls/config';

const StepChartWrapper = styled.div`
  flex: 1 1;
`;

const ActionButtonsWrapper = styled.div`
  display: block;
`;

const ChartsWrapper = styled.div`
  max-height: 600px;
  flex: 1 1;
  display: flex;
  flex-direction: column;
`;

const StyledTab = styled.div`
  flex: 1 1;
  display: flex;
  flex-direction: column;
  margin-top: 24px;
`;

const StyledTabPane = styled.div`
  flex: 1 1;
  display: flex;
`;

interface SessionControlsProps {
  sessionId: string;
  currentViewStep: CurrentStepProps;
  data: SessionData;
}

export const SessionControls = ({ sessionId, currentViewStep, data }: SessionControlsProps) => {
  const { t } = useTranslation();
  const { resetStepActions } = useSteps();
  const [actions, setActions] = useState<any[]>([]);

  const isSessionArchived = data?.status === SessionStatus.ARCHIVED;

  const StepChart = currentViewStep && CHART_MAP[currentViewStep.type] ? CHART_MAP[currentViewStep.type] : () => null;

  useEffect(() => {
    return firebase
      .firestore()
      .collection(SESSION_COLLECTION)
      .doc(sessionId)
      .collection(STEP_SUBCOLLECTION)
      .doc(String(currentViewStep?.id))
      .collection(ACTION_SUBCOLLECTION)
      .onSnapshot((snapshot) => {
        const list = [];
        snapshot.forEach((doc) => list.push(doc.data()));
        setActions(list);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentViewStep]);

  const onResetStep = async () => {
    resetStepActions({
      sessionId,
      stepId: currentViewStep.id,
    })
      .then(() => emitToast({ title: t('session.resetStep.successToast') }, ToastType.Success))
      .catch(() => emitToast({ title: t('session.resetStep.errorToast') }, ToastType.Error));
  };

  const GameSpecificTab = getControls(data?.gameId);

  const groupTabPresent = hasGroupTab(data?.gameId);

  const panes = [
    ...(GameSpecificTab
      ? [
          {
            key: 0,
            menuItem: 'Main',
            render: () => (
              <Tab.Pane as={StyledTabPane} attached={false}>
                <GameSpecificTab sessionId={sessionId} sessionData={data} />
              </Tab.Pane>
            ),
          },
        ]
      : []),
    ...(groupTabPresent
      ? [
          {
            key: 1,
            menuItem: t('session.sessionOverview'),
            render: () => (
              <Tab.Pane as={StyledTabPane} attached={false}>
                <Groups {...{ sessionId, data, isSessionArchived, currentViewStep }} />
              </Tab.Pane>
            ),
          },
        ]
      : []),
    {
      key: 2,
      menuItem: <Menu.Item disabled={!currentViewStep}>{t('session.stepDetails')}</Menu.Item>,
      render: () => (
        <Tab.Pane as={StyledTabPane} attached={false}>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            <LabeledText bottom={16} textKey={t('session.notes')} value={currentViewStep?.notes} />
            <ChartsWrapper>
              <Form>
                <Form.Field>
                  <label>{t('session.stepDataView')}</label>
                </Form.Field>
              </Form>
              {!isSessionArchived && (
                <ActionButtonsWrapper>
                  <CustomButton
                    withConfirmation
                    content={t('session.resetStep.content')}
                    onMakeAction={onResetStep}
                    negative
                  >
                    <Icon name="undo" />
                    {t('session.resetStep.button')}
                  </CustomButton>
                </ActionButtonsWrapper>
              )}
            </ChartsWrapper>
            <StepChartWrapper>
              <StepChart
                showTooltip
                actions={currentViewStep?.actionsAreNotUnique ? actions : getUniqueUserActions(actions)}
                groups={data?.groups}
                currentViewStep={currentViewStep}
              />
            </StepChartWrapper>
          </div>
        </Tab.Pane>
      ),
    },
    ...(isSessionArchived
      ? []
      : [
          {
            key: 3,
            menuItem: <Menu.Item disabled={!currentViewStep}>{t('session.edit')}</Menu.Item>,
            render: () => (
              <Tab.Pane as={StyledTabPane} attached={false} key={currentViewStep.id}>
                {currentViewStep && <EditStep {...{ sessionId, stepId: currentViewStep.id, currentViewStep }} />}
              </Tab.Pane>
            ),
          },
        ]),
  ];

  return <Tab as={StyledTab} panes={panes} menu={{ secondary: true }} />;
};
