import React from 'react';
import { HowToPlay, HowToPlayRules, HowToPlayGame, HowToPlayDetails } from './how-to-play';
import { FollowPresentation } from './player/follow-presentation';
import { GameTitle } from './intro/game-title';
import { ChronosStepType } from './types';
import { SelectGroupExplanation } from './intro/select-group-explanation';
import { SelectGroup } from './player/select-group/select-group';
import { SelectGroupNickname } from './player/select-group/select-group-nickname';
import { SessionPublicData } from '@disco/data';
import { VideoStep } from '../generic/video-step/video-step';
import { PresentSelected } from './player/present-selected';
import { PresentSolution } from './player/present-solution';
import { PresentScore } from './player/present-score';
import {
  ArtHousePuzzle8,
  ArtHousePuzzle8Story,
  BarnPuzzle4,
  BarnPuzzle4Solution,
  BarnPuzzle4Story,
  BarnPuzzle5,
  BarnPuzzle5Solution,
  BarnPuzzle5Story,
  LibraryPuzzle6,
  LibraryPuzzle6Solution,
  LibraryPuzzle6Story1,
  LibraryPuzzle6Story2,
  LibraryPuzzle7,
  LibraryPuzzle7Story,
  ArtHousePuzzle9Solution,
  ArtHousePuzzle9Story,
  ArtHousePuzzle9,
  OfficePuzzle1,
  OfficePuzzle1Solution,
  OfficePuzzle1Story,
  PostOfficePuzzle2,
  PostOfficePuzzle2Solution,
  PostOfficePuzzle2Story,
  PostOfficePuzzle3,
  PostOfficePuzzle3Solution,
  PostOfficePuzzle3Story,
  SantaHowToPlay,
  SantaScoreTable,
  SantaYourChoice,
  SantaIntro,
} from './santa';
import {
  HypatiaInformations,
  HypatiaItems,
  HypatiaPuzzle1,
  HypatiaPuzzle1Counting,
  HypatiaPuzzle1Solution,
  HypatiaPuzzle1Story,
  HypatiaPuzzle2,
  HypatiaPuzzle2Counting,
  HypatiaPuzzle2Solution,
  HypatiaPuzzle2Story,
  HypatiaPuzzle3,
  HypatiaPuzzle3Story,
  HypatiaPuzzle3Counting,
  HypatiaPuzzle3Solution,
} from './hypatia';
import {
  LeonardoInformations,
  LeonardoItems,
  LeonardoPuzzle1,
  LeonardoPuzzle1Counting,
  LeonardoPuzzle1Solution,
  LeonardoPuzzle1Story,
  LeonardoPuzzle2,
  LeonardoPuzzle2Counting,
  LeonardoPuzzle2Solution,
  LeonardoPuzzle2Story,
  LeonardoPuzzle3,
  LeonardoPuzzle3Counting,
  LeonardoPuzzle3Solution,
  LeonardoPuzzle3Story,
  LeonardoPuzzle4,
  LeonardoPuzzle4Counting,
  LeonardoPuzzle4Solution,
  LeonardoPuzzle4Story,
} from './leonardo';
import {
  TeslaInformations,
  TeslaItems,
  TeslaPuzzle1,
  TeslaPuzzle1Counting,
  TeslaPuzzle1Solution,
  TeslaPuzzle1Story,
  TeslaPuzzle2,
  TeslaPuzzle2Counting,
  TeslaPuzzle2Solution,
  TeslaPuzzle2Story,
  TeslaPuzzle3,
  TeslaPuzzle3Counting,
  TeslaPuzzle3Solution,
  TeslaPuzzle3Story,
  TeslaPuzzle4,
  TeslaPuzzle4Counting,
  TeslaPuzzle4Solution,
  TeslaPuzzle4Story,
  TeslaPuzzle5,
  TeslaPuzzle5Counting,
  TeslaPuzzle5Solution,
  TeslaPuzzle5Story,
} from './tesla';
import { Epilog, Final, HowToPlayFinal } from './final';
import { SelectAnswer } from './player/select-answer/select-answer';
import { InterfaceOptions, playerInterfaceWrapper } from './player/player-interface';
import { SelectedInventoryItems } from './player/selected-inventory-items';
import { LeonardoItemsCollecting } from './leonardo/leonardo-items-collecting';
import { RoundSummary } from './components/round-summary';
import { TeslaItemsCollecting } from './tesla/tesla-items-collecting';
import { PresentSelectedSanta } from './player/present-selected-santa';
import { FinalStory } from './santa/final-story';
import { FinalSelect } from './santa/final-select';
import { FinalQuiz } from './santa/final-quiz';
import { FinalSolution } from './santa/final-solution';
import { SantaCharacterDescription } from './santa/santa-character-description';
import { FinalSelectChronos } from './final/final-select';
import { FinalSolutionChronosPresent } from './final/final-solution-chronos-present';
import { FinalSolutionChronos } from './final/final-solution-chronos';
import { FinalSolutionScoreChronos } from './final/final-solution-scores';
import { GameSummary } from './components/game-summary';

interface StepProps {
  data: SessionPublicData;
  presentation?: boolean;
  [key: string]: any;
}

const chronosStep = (Presenter, Player, playerOptions: InterfaceOptions = { groupRequired: true }) => ({
  presentation,
  data,
  ...props
}: StepProps) =>
  presentation ? <Presenter presentation {...{ data, ...props }} /> : <Player {...{ playerOptions, data, ...props }} />;

export const ChronosSteps = {
  [ChronosStepType.GAME_TITLE]: chronosStep(GameTitle, FollowPresentation, {
    groupRequired: false,
    hideButtons: true,
  }),
  [ChronosStepType.CHRONOS_VIDEO_STEP]: chronosStep(VideoStep, FollowPresentation, {
    groupRequired: false,
    hideButtons: true,
  }),

  [ChronosStepType.HOW_TO_PLAY]: chronosStep(HowToPlay, FollowPresentation, {
    groupRequired: false,
    hideButtons: true,
  }),
  [ChronosStepType.HOW_TO_PLAY_RULES]: chronosStep(HowToPlayRules, FollowPresentation, {
    groupRequired: false,
    hideButtons: true,
  }),
  [ChronosStepType.HOW_TO_PLAY_GAME]: chronosStep(HowToPlayGame, FollowPresentation, {
    groupRequired: false,
    hideButtons: true,
  }),
  [ChronosStepType.HOW_TO_PLAY_DETAILS]: chronosStep(HowToPlayDetails, FollowPresentation, {
    groupRequired: false,
    hideButtons: true,
  }),

  [ChronosStepType.SELECT_GROUP]: chronosStep(SelectGroupExplanation, SelectGroup, {
    hideButtons: true,
  }),
  [ChronosStepType.SELECT_GROUP_NICKNAME]: chronosStep(SelectGroupExplanation, SelectGroupNickname, {
    hideButtons: true,
  }),

  // hypatia
  [ChronosStepType.HYPATIA_ITEMS]: chronosStep(HypatiaItems, playerInterfaceWrapper(SelectedInventoryItems)),
  [ChronosStepType.HYPATIA_INFORMATIONS]: chronosStep(HypatiaInformations, FollowPresentation),

  [ChronosStepType.HYPATIA_STORY_1]: chronosStep(HypatiaPuzzle1Story, FollowPresentation),
  [ChronosStepType.HYPATIA_PUZZLE_1]: chronosStep(HypatiaPuzzle1, SelectAnswer),
  [ChronosStepType.HYPATIA_PUZZLE_1_COUNTING]: chronosStep(HypatiaPuzzle1Counting, PresentSelected),
  [ChronosStepType.HYPATIA_SOLUTION_1]: chronosStep(HypatiaPuzzle1Solution, PresentSolution),

  [ChronosStepType.HYPATIA_STORY_2]: chronosStep(HypatiaPuzzle2Story, FollowPresentation),
  [ChronosStepType.HYPATIA_PUZZLE_2]: chronosStep(HypatiaPuzzle2, SelectAnswer),
  [ChronosStepType.HYPATIA_PUZZLE_2_COUNTING]: chronosStep(HypatiaPuzzle2Counting, PresentSelected),
  [ChronosStepType.HYPATIA_SOLUTION_2]: chronosStep(HypatiaPuzzle2Solution, PresentSolution),

  [ChronosStepType.HYPATIA_STORY_3]: chronosStep(HypatiaPuzzle3Story, FollowPresentation),
  [ChronosStepType.HYPATIA_PUZZLE_3]: chronosStep(HypatiaPuzzle3, SelectAnswer),
  [ChronosStepType.HYPATIA_PUZZLE_3_COUNTING]: chronosStep(HypatiaPuzzle3Counting, PresentSelected),
  [ChronosStepType.HYPATIA_SOLUTION_3]: chronosStep(HypatiaPuzzle3Solution, PresentSolution),
  [ChronosStepType.HYPATIA_ROUND_SUMMARY]: chronosStep(RoundSummary, PresentScore, { hideButtons: true }),

  // leonardo
  [ChronosStepType.LEONARDO_INFORMATIONS]: chronosStep(LeonardoInformations, FollowPresentation),
  [ChronosStepType.LEONARDO_ITEMS]: chronosStep(LeonardoItems, SelectAnswer, { hideButtons: true }),
  [ChronosStepType.LEONARDO_ITEMS_COLLECTING]: chronosStep(
    LeonardoItemsCollecting,
    playerInterfaceWrapper(SelectedInventoryItems)
  ),

  [ChronosStepType.LEONARDO_STORY_1]: chronosStep(LeonardoPuzzle1Story, FollowPresentation),
  [ChronosStepType.LEONARDO_PUZZLE_1]: chronosStep(LeonardoPuzzle1, SelectAnswer),
  [ChronosStepType.LEONARDO_PUZZLE_1_COUNTING]: chronosStep(LeonardoPuzzle1Counting, PresentSelected),
  [ChronosStepType.LEONARDO_SOLUTION_1]: chronosStep(LeonardoPuzzle1Solution, PresentSolution),

  [ChronosStepType.LEONARDO_STORY_2]: chronosStep(LeonardoPuzzle2Story, FollowPresentation),
  [ChronosStepType.LEONARDO_PUZZLE_2]: chronosStep(LeonardoPuzzle2, SelectAnswer),
  [ChronosStepType.LEONARDO_PUZZLE_2_COUNTING]: chronosStep(LeonardoPuzzle2Counting, PresentSelected),
  [ChronosStepType.LEONARDO_SOLUTION_2]: chronosStep(LeonardoPuzzle2Solution, PresentSolution),

  [ChronosStepType.LEONARDO_STORY_3]: chronosStep(LeonardoPuzzle3Story, FollowPresentation),
  [ChronosStepType.LEONARDO_PUZZLE_3]: chronosStep(LeonardoPuzzle3, SelectAnswer),
  [ChronosStepType.LEONARDO_PUZZLE_3_COUNTING]: chronosStep(LeonardoPuzzle3Counting, PresentSelected),
  [ChronosStepType.LEONARDO_SOLUTION_3]: chronosStep(LeonardoPuzzle3Solution, PresentSolution),

  [ChronosStepType.LEONARDO_STORY_4]: chronosStep(LeonardoPuzzle4Story, FollowPresentation),
  [ChronosStepType.LEONARDO_PUZZLE_4]: chronosStep(LeonardoPuzzle4, SelectAnswer),
  [ChronosStepType.LEONARDO_PUZZLE_4_COUNTING]: chronosStep(LeonardoPuzzle4Counting, PresentSelected),
  [ChronosStepType.LEONARDO_SOLUTION_4]: chronosStep(LeonardoPuzzle4Solution, PresentSolution),
  [ChronosStepType.LEONARDO_ROUND_SUMMARY]: chronosStep(RoundSummary, PresentScore, { hideButtons: true }),
  [ChronosStepType.LEONARDO_GAME_SUMMARY]: chronosStep(GameSummary, PresentScore, { hideButtons: true }),

  // tesla
  [ChronosStepType.TESLA_INFORMATIONS]: chronosStep(TeslaInformations, FollowPresentation),
  [ChronosStepType.TESLA_ITEMS]: chronosStep(TeslaItems, SelectAnswer),
  [ChronosStepType.TESLA_ITEMS_COLLECTING]: chronosStep(
    TeslaItemsCollecting,
    playerInterfaceWrapper(SelectedInventoryItems)
  ),

  [ChronosStepType.TESLA_STORY_1]: chronosStep(TeslaPuzzle1Story, FollowPresentation),
  [ChronosStepType.TESLA_PUZZLE_1]: chronosStep(TeslaPuzzle1, SelectAnswer),
  [ChronosStepType.TESLA_PUZZLE_1_COUNTING]: chronosStep(TeslaPuzzle1Counting, PresentSelected),
  [ChronosStepType.TESLA_SOLUTION_1]: chronosStep(TeslaPuzzle1Solution, PresentSolution),

  [ChronosStepType.TESLA_STORY_2]: chronosStep(TeslaPuzzle2Story, FollowPresentation),
  [ChronosStepType.TESLA_PUZZLE_2]: chronosStep(TeslaPuzzle2, SelectAnswer),
  [ChronosStepType.TESLA_PUZZLE_2_COUNTING]: chronosStep(TeslaPuzzle2Counting, PresentSelected),
  [ChronosStepType.TESLA_SOLUTION_2]: chronosStep(TeslaPuzzle2Solution, PresentSolution),

  [ChronosStepType.TESLA_STORY_3]: chronosStep(TeslaPuzzle3Story, FollowPresentation),
  [ChronosStepType.TESLA_PUZZLE_3]: chronosStep(TeslaPuzzle3, SelectAnswer),
  [ChronosStepType.TESLA_PUZZLE_3_COUNTING]: chronosStep(TeslaPuzzle3Counting, PresentSelected),
  [ChronosStepType.TESLA_SOLUTION_3]: chronosStep(TeslaPuzzle3Solution, PresentSolution),

  [ChronosStepType.TESLA_STORY_4]: chronosStep(TeslaPuzzle4Story, FollowPresentation),
  [ChronosStepType.TESLA_PUZZLE_4]: chronosStep(TeslaPuzzle4, SelectAnswer),
  [ChronosStepType.TESLA_PUZZLE_4_COUNTING]: chronosStep(TeslaPuzzle4Counting, PresentSelected),
  [ChronosStepType.TESLA_SOLUTION_4]: chronosStep(TeslaPuzzle4Solution, PresentSolution),

  [ChronosStepType.TESLA_STORY_5]: chronosStep(TeslaPuzzle5Story, FollowPresentation),
  [ChronosStepType.TESLA_PUZZLE_5]: chronosStep(TeslaPuzzle5, SelectAnswer),
  [ChronosStepType.TESLA_PUZZLE_5_COUNTING]: chronosStep(TeslaPuzzle5Counting, PresentSelected),
  [ChronosStepType.TESLA_SOLUTION_5]: chronosStep(TeslaPuzzle5Solution, PresentSolution),
  [ChronosStepType.TESLA_ROUND_SUMMARY]: chronosStep(RoundSummary, PresentScore, { hideButtons: true }),
  [ChronosStepType.TESLA_GAME_SUMMARY]: chronosStep(GameSummary, PresentScore, { hideButtons: true }),

  // final
  [ChronosStepType.EPILOG]: chronosStep(Epilog, FollowPresentation),
  [ChronosStepType.HOW_TO_PLAY_FINAL]: chronosStep(HowToPlayFinal, FollowPresentation),
  [ChronosStepType.FINAL]: chronosStep(Final, FinalSelectChronos),
  [ChronosStepType.FINAL_SOLUTION]: chronosStep(FinalSolutionChronos, FinalSolutionChronosPresent),
  [ChronosStepType.FINAL_SCORES]: chronosStep(FinalSolutionScoreChronos, PresentScore, { hideButtons: true }),

  //---------
  // SaNtA cLaUs

  [ChronosStepType.SANTA_INTRO]: chronosStep(SantaIntro, FollowPresentation, { hideButtons: true }),
  [ChronosStepType.SANTA_HOW_TO_PLAY]: chronosStep(SantaHowToPlay, FollowPresentation, { hideButtons: true }),
  [ChronosStepType.SANTA_YOUR_CHOICE]: chronosStep(SantaYourChoice, FollowPresentation),
  [ChronosStepType.SANTA_SCORE_TABLE]: chronosStep(SantaScoreTable, FollowPresentation),

  // character descriptions
  [ChronosStepType.CHARACTER_DESCRIPTION]: chronosStep(SantaCharacterDescription, FollowPresentation),

  // office
  [ChronosStepType.OFFICE_STORY_1]: chronosStep(OfficePuzzle1Story, FollowPresentation),
  [ChronosStepType.OFFICE_PUZZLE_1]: chronosStep(OfficePuzzle1, SelectAnswer),
  [ChronosStepType.OFFICE_EFFECT_1]: chronosStep(OfficePuzzle1Solution, PresentSelectedSanta),

  // postOffice
  [ChronosStepType.POST_OFFICE_STORY_1]: chronosStep(PostOfficePuzzle2Story, FollowPresentation),
  [ChronosStepType.POST_OFFICE_PUZZLE_1]: chronosStep(PostOfficePuzzle2, SelectAnswer),
  [ChronosStepType.POST_OFFICE_EFFECT_1]: chronosStep(PostOfficePuzzle2Solution, PresentSelectedSanta),

  [ChronosStepType.POST_OFFICE_STORY_2]: chronosStep(PostOfficePuzzle3Story, FollowPresentation),
  [ChronosStepType.POST_OFFICE_PUZZLE_2]: chronosStep(PostOfficePuzzle3, SelectAnswer),
  [ChronosStepType.POST_OFFICE_EFFECT_2]: chronosStep(PostOfficePuzzle3Solution, PresentSelectedSanta),

  // barn
  [ChronosStepType.BARN_STORY_1]: chronosStep(BarnPuzzle4Story, FollowPresentation),
  [ChronosStepType.BARN_PUZZLE_1]: chronosStep(BarnPuzzle4, SelectAnswer),
  [ChronosStepType.BARN_EFFECT_1]: chronosStep(BarnPuzzle4Solution, PresentSelectedSanta),

  [ChronosStepType.BARN_STORY_2]: chronosStep(BarnPuzzle5Story, FollowPresentation),
  [ChronosStepType.BARN_PUZZLE_2]: chronosStep(BarnPuzzle5, SelectAnswer),
  [ChronosStepType.BARN_EFFECT_2]: chronosStep(BarnPuzzle5Solution, PresentSelectedSanta),

  // library
  [ChronosStepType.LIBRARY_STORY_1]: chronosStep(LibraryPuzzle6Story1, FollowPresentation),
  [ChronosStepType.LIBRARY_STORY_2]: chronosStep(LibraryPuzzle6Story2, FollowPresentation),
  [ChronosStepType.LIBRARY_PUZZLE_1]: chronosStep(LibraryPuzzle6, SelectAnswer),
  [ChronosStepType.LIBRARY_EFFECT_1]: chronosStep(LibraryPuzzle6Solution, PresentSelectedSanta),

  [ChronosStepType.LIBRARY_STORY_3]: chronosStep(LibraryPuzzle7Story, FollowPresentation),
  [ChronosStepType.LIBRARY_PUZZLE_2]: chronosStep(LibraryPuzzle7, SelectAnswer),
  [ChronosStepType.LIBRARY_EFFECT_2]: chronosStep(LibraryPuzzle6Solution, PresentSelectedSanta),

  // artHouse
  [ChronosStepType.ART_HOUSE_STORY_1]: chronosStep(ArtHousePuzzle8Story, FollowPresentation),
  [ChronosStepType.ART_HOUSE_PUZZLE_1]: chronosStep(ArtHousePuzzle8, SelectAnswer),
  [ChronosStepType.ART_HOUSE_EFFECT_1]: chronosStep(ArtHousePuzzle9Solution, PresentSelectedSanta),

  [ChronosStepType.ART_HOUSE_STORY_2]: chronosStep(ArtHousePuzzle9Story, FollowPresentation),
  [ChronosStepType.ART_HOUSE_PUZZLE_2]: chronosStep(ArtHousePuzzle9, SelectAnswer),
  [ChronosStepType.ART_HOUSE_EFFECT_2]: chronosStep(ArtHousePuzzle9Solution, PresentSelectedSanta),

  // final
  [ChronosStepType.WHERE_SANTA]: chronosStep(FinalStory, FollowPresentation),
  [ChronosStepType.FINAL_SANTA_PUZZLE]: chronosStep(FinalQuiz, FinalSelect),
  [ChronosStepType.FINAL_SANTA_STORY_1]: chronosStep(FinalSolution, FollowPresentation),
  [ChronosStepType.FINAL_SANTA_STORY_2]: chronosStep(FinalStory, FollowPresentation),
  [ChronosStepType.FINAL_SANTA_TOP]: chronosStep(SantaScoreTable, FollowPresentation),
  [ChronosStepType.FINAL_SANTA_STORY_3]: chronosStep(FinalStory, FollowPresentation),
  [ChronosStepType.FINAL_THANKS]: chronosStep(FinalStory, FollowPresentation),
};
