import React from 'react';
import { Text } from './text';
import { Colors } from '../constants';
import styled from 'styled-components';

const maxToDisplay = 8;
const primaryScoresCount = 3;

export const DisplayScore = ({ groups, getImg, maxHours = 24 }) => {
  const asc = (a, b) => (a.score <= b.score ? -1 : 1);
  const desc = (a, b) => (a.score <= b.score ? 1 : -1);

  groups.sort(asc);

  const displayInOneCol = groups?.length <= 4 || false;

  const timeImg = <TimeWrapper alt="time" src={getImg('timeImage')} />;

  return displayInOneCol ? (
    <OneColDisplay {...{ timeImg, groups, maxHours }} />
  ) : (
    <TwoColsDisplay {...{ timeImg, groups, maxHours }} />
  );
};

const OneColDisplay = ({ timeImg: TimeImg, groups, maxHours }) => {
  return (
    <ContentWrapper>
      <ImgWrapper>{TimeImg}</ImgWrapper>
      <GroupScoreWrapper>
        <ColumnWrapper>
          {groups?.slice(0, maxToDisplay)?.map((ele, idx) => (
            <TopScore key={idx} number={idx + 1} text={ele.text} score={ele.score} maxHours={maxHours} />
          ))}
        </ColumnWrapper>
      </GroupScoreWrapper>
    </ContentWrapper>
  );
};

const TwoColsDisplay = ({ timeImg: TimeImg, groups, maxHours }: { timeImg: any; groups: any[]; maxHours: number }) => {
  return (
    <ContentWrapper>
      <ImgWrapper isTwoRows>{TimeImg}</ImgWrapper>
      <GroupScoreWrapper>
        <RowWrapper>
          <ColumnWrapper>
            {groups?.slice(0, primaryScoresCount)?.map((ele, idx) => (
              <TopScore key={idx} number={idx + 1} text={ele.text} score={ele.score} maxHours={maxHours} />
            ))}
          </ColumnWrapper>
          <ColumnWrapper>
            {groups?.slice(primaryScoresCount, maxToDisplay)?.map((ele, idx) => (
              <TopScore
                key={idx + 4}
                isSecondary
                number={idx + 4}
                text={ele.text}
                score={ele.score}
                maxHours={maxHours}
              />
            ))}
          </ColumnWrapper>
        </RowWrapper>
      </GroupScoreWrapper>
    </ContentWrapper>
  );
};

const TopScore = ({ number, text, score, isSecondary = false, maxHours }) => {
  return (
    <ScoreWrapper>
      <ScoreCircle size={isSecondary ? 80 : 100} isSecondary={isSecondary}>
        <Text color={Colors.WHITEISH}>{number}</Text>
      </ScoreCircle>
      <TextWrapper>
        <ConstrainedText color={Colors.BROWN}>{text}</ConstrainedText>
        <TextInside color={Colors.MAIN}>{`${maxHours - score || 0}h`}</TextInside>
      </TextWrapper>
    </ScoreWrapper>
  );
};

export const ImgWrapper = styled.div<any>`
  margin-left: ${(props) => (props.isTwoRows ? `-40%` : '-25%')};
`;

export const GroupScoreWrapper = styled.div`
  margin-left: 10%;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
`;

const TimeWrapper = styled.img`
  height: 65vh;
  margin-top: 60px;
`;

export const ScoreWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;
  width: 100%;
`;

export const RowWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 128px;
`;

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 32px;
`;

const ScoreCircle = styled.div<any>`
  transition: opacity 0.3s;
  width: ${(props) => (props.size ? `${props.size}px` : '240px')};
  height: ${(props) => (props.size ? `${props.size}px` : '240px')};
  background: ${(props) => (props.isSecondary ? Colors.BROWN2 : Colors.BEIGE)};
  -moz-border-radius: ${(props) => (props.size ? `${props.size / 2}px` : '120px')};
  -webkit-border-radius: ${(props) => (props.size ? `${props.size / 2}px` : '120px')};
  border-radius: ${(props) => (props.size ? `${props.size / 2}px` : '120px')};
  outline: 2px solid white;
  outline-offset: -4px;
  align-items: center;
  display: flex;
  justify-content: center;

  box-shadow: 1px 1px 5px 1px #b9b9b9;
}
`;

const TextInside = styled(Text)`
  text-transform: ${(props) => (props.isCaps ? `uppercase` : undefined)};
`;

const ConstrainedText = styled(TextInside)`
  width: 450px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
