import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  height: 100%;

  .box-drift {
    width: 30px;
    height: 100%;
    left: 0%; //ani inc
    //border: solid 4px red;
  }

  .box-bob {
    position: absolute;
    top: 0; //ani up down
    width: 30px;
    background: none;
    //border: solid 4px black;
  }

  .box-pitch {
    height: 30px;
    //border: solid 4px blue;
  }

  .left-sail {
    background: ${(props) => props.color};
    position: absolute;
    top: 20%;
    left: 5%;
    width: 40%;
    height: 40%;
    -webkit-clip-path: polygon(100% 0, 0% 100%, 100% 100%);
    clip-path: polygon(100% 0, 0% 100%, 100% 100%);
  }

  .right-sail {
    background: ${(props) => props.color};
    position: absolute;
    top: 5%;
    left: 50%;
    width: 45%;
    height: 55%;
    -webkit-clip-path: polygon(0 0, 0% 100%, 100% 100%);
    clip-path: polygon(0 0, 0% 100%, 100% 100%);
  }

  .hull {
    background: ${(props) => props.color};
    position: absolute;
    top: 65%;
    left: 10%;
    width: 80%;
    height: 25%;
    -webkit-clip-path: polygon(0 0, 100% 0, 79% 100%, 18% 100%);
    clip-path: polygon(0 0, 100% 0, 79% 100%, 18% 100%);
  }
`;

export const Boat = ({ color = '#114A94' }) => (
  <Wrapper color={color}>
    <div className="box-drift">
      <div className="box-bob">
        <div className="box-pitch">
          <div className="left-sail"></div>
          <div className="right-sail"></div>
          <div className="hull"></div>
        </div>
      </div>
    </div>
  </Wrapper>
);
