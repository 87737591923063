import React, { useState } from 'react';
import { playerInterfaceWrapper } from '../player/player-interface';
import { useTranslation } from 'react-i18next';
import { useStepAction } from '../../api/use-step-action';
import { Text } from '../components/text';
import { Button, Form, Icon } from 'semantic-ui-react';
import { Timer } from '../../generic/timer/timer';
import styled from 'styled-components';

export const FinalSelect = playerInterfaceWrapper(({ step, data, uid, getImg, inventoryItems }) => {
  const { t } = useTranslation();
  const { text1, text2, options1, options2 } = step;
  const { currentStep } = data;

  const [submitted, setSubmitted] = useState(false);

  const [selectedPlace, setSelectedPlace] = useState(null);
  const [selectedCharacter, setSelectedCharacter] = useState(null);

  const { postAction } = useStepAction();

  const sendAnswers = async () => {
    setSubmitted(true);
    await postAction(
      {
        data: {
          selectedOption: selectedPlace,
          isFinalPlace: true,
        },
        userId: uid,
        slideData: data,
      },
      true
    ).catch(() => setSubmitted(false));
    await postAction(
      {
        data: {
          selectedOption: selectedCharacter,
          isFinalCharacter: true,
        },
        userId: uid,
        slideData: data,
      },
      true
    ).catch(() => setSubmitted(false));
  };

  if (submitted) {
    return (
      <ContentWrapper>
        <Text>{t('chronos.answerSubmitted')}</Text>
      </ContentWrapper>
    );
  }

  return (
    <ContentWrapper>
      {data.currentStep?.timer && <Timer data={data} presentation={false} />}
      <RadioWrapper>
        <CustomRadio
          text={text1}
          options={options1}
          setSelectedOption={setSelectedPlace}
          selectedValue={selectedPlace}
        />
        <CustomRadio
          text={text2}
          options={options2}
          setSelectedOption={setSelectedCharacter}
          selectedValue={selectedCharacter}
        />
      </RadioWrapper>
      <StyledButton disabled={submitted || !selectedPlace || !selectedCharacter} onClick={sendAnswers}>
        {t('util.send')}
      </StyledButton>
    </ContentWrapper>
  );
});

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 70vh;
  justify-content: center;
  margin-top: 32px;
`;

const RadioWrapper = styled.div`
  display: flex;
  max-height: 60vh;
  width: 90vw;
  overflow-y: scroll;
  flex-direction: column;
`;

const CustomRadio = ({ text, options, selectedValue, setSelectedOption }) => {
  return (
    <Form style={{ marginTop: '32px' }}>
      <Text>{text}</Text>
      {options?.map((ele) => {
        return (
          <Form.Field key={ele}>
            <StyledButton basic fluid onClick={() => setSelectedOption(ele)} active={selectedValue === ele}>
              <OptionRow>
                <IconWrapper>
                  <Icon size="large" name={selectedValue === ele ? 'check circle outline' : 'circle outline'} />
                </IconWrapper>
                <Text small>{ele}</Text>
              </OptionRow>
            </StyledButton>
          </Form.Field>
        );
      })}
    </Form>
  );
};

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 4px;
  color: white;
`;

const OptionRow = styled.div<any>`
  display: flex;
  gap: 8px;
`;

const StyledButton = styled<any>(Button)`
  margin-top: 32px !important;
  ${(props) => (props.active ? 'background-color: #cacbcd;' : '')}
`;
