import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  height: 100%;

  .box-drift {
    width: 100px;
    height: 100%;
    left: 0%; //ani inc
    //border: solid 4px red;
    animation: doDrift 16s infinite;
  }

  .box-bob {
    position: absolute;
    top: 55%; //ani up down
    width: 100px;
    background: none;
    //border: solid 4px black;
    animation: doBob 2s infinite;
  }

  .box-pitch {
    height: 100px;
    //border: solid 4px blue;
    animation: doPitch 1s infinite;
  }

  .left-sail {
    background: #114A94;
    position: absolute;
    top: 20%;
    left: 5%;
    width: 40%;
    height: 40%;
    -webkit-clip-path: polygon(100% 0, 0% 100%, 100% 100%);
    clip-path: polygon(100% 0, 0% 100%, 100% 100%);
  }

  .right-sail {
    background: #114A94;
    position: absolute;
    top: 5%;
    left: 50%;
    width: 45%;
    height: 55%;
    -webkit-clip-path: polygon(0 0, 0% 100%, 100% 100%);
    clip-path: polygon(0 0, 0% 100%, 100% 100%);
  }

  .hull {
    background: #114A94;
    position: absolute;
    top: 65%;
    left: 10%;
    width: 80%;
    height: 25%;
    -webkit-clip-path: polygon(0 0, 100% 0, 79% 100%, 18% 100%);
    clip-path: polygon(0 0, 100% 0, 79% 100%, 18% 100%);
  }

  @keyframes doBob {
    0% {
      top: 45%;
    }
    50% {
      top: 30%;
    }
    100% {
      top: 45%;
    }
  }

  @keyframes doDrift {
    0% {
      left: 0%;
    }
    10% {
      left: 10%;
    }
    20% {
      left: 20%;
    }
    50% {
      left: 50%;
    }
    50% {
      left: 50%;
    }
    80% {
      left: 80%;
    }

    100% {
      left: 90%;
    }
  }

  @keyframes doPitch {
    0% {
      transform: rotate(-10deg);
    }
    50% {
      transform: rotate(10deg);
    }
    100% {
      transform: rotate(-10deg);
    }
  }
`;

export const LoadingAnimation = () => {
  return (
    <Wrapper>
      <div className="box-drift">
        <div className="box-bob">
          <div className="box-pitch">
            <div className="left-sail"></div>
            <div className="right-sail"></div>
            <div className="hull"></div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
