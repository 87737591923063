import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, Label, List, Modal } from 'semantic-ui-react';
import { Text } from '@disco/ui';
import { useForm } from 'react-hook-form';
import { HFSelect } from 'libs/ui/src/lib/form/hf-select';
import { HFInput } from 'libs/ui/src/lib/form/hf-input';
import { HFDatepicker } from 'libs/ui/src/lib/form/hf-datepicker';
import firebase from 'firebase/app';
import { COMPANIES_COLLECTION, GAMES_COLLECTION } from '@disco/data';
import moment from 'moment';

export interface Option {
  key: string;
  value: string;
  text?: string;
  data?: any;
}

export const GAMES: Option[] = [
  { key: 'kolekcjonerzy', value: 'kolekcjonerzy', text: 'Kolekcjonerzy' },
  { key: 'kolekcjonerzy-swieta', value: 'kolekcjonerzy-swieta', text: 'Kolekcjonerzy Świąteczni' },
  { key: 'collectors', value: 'collectors', text: 'Collectors' },
  { key: 'collectors-christmas', value: 'collectors-christmas', text: 'Collectors Christmas' },
  { key: 'chronos', value: 'chronos', text: 'Chronos' },
  { key: 'chronos-en', value: 'chronos-en', text: 'Chronos En' },
  { key: 'chronos-christmas-pl', value: 'chronos-christmas-pl', text: 'Chronos Christmas Pl' },
  { key: 'chronos-christmas-en', value: 'chronos-christmas-en', text: 'Chronos Christmas En' },
  { key: 'lowisko', value: 'lowisko', text: 'Łowisko Pl' },
  { key: 'lowisko-en', value: 'lowisko-en', text: 'Łowisko En' },
  { key: 'test', value: 'test', text: 'test' },
];

export const CompanyModal = ({ onClose, selectedCompany, setSelectedCompany }) => {
  const { t } = useTranslation();

  const {
    handleSubmit,
    control,
    getValues,
    formState: { errors },
  } = useForm({ defaultValues: selectedCompany });

  const onSubmit = async () => {
    const { name } = getValues();

    const companyRef = firebase.firestore().collection(COMPANIES_COLLECTION).doc(name);
    const gamesRef = firebase.firestore().collection(GAMES_COLLECTION);
    firebase
      .firestore()
      .runTransaction((transaction) => {
        return Promise.all(
          selectedCompany?.games?.map(({ id }) =>
            transaction.get(gamesRef.doc(id)).then((game) => {
              if (!game.exists) {
                throw 'Document does not exist!';
              }
              const companies = game.data().companies?.filter((company) => company !== name);
              transaction.update(gamesRef.doc(id), { companies: [...(companies || []), name] });
            })
          )
        );
      })
      .then(async () => {
        await companyRef.set({ ...selectedCompany, name }, { merge: true });
        onClose();
      });
  };

  const onAddGame = ({ gameId, accessUntil }) => {
    setSelectedCompany((company) => ({
      ...company,
      games: [
        ...(company?.games?.filter((game) => game.id !== gameId) || []),
        { id: gameId, until: moment(accessUntil).format('YYYY-MM-DD') },
      ],
    }));
  };

  const onDeleteGame = (id) => {
    const { name } = getValues();

    const gamesRef = firebase.firestore().collection(GAMES_COLLECTION);
    const companyRef = firebase.firestore().collection(COMPANIES_COLLECTION).doc(name);
    firebase
      .firestore()
      .runTransaction((transaction) => {
        return transaction.get(gamesRef.doc(id)).then((game) => {
          if (!game.exists) {
            throw 'Document does not exist!';
          }
          const companies = game.data().companies?.filter((company) => company !== name);
          transaction.update(gamesRef.doc(id), { companies });
        });
      })
      .then(async () => {
        const company = { ...selectedCompany, games: selectedCompany?.games?.filter((game) => game.id !== id) };
        setSelectedCompany(company);
        await companyRef.set({ ...company, name }, { merge: true });
      });
  };

  return (
    <Modal dimmer={'inverted'} size="small" open={true} onClose={onClose}>
      <Modal.Header>
        {t('companies.modalTitle')} {selectedCompany.name && '- ' + selectedCompany.name}
      </Modal.Header>
      <Modal.Content>
        <Form onSubmit={handleSubmit(onAddGame)}>
          <Form.Group widths="equal">
            <HFInput
              {...{
                name: 'name',
                label: t('companies.name'),
                required: true,
                errors,
                control,
                disabled: !!selectedCompany.name,
              }}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <HFSelect
              {...{
                name: 'gameId',
                label: t('companies.game'),
                options: GAMES,
                required: true,
                errors,
                control,
              }}
            />
            <HFDatepicker
              {...{
                name: 'accessUntil',
                label: t('companies.accessUntil'),
                required: true,
                errors,
                control,
              }}
            />
            <Form.Field>
              <label>{'\u00A0'}</label>
              <Button type="submit" positive>
                {t('companies.addAccess')}
              </Button>
            </Form.Field>
          </Form.Group>
        </Form>
        <List size="big">
          {selectedCompany?.games?.length > 0 ? (
            selectedCompany?.games?.map(({ id, until }) => (
              <List.Item key={id}>
                <Label size="large">{id}</Label>
                <Label size="large">
                  {t('companies.accessUntil')}: {until}
                </Label>
                {'\u00A0'}
                <Button onClick={() => onDeleteGame(id)} negative icon="delete" size="mini" />
              </List.Item>
            ))
          ) : (
            <Text>{t('companies.noSubscriptions')}</Text>
          )}
        </List>
      </Modal.Content>
      <Modal.Actions>
        <Button positive onClick={onSubmit}>
          {t('utils.save')}
        </Button>
        <Button onClick={onClose}>{t('sessions.cancel')}</Button>
      </Modal.Actions>
    </Modal>
  );
};
