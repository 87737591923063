import React from 'react';
import { slideWrapper } from '../components/slide';
import { TextBox } from '../components/text-box';
import { Content } from '../components/content';
import { useAnimationSteps } from '@disco/hooks';
import styled from 'styled-components';
import { Rule } from '../components/rule';

const SantaImage = styled.img<any>`
  opacity: ${(props) => (props.show ? 1 : 0)};
  transition: opacity 0.7s;
  height: 90vh;
`;

const WhereSantaImage = styled.img<any>`
  position: absolute;
  opacity: ${(props) => (props.show ? 1 : 0)};
  transition: opacity 0.7s;
  height: 90vh;
  z-index: 100;
`;

export const SantaHowToPlay = slideWrapper(({ step, getImg, resources, ...props }) => {
  const { title, text1, text2, text3, text4, text5 } = step;

  const texts = [text1, text2, text3, text4, text5].filter((r) => r);
  const animationStep = useAnimationSteps(texts.length);

  const whereSanta = getImg('where-santa');

  return (
    <>
      <Content xNotCenter left={-2} top={50}>
        <SantaImage show src={getImg('santa')} />
        {whereSanta && <WhereSantaImage show={animationStep >= 1} src={whereSanta} />}
      </Content>
      <Content yNotCenter top={7} left={50}>
        <TextBox>{title}</TextBox>
      </Content>
      <Content yNotCenter y={140} top={15} left={60}>
        {texts.map((text, index) => (
          <Rule width={'70vw'} key={index} show={animationStep > index} text={text} />
        ))}
      </Content>
    </>
  );
});
