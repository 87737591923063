export enum Colors {
  MAIN = '#A98A53',
  TEXT = '#4A382C',
  RED = '#FF0000',
  BLUE = '#5B9BD5',
  GREEN = '#70AD47',
  WHITEISH = '#f4efe9',
  BROWN = '#665b50',
  BROWN2 = '#6a5b45',
  DARK_GREY_DISABLED = '#2a2929',
  BEIGE = '#d6ad75',
}
