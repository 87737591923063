import React from 'react';
import { useTranslation } from 'react-i18next';

import { Wrapper } from '../../../components/Wrapper';
import { InterfaceWrapper } from '../../../components/InterfaceWrapper';
import { PlayerInterface } from './PlayerInterface';

import styled from 'styled-components';
import redStrategy from '../../../assets/RedStrategy.png';
import { StrategyHeader } from '../../../components/StrategyHeader';
import { colorConfig } from '../../../config/colors';

const Box = styled.div`
  gap: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h1,
  h2 {
    text-align: center;
  }
  img {
    margin-left: -100px;
  }
`;

export const Rules5 = InterfaceWrapper({
  Interface: PlayerInterface,
  Step: ({ data }) => {
    const { t } = useTranslation();

    return (
      <Wrapper>
        <Box>
          <img src={redStrategy} alt="red" height="320px" />
          <div>
            <StrategyHeader>
              <h1
                dangerouslySetInnerHTML={{ __html: t('fishery.rules5', { strategyColor: colorConfig['red'].card }) }}
              />
            </StrategyHeader>
            <h2>{t('fishery.rules5a')}</h2>
          </div>
        </Box>
      </Wrapper>
    );
  },
});
