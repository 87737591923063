import React from 'react';
import styled from 'styled-components';
import { getScoreTable } from '@disco/data';
import { colorConfig } from '../config/colors';
import { useTranslation } from 'react-i18next';

const Table = styled.table`
  &,
  th,
  td {
    padding: 10px;
    border: 3px solid white;
    border-collapse: collapse;
    text-align: center;
    text-transform: uppercase;
    font-size: 1.5rem;
  }
`;

const TableHeader = styled.th<{ bgColor: string }>`
  background-color: ${({ bgColor }) => bgColor};
`;

const TableData = styled.td<{ bgColor }>`
  background-color: ${({ bgColor }) => bgColor};
`;

export const ScoreTable = ({ context, userCount }) => {
  const { t } = useTranslation();

  const premiums = getScoreTable({ ...context, userCount } as { userCount: number; stocking: number });

  return (
    <Table>
      <thead>
        <tr>
          <TableHeader bgColor={colorConfig.blue.dark}>{t('fishery.roundResultRedCount')}</TableHeader>
          <TableHeader bgColor={colorConfig.green.dark}>{t('fishery.roundResultGreenProfit')}</TableHeader>
          <TableHeader bgColor={colorConfig.red.dark}>{t('fishery.roundResultRedProfit')}</TableHeader>
        </tr>
      </thead>
      <tbody>
        {premiums.map((premium, index) => (
          <tr key={index}>
            <TableData bgColor={index % 2 ? colorConfig.blue.dark : colorConfig.blue.light}>{premium.range}</TableData>
            <TableData bgColor={index % 2 ? colorConfig.green.dark : colorConfig.green.light}>
              {premium.green}
            </TableData>
            <TableData bgColor={index % 2 ? colorConfig.red.dark : colorConfig.red.light}>{premium.red}</TableData>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
