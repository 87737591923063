import React from 'react';
import { useTranslation } from 'react-i18next';
import { BarChartPresentation } from './presentation/bar-chart-presentation';
import { GroupData } from '@disco/data';

interface SelectGroupChartProps {
  actions: { data: any }[];
  groups: GroupData[];
}

export const SelectGroupChart = ({ actions, groups }: SelectGroupChartProps) => {
  const { t } = useTranslation();

  const data = [
    {
      name: t('charts.groups'),
      ...actions.reduce((data, action) => {
        const groupName = action.data.groupName
        const count = data[groupName] || 0;
        data[groupName] = count + 1 ;
        return data;
      }, {})
    }
  ];

  return <BarChartPresentation data={data} groups={groups} />;
};
