export interface ColorPair {
  light: string;
  dark: string;
}

export const colorConfig = {
  blue: {
    light: '#A5D6EE',
    dark: '#D6EFF7',
    card: '#08a2dd',
  },
  red: {
    light: '#FB856B',
    dark: '#FFDBDD',
    card: '#f04130',
  },
  green: {
    light: '#93C67B',
    dark: '#CEE3A9',
    card: '#53a64a',
  },
  yellow: {
    light: '#FFE27A',
    dark: '#FFFAE6',
    card: '#edbf1c',
  },
  orange: {
    light: '#FFA266',
    dark: '#FFD4B2',
    card: '#f68c46',
  },
  none: {
    light: '#B4B4B4FF',
    dark: '#B4B4B4FF',
  },
};
