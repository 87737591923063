import React from 'react';
import { Content } from '../components/content';
import { SlideImage } from '../components/slide-image';
import { slideWrapper } from '../components/slide';
import { PuzzleItems } from '../components/puzzle-items';
import { RoundLetters, RoundType } from '@disco/data';

export const TeslaPuzzle3Counting = slideWrapper(
  ({ step, getImg, countingResults, countingResultsLoading }) => {
    const { answers } = step;

    return (
      <>
        <Content left={-4} bottom={5} xNotCenter yNotCenter>
          <SlideImage width="700px" src={getImg('deska')} show={true} />
        </Content>
        <Content left={15} bottom={7} yNotCenter>
          <SlideImage width="300px" src={getImg('kasa')} show={true} />
        </Content>
        <Content left={15} top={15} yNotCenter>
          <SlideImage width="250px" src={getImg('pistolet')} show={true} />
        </Content>
        <PuzzleItems
          {...{ countingResults, countingResultsLoading, getImg, answers, letters: RoundLetters[5] }}
          smallItem
        />
      </>
    );
  },
  { countAnswersTour: RoundType.Tesla }
);
