import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { StrategyBox } from '../../../components/player-interface/StrategyBox';
import { colorConfig } from '../../../config/colors';
import { useRoundScore } from '../../../utils/use-round-score';
import { useCurrentContext } from '../../../utils/use-current-context';
import { ScoreTable } from '../../../components/ScoreTable';

const StrategyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  padding: 0 16px;
  max-width: 600px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
`;

const Box = styled.div`
  min-width: 350px;
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const Boxes = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 24px;
`;

export const Content = ({ data }) => {
  const { t } = useTranslation();

  const context = useCurrentContext(data?.sessionId);
  const currentStep = data?.currentStep;

  const { scoreData } = useRoundScore(currentStep, data);

  const strategies = Object.keys(scoreData?.overall || {});
  const userVouts = Object.values(scoreData?.overall || {});
  const userCount = userVouts.reduce((acc: number, curr: number) => acc + curr, 0);
  const strategiesScore = Object.keys(scoreData?.strategiesScore || {});

  const strategyBoxes = (
    <StrategyWrapper>
      {strategiesScore
        ?.filter((name) => name !== 'none')
        .map((name) => {
          const strategy = strategies[name] || name;
          return (
            <StrategyBox
              key={strategy}
              title={t(`fishery.strategy${strategy.charAt(0).toUpperCase() + strategy.slice(1)}Title`)}
              color={colorConfig[strategy]}
              text={`: ${scoreData?.overall[strategy] || '0'}`}
              points={scoreData?.strategiesScore[strategy]}
            />
          );
        })}
    </StrategyWrapper>
  );

  return (
    <Wrapper>
      <h1>{t('fishery.roundResultsTitle')}</h1>
      <Boxes>
        <Box>
          <ScoreTable context={context} userCount={userCount} />
        </Box>
        <Box>{strategyBoxes}</Box>
      </Boxes>
    </Wrapper>
  );
};
