import React from 'react';
import { useEffect, useCallback, useState } from 'react';
import { useSoundFx } from './use-sound';
import { Button } from 'semantic-ui-react';
import styled from 'styled-components';
import { Content } from '../components/content';

const StyledButton = styled(Button)`
  &&& {
    z-index: 9999;
  }
`;

export const useGameSound = (url) => {
  const { play, exposedData } = useSoundFx(url, { loop: false, volume: 0.1 });

  useEffect(() => {
    if (exposedData.duration) {
      play();
    }
    return () => exposedData.stop();
  }, [url, play, exposedData.duration]);

  const [stop, setStop] = useState(false);

  const StopButton = useCallback(
    () => (
      <Content top={8} left={4} zIndex={99999}>
        <StyledButton
          onClick={() => {
            setStop(!stop);
            stop ? play() : exposedData.pause();
          }}
        >
          {stop ? 'Start' : 'Stop'}
        </StyledButton>
      </Content>
    ),
    [url, exposedData, stop, play]
  );
  return StopButton;
};
