import React from 'react';
import { slideWrapper } from '../components/slide';
import { Content } from '../components/content';
import styled from 'styled-components';
import { Text } from '../components/text';
import { useAnimationSteps } from '@disco/hooks';
import { SlideImage } from '../components/slide-image';

const SlideText = styled.div`
  font-size: 60px;
  margin: 0 20px;
`;

export const HypatiaPuzzle3Story = slideWrapper(({ step, getImg, ...props }) => {
  const { text } = step;

  const animationStep = useAnimationSteps(1, true);

  return (
    <>
      <Content top={0} left="-10" yNotCenter xNotCenter>
        <SlideImage width="900px" src={getImg('buildings')} show />
      </Content>

      <Content top={25} left={75} yNotCenter xNotCenter>
        <SlideImage width="200px" src={getImg('man')} show={animationStep < 1} />
      </Content>

      <Content top={0} left={70} yNotCenter xNotCenter>
        <SlideImage width="200px" src={getImg('column')} show />
      </Content>

      <Content width="80%" yNotCenter bottom={5}>
        <SlideText>
          <Text big alignCenter>
            {text}
          </Text>
        </SlideText>
      </Content>
    </>
  );
});
