import React from 'react';
import { slideWrapper } from '../components/slide';
import { Content } from '../components/content';
import styled from 'styled-components';
import { Text } from '../components/text';

const Image = styled.img`
  height: 80vh;
  width: auto;
`;

export const SantaIntro = slideWrapper(({ step, resources, getImg, ...props }) => {
  return (
    <>
      <Content top={50}>
        <Image src={getImg('image')} />
      </Content>
    </>
  );
});
