import { slideWrapper } from '../components/slide';
import { Content } from '../components/content';
import { TextBox } from '../components/text-box';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Timer } from '../../generic/timer/timer';
import styled from 'styled-components';
import firebase from 'firebase/app';
import { SESSION_COLLECTION, STEP_SUBCOLLECTION, ACTION_SUBCOLLECTION } from '@disco/data';
import { UserCounter } from '../../collectors/components/user-counter';

const TimerBox = styled.div`
  display: flex;
  margin-top: 16px;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const SelectGroupExplanation = slideWrapper(({ step, resources, data, ...props }) => {
  const { t } = useTranslation();
  const { title } = step;
  const { currentStep } = data;

  const { counter } = currentStep;
  const [actions, setActions] = useState([]);

  useEffect(() => {
    return (
      counter &&
      firebase
        .firestore()
        .collection(SESSION_COLLECTION)
        .doc(data.sessionId)
        .collection(STEP_SUBCOLLECTION)
        .doc(String(data.currentStep.id))
        .collection(ACTION_SUBCOLLECTION)
        .onSnapshot((snapshot) => {
          const list = [];
          snapshot.forEach((doc) => list.push(doc.data()));
          setActions(list);
        })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Content top={45} left={50}>
        <TextBox>{title}</TextBox>
        {counter && <UserCounter actions={actions} />}
        <TimerBox>{currentStep?.timer && <Timer presentation data={data} />}</TimerBox>
      </Content>
    </>
  );
});
