import { slideWrapper } from '../components/slide';
import { Content } from '../components/content';
import { TextBox } from '../components/text-box';
import React from 'react';

export const GameTitle = slideWrapper(({ step, resources, ...props }) => {
  const { title } = step;

  return (
    <>
      <Content yNotCenter top={5} left={50}>
        <TextBox>{title}</TextBox>
      </Content>
    </>
  );
});
