import React from 'react';
import { Controller } from 'react-hook-form';
import { Form } from 'semantic-ui-react';
import { Text } from '../text/text';
import { HFSelectProps } from './types';


export const HFSelect = (
  {
    name,
    label,
    options,
    defaultValue,
    required = false,
    width,
    multiple,
    errors = {},
    control
  }: HFSelectProps) => {
  return (
    <Form.Field {...{ required, width, error: !!errors[name] }} >
      <label>{label}</label>
      <Controller
        {...{ name, defaultValue, control }}
        rules={{ required }}
        render={({ field: { name, value, onChange } }) => (
          <Form.Select
            {...{ name, options, defaultValue, value, multiple }}
            onChange={(event, { value: newValue }) => onChange(newValue)}
          />
        )}
      />
      {errors[name]?.message && <Text color='#9f3a38'>{errors[name]?.message}</Text>}
    </Form.Field>
  );
}
