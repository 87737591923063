import React from 'react';
import { slideWrapper } from '../components/slide';
import { Content } from '../components/content';
import styled from 'styled-components';
import { Text } from '../components/text';
import { ConditionalSoundButton } from '../components/sound-button';

const Image = styled.img`
  width: 100vw;
  height: auto;
`;

const SlideText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-size: 60px;
  margin: 32px 20px;
  z-index: 999;
`;

const JanitorImage = styled.img`
  position: absolute;

  top: 45%;
  left: 10%;
  width: 450px;
`;

const ClerkImage = styled.img`
  position: absolute;
  top: 40%;
  right: 10%;
  width: 300px;
`;

const ImageWrapper = styled.div`
  position: relative;
`;

export const OfficePuzzle1Story = slideWrapper(({ step, resources, fetching, getImg, ...props }) => {
  const { text1, text2 } = step;
  console.log(fetching, props);
  return (
    <>
      {fetching && <ConditionalSoundButton getImg={getImg} />}

      <Content width="90%" yNotCenter top={0} left={0}>
        <ImageWrapper>
          <Image src={getImg('office')} />
          <JanitorImage src={getImg('janitor')} />
          <ClerkImage src={getImg('clerk')} />
        </ImageWrapper>
        <SlideText>
          <Text medium>{text1}</Text>
          <Text medium>{text2}</Text>
        </SlideText>
      </Content>
    </>
  );
});
