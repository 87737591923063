import React from 'react';
import { slideWrapper } from '../components/slide';
import { Content } from '../components/content';
import { SlideImage } from '../components/slide-image';
import styled from 'styled-components';
import { Text } from '../components/text';

const SlideText = styled.div`
  font-size: 60px;
  margin: 0 20px;
  z-index: 999;
`;

export const TeslaPuzzle4Story = slideWrapper(({ step, getImg, ...props }) => {
  const { text, animationsCount } = step;

  return (
    <>
      <Content left={40} top={5} yNotCenter>
        <SlideImage width="600px" src={getImg('kartki')} show={true} />
      </Content>
      <Content left={55} top={40} yNotCenter>
        <SlideImage width="800px" src={getImg('ksiazka')} show={true} />
      </Content>
      <Content width="80%" yNotCenter bottom={10}>
        <SlideText>
          <Text alignCenter>{text}</Text>
        </SlideText>
      </Content>
    </>
  );
});
