import { useTranslation } from 'react-i18next';
import { Button, Modal } from 'semantic-ui-react';
import React from 'react';

interface ConfirmModalProps {
  header: string;
  content: string;
  displayConfirm: boolean;
  onClose: () => void
  onAction: () => void;
}

export function ConfirmModal({ header, content, displayConfirm, onClose, onAction }: ConfirmModalProps) {
  const { t } = useTranslation();

  const invokeActionAndClose = () => {
    onAction();
    onClose();
  }

  return (
    <Modal dimmer={'inverted'} size="small" {...{ open: displayConfirm, onClose }}>
      <Modal.Header>
        {header || t('misc.attention')}
      </Modal.Header>
      <Modal.Content>
        {content || t('misc.areYouSure')}
      </Modal.Content>
      <Modal.Actions>
        <Button positive onClick={invokeActionAndClose}>
          {t('misc.yes')}
        </Button>
        <Button negative onClick={onClose}>{t('misc.no')}</Button>
      </Modal.Actions>
    </Modal>
  )
}
