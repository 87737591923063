import React from 'react';
import { Content } from '../components/content';
import { SlideImage } from '../components/slide-image';
import { slideWrapper } from '../components/slide';
import { PuzzleItem, PuzzleItemsWrapper } from '../components/puzzle-item';

export const LeonardoPuzzle1Solution = slideWrapper(({ step, getImg }) => {
  const { answers } = step;

  return (
    <>
      <Content left={12} top={0} yNotCenter>
        <SlideImage width="600px" src={getImg('path')} show={true} />
      </Content>
      <Content left={12} top={10} yNotCenter>
        <SlideImage width="400px" src={getImg('glasses')} show={true} />
      </Content>
      <Content left={14} bottom={0} yNotCenter>
        <SlideImage width="400px" src={getImg('mona-smile')} show={true} />
      </Content>
      <PuzzleItemsWrapper>
        <PuzzleItem getImg={getImg} letter="a" answers={answers} />
        <PuzzleItem getImg={getImg} letter="b" answers={answers} />
        <PuzzleItem getImg={getImg} letter="c" answers={answers} />
        <PuzzleItem getImg={getImg} letter="d" answers={answers} />
        <PuzzleItem getImg={getImg} letter="e" answers={answers} />
      </PuzzleItemsWrapper>
    </>
  );
});
