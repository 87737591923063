import { useCookies } from '@disco/hooks';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Text } from '../../components/text';
import { Form, Loader } from 'semantic-ui-react';
import { HFInput } from '../../../../../../ui/src/lib/form/hf-input';
import { Button } from '../components/button';
import React, { useEffect, useState } from 'react';
import { useStepAction } from '../../../api/use-step-action';
import firebase from 'firebase/app';
import { SESSION_COLLECTION, SESSION_GROUP_LEADERS_SUBCOLLECTION } from '@disco/data';
import { useSelectGroup } from '../../../api/use-select-group';
import { Timer } from '../../../generic/timer/timer';
import { playerInterfaceWrapper } from '../player-interface';
import styled from 'styled-components';
import { Content } from '../../components/content';

const StyledContent = styled(Content)`
  &&& {
    form {
      width: 100%;
      max-width: 300px;
    }
  }
`;

export const SelectGroupNickname = playerInterfaceWrapper(({ uid, data }) => {
  const { t } = useTranslation();

  const { currentStep } = data;
  const { updateSessionCookie, getSessionCookie } = useCookies();
  const { postAction } = useStepAction();
  const { pickGroupNickname } = useSelectGroup();
  const { groupName, groupNickname: cookieNickname, sessionCode } = getSessionCookie();

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting, isDirty },
    setError,
  } = useForm();

  const [isAScribe, setIsAScribe] = useState(undefined);
  const [groupNickname, setGroupNickname] = useState(undefined);

  // listen for leaderId change (separate collection to reduce reads)
  useEffect(() => {
    return firebase
      .firestore()
      .collection(SESSION_COLLECTION)
      .doc(data.sessionId)
      .collection(SESSION_GROUP_LEADERS_SUBCOLLECTION)
      .doc(groupName)
      .onSnapshot((snapshot) => {
        const { leaderId, groupNickname } = snapshot.data();

        setIsAScribe(leaderId === uid);
        if (groupNickname) setGroupNickname(groupNickname);
      });
  }, [data.sessionId, groupName, uid]);

  const onNicknameSubmit = async ({ groupNickname }) => {
    if (!groupNickname) {
      setError('groupNickname', { message: t('util.valueCantBeEmpty') });
      return;
    }

    const result = await pickGroupNickname({
      sessionId: data.sessionId,
      sessionCode,
      groupName,
      groupNickname,
    });

    if (result?.code === 200) {
      await postAction({ data: { groupNickname }, userId: uid, slideData: data });
      updateSessionCookie({ groupNickname });
      const event = new Event('cookie-updated');
      document.dispatchEvent(event);
    } else {
      setError('groupNickname', { message: t('group.nicknameAlreadyUsed') });
    }
  };

  if (groupNickname === undefined && isAScribe === undefined) {
    return (
      <Content width="80vw" left={50} top={45}>
        <Loader />
      </Content>
    );
  } else if (groupNickname)
    return (
      <Content width="80vw" left={50} top={45}>
        <Text alignCenter>{t('group.nicknamePicked', { groupNickname })}</Text>
      </Content>
    );
  else if (isAScribe)
    return (
      <StyledContent width="80vw" left={50} top={45}>
        <Text alignCenter style={{ marginBottom: '16px' }}>
          {t('chronos.youAreScribe')}
        </Text>
        {currentStep?.timer && <Timer presentation={false} data={data} />}
        <Form onSubmit={handleSubmit(onNicknameSubmit)}>
          <HFInput
            {...{
              name: 'groupNickname',
              defaultValue: '',
              errors,
              control,
              maxLength: 25,
              placeholder: t('group.nicknamePlaceholder'),
              transform: (e) => e.target.value.toUpperCase(),
            }}
          />
          <Button disabled={!isDirty} loading={isSubmitting} fluid>
            {t('util.send')}
          </Button>
        </Form>
      </StyledContent>
    );
  else {
    return (
      <Content width="80vw" left={50} top={45}>
        {currentStep?.timer && <Timer presentation={false} data={data} />}
        <Text alignCenter>{t('chronos.waitForNickname')}</Text>
      </Content>
    );
  }
});
