import React from 'react';
import { slideWrapper } from '../components/slide';
import { Content } from '../components/content';
import styled from 'styled-components';
import { Text } from '../components/text';
import { AnimFull } from './puzzle-3-story-post-office';
import { ConditionalSoundButton } from '../components/sound-button';

const Image = styled.img`
  width: 100vw;
  height: auto;
`;

const SlideText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-size: 60px;
  margin: 16px 20px;
  z-index: 999;
`;

const Snow = styled.img`
  width: 100vw;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
`;

const Man = styled.img`
  position: absolute;
  left: 10%;
  top: 12%;
  width: 350px;
  height: auto;
`;

const Owner = styled.img`
  position: absolute;
  top: 65%;
  right: 33%;
  width: 70px;
`;

const ImageWrapper = styled.div`
  position: relative;
`;

export const LibraryPuzzle6Story1 = slideWrapper(({ step, fetching, resources, getImg, ...props }) => {
  const { text1, text2 } = step;

  return (
    <>
      {fetching && <ConditionalSoundButton getImg={getImg} />}
      <Content width="90%" yNotCenter top={0} left={0}>
        <ImageWrapper>
          <Image src={getImg('image')} />
          <Owner src={getImg('gospodarz')} />
        </ImageWrapper>
        <Snow src={getImg('snow')} />
        <Man src={getImg('man')} />
        <AnimFull src={getImg('notesAnim')} />
        <SlideText>
          <Text medium>{text1}</Text>
          <Text medium>{text2}</Text>
        </SlideText>
      </Content>
    </>
  );
});
