import React from 'react';

import { Wrapper } from '../../../components/Wrapper';
import { InterfaceWrapper } from '../../../components/InterfaceWrapper';
import { PlayerInterface } from './PlayerInterface';

import fisheryLogo from '../../../assets/lowisko-logo.png';

export const TitleSlide = InterfaceWrapper({
  Interface: PlayerInterface,
  Step: ({ data }) => {
    return (
      <Wrapper>
        <img src={fisheryLogo} alt="logo" width="40%" />
      </Wrapper>
    );
  },
});
