import React from 'react';
import { slideWrapper } from '../components/slide';
import { RoundLetters, RoundType } from '@disco/data';
import { PuzzleItems } from '../components/puzzle-items';

import { SlideImage } from '../components/slide-image';
import { Content } from '../components/content';

export const LeonardoPuzzle4Counting = slideWrapper(
  ({ step, getImg, countingResults, countingResultsLoading }) => {
    const { answers } = step;

    return (
      <>
        <Content left={13} bottom={-5} yNotCenter>
          <SlideImage width="400px" src={getImg('element2')} show={true} />
        </Content>

        <Content left={15} bottom={15} yNotCenter>
          <SlideImage width="300px" src={getImg('statyw')} show={true} />
        </Content>
        <PuzzleItems
          {...{ countingResults, countingResultsLoading, getImg, answers, letters: RoundLetters[5] }}
          smallItem
        />
      </>
    );
  },
  { countAnswersTour: RoundType.Leonardo }
);
