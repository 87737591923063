import React from 'react';
import styled from 'styled-components';

import { useTranslation } from 'react-i18next';
import { usePresenterActions } from '../../../utils/use-presenter-actions';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .label,
  .value {
    font-family: Klavika !important;
    color: #114a94 !important;
  }
`;

const WidgetsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 24px;
  margin-top: 16px;
  font-size: 2rem;

  span {
    font-weight: bold;
  }
`;

export const Content = ({ data }) => {
  const { t } = useTranslation();

  const { actions = [] } = usePresenterActions(data, 1);

  const anwser1 = actions.filter((action) => action.data.selectedOption === 'true').length;
  const anwser2 = actions.filter((action) => action.data.selectedOption === 'false').length;
  const sum = anwser1 + anwser2
  const getPercent = (value, sum) => (sum ? ((value / sum) * 100).toFixed(0) : 0);

  return (
    <Wrapper>
      <h1>{t('fishery.referendumVotingTitle')}</h1>
      <WidgetsWrapper>
        <div>
          {t('fishery.referendumResultsYes')}: <span>{getPercent(anwser1, sum)}%</span>
        </div>
        <div>
          {t('fishery.referendumResultsNo')}: <span>{getPercent(anwser2, sum)}%</span>
        </div>
      </WidgetsWrapper>
    </Wrapper>
  );
};
