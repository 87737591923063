import styled from 'styled-components';

export const RoundSummary = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-width: 240px;
`;

export const RoundSettings = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 8px;
`;

export const RoundWrapper = styled.div`
  display: flex;
  gap: 24px;
  width: 100%;
  flex-wrap: wrap;
`;
