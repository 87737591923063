import React from 'react';
import styled from 'styled-components';
import { LoadingAnimation } from '../LoadingAnimation';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  margin-bottom: 66px;
`;

export const Content = ({ children }) => {
  return <Wrapper>{children ? children : <LoadingAnimation />}</Wrapper>;
};
