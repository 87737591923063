import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Message, Modal } from 'semantic-ui-react';
import { useSessions } from '../../../api/use-sessions';
import firebase from 'firebase/app';
import { GROUPS_COLLECTION } from 'libs/data/src/lib/group';
import { useForm } from 'react-hook-form';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-datepicker/dist/react-datepicker-cssmodules.min.css';
import { Option, SessionForm } from './session-form';
import { DEFAULT_VARIANT } from './initial-groups';
import { UserDiscoContext, UserDiscoData } from '@disco/ui';
import { emitToast, ToastType } from 'libs/utils/src/lib/toast';
import { ApiResponse } from '@disco/data';

interface CreateSessionProps {
  open: boolean;
  presenters: string[];
  onClose: () => void;
  games: string[];
}

export function CreateSessionModal({ open, presenters, games, onClose: closeFn }: CreateSessionProps) {
  const { t } = useTranslation();

  const { createSession } = useSessions();

  const { companies } = useContext<UserDiscoData>(UserDiscoContext);
  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    clearErrors,
    setError,
  } = useForm({ defaultValues: { companies } });
  const gameId = watch('gameId' as any);
  const variantKey = watch('variantKey' as any, DEFAULT_VARIANT);

  const [variants, setVariants] = useState<Option[]>([]);
  const [saving, setSaving] = useState<boolean>(false);
  const [unknownError, setUnknownError] = useState<boolean>(false);

  const onClose = () => {
    closeFn();
    clearErrors();
  };

  const onSubmit = async (data) => {
    setSaving(true);

    const res = (await createSession({ ...data, groupSize: data?.groupSize || '' }).catch((err) => {
      console.error(err);
      setSaving(false);
      setUnknownError(true);
    })) as ApiResponse;

    setSaving(false);

    if (res.code === 409) {
      setError('sessionCode' as any, { message: t('createSession.error.codeConflict'), shouldFocus: true } as any);
    } else if (res.code === 200) {
      emitToast({ title: t('createSession.sessionSaved') }, ToastType.Success);
      onClose();
    }
  };

  useEffect(() => {
    async function getVariants() {
      const groupVariants = await firebase
        .firestore()
        .collection(GROUPS_COLLECTION)
        .doc(gameId as string)
        .get();
      const data = groupVariants.exists && groupVariants.data();
      if (typeof data === 'object') {
        setVariants(
          Object.keys(data)?.map((ele) => ({
            key: ele,
            value: ele,
            text: `${ele} (${data[ele]?.length})`,
            data: data[ele],
          }))
        );
      }
    }
    if (gameId) getVariants();
  }, [gameId]);

  return (
    <Modal dimmer={'inverted'} size="small" {...{ open, onClose }}>
      <Modal.Header>{t('createSession.createModalTitle')}</Modal.Header>
      <Modal.Content>
        <SessionForm
          {...{
            handleSubmit: () => handleSubmit(onSubmit),
            errors,
            control,
            variantKey,
            games,
            presenters,
            companies: companies.map((company) => ({ key: company, value: company, text: company })),
            groupVariants: variants,
          }}
        />
        {unknownError && <Message error header={t('createSession.error.header')} />}
      </Modal.Content>
      <Modal.Actions>
        <Button positive onClick={handleSubmit(onSubmit)} loading={!!saving} disabled={!!saving}>
          {t('createSession.create')}
        </Button>
        <Button onClick={onClose}>{t('createSession.cancel')}</Button>
      </Modal.Actions>
    </Modal>
  );
}
