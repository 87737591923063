import { RoundType } from '@disco/data';

export const getRoundTypeFromStepType = (currStepType: string) => {
  if (currStepType.includes(RoundType.Hypatia.toString())) return RoundType.Hypatia;
  if (currStepType.includes(RoundType.Leonardo.toString())) return RoundType.Leonardo;
  if (currStepType.includes(RoundType.Tesla.toString())) return RoundType.Tesla;

  if (currStepType.includes(RoundType.Office.toString())) return RoundType.Office;
  if (currStepType.includes(RoundType.PostOffice.toString())) return RoundType.PostOffice;
  if (currStepType.includes(RoundType.Barn.toString())) return RoundType.Barn;
  if (currStepType.includes(RoundType.Library.toString())) return RoundType.Library;
  if (currStepType.includes(RoundType.ArtHouse.toString())) return RoundType.ArtHouse;
};
