import React from 'react';
import { PresentVotingStep, SessionPublicData } from '@disco/data';
import Page from '../components/page';
import { QuestionRadioChart } from '../components/charts/question-radio';
import styled from 'styled-components';
import { useCookies } from '@disco/hooks';
import { useTranslation } from 'react-i18next';
import { useCollectorsScore } from '../hooks/use-collectors-score';
import { Text } from '../components/text';
import { Team } from '../components/team';
import { CustomHeader } from '../components/custom-header';

export interface PresentVotingProps {
  presentation: boolean;
  uid: string;
  fetching: boolean;
  data: SessionPublicData;
}

const FlexWrapper = styled.div`
  display: flex;
`;

const FlexColumnWrapper = styled.div`
  flex-direction: column;
  align-items: flex-start;
`;

const StyledImage = styled.img`
  max-width: 60px;
  margin-right: 8px;
`;

const StyledSuitcaseImage = styled.img`
  max-width: 60px;
  margin-left: -8px;
  margin-right: -8px;
`;

const TimerWrapper = styled.div`
  min-height: 100px;
`;

const getActionsFromScoreData = (scoreData) => {
  const actions = [];
  scoreData?.options &&
    Object.entries(scoreData.options).map(([group, options]) => {
      Object.entries(options).map(([option, selections]) => {
        actions.push(...Array(selections).fill({ data: { selectedOption: option, groupName: group } }));
      });
    });
  return actions;
};

export function PresentVoting({ data, presentation, fetching = true }: PresentVotingProps) {
  const { t } = useTranslation();

  const { getSessionCookie } = useCookies();
  const { groupName } = getSessionCookie();

  const currentStep = data?.currentStep as PresentVotingStep;
  const { scoreData } = useCollectorsScore(currentStep, data, fetching && presentation);

  const resources = data?.resources;
  const { imageLabels = {} } = currentStep;

  return (
    <Page {...{ data, presentation }} alignContent="top">
      <CustomHeader currentStep={currentStep} prop={'title'} />
      {presentation ? (
        <>
          <TimerWrapper />
          <FlexWrapper>
            <div style={{ minHeight: '55vh', minWidth: '100%', display: 'grid' }}>
              <QuestionRadioChart
                resources={resources}
                actions={getActionsFromScoreData(scoreData)}
                currentViewStep={data.currentStep}
                groups={data?.groups}
              />
            </div>
          </FlexWrapper>
        </>
      ) : (
        <FlexColumnWrapper>
          <Text marginTop={16} marginBottom={24} isBold align="left">
            {t('steps.yourTeam')}
          </Text>
          <Team score={data.scoring} />
          {currentStep?.options?.map((option) => {
            const value = typeof option === 'string' ? option : option.value;
            const optionSelections = data.scoring?.options[groupName]?.[option.value];

            return (
              <FlexSpace key={value}>
                <StyledImage src={resources[imageLabels[value]]} />
                <Text>{t('steps.selections_interval', { postProcess: 'interval', count: optionSelections || 0 })}</Text>
              </FlexSpace>
            );
          })}
          <Text marginTop={32} isBold align="left">
            {t('steps.collected')}
          </Text>
          <FlexSpace justify="space-between">
            <Collected
              data={data}
              resources={resources}
              scoreData={data.scoring}
              groupName={groupName}
              colorLetter="y"
            />
            <Collected
              data={data}
              resources={resources}
              scoreData={data.scoring}
              groupName={groupName}
              colorLetter="r"
            />
            <Collected
              data={data}
              resources={resources}
              scoreData={data.scoring}
              groupName={groupName}
              colorLetter="g"
            />
            <Collected
              data={data}
              resources={resources}
              scoreData={data.scoring}
              groupName={groupName}
              colorLetter="b"
            />
          </FlexSpace>
        </FlexColumnWrapper>
      )}
    </Page>
  );
}

const Collected = ({ data, scoreData, resources, groupName, colorLetter }) => (
  <FlexSpace>
    <StyledSuitcaseImage
      src={
        resources[
          `${
            data?.currentStep?.style == 'christmas' ? 'collectors-christmas/' : 'images/game/collectors/'
          }1-${colorLetter}.png`
        ]
      }
    />
    <Text>{scoreData?.suitcases[groupName]?.[colorLetter] || 0}</Text>
  </FlexSpace>
);

const FlexSpace = styled.div<{ justify?: string }>`
  margin-top: 8px;
  margin-right: 8px;
  display: flex;
  justify-content: ${(props) => props.justify || 'flex-start'};
  align-items: center;
`;

export default PresentVoting;
