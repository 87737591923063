import React from 'react';
import { Wrapper, Center } from '../../../components/Wrapper';
import { InterfaceWrapper } from '../../../components/InterfaceWrapper';
import { PlayerInterface } from './PlayerInterface';
import { ChartPresenterRadioAnswer } from '../../../components/ChartPresenterRadioAnswer';
import { useTranslation } from 'react-i18next';
import { shipOptions } from './options';

export const ShipVisionSlide = InterfaceWrapper({
  Interface: PlayerInterface,
  Step: ({ data }) => {
    const { t } = useTranslation();

    return (
      <Wrapper>
        <Center>
          <ChartPresenterRadioAnswer data={data} title={t('fishery.introShipVisionTitle')} options={shipOptions} />
        </Center>
      </Wrapper>
    );
  },
});
