import React, { useEffect, useState } from 'react';
import firebase from 'firebase/app';

import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Dots } from '../../../components/player-interface/Dots';
import { useConferenceSelectSpeakers } from '../../../utils/use-conference-select-speakers';
import { SESSION_COLLECTION, SESSION_USERS_COLLECTION } from '@disco/data';
import { Timer } from 'libs/steps/src/lib/generic/timer/timer';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .label,
  .value {
    font-family: Klavika !important;
    color: #114a94 !important;
  }
`;

const CaptainsWrapper = styled.div`
  max-width: 400px;
  display: flex;
  align-items: center;
  gap: 24px;
  flex-direction: column;
  text-align: center;

  div {
    font-size: 2rem;
  }
`;

const WidgetsWrapper = styled.div`
  display: flex;
  gap: 32px;
  flex-direction: column;
  margin-bottom: 32px;
`;

export const Content = ({ data }) => {
  const { currentStep } = data;

  const [speakers, setSpeakers] = useState([]);

  const { conferance } = currentStep;

  const { selectedSpeakers, loading } = useConferenceSelectSpeakers(data?.sessionId, data?.currentStep?.id);

  const { t } = useTranslation();

  const getUser = async (uid) => {
    const doc = await firebase
      .firestore()
      .collection(SESSION_COLLECTION)
      .doc(data.sessionId)
      .collection(SESSION_USERS_COLLECTION)
      .doc(uid)
      .get();
    return doc.exists && doc.data();
  };

  useEffect(() => {
    if (loading) return;

    (async () => {
      const names = [];
      if (selectedSpeakers.length) {
        for (const index in selectedSpeakers) {
          const user = await getUser(selectedSpeakers[index]);
          if (user && user.shipName) {
            names.push(user.shipName);
          }
        }
        setSpeakers(names);
      }
    })();
  }, [selectedSpeakers, loading]);

  return loading ? (
    <Wrapper>
      <Dots />
    </Wrapper>
  ) : (
    <Wrapper>
      {!conferance ? (
        <h2>{t('fishery.conferenceNoOneWillSpeak')}</h2>
      ) : (
        <CaptainsWrapper>
          {!selectedSpeakers.length ? (
            <h1>{t('fishery.conferenceNobodySpeaks')}</h1>
          ) : (
            <>
              <h1>{t('fishery.conferenceChosenTitle')}:</h1>
              <WidgetsWrapper>
                {speakers.map((name, index) => (
                  <div key={index}>
                    {index + 1}. {name}
                  </div>
                ))}
              </WidgetsWrapper>
              <Timer data={data} presentation />
            </>
          )}
        </CaptainsWrapper>
      )}
    </Wrapper>
  );
};
