import React, { useEffect, useState } from 'react';
import { PresentScoreTableStep, SessionPublicData } from '@disco/data';
import Page from '../components/page';
import { Title } from '../components/title';
import styled from 'styled-components';
import { ScoreChart } from '../components/charts/score-table';
import { useCollectorsScore } from '../hooks/use-collectors-score';
import { Text } from '../components/text';
import { useTranslation } from 'react-i18next';
import { useCookies } from '@disco/hooks';
import { Team } from '../components/team';
import { CustomHeader } from '../components/custom-header';

export interface PresentScoreTableProps {
  presentation: boolean;
  uid: string;
  fetching: boolean;
  data: SessionPublicData;
}

const FlexWrapper = styled.div`
  display: flex;
`;

export function PresentScoreTable({ data, presentation, fetching = true }: PresentScoreTableProps) {
  const { t } = useTranslation();

  const currentStep = data?.currentStep as PresentScoreTableStep;

  const { getSessionCookie } = useCookies();
  const { groupName } = getSessionCookie();

  const { scoreData, loading } = useCollectorsScore(currentStep, data, fetching && presentation);

  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    if (data.scoring || scoreData) {
      const unsortedData = Object.entries(data?.scoring?.score || scoreData.score).map(([groupName, points]) => ({
        points,
        name: groupName,
        [groupName]: points,
      }));
      unsortedData.sort((a, b) => (a.points >= b.points ? -1 : 1));
      setChartData(unsortedData);
      return;
    }
  }, [data.scoring, scoreData, data?.groups]);

  const position = chartData.findIndex((item) => item.name === groupName);

  return (
    <Page {...{ data, presentation }} alignContent="top">
      <CustomHeader currentStep={currentStep} prop={'title'} />
      {presentation ? (
        fetching &&
        (chartData?.length || loading ? (
          <FlexWrapper>
            <div style={{ minHeight: '50vh', minWidth: '100%', display: 'grid' }}>
              {!loading && (
                <ScoreChart
                  data={chartData.length > 8 ? chartData.slice(0, 8) : chartData.slice(0, 5)}
                  groups={data?.groups}
                />
              )}
            </div>
          </FlexWrapper>
        ) : (
          <Text marginTop={32} align="left">
            {t('steps.noVotesToCount')}
          </Text>
        ))
      ) : (
        <>
          {data.scoring?.score?.[groupName] !== undefined && (
            <FlexColumnWrapper>
              <Text marginTop={16} marginBottom={24} isBold align="left">
                {t('steps.yourTeam')}
              </Text>
              <Team score={data.scoring} />
              <Text marginTop={32} marginBottom={32} align="left">
                {t('steps.youCollected', { points: data.scoring?.score?.[groupName] })}
              </Text>
            </FlexColumnWrapper>
          )}
          {position >= 0 && (
            <>
              <Text marginTop={16} marginBottom={16} isBold align="left">
                {t('steps.yourPosition')}
              </Text>
              <Text marginBottom={32} align="left">
                {t('steps.outOf', { position: position + 1, teams: Object.keys(data.scoring?.score || {}).length })}
              </Text>
            </>
          )}
        </>
      )}
    </Page>
  );
}

const FlexColumnWrapper = styled.div`
  flex-direction: column;
  align-items: flex-start;
`;

export default PresentScoreTable;
