import React from 'react';
import styled from 'styled-components';
import Page from '../../collectors/components/page';
import YouTube from 'react-youtube';
import { useLocation } from 'react-router-dom';

const YT = styled(YouTube)`
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  position: absolute;
`;

const Container = styled.div`
  width: 95vh;
  margin: auto;
  height: 95vh;
  padding: 2rem;
`;

const YoutubeEmbed = ({ youtubeVideoId }) => {
  const onEnd = () => {
    document.dispatchEvent(
      new KeyboardEvent('keydown', {
        key: '39',
        // @ts-ignore
        keyCode: 39,
        code: 'ArrowRight',
        which: 39,
        shiftKey: false,
        ctrlKey: false,
        metaKey: false,
      })
    );
  };

  return (
    <Container>
      <YT
        id={youtubeVideoId}
        videoId={youtubeVideoId}
        onEnd={onEnd}
        opts={{ playerVars: { autoplay: 1, controls: 0, showinfo: 0, rel: 0 } }}
      />
    </Container>
  );
};

export const VideoStep = ({ data, presentation }) => {
  const { youtubeVideoId } = data.currentStep;

  const location = useLocation();
  const checkIfPresentationPath = () => {
    const split = location.pathname.split('/');
    return split.length && split[split.length - 1] === 'present';
  };

  return (
    <Page {...{ data, presentation, padding: '2em', noWrap: true }}>
      {youtubeVideoId && checkIfPresentationPath() ? (
        <YoutubeEmbed {...{ youtubeVideoId }} />
      ) : (
        'VIDEO'
      )}
    </Page>
  );
};
