import React from 'react';
import { Wrapper } from '../../components/Wrapper';
import { LoadingAnimation } from '../../components/LoadingAnimation';

export const PlayerInterface = ({ data, uid, ...props }) => {
  return (
    <Wrapper>
      <LoadingAnimation />
    </Wrapper>
  );
};
