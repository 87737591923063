import React from 'react';
import { SlideImage } from '../components/slide-image';
import { Content } from '../components/content';
import { slideWrapper } from '../components/slide';
import { RoundLetters, RoundType } from '@disco/data';
import { PuzzleItems } from '../components/puzzle-items';

export const TeslaPuzzle4Counting = slideWrapper(
  ({ step, getImg, countingResults, countingResultsLoading }) => {
    const { answers } = step;

    return (
      <>
        <Content left={-2} top={20} xNotCenter yNotCenter>
          <SlideImage width="300px" src={getImg('polowaziomka')} show={true} />
        </Content>
        <Content left={20} top={20} yNotCenter>
          <SlideImage width="150px" rotate={'10deg'} src={getImg('szklanka')} show={true} />
        </Content>
        <PuzzleItems
          {...{ countingResults, countingResultsLoading, getImg, answers, letters: RoundLetters[6] }}
          smallItem
        />
      </>
    );
  },
  { countAnswersTour: RoundType.Tesla }
);
