import React, { useState } from 'react';
import { slideWrapper } from '../components/slide';
import { TextBox } from '../components/text-box';
import { Content } from '../components/content';
import { DisplayScore } from '../components/display-score';
import { useRoundSummary } from '../hooks/use-round-summary';
import firebase from 'firebase/app';
import { useEffect } from 'react';
import { SESSION_PUBLIC_COLLECTION } from '@disco/data';

export const FinalSolutionScoreChronos = slideWrapper(({ step, resources, data, getImg }) => {
  const { type, text1 } = step;
  const { sessionId, sessionCode } = data;

  const { summaryData } = useRoundSummary({ sessionId, type });

  // mock many groups
  // const groups = [{groupName: 'Test 1', score: 100}, {groupName: 'Test 2', score: 90}, {groupName: 'Test 3', score: 80}, {groupName: 'Test 4', score: 70}, {groupName: 'Test 5', score: 90}, {groupName: 'Test 6', score: 90}, {groupName: 'Test 7', score: 90}, {groupName: 'Test 8', score: 90}, {groupName: 'Test 9', score: 90}]

  const groups = summaryData?.length
    ? summaryData.map((ele) => ({
        groupName: ele.groupName,
        text: ele?.groupNickname || ele.groupName,
        score: Number(ele.hours) + Number(ele.finalPoints || 0) * -1,
      }))
    : [];

  useEffect(() => {
    if (!summaryData.length) return;
    firebase
      .firestore()
      .collection(SESSION_PUBLIC_COLLECTION)
      .doc(sessionCode)
      .set({ groupScores: groups, maxHours: 72 }, { merge: true });
  }, [summaryData]);

  return (
    <>
      <Content top={16}>
        <TextBox>{text1}</TextBox>
      </Content>
      <Content width="100%" top={55}>
        <DisplayScore {...{ groups, getImg, maxHours: 72 }} />
      </Content>
    </>
  );
});
