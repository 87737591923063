import React from 'react';
import { slideWrapper } from '../components/slide';
import { Content } from '../components/content';
import styled from 'styled-components';
import { Text } from '../components/text';
import { useAnimationSteps } from '@disco/hooks';

const Image = styled.img`
  max-width: 100%;
  height: auto;
`;

const SlideText = styled.div`
  font-size: 60px;
  margin: 10px;
`;

const HeadImage = styled.img<any>`
  position: absolute;
  width: 300px;
  height: auto;
  left: ${(props) => (props.show ? '300px' : '1500px')};
  transition: all 1s;
`;

export const HypatiaPuzzle1Story = slideWrapper(({ step, resources, getImg, ...props }) => {
  const { text1, text2 } = step;

  const animationStep = useAnimationSteps(1, true);

  return (
    <>
      <Content left={30} top={15}>
        <HeadImage show={animationStep > 0} src={getImg('head')} />
      </Content>
      <Content width="80%" yNotCenter bottom={6}>
        <SlideText>
          <Text alignCenter>{text1}</Text>
        </SlideText>
        <SlideText>
          <Text alignCenter>{text2}</Text>
        </SlideText>
      </Content>
    </>
  );
});
