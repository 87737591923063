import React from 'react';
import styled from 'styled-components';
import { Boat } from './Boat';
import { useTranslation } from 'react-i18next';

const Box = styled.div`
  background-color: #9cd5ef;
  width: 100%;
  height: 76px;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
`;

const ShipName = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const ShipBox = styled.div`
  height: 30px;
  display: flex;
  align-items: center;
  gap: 8px;
  min-width: 0px;
`;

const ScoreBox = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  gap: 8px;
  font-size: 1.1rem;
  min-width: 136px;
  justify-content: flex-end;
`;

export const Navbar = ({ stocking, shipName, score }) => {
  const { t } = useTranslation();
  return (
    <Box>
      <ShipBox>
        <Boat />
        <ShipName>{shipName}</ShipName>
      </ShipBox>
      <ScoreBox>
        <div>{t('fishery.stockingLevel', { stocking })}</div>
        <div>{t('fishery.playerScore', { score })}</div>
      </ScoreBox>
    </Box>
  );
};
