import styled from 'styled-components';

export const Text = styled.div<{
  color?: string;
  align?: string;
  marginTop?: number;
  marginBottom?: number;
  isBold?: boolean;
}>`
  &&& {
    font-size: 48px;
    line-height: 64px;
    color: ${(props) => props.color};
    text-align: ${(props) => props.align};
    margin-top: ${(props) => props.marginTop}px;
    margin-bottom: ${(props) => props.marginBottom}px;
    font-weight: ${(props) => props.isBold && 'bold'};

    @media screen and (max-width: 1000px) {
      font-size: 24px;
      line-height: 27px;
    }
  }
`;
