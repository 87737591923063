import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Countdown from 'react-countdown';
import firebase from 'firebase/app';
import { CountdownStatus, SESSION_PUBLIC_COLLECTION, SessionPublicData, StepTimer, SessionStatus } from '@disco/data';
import moment from 'moment';
import { TimerControl } from './timer-control';
import { Statistic } from 'semantic-ui-react';
import { useCookies } from '@disco/hooks';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useApi } from '../hooks/use-api';

export interface TimerProps {
  presentation?: boolean;
  data: SessionPublicData;
  disabled?: boolean;
  autoStart?: boolean;
}

interface CommonCookiePart {
  sessionCode: string;
}

export function Timer({ data, presentation, disabled = false, autoStart = false }: TimerProps) {
  const { currentStep } = data;
  const callApi = useApi();
  const { getPresentationCookie, getSessionCookie } = useCookies();
  const [cookie, setCookie] = useState<{ sessionCode: string }>();
  const [timerData, setTimerData] = useState<StepTimer>(null);

  const changeTimer = useCallback(
    async (seconds, status) => {
      if (cookie?.sessionCode && (!data.status || data.status === SessionStatus.OPEN)) {
        return callApi('/steps/timer', {
          method: 'POST',
          body: {
            sessionCode: cookie.sessionCode,
            seconds,
            status,
          } as StepTimer,
        });
      }
    },
    [cookie?.sessionCode, data, callApi]
  );

  useEffect(() => {
    if (cookie?.sessionCode && presentation && autoStart) {
      changeTimer(currentStep.seconds, CountdownStatus.STARTED);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookie?.sessionCode, presentation]);

  useEffect(() => {
    // get session code from cookie - required for session_public changes (facilitator has different cookie than player...)
    const ck = presentation ? getPresentationCookie() : getSessionCookie();
    setCookie(ck as CommonCookiePart);
  }, [getPresentationCookie, getSessionCookie, presentation]);

  const snapToTimerData = (snapData: firebase.firestore.DocumentData) => {
    const snapDate = snapData.timer?.startAt?.toDate();

    const endAt = moment(snapDate).add(snapData.timer.seconds, 'seconds');
    return {
      ...snapData.timer,
      startAt: moment(snapData.timer?.startAt?.toDate()),
      isFinished: moment(endAt).isBefore(moment()),
      endAt,
    };
  };

  useEffect(() => {
    if (cookie && !disabled) {
      if (!cookie.sessionCode) {
        //alert('Missing session data, please open game again.');
        return;
      }

      return firebase
        .firestore()
        .collection(SESSION_PUBLIC_COLLECTION)
        .doc(cookie.sessionCode)
        .onSnapshot((snapshot) => {
          const snapData = snapshot.exists && snapshot.data();
          if (snapData && snapData.timer) {
            setTimerData(snapToTimerData(snapData));
          }
        });
    }
  }, [cookie]);

  return (
    <CustomCountdown {...{ presentation, currentStep, timerData, setTimerData, cookie, autoStart, changeTimer }} />
  );
}

const CustomCountdown = ({ presentation, currentStep, timerData, cookie, autoStart, changeTimer }) => {
  const { t } = useTranslation();

  const ref = useRef<Countdown>(null);

  const [state, setState] = useState<{ status: CountdownStatus; seconds: number }>();
  const date = timerData && timerData.endAt.toDate();

  useEffect(() => {
    if (timerData && timerData.status && timerData.endAt && ref.current) {
      switch (timerData.status) {
        case CountdownStatus.COMPLETED:
          break;
        case CountdownStatus.PAUSED:
          ref.current.api.pause();
          break;
        default:
          ref.current.api.start();
      }
    }
  }, [timerData]);

  const onTick = () => updateState('tick');
  const onStart = () => updateState('start');
  const onPause = () => updateState('pause');
  const onComplete = () => updateState('complete');

  const updateState = (lifecycle: string) => {
    setState({
      // @ts-ignore
      status: ref.current.state.status,
      seconds: ref.current.state.timeDelta.total / 1000,
    });
  };

  const DefaultRenderer = useMemo(
    () => ({ minutes, seconds }) => (
      <Statistic>
        <Statistic.Label>{t('steps.timer')}</Statistic.Label>
        <Statistic.Value>
          {minutes}:{seconds < 10 ? '0' + seconds : seconds}
        </Statistic.Value>
      </Statistic>
    ),
    [t]
  );

  return (
    <TimerWrapper>
      {date && (
        <Countdown
          {...{
            autoStart,
            onStart,
            onComplete,
            onPause,
            onTick,
            ref,
            date,
            renderer: DefaultRenderer,
          }}
        />
      )}
      {presentation && !autoStart && <TimerControl {...{ currentStep, changeTimer, state, cookie }} />}
    </TimerWrapper>
  );
};

const TimerWrapper = styled.div`
  margin: auto;
  padding-top: 6px;
  padding-bottom: 12px;
`;
