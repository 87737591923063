export const GROUP_COLORS: string[] = [
  '#28A1D3',
  '#7E6ABF',
  '#E473DD',
  '#28A19C',
  '#428B47',
  '#FAC863',
  '#F99157',
  '#BD3729',
  '#707176',
  '#000000',
  '#3551cf',
  '#4d23c0',
  '#d102e4',
  '#02dbe3',
  '#1dc407',
  '#ece10f',
  '#ffc002',
  '#ff4a03',
  '#114e67',
  '#3a315c',
  '#9f4299',
  '#165754',
  '#a37c2e',
  '#b56132',
];

export const CHRISTMAS_GROUP_COLORS: string[] = [
  '#e13636',
  '#76a700',
  '#ff8f00',
  '#1b1f3e',
  '#1d7da6',
  '#9e4030',
  '#ff0708',
  '#90e306',
  '#f7e628',
  '#4a5daa',
  '#117df7',
  '#d17828',
  ...GROUP_COLORS,
];

export const companyColor = {
  violet: '#2c0b47',
};
