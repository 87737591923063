import React from 'react';
import { slideWrapper } from '../components/slide';
import { TextBox } from '../components/text-box';
import { Text } from '../components/text';
import { Content } from '../components/content';
import { useAnimationSteps } from '@disco/hooks';
import styled from 'styled-components';
import { Colors } from '../constants';

const TimeImage = styled.img<any>`
  opacity: ${(props) => (props.show ? 1 : 0)};
  transition: opacity 0.3s;
  max-height: 100vh;
`;

const Subtitle = styled.div`
  margin: 64px;
`;

export const HowToPlayGame = slideWrapper(({ step, resources, ...props }) => {
  const { title, subtitle, text1, text2, text3, imageLabels } = step;

  const texts = [text1, text2, text3];
  const animationStep = useAnimationSteps(texts.length + 1);

  return (
    <>
      <Content left={80} top={50}>
        <TimeImage show={animationStep > 0} src={resources[imageLabels?.time]} />
      </Content>
      <Content yNotCenter top={7} left={50}>
        <TextBox>{title}</TextBox>
        <Subtitle>
          <Text>{subtitle}</Text>
        </Subtitle>
        <RowContent>
          {texts.map((text, index) => (
            <>
              <Right show={index !== 0 && animationStep - 1 > index} />
              <Circle key={index} show={animationStep - 1 > index}>
                <TextInside color={Colors.WHITEISH}>{text}</TextInside>
              </Circle>
            </>
          ))}
        </RowContent>
      </Content>
    </>
  );
});

const RowContent = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TextInside = styled(Text)`
  padding: 40px;
  text-align: center;
`;

const Circle = styled.div<any>`
  opacity: ${(props) => (props.show ? 1 : 0)};
  transition: opacity 0.3s;
  width: 350px;
  height: 350px;
  background: ${Colors.MAIN};
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  outline: 2px solid white;
  outline-offset: -4px;
  align-items: center;
  display: flex;
  justify-content: center;
`;

const Right = styled.div<any>`
  opacity: ${(props) => (props.show ? 1 : 0)};
  transition: opacity 0.3s;
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-left: 20px solid ${Colors.BROWN};
  border-bottom: 20px solid transparent;
  margin: 0 16px;
`;
