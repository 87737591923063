import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useStepAction } from '../../../../api/use-step-action';
import { Wrapper } from '../../../components/Wrapper';
import RadioGroup from '../../../../../../../ui/src/lib/form/radio-group';
import { RadioWrapper } from '../../../components/player-interface/RadioWrapper';
import { shipOptions } from './options';

export const PlayerInterface = ({ data, uid, ...props }) => {
  const { t } = useTranslation();

  const resources = data?.resources || {};
  const { postAction } = useStepAction();

  const onSelect = (args) => {
    postAction({ data: { selectedOption: args }, userId: uid, slideData: data });
    setOptionSubmitted(true);
  };

  const [optionSubmitted, setOptionSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  return (
    <Wrapper>
      <h1>{optionSubmitted ? t('question.submitted') : t('fishery.introShipVisionTitle')}</h1>
      {!optionSubmitted && (
        <RadioWrapper>
          <RadioGroup
            submitting={submitting}
            options={shipOptions}
            resources={resources}
            buttonColor={null}
            currentViewStep={data.currentStep}
            postAction={onSelect}
            presentation={false}
            maxOptions={1}
          />
        </RadioWrapper>
      )}
    </Wrapper>
  );
};
