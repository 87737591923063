import React, { useEffect } from 'react';
import { Button, Form } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { HFInput } from './hf-input';
import { useForm } from 'react-hook-form';
import { companyColor } from '@disco/data';
import styled from 'styled-components';
import { GameButton } from 'libs/steps/src/lib/collectors/components/game-button';

export interface TextGroupProps {
  postAction: (string) => void;
  submitting?: boolean;
  maxLength: number;
}

const MAX_ANSWER_LENGTH = 255;
const ANSWER_INPUT_FIELD_NAME = 'text';

export function TextGroup({ postAction, submitting, maxLength }: TextGroupProps) {
  const { t } = useTranslation();

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    setValue,
  } = useForm();

  const textValue = watch(ANSWER_INPUT_FIELD_NAME, '');
  const textLength = textValue?.length || 0;

  useEffect(() => {
    if (textLength > maxLength || MAX_ANSWER_LENGTH)
      setValue(ANSWER_INPUT_FIELD_NAME, textValue.substr(0, maxLength || MAX_ANSWER_LENGTH));
  }, [setValue, textLength, textValue, maxLength]);

  return (
    <Form onSubmit={handleSubmit((data) => postAction(data?.text))}>
      <Form.Field>
        <HFInput
          {...{
            name: ANSWER_INPUT_FIELD_NAME,
            defaultValue: '',
            errors,
            control,
            required: true,
            textarea: true,
          }}
        />
      </Form.Field>
      <LengthCounter>
        {textLength} / {maxLength || MAX_ANSWER_LENGTH}
      </LengthCounter>
      <GameButton loading={submitting} fluid type="submit">
        {t('send')}
      </GameButton>
    </Form>
  );
}

const LengthCounter = styled.p`
  color: gray;
  font-size: 12px;
`;

export default TextGroup;
