import styled from 'styled-components';

export const Button = styled.button<{ bgColor: string; fontColor: string; selected: boolean }>`
  display: flex;
  padding: 20px;
  border-radius: 8px;
  background-color: ${(props) => props.bgColor};
  color: white;
  font-size: 1.8rem;
  font-weight: bold;
  width: 100%;
  height: 70px;
  align-items: center;
  transform: scale(${(props) => (props.selected ? 1.04 : 1)});
  border: ${(props) => (props.selected ? '4px solid #002e7c' : '0px')};
  gap: 10px;
  transition: filter, transform 0.1s ease-in-out;
  filter: contrast(${(props) => (props.selected ? 1.3 : 1.05)});
`;
