import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Message, Modal } from 'semantic-ui-react';
import { useSessions } from '../../../api/use-sessions';
import { useForm } from 'react-hook-form';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-datepicker/dist/react-datepicker-cssmodules.min.css';
import { DEFAULT_VARIANT } from './initial-groups';
import { SessionForm } from './session-form';
import { SessionData } from '../../../models/session';
import { ApiResponse } from '@disco/data';
import { emitToast, ToastType } from '../../../../../../../libs/utils/src/lib/toast';

interface EditSessionProps {
  selectedSession: SessionData;
  open: boolean;
  presenters: string[];
  games: string[];
  onClose: () => void;
}

export function EditSessionModal({ selectedSession, games, presenters, open, onClose: closeFn }: EditSessionProps) {
  const { t } = useTranslation();

  const { editSession } = useSessions();

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    clearErrors,
    reset,
    setError,
  } = useForm();

  const variantKey = watch('variantKey', DEFAULT_VARIANT);

  const [saving, setSaving] = useState<boolean>(false);
  const [unknownError, setUnknownError] = useState<boolean>(false);

  const mapToDefaultValues = (selectedSession: SessionData) => {
    return {
      ...selectedSession,
      plannedDate: new Date(selectedSession.plannedDate),
      sessionId: selectedSession.id,
    };
  };

  useEffect(() => {
    if (selectedSession) {
      // @ts-ignore
      reset(mapToDefaultValues(selectedSession));
    }
  }, [reset, selectedSession]);

  const onClose = () => {
    closeFn();
    clearErrors();
  };

  const onSubmit = async (data) => {
    setSaving(true);
    const res = (await editSession(selectedSession.id, { ...data, groupSize: data?.groupSize || '' }).catch((err) => {
      console.log(err);
      setSaving(false);
      setUnknownError(true);
    })) as ApiResponse;

    setSaving(false);

    if (res.code === 409) {
      setError('sessionCode', { message: t('createSession.error.codeConflict'), shouldFocus: true } as any);
    } else if (res.code === 200) {
      emitToast({ title: t('createSession.sessionSaved') }, ToastType.Success);
      onClose();
    }
  };

  return (
    <Modal dimmer={'inverted'} size="small" {...{ open, onClose }}>
      <Modal.Header>{t('createSession.editModalTitle')}</Modal.Header>
      <Modal.Content>
        <SessionForm
          {...{
            edit: true,
            handleSubmit: () => handleSubmit(onSubmit),
            errors,
            control,
            presenters,
            games,
            variantKey,
            companies: [],
            groupVariants: [],
          }}
        />
        {unknownError && <Message error header={t('createSession.error.header')} />}
      </Modal.Content>
      <Modal.Actions>
        <Button positive onClick={handleSubmit(onSubmit)} loading={!!saving} disabled={!!saving}>
          {t('utils.save')}
        </Button>
        <Button onClick={onClose}>{t('utils.cancel')}</Button>
      </Modal.Actions>
    </Modal>
  );
}
