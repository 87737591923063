import React from 'react';
import { slideWrapper } from '../components/slide';
import { Text } from '../components/text';
import { useAnimationSteps } from '@disco/hooks';
import { Content } from '../components/content';
import styled from 'styled-components';
import { TextBox } from '../components/text-box';
import { Timer } from '../../generic/timer/timer';

const Rule = styled.div<any>`
  opacity: ${(props) => (props.show ? 1 : 0)};
  width: 70vw;
  transition: opacity 0.7s;
  margin: 16px;
`;

export const FinalQuiz = slideWrapper(({ step, getImg, data }) => {
  const { title, text1, text2, options1, options2 } = step;

  const texts = [text1, text2].filter((r) => r);
  const animationStep = useAnimationSteps(texts.length);

  return (
    <>
      <Content yNotCenter top={7} left={50}>
        <div style={{ display: 'flex' }}>
          <TextBox>{title}</TextBox>
          <Timer data={data} presentation />
        </div>
      </Content>
      <Content xNotCenter left={65} top={50}>
        <MapImage show src={getImg('map')} />
      </Content>
      <Content yNotCenter top={25} left={50}>
        <Question {...{ text: text1, show: animationStep > 0, options: options1 }} />
        <Question {...{ text: text2, show: animationStep > 1, options: options2 }} />
      </Content>
    </>
  );
});

const Question = ({ text, show, options }) => {
  return (
    <Rule show={show}>
      <TextWrapper marginBottom={'16px'} maxWidth={'40vw'}>
        <Text huge>{text}</Text>
      </TextWrapper>
      <OptionsWrapper>
        {options?.map((option) => (
          <TextWrapper key={option} marginLeft={'42px'}>
            <Text>{option}</Text>
          </TextWrapper>
        ))}
      </OptionsWrapper>
    </Rule>
  );
};

const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const TextWrapper = styled.div<any>`
  margin-left: ${(props) => props.marginLeft || undefined};
  margin-bottom: ${(props) => props.marginBottom || undefined};
  max-width: ${(props) => props.maxWidth || undefined};
`;

const MapImage = styled.img<any>`
  opacity: ${(props) => (props.show ? 1 : 0)};
  transition: opacity 0.7s;
  max-height: 60vh;
`;
