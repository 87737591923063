export enum Strategy {
  green = 'green',
  red = 'red',
  blue = 'blue',
  yellow = 'yellow',
  orange = 'orange',
  none = 'none',
}

export interface StockingRedCountProps {
  stepId: string;
  roundNumber: number;
  count: number;
  countAll: number;
}
