import React, { useEffect, useState } from 'react';
import { BarStackedChart } from './presentation/bar-stacked-chart';
import { CHRISTMAS_GROUP_COLORS, GroupData, GROUP_COLORS } from '@disco/data';

interface QuestionRadioChartProps {
  actions: { data: any }[];
  currentViewStep: any;
  groups: GroupData[];
  resources: {};
}

export const QuestionRadioChart = ({ resources, actions, currentViewStep, groups }: QuestionRadioChartProps) => {
  const answersList = [];

  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    const columns = [];

    actions.forEach((ele) => {
      const groupName = ele.data.groupName;
      const key = groupName || ele.data.selectedOption + '-option';

      if (!groupName && !columns.find((column) => column.name === key))
        columns.push({
          name: key,
          color:
            currentViewStep?.style === 'christmas'
              ? CHRISTMAS_GROUP_COLORS[columns.length]
              : GROUP_COLORS[columns.length],
        });

      const curr = answersList[ele.data.selectedOption]?.[key];
      console.log('here', String(ele.data.selectedOption));
      console.log('here2', answersList[String(ele.data.selectedOption)]);

      answersList[String(ele.data.selectedOption)] = {
        ...answersList[String(ele.data.selectedOption)],
        [key]: curr ? curr + 1 : 1,
      };
    });

    const allColumns = [...columns, ...(groups || [])];

    const data = currentViewStep.options?.map((option) => ({
      name: typeof option === 'string' ? option : option.value,
      label: typeof option === 'string' ? option : option.label,
      ...allColumns.reduce((current, column) => {
        current[column.name] = 0;
        return current;
      }, {}),
      ...answersList[typeof option === 'string' ? option : option.value],
    }));

    setColumns(allColumns);
    setData(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actions]);

  return (
    <BarStackedChart
      resources={resources}
      data={data}
      options={currentViewStep.options}
      groups={columns}
      labels={currentViewStep.imageLabels}
    />
  );
};
