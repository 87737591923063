import { FisheryControls } from './fishery/FisheryControls';

export const getControls = (gameId) => {
  if (['lowisko', 'lowisko-en'].includes(gameId)) {
    return FisheryControls;
  }

  return null;
};

export const hasGroupTab = (gameId) => {
  if (['lowisko', 'lowisko-en'].includes(gameId)) {
    return false;
  }

  return true;
};
