import { useEffect, useState } from 'react';
import { useApi } from '../../generic/hooks/use-api';

export const useStockingCalculation = (sessionId, currentStepId, fetching = true) => {
  const [response, setResponse] = useState<any>({});
  const [loading, setLoading] = useState(true);

  const callApi = useApi();

  useEffect(() => {
    const fetch = async () => {
      const response = await callApi('/fishery-game/calculate-stocking', {
        method: 'post',
        body: {
          sessionId,
          currentStepId,
        },
      });
      setResponse(response.data);
      setLoading(false);
    };

    if (fetching) {
      fetch();
    }
    return () => fetch();
  }, [sessionId, callApi]);

  return { stocking: response.stocking, stockingChange: response.stockingChange, loading };
};
