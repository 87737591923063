import React from 'react';
import { slideWrapper } from '../components/slide';
import { Content } from '../components/content';
import { SlideImage } from '../components/slide-image';
import styled from 'styled-components';
import { Text } from '../components/text';

const Image = styled.img`
  max-width: 100%;
  height: auto;
`;

const SlideText = styled.div`
  font-size: 60px;
  margin: 0 20px;
  z-index: 999;
`;

export const TeslaPuzzle1Story = slideWrapper(({ step, resources, getImg, ...props }) => {
  const { text } = step;

  return (
    <>
      <Content width={'100%'} left={-1} top={-1} xNotCenter yNotCenter>
        <SlideImage width="100%" src={getImg('snieg')} show={true} />
      </Content>
      <Content left={75} top={0} yNotCenter>
        <SlideImage width="1070px" src={getImg('drzewo')} show={true} />
      </Content>
      <Content width="80%" yNotCenter bottom={10}>
        <SlideText>
          <Text big alignCenter>
            {text}
          </Text>
        </SlideText>
      </Content>
    </>
  );
});
