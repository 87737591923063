import { useCallback } from 'react';
import firebase from 'firebase/app';
import 'firebase/storage';

export const useFirebaseStorage = () => {
  const storage = firebase.storage().ref();

  const getDirectoryDocumentRefs = useCallback(
    async (dir, subDir) => {
      return storage
        .child(`${dir}/${subDir}`)
        .listAll()
        .then((list) => {
          return list.items;
        })
        .catch(function (error) {
          console.error(error);
        });
    },
    [storage]
  );

  const getDocumentByRef = useCallback(async (ref) => {
    return ref
      .getDownloadURL()
      .then((url) => url)
      .catch(function (error) {
        console.error(error);
      });
  }, []);

  return { getDirectoryDocumentRefs, getDocumentByRef };
};
