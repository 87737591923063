import { useApi } from '../../generic/hooks/use-api';
import { useCallback } from 'react';

export const useRoundLifecycle = () => {
  const callApi = useApi();

  const roundStart = useCallback(async (sessionData) => {
    if (sessionData.sessionId === undefined || sessionData.currentStep.id === undefined) {
      console.log('Missing voting step id or session id');
      return;
    }
    await callApi('/fishery-game/round-start/', {
      method: 'post',
      body: {
        sessionId: sessionData.sessionId,
        invokedStepId: sessionData.currentStep.id,
      },
    });
  }, []);

  return {
    roundStart,
  };
};
