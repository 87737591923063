import { COMPANIES_COLLECTION } from '@disco/data';
import React, { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import { useTranslation } from 'react-i18next';
import { Card, Header, Segment } from 'semantic-ui-react';
import { useUsers } from '../api/use-users';
import { Contact } from '../components/contact';
import { Profile } from '../components/profile';
import { Subscription } from '../components/subscription';

export function Settings() {
  const { t } = useTranslation();
  const [games, setGames] = useState([]);

  const { currentUserCompanies } = useUsers();

  useEffect(() => {
    const gamesRef = firebase.firestore().collection(COMPANIES_COLLECTION).where('name', 'in', currentUserCompanies);

    const unsubscribe = gamesRef.onSnapshot((snapshot) => {
      const list = [];
      snapshot.forEach((doc) => {
        const { games } = doc.data();
        list.push(...(games || []));
      });
      setGames(list);
    });
    return unsubscribe;
  }, []);

  return (
    <Segment basic>
      <Header size="huge">{t('settings.title')}</Header>
      <Card.Group>
        <Profile />
        <Contact />
      </Card.Group>
      <Header size="huge">{t('settings.games')}</Header>
      <Card.Group>
        {games?.length ? (
          games.map((game, index) => <Subscription key={index} {...game} />)
        ) : (
          <div style={{ marginLeft: '8px' }}>{t('companies.noSubscriptions')}</div>
        )}
      </Card.Group>
    </Segment>
  );
}

export default Settings;
