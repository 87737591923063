import React from 'react';
import { usePermission } from '@disco/hooks';
import { Route, Switch } from 'react-router-dom';
import { Login, PrivateRoute } from '@disco/ui';
import { panelViewWrapper } from './wrappers/panel-view-wrapper';
import Panel from './pages/sessions/panel';
import Settings from './pages/settings';
import Sessions from './pages/sessions';
import Users from './pages/users';
import Games from './pages/games';
import Game from './pages/games/game';
import Presentation from './pages/sessions/presentation';
import GamePreview from './pages/games/game-preview';
import Companies from './pages/companies';
import { useTranslation } from 'react-i18next';
import ArchivedSessions from './pages/archived';

export const AppRouter = ({
  authenticated,
  error,
  btnLoading,
  setBtnLoading,
  onSubmit,
  setEmail,
  setPassword,
  onResetPassword,
}) => {
  const { t } = useTranslation();

  return (
    <Switch>
      <PrivateRoute allow={authenticated} exact path="/" component={panelViewWrapper(Sessions, t)} />
      <PrivateRoute allow={authenticated} exact path="/sessions/:id/present" component={Presentation} />
      <PrivateRoute allow={authenticated} exact path="/sessions/:id" component={panelViewWrapper(Panel, t)} />
      <PrivateRoute allow={authenticated} exact path="/archived" component={panelViewWrapper(ArchivedSessions, t)} />
      <PrivateRoute allow={authenticated} exact path="/settings" component={panelViewWrapper(Settings, t)} />
      <PrivateRoute
        allow={usePermission('usersView') && authenticated}
        exact
        path="/users"
        component={panelViewWrapper(Users, t)}
      />
      <PrivateRoute
        allow={usePermission('companiesView') && authenticated}
        exact
        path="/companies"
        component={panelViewWrapper(Companies, t)}
      />
      <PrivateRoute allow={authenticated} exact path="/games" component={panelViewWrapper(Games, t)} />
      <PrivateRoute
        allow={usePermission('gameDetailView') && authenticated}
        exact
        path="/games/:id"
        component={panelViewWrapper(Game, t)}
      />
      <PrivateRoute allow={authenticated} exact path="/games/:id/preview/:stepId" component={GamePreview} />
      <Route exact path="/login">
        <Login {...{ error, btnLoading, setBtnLoading, onSubmit, setEmail, setPassword, onResetPassword }} />
      </Route>
    </Switch>
  );
};
