import React from 'react';
import { Content } from '../components/content';
import { SlideImage } from '../components/slide-image';
import { slideWrapper } from '../components/slide';
import { PuzzleItem, PuzzleItemsWrapper } from '../components/puzzle-item';

export const TeslaPuzzle3Solution = slideWrapper(({ step, getImg }) => {
  const { answers } = step;

  return (
    <>
      {' '}
      <Content left={-4} bottom={5} xNotCenter yNotCenter>
        <SlideImage width="700px" src={getImg('deska')} show={true} />
      </Content>
      <Content left={15} bottom={7} yNotCenter>
        <SlideImage width="300px" src={getImg('kasa')} show={true} />
      </Content>
      <Content left={15} top={15} yNotCenter>
        <SlideImage width="250px" src={getImg('pistolet')} show={true} />
      </Content>
      <PuzzleItemsWrapper>
        <PuzzleItem getImg={getImg} letter="a" answers={answers} />
        <PuzzleItem getImg={getImg} letter="b" answers={answers} />
        <PuzzleItem getImg={getImg} letter="c" answers={answers} />
        <PuzzleItem getImg={getImg} letter="d" answers={answers} />
        <PuzzleItem getImg={getImg} letter="e" answers={answers} />
      </PuzzleItemsWrapper>
    </>
  );
});
