import { StockingRedCountProps } from '../fishery/types';

export interface GeneratedSectionContextFishery {
  invokedGenerationStepIds?: string[];
  invokedRoundStartStepIds?: string[];
  invokedStockingStepIds?: string[];
  stockingRedCounts?: StockingRedCountProps[];
  roundNumber: number;
  stocking?: number;
  stockingChange?: number;
  dynamiteUserId?: string;
  settingsBlueTakesAll: boolean;
  settingsRedPunishment: boolean;
}

export const initialGeneratedSectionContextFishery: GeneratedSectionContextFishery = {
  invokedGenerationStepIds: [],
  invokedRoundStartStepIds: [],
  roundNumber: 0,
  stocking: 6,
  dynamiteUserId: '',
  settingsBlueTakesAll: false,
  settingsRedPunishment: false,
};

export const getNextRoundFishery = (roundNumber, config) => ({
  roundStocking: roundNumber % 3 === 0 ? true : false,
  roundConference: roundNumber % 3 === 0 ? true : false,
  roundReferendum: roundNumber === 9 || roundNumber === 12 ? true : false,
  roundHowAreYou: roundNumber % 3 === 0 ? true : false,

  strategyBlue: roundNumber > 6 ? true : false,
  strategyYellow: roundNumber > 6 ? true : false,
  strategyOrange: roundNumber > 6 ? true : false,

  settingsDynamit: roundNumber >= 6 ? true : false,

  settingsRedPunishment: config.settingsRedPunishment ? true : false,
  settingsBlueTakesAll: config.settingsBlueTakesAll ? true : false,
});
