import React from 'react';
import { slideWrapper } from '../components/slide';
import { SlideImage } from '../components/slide-image';
import { Content } from '../components/content';
import styled from 'styled-components';
import { Text } from '../components/text';

const SlideText = styled.div`
  font-size: 60px;
  margin: 0 20px;
  z-index: 999;
`;

export const TeslaPuzzle5Story = slideWrapper(({ step, getImg, ...props }) => {
  const { text, bigText } = step;

  return (
    <>
      <Content left={80} top={14} xNotCenter yNotCenter>
        <SlideImage width="250px" src={getImg('klan')} show={true} />
      </Content>
      <Content left={60} top={35} yNotCenter>
        <SlideImage width="1100px" src={getImg('stol')} show={true} />
      </Content>
      <Content left={8} top={-3} yNotCenter>
        <SlideImage width="550px" src={getImg('element5')} show={true} />
      </Content>
      <Content width="80%" yNotCenter bottom={10}>
        <SlideText>
          <Text big={bigText} alignCenter>
            {text}
          </Text>
        </SlideText>
      </Content>
    </>
  );
});
