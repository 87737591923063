import { useEffect, useState } from 'react';
import { useApi } from '../../generic/hooks/use-api';

export const useConferenceSelectSpeakers = (sessionId, stepId) => {
  const [selectedSpeakers, setSelectedSpeakers] = useState([]);
  const [loading, setLoading] = useState(true);

  const callApi = useApi();

  useEffect(() => {
    if (!stepId) return;

    const fetch = async () => {
      const response = await callApi('/fishery-game/conference-select-speakers/', {
        method: 'post',
        body: {
          sessionId: sessionId,
          stepId: stepId,
        },
      });
      setSelectedSpeakers(response.data);
      setLoading(false);
    };
    fetch();
  }, [sessionId, stepId, callApi]);

  return { selectedSpeakers, loading };
};
