import React from 'react';
import { useTranslation } from 'react-i18next';

import { Wrapper } from '../../../components/Wrapper';
import { InterfaceWrapper } from '../../../components/InterfaceWrapper';
import { PlayerInterface } from './PlayerInterface';


export const Rules2 = InterfaceWrapper({
  Interface: PlayerInterface,
  Step: ({ data }) => {
    const { t } = useTranslation();

    return (
      <Wrapper>
        <h1>{t('fishery.rules2')}</h1>
      </Wrapper>
    );
  },
});
