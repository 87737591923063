import { FisheryStepType } from './types';

import { TitleSlide } from './steps/part-1-intro/s1/TitleSlide';
import { VideoSlide } from './steps/part-1-intro/s2/VideoSlide';
import { MoodSlide } from './steps/part-1-intro/s3/MoodSlide';
import { ShipVisionSlide } from './steps/part-1-intro/s4/ShipVisionSlide';
import { NameYourShip } from './steps/part-1-intro/s5/NameYourShip';
import { RoundNumber } from './steps/part-2-game/s1/RoundNumber';
import { SelectStrategy } from './steps/part-2-game/s2/SelectStrategy';
import { RoundResults } from './steps/part-2-game/s3/RoundResults';
import { FishStockingLevel } from './steps/part-2-game/s4/FishStockingLevel';
import { HowAreYou } from './steps/part-2-game/s8b/HowAreYou';
import { DoYouWantConference } from './steps/part-2-game/s6/DoYouWantConference';
import { DoYouWantSpeakAtConference } from './steps/part-2-game/s7/DoYouWantSpeakAtConference';
import { Conference } from './steps/part-2-game/s8/Conference';
import { ReferendumVoting } from './steps/part-2-game/s9/ReferendumVoting';
import { ReferendumResults } from './steps/part-2-game/s10/ReferendumResults';
import { GenerateNextRound } from './steps/generate-next-round';
import { OutroSlide } from './steps/part-3-end/s1/OutroSlide';
import { SurveyGameFeeling } from './steps/part-3-end/s2/SurveyGameFeeling';
import { ResultsSlide } from './steps/part-3-end/s4/ResultsSlide';
import { SurveyGameTarget } from './steps/part-3-end/s3/SurveyGameTarget';
import { GradeMe } from './steps/part-3-end/s6/GradeMe';
import { ThankYou } from './steps/part-3-end/s5/ThankYou';
import { Rules1 } from './steps/part-1-intro/s6/Rules';
import { Rules5 } from './steps/part-1-intro/s10/Rules';
import { Rules4 } from './steps/part-1-intro/s9/Rules';
import { Rules3 } from './steps/part-1-intro/s8/Rules';
import { Rules2 } from './steps/part-1-intro/s7/Rules';
import { Rules6 } from './steps/part-2-game/s11/Rules';
import { Rules7 } from './steps/part-2-game/s12/Rules';
import { Rules8 } from './steps/part-2-game/s13/Rules';
import { Rules9 } from './steps/part-2-game/s14/Rules';
import { StartingStocking } from './steps/part-1-intro/s11/StartingStocking';
import { RulesStart } from './steps/part-1-intro/s12/RulesStart';

export const FisherySteps = {
  [FisheryStepType.SLIDE_1_INTRO]: TitleSlide,
  [FisheryStepType.SLIDE_2_INTRO]: VideoSlide,
  [FisheryStepType.SLIDE_3_INTRO]: MoodSlide,
  [FisheryStepType.SLIDE_4_INTRO]: ShipVisionSlide,
  [FisheryStepType.SLIDE_5_INTRO]: NameYourShip,
  [FisheryStepType.SLIDE_6_INTRO]: Rules1,
  [FisheryStepType.SLIDE_7_INTRO]: Rules2,
  [FisheryStepType.SLIDE_8_INTRO]: Rules3,
  [FisheryStepType.SLIDE_9_INTRO]: Rules4,
  [FisheryStepType.SLIDE_10_INTRO]: Rules5,
  [FisheryStepType.SLIDE_10_1_INTRO]: StartingStocking,
  [FisheryStepType.SLIDE_10_2_INTRO]: RulesStart,

  [FisheryStepType.SLIDE_ROUND_NUMBER]: RoundNumber,
  [FisheryStepType.SLIDE_ROUND_STRATEGY]: SelectStrategy,
  [FisheryStepType.SLIDE_ROUND_SUMMARY]: RoundResults,
  [FisheryStepType.SLIDE_STOCKING_LEVEL]: FishStockingLevel,
  [FisheryStepType.SLIDE_HOW_ARE_YOU]: HowAreYou,
  [FisheryStepType.SLIDE_CONFERENCE_VOTING]: DoYouWantConference,
  [FisheryStepType.SLIDE_CONFERENCE_SPEAKERS]: DoYouWantSpeakAtConference,
  [FisheryStepType.SLIDE_CONFERENCE]: Conference,
  [FisheryStepType.SLIDE_REFERENDUM_VOTING]: ReferendumVoting,
  [FisheryStepType.SLIDE_REFERENDUM]: ReferendumResults,
  [FisheryStepType.SLIDE_11_INTRO]: Rules6,
  [FisheryStepType.SLIDE_12_INTRO]: Rules7,
  [FisheryStepType.SLIDE_13_INTRO]: Rules8,
  [FisheryStepType.SLIDE_14_INTRO]: Rules9,

  [FisheryStepType.SLIDE_GENERATE_NEXT_ROUND]: GenerateNextRound,

  [FisheryStepType.SLIDE_1_END]: OutroSlide,
  [FisheryStepType.SLIDE_2_END]: SurveyGameFeeling,
  [FisheryStepType.SLIDE_3_END]: SurveyGameTarget,
  [FisheryStepType.SLIDE_4_END]: ResultsSlide,
  [FisheryStepType.SLIDE_5_END]: ThankYou,
  [FisheryStepType.SLIDE_6_END]: GradeMe,
};
