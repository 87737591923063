export enum FisheryStepType {
  SLIDE_1_INTRO = 'fishery-s1-intro',
  SLIDE_2_INTRO = 'fishery-s2-intro',
  SLIDE_3_INTRO = 'fishery-s3-intro',
  SLIDE_4_INTRO = 'fishery-s4-intro',
  SLIDE_5_INTRO = 'fishery-s5-select-group-intro',
  SLIDE_6_INTRO = 'fishery-s6-intro',
  SLIDE_7_INTRO = 'fishery-s7-intro',
  SLIDE_8_INTRO = 'fishery-s8-intro',
  SLIDE_9_INTRO = 'fishery-s9-intro',
  SLIDE_10_INTRO = 'fishery-s10-intro',
  SLIDE_10_1_INTRO = 'fishery-s10-1-intro',
  SLIDE_10_2_INTRO = 'fishery-s10-2-intro',

  SLIDE_ROUND_NUMBER = 'fishery-s1-game',
  SLIDE_ROUND_STRATEGY = 'fishery-s2-game',
  SLIDE_ROUND_SUMMARY = 'fishery-s3-game',
  SLIDE_STOCKING_LEVEL = 'fishery-s4-game',
  SLIDE_HOW_ARE_YOU = 'fishery-s8b-game',
  SLIDE_CONFERENCE_VOTING = 'fishery-s6-game',
  SLIDE_CONFERENCE_SPEAKERS = 'fishery-s7-game',
  SLIDE_CONFERENCE = 'fishery-s8-game',
  SLIDE_REFERENDUM_VOTING = 'fishery-s9-game',
  SLIDE_REFERENDUM = 'fishery-s10-game',
  SLIDE_11_INTRO = 'fishery-s11-intro',
  SLIDE_12_INTRO = 'fishery-s12-intro',
  SLIDE_13_INTRO = 'fishery-s13-intro',
  SLIDE_14_INTRO = 'fishery-s14-intro',

  SLIDE_GENERATE_NEXT_ROUND = 'fishery-generate-next-round',

  SLIDE_1_END = 'fishery-s1-end',
  SLIDE_2_END = 'fishery-s2-end',
  SLIDE_3_END = 'fishery-s3-end',
  SLIDE_4_END = 'fishery-s4-end',
  SLIDE_5_END = 'fishery-s5-end',
  SLIDE_6_END = 'fishery-s6-end',
}
