import React from 'react';
import { slideWrapper } from '../components/slide';
import { Content } from '../components/content';
import styled from 'styled-components';
import { useAnimationSteps } from '@disco/hooks';
import { TextBox } from '../components/text-box';
import { Text } from '../components/text';
import { useSantaFinalSolution } from '../hooks/use-santa-final-solution';
import { Rule } from '../components/rule';
import { ConditionalSoundButton } from '../components/sound-button';

const FinalText = styled.div`
  font-size: 30px;
  line-height: 60px;
  white-space: pre;
  text-align: center;

  color: ${(props) => props.theme.textColor};
  text-shadow: ${(props) => props.theme.textShadow || 'none'};
`;

export const FinalStory = slideWrapper(({ step, data, resources, fetching, getImg, ...props }) => {
  const { title, text, text1, text2, text3, text4, text5, type } = step;
  const { sessionId } = data;
  const { groupNameText, worstGroupNameText } = useSantaFinalSolution(sessionId);

  const texts = [text1, text2, text3, text4, text5].filter((r) => r);
  const animationStep = useAnimationSteps(texts.length);

  const replaceWithText = type === 'chronos-santa-final-santa-3-story' ? worstGroupNameText : groupNameText;

  const isStory23 = type === 'chronos-santa-final-santa-2-story' || type === 'chronos-santa-final-santa-3-story';

  const renderText = () => {
    if (isStory23) {
      return texts.map((text, index) => (
        <TextWrapper key={index} show={animationStep > index}>
          <Text big>{text?.replace('{{groupName}}', `<b style="color:#e13636">${replaceWithText}</b>`)}</Text>
        </TextWrapper>
      ));
    } else {
      return texts.map((text, index) => (
        <Rule
          width="60vw"
          key={index}
          show={animationStep > index}
          text={text.replace('{{groupName}}', replaceWithText)}
        />
      ));
    }
  };

  return (
    <>
      {fetching && <ConditionalSoundButton getImg={getImg} />}
      <Content yNotCenter top={7} left={isStory23 ? 40 : 50}>
        <TextBox>{title}</TextBox>
        {text && <FinalText>{text}</FinalText>}
      </Content>
      <Content yNotCenter y={140} top={15} left={40}>
        {renderText()}
      </Content>
      <Content xNotCenter left={70} top={50}>
        <SantaImage show src={getImg('santa')} />
      </Content>
    </>
  );
});

export const SantaImage = styled.img<any>`
  opacity: ${(props) => (props.show ? 1 : 0)};
  transition: opacity 0.7s;
  max-height: 100vh;
`;

const TextWrapper = styled.div<any>`
  opacity: ${(props) => (props.show ? 1 : 0)};
  transition: opacity 0.7s;
  margin-top: 64px;
`;
