import React from 'react';
import { slideWrapper } from '../components/slide';
import { TextBox } from '../components/text-box';
import { Content } from '../components/content';
import styled from 'styled-components';
import { Text } from '../components/text';
import { Colors } from '../constants';

const MapWrapper = styled.div`
  position: relative;
  height: 80vh;
  width: 80vh;
`;

const MapImage = styled.img<any>`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;

const TextWrapper = styled.div`
  margin-top: 24px;
  text-align: center;
`;

export const SantaYourChoice = slideWrapper(({ step, getImg, resources, ...props }) => {
  const { title, text } = step;

  return (
    <>
      {text && (
        <Content xNotCenter top={50} left={4} width="400px">
          <TextWrapper>
            <Text big>{text}</Text>
          </TextWrapper>
        </Content>
      )}
      <Content yNotCenter top={5} left={50}>
        <TextBox style={{ paddingBottom: '16px' }}>{title}</TextBox>
        <MapWrapper>
          <MapImage show={1} src={getImg('map')} />
          <MapImage show={1} src={getImg('path')} />
        </MapWrapper>
      </Content>
    </>
  );
});
