import React, { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import { useParams } from 'react-router-dom';
import { SESSION_COLLECTION, SessionData, STEP_SUBCOLLECTION } from '@disco/data';
import { Dimmer, Loader } from 'semantic-ui-react';
import { byOrderId, SessionDurationAction, SessionStepData } from '../../models/session';
import { STEP_TYPES } from '@disco/steps';
import { useResources } from '../../api/use-resources';
import { setNextStep } from '../../utils/set-next-step';
import { useTranslation } from 'react-i18next';
import { useSessions } from '../../api/use-sessions';
import { ScaleWrapper } from '../../components/scale-wrapper';
import { UnhandledTypeSlide } from '../../../../../../libs/steps/src/lib/generic/UnhandledTypeSlide';

export function Presentation() {
  const { i18n } = useTranslation();
  const { editSessionDuration } = useSessions();

  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<SessionData>();
  const [steps, setSteps] = useState<SessionStepData[]>();
  const [currentStep, setCurrentStep] = useState<SessionStepData>();
  const [currentStepIndex, setCurrentStepIndex] = useState<number | null>();
  const [currentOrderId, setCurrentOrderId] = useState<number | null>();

  const { resources } = useResources(data?.gameId);

  const findEdgeStepOrderId = (func: (a: number, b: number) => number) =>
    steps?.map((ele) => ele.orderId)?.reduce((a, b) => func(a, b));

  const maxOrderId = findEdgeStepOrderId(Math.max);
  const minOrderId = findEdgeStepOrderId(Math.min);

  useEffect(() => {
    if (data?.language && data?.language !== i18n.language) {
      i18n.changeLanguage(data.language);
    }
  }, [data?.language]);

  useEffect(() => {
    return firebase
      .firestore()
      .collection(SESSION_COLLECTION)
      .doc(id)
      .onSnapshot((doc) => {
        setData(doc.data() as SessionData);
      });
  }, [id]);

  useEffect(() => {
    return firebase
      .firestore()
      .collection(SESSION_COLLECTION)
      .doc(id)
      .collection(STEP_SUBCOLLECTION)
      .onSnapshot((snapshot) => {
        const list: SessionStepData[] = [];
        snapshot.forEach((doc) => {
          list.push({ ...doc.data(), id: doc.id });
        });
        list.sort(byOrderId);
        setSteps(list);
      });
  }, [id]);

  useEffect(() => {
    if (!data || !steps?.length) return;
    const stepIndex = steps.findIndex((s) => s.id === data.currentStepId);
    if (stepIndex !== undefined) {
      const step = steps[stepIndex];
      setCurrentStep(step);
      setCurrentStepIndex(stepIndex);
      setCurrentOrderId(step.orderId);
      setLoading(false);
    }
  }, [data, steps]);

  const Component = currentStep ? STEP_TYPES[currentStep.type] || UnhandledTypeSlide : () => null;

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.keyCode === 37 && !currentStep?.blockPrevStepKey) {
        const prevStepIndex = currentStepIndex > 0 ? currentStepIndex - 1 : 0;
        const prevOrderId = steps[prevStepIndex]?.orderId || minOrderId;
        setNextStep(id, prevOrderId, data, steps);
        return;
      }
      if (e.keyCode === 39 && !currentStep?.blockNextStepKey) {
        if (currentOrderId === minOrderId + 1) {
          editSessionDuration(id, { durationAction: SessionDurationAction.START });
        }
        if (currentOrderId === maxOrderId) {
          editSessionDuration(id, { durationAction: SessionDurationAction.END });
        }

        const nextStepIndex = currentStepIndex < steps.length - 1 ? currentStepIndex + 1 : steps.length - 1;
        const nextOrderId = steps[nextStepIndex]?.orderId || maxOrderId;
        setNextStep(id, nextOrderId, data, steps);
        return;
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [id, data, steps, currentStepIndex]);

  return (
    <ScaleWrapper scaleView={data?.scaleView}>
      <Dimmer inverted active={loading}>
        <Loader />
      </Dimmer>
      <Component
        key={currentStep?.id}
        data={{
          sessionCode: data?.sessionCode,
          currentStep,
          resources: { ...resources },
          sessionId: id,
          groups: data?.groups,
          status: data?.status,
        }}
        presentation
      />
    </ScaleWrapper>
  );
}

export default Presentation;
