import React, { useState } from 'react';
import { Button, ButtonProps } from 'semantic-ui-react';
import { ConfirmModal } from './confirm-modal';

interface CustomButtonProps extends ButtonProps {
  onMakeAction?: () => void;
  withConfirmation?: boolean;
  header?: string;
  content?: string;
  fluid?: boolean;
  children?: React.ReactNode;
}

export function CustomButton({
  onMakeAction,
  withConfirmation,
  fluid,
  header,
  content,
  children,
  ...rest
}: CustomButtonProps) {
  const [displayConfirm, setDisplayConfirm] = useState(false);

  const onClose = () => setDisplayConfirm(false);

  const actionAndClose = () => {
    onMakeAction();
    onClose();
  };

  return (
    <>
      <ConfirmModal {...{ displayConfirm, onAction: onMakeAction, onClose, header, content }} />
      <Button fluid={fluid} onClick={() => (withConfirmation ? setDisplayConfirm(true) : actionAndClose())} {...rest}>
        {children}
      </Button>
    </>
  );
}
