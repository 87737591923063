import React from 'react';
import { slideWrapper } from '../components/slide';
import { Timer } from '../components/timer';
import { PuzzleItem, PuzzleItemsWrapper } from '../components/puzzle-item';
import { Content } from '../components/content';
import styled from 'styled-components';

const Image = styled.img`
  height: auto;
`;

export const OfficePuzzle1 = slideWrapper(({ data, step, getImg }) => {
  const { answers } = step;

  return (
    <>
      <Content left={10} top={50}>
        <Image src={getImg('clerk')} />
      </Content>
      <Timer data={data} presentation />
      <PuzzleItemsWrapper>
        <PuzzleItem getImg={getImg} letterWrapper="t1" letter="a" answers={answers} />
        <PuzzleItem getImg={getImg} letterWrapper="t2" letter="b" answers={answers} />
        <PuzzleItem getImg={getImg} letterWrapper="t4" letter="c" answers={answers} />
      </PuzzleItemsWrapper>
    </>
  );
});
