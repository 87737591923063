import { Button } from 'semantic-ui-react';
import styled from 'styled-components';
import { companyColor } from '@disco/data';

export const GameButton = styled(Button)`
  &&& {
    background-color: ${(props) => props.theme.buttonColor || companyColor.violet};
    color: white;
  }
`;
