import React, { useState } from 'react';
import { Button, Icon, Modal } from 'semantic-ui-react';
import { Text } from 'libs/ui/src/lib/text/text';
import { useTranslation } from 'react-i18next';

export const DEFAULT_VARIANT = 'variant1';

export const InitialGroupList = ({ variants, variantKey = DEFAULT_VARIANT }) => {
  const { t } = useTranslation();

  const [show, setShow] = useState(false);

  const list = (variants && variants.find((ele) => ele.key === variantKey)?.data) || [];

  const handleShowHide = (e) => {
    e.preventDefault();
    setShow(!show);
  };

  return (
    <>
      <Modal closeIcon dimmer={'inverted'} size="small" open={show} onClose={() => setShow(false)}>
        <Modal.Header>Groups</Modal.Header>
        <Modal.Content>
          {list.map(({ name, color }) => (
            <Text key={name}>
              {name} - {color}
            </Text>
          ))}
        </Modal.Content>
      </Modal>
      <Button disabled={!list?.length} secondary inverted size="medium" onClick={handleShowHide}>
        <Icon name="group" />
        {t('utils.show')}
      </Button>
    </>
  );
};
