import { useTranslation } from 'react-i18next';
import { useCookies } from '@disco/hooks';
import { useForm } from 'react-hook-form';
import { Form } from 'semantic-ui-react';
import { HFInput } from '../../../../../../ui/src/lib/form/hf-input';
import { Button } from '../components/button';
import React, { useState } from 'react';
import styled from 'styled-components';
import { useStepAction } from '../../../api/use-step-action';
import { useSelectGroup } from '../../../api/use-select-group';
import { Content } from '../../components/content';
import { Text } from '../../components/text';

const StyledContent = styled(Content)`
  &&& {
    form {
      width: 100%;
      max-width: 300px;
    }
  }
`;

export const SelectGroupContent = ({ uid, data, isSanta }) => {
  const { t } = useTranslation();

  const { updateSessionCookie, getSessionCookie } = useCookies();

  const { groupName } = getSessionCookie();
  const [groupSelected, setGroupSelected] = useState(!!groupName);

  const { postAction } = useStepAction();
  const { validateGroup } = useSelectGroup();

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting, isDirty },
    setError,
  } = useForm();

  const placeholder = isSanta ? t('group.placeholder') : t('group.placeholderWagon')
  const playerQuestion = isSanta ? null : t('group.playerQuestionWagon')
  const notFoundError = isSanta ? t('group.groupNotFound') : t('group.wagonNotFound')
  const groupSelectedText = isSanta ? t('group.groupSelectedSanta', { groupName }) : t('group.wagonSelected', { groupName })


  const onGroupSubmit = async ({ groupName }) => {
    if (!groupName) {
      setError('groupName', { message: t('util.valueCantBeEmpty') });
      return;
    }

    const result = await validateGroup(data.sessionId, groupName);

    if (result.code === 200) {
      await postAction({ data: { groupName }, userId: uid, slideData: data, isGroupSelection: true });
      updateSessionCookie({ groupName });
      setGroupSelected(true);
      const event = new Event('cookie-updated');
      document.dispatchEvent(event);
    } else {
      setError('groupName', { message: notFoundError });
    }
  };

  //todo move to separate shared component if inputs for picking group and nickname have same styling
  if (!groupSelected) {
    return (
      <StyledContent width="80vw" top={45} left={50}>
        {playerQuestion && (
          <Text alignCenter style={{marginBottom: '16px'}}>
            {playerQuestion}
          </Text>
        )}
        <Form onSubmit={handleSubmit(onGroupSubmit)}>
          <HFInput
            {...{
              name: 'groupName',
              defaultValue: '',
              control,
              placeholder,
              transform: (e) => e.target.value.toUpperCase(),
              errors,
            }}
          />
          <Button loading={isSubmitting} fluid>
            {t('util.send')}
          </Button>
        </Form>
      </StyledContent>
    );
  } else {
    return (
      <Content width="80vw" top={45} left={50}>
        <Text alignCenter>
          {groupSelectedText}
        </Text>
      </Content>
    );
  }
};
