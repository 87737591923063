import React from 'react';
import { slideWrapper } from '../components/slide';
import { PuzzleItem, PuzzleItemsWrapper } from '../components/puzzle-item';
import { Timer } from '../components/timer';

import { SlideImage } from '../components/slide-image';
import { Content } from '../components/content';
import { useConfirmation } from '../../generic/hooks/use-confirmation';

export const LeonardoPuzzle4 = slideWrapper(({ step, data, getImg, ...props }) => {
  const { answers } = step;

  const ConfirmationPopup = useConfirmation(1);

  return (
    <>
      <ConfirmationPopup />
      <Timer data={data} presentation />

      <Content left={13} bottom={-5} yNotCenter>
        <SlideImage width="400px" src={getImg('element2')} show={true} />
      </Content>

      <Content left={15} bottom={15} yNotCenter>
        <SlideImage width="300px" src={getImg('statyw')} show={true} />
      </Content>
      <PuzzleItemsWrapper>
        <PuzzleItem getImg={getImg} letter="a" answers={answers} smallItem />
        <PuzzleItem getImg={getImg} letter="b" answers={answers} smallItem />
        <PuzzleItem getImg={getImg} letter="c" answers={answers} smallItem />
        <PuzzleItem getImg={getImg} letter="d" answers={answers} smallItem />
        <PuzzleItem getImg={getImg} letter="e" answers={answers} smallItem />
      </PuzzleItemsWrapper>
    </>
  );
});
