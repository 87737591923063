import React, { useState } from 'react';
import firebase from 'firebase/app';
import { useTranslation } from 'react-i18next';
import { Button, Input, Modal } from 'semantic-ui-react';
import { Container, Text } from '@disco/ui';
import { SESSION_PUBLIC_COLLECTION } from '@disco/data';
import { emitToast, ToastType } from '../../../../../../../libs/utils/src/lib/toast';

interface DeleteSessionProps {
  open: boolean;
  id: string;
  name: string;
  sessionCode: string;
  onClose: () => void;
}

export function DeleteSessionModal({ open, id, name, sessionCode, onClose }: DeleteSessionProps) {
  const { t } = useTranslation();

  const [sessionName, setSessionName] = useState('');

  const onDelete = async () => {
    onClose();
    await firebase.firestore().collection('sessions').doc(id).set({ status: 'deleted' }, { merge: true });
    await firebase
      .firestore()
      .collection(SESSION_PUBLIC_COLLECTION)
      .doc(sessionCode)
      .delete()
      .then(() => emitToast({ title: t('utils.toastSuccess') }, ToastType.Success))
      .catch(() => emitToast({ title: t('utils.toastError') }, ToastType.Error));
  };

  return (
    <Modal dimmer={'inverted'} size="small" open={open} onClose={onClose}>
      <Modal.Header>{t('sessions.deleteModalTitle')}</Modal.Header>
      <Modal.Content>
        <Container margin="0 0 16px 0" flex>
          <Text padding={2}>{t('sessions.deleteModalPlaceholder')}</Text>
          <Text padding={2} color="red">
            {name}
          </Text>
        </Container>
        <Input placeholder={name} fluid value={sessionName} onChange={(e) => setSessionName(e.target.value)} />
      </Modal.Content>
      <Modal.Actions>
        <Button negative disabled={sessionName !== name} onClick={onDelete}>
          {t('sessions.delete')}
        </Button>
        <Button onClick={onClose}>{t('sessions.cancel')}</Button>
      </Modal.Actions>
    </Modal>
  );
}
