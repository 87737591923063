import { useCookies, useFirebase } from '@disco/hooks';
import { PermissionsContext, UserDiscoContext } from '@disco/ui';
import firebase from 'firebase/app';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { Dimmer, Loader } from 'semantic-ui-react';
import { ToastContainer } from 'react-toastify';
import { emitToast, ToastType } from 'libs/utils/src/lib/toast';
import ErrorBoundary from './error-boundary';
import { AppRouter } from './router';
import { languages } from './components/language-picker';

const prodConfig = {
  apiKey: 'AIzaSyAsybcHC6HvWdQN2f7g332D43quWFkdGVM',
  authDomain: 'disco-ament.firebaseapp.com',
  projectId: 'disco-ament',
  storageBucket: 'disco-ament.appspot.com',
  messagingSenderId: '312472167489',
  appId: '1:312472167489:web:dc6e7cdf0b82f20ccbd2c0',
  measurementId: 'G-D3M21Y9LJK',
};

const testConfig = {
  apiKey: 'AIzaSyA8SfSN9CDMce6UEfsFW1YM03hlwTfWJCs',
  authDomain: 'asobi-7cc09.firebaseapp.com',
  projectId: 'asobi-7cc09',
  storageBucket: 'asobi-7cc09.appspot.com',
  messagingSenderId: '748579902292',
  appId: '1:748579902292:web:6b0aec161c459634d76ec1',
  measurementId: 'G-L3PV3HH7DQ',
};

export function App() {
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const config = prodConfig;

  const { loading } = useFirebase(config);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const [user, setUser] = useState({});
  const [permission, setPermissions] = useState({});
  const [authenticated, setAuthenticated] = useState(false);
  const [userLoaded, setUserLoaded] = useState(false);

  const [error, setError] = useState<string | boolean>(false);

  const { getLanguageCookie } = useCookies();
  const [btnLoading, setBtnLoading] = useState(false);

  useEffect(() => {
    const lang = getLanguageCookie();

    // @ts-ignore
    if (languages.map((lan) => lan.value).includes(lang)) {
      // @ts-ignore
      i18n.changeLanguage(lang);
    }
  }, [getLanguageCookie, i18n]);

  useEffect(() => {
    if (!loading) {
      firebase.auth().onAuthStateChanged(async (user) => {
        if (user) {
          const token = await user?.getIdToken(true);
          const { claims = {} } = await user?.getIdTokenResult();
          setAuthenticated(!!user?.uid);
          setUser({
            token,
            email: user.email,
            roles: claims.roles,
            companies: claims.companies,
            name: user.displayName,
          });
          setInterval(async () => {
            const newUser = { uid: await user?.uid, token: await user?.getIdToken(true), name: user.displayName };
            setUser((data) => ({ ...data, ...newUser }));
          }, 15 * 60 * 1000);
          setError(false);
        } else {
          setAuthenticated(false);
        }
        if (submitted) {
          history.push('/');
        }
        setUserLoaded(true);
        setBtnLoading(false);
      });
    }
  }, [loading, submitted]);

  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection('permissions')
      .onSnapshot((snapshot) => {
        const permissions = {};
        snapshot.forEach((doc) => (permissions[doc.id] = doc.data()));
        setPermissions(permissions);
      });
    return unsubscribe;
  }, [authenticated]);

  const onSubmit = (e) => {
    setBtnLoading(true);
    if (!loading) {
      e.preventDefault();
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .catch(() => {
          const errorMsg = t('utils.wrongPasswordOrEmail');
          setError(errorMsg);
          setBtnLoading(false);
        });
      setSubmitted(true);
    }
  };

  const onResetPassword = () => {
    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => emitToast({ title: t('users.resetLinkSent') }, ToastType.Success))
      .catch(() => emitToast({ title: t('utils.toastError') }, ToastType.Error));
  };

  return userLoaded ? (
    <ErrorBoundary>
      <UserDiscoContext.Provider value={user}>
        <PermissionsContext.Provider value={permission}>
          <ToastContainer />
          <AppRouter
            {...{ authenticated, error, btnLoading, setBtnLoading, onSubmit, setEmail, setPassword, onResetPassword }}
          />
        </PermissionsContext.Provider>
      </UserDiscoContext.Provider>
    </ErrorBoundary>
  ) : (
    <Dimmer active inverted>
      <Loader />
    </Dimmer>
  );
}

export default App;
