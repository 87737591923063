import { useApi } from '@disco/hooks';
import { useCallback, useEffect, useState } from 'react';
import { ChronosApiEndpoints } from '../../../../../data/src/lib/chronos/api-endpoints';
import { getTopGroupNameText, getWorstGroupNameText } from './use-santa-final-counting';
import { usePresentationCheck } from './use-presentation-check';

export const useSantaFinalSolution = (sessionId: string) => {
  const callApi = useApi();
  const { actualPresentationScreen } = usePresentationCheck();

  const [result, setResult] = useState<any>();

  useEffect(() => {
    if (actualPresentationScreen) {
      getSantaFinalSolution().then((data) => setResult(data));
    }
  }, []);

  const getSantaFinalSolution = useCallback(async () => {
    const { data } = await callApi(ChronosApiEndpoints.getSantaFinal, {
      method: 'POST',
      body: {
        sessionId,
      },
    });
    return data;
  }, [callApi, sessionId]);

  return { groupNameText: getTopGroupNameText(result), worstGroupNameText: getWorstGroupNameText(result) };
};
