import React from 'react';
import { slideWrapper } from '../components/slide';
import { PuzzleItems } from '../components/puzzle-items';
import { RoundLetters } from '@disco/data';
import { SlideImage } from '../components/slide-image';
import { Content } from '../components/content';
import {PuzzleItem, PuzzleItemsWrapper} from "../components/puzzle-item";

export const LeonardoPuzzle4Solution = slideWrapper(({ step, getImg, countingResults, countingResultsLoading }) => {
  const { answers } = step;

  return (
    <>
      <Content left={13} bottom={-5} yNotCenter>
        <SlideImage width="400px" src={getImg('element2')} show={true} />
      </Content>

      <Content left={15} bottom={15} yNotCenter>
        <SlideImage width="300px" src={getImg('statyw')} show={true} />
      </Content>

      <Content left={6} bottom={6} yNotCenter>
        <SlideImage width="400px" rotate={'30deg'} src={getImg('cyrkiel')} show={true} />
      </Content>
      <PuzzleItemsWrapper>
        <PuzzleItem getImg={getImg} letter="a" answers={answers} />
        <PuzzleItem getImg={getImg} letter="b" answers={answers} />
        <PuzzleItem getImg={getImg} letter="c" answers={answers} />
        <PuzzleItem getImg={getImg} letter="d" answers={answers} />
        <PuzzleItem getImg={getImg} letter="e" answers={answers} />
      </PuzzleItemsWrapper>
    </>
  );
});
