import React from 'react';
import { Wrapper } from '../../../components/Wrapper';
import { InterfaceWrapper } from '../../../components/InterfaceWrapper';
import { PlayerInterface } from './PlayerInterface';
import { Content } from './Content';

export const DoYouWantConference = InterfaceWrapper({
  Interface: PlayerInterface,
  Step: ({ data }) => {
    return <Wrapper><Content data={data} /></Wrapper>;
  },
});
