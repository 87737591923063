import React from 'react';
import styled from 'styled-components';
import { Timer as GenericTimer } from '../../generic/timer/timer';
import { Content } from './content';

const TimerBox = styled.div`
  .ui.statistic > .label,
  .ui.statistic > .value {
    color: ${(props) => props.theme.textColor};
    text-shadow: ${(props) => props.theme.textShadow || 'none'};
  }

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const Timer = ({ data, presentation }) => {
  const {
    currentStep: { timer },
  } = data;

  return (
    <Content width="300px" zIndex={100} yNotCenter left={94} top={8}>
      <TimerBox>{timer && <GenericTimer presentation={presentation} data={data} />}</TimerBox>
    </Content>
  );
};
