import { CollectorsStep } from './collectors/step-types';
import { ChronosSteps } from './chronos/step-types';
import { VideoStep } from './generic/video-step/video-step';
import { GenericStepType } from './types';
import { FisherySteps } from './fishery/step-types';

const GenericSteps = {
  [GenericStepType.VIDEO_STEP]: VideoStep,
};

export const STEP_TYPES = {
  ...CollectorsStep,
  ...ChronosSteps,
  ...GenericSteps,
  ...FisherySteps,
};
