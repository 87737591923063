import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useApi } from '../../../../generic/hooks/use-api';
import { Dots } from '../../../components/player-interface/Dots';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 2.4rem;
  .label,
  .value {
    font-family: Klavika !important;
    color: #114a94 !important;
  }
`;

const ListItem = styled.li`
  margin-top: 16px;
`;

export const Content = ({ data }) => {
  const { t } = useTranslation();
  const sessionId = data.sessionId;
  const [topUsers, setTopUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  const callApi = useApi();

  useEffect(() => {
    const fetch = async () => {
      const response = await callApi('/fishery-game/final-score', {
        method: 'post',
        body: {
          sessionId: sessionId,
        },
      });
      setTopUsers(response.data || []);
      setLoading(false);
    };
    fetch();
  }, [sessionId, callApi]);

  return loading ? (
    <Wrapper>
      <Dots />
    </Wrapper>
  ) : (
    <Wrapper>
      <h1>{t('fishery.summaryResults')}</h1>
      <ol>
        {topUsers?.map((user, index) => {
          return <ListItem key={index}>{`${user.shipName} - ${user.overallPoints}`}</ListItem>;
        })}
      </ol>
    </Wrapper>
  );
};
