import React from 'react';
import { useTranslation } from 'react-i18next';

export const Content = ({ data, context }) => {
  const { t } = useTranslation();

  const { currentStep } = data;
  const { roundNumber } = currentStep || {};

  return <h1>{t('fishery.roundNumber', { number: roundNumber })}</h1>;
};
