import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dimmer, Header, Icon, Label, Loader, Popup, Segment, Select, Table } from 'semantic-ui-react';
import { useUsers } from '../api/use-users';
import { EditUserModal } from '../components/modals/users/edit-user';
import { User } from '../models/user';
import { CreateUserModal } from '../components/modals/users/create-user';
import { CustomButton } from '@disco/ui';
import firebase from 'firebase/app';
import { emitToast, ToastType } from 'libs/utils/src/lib/toast';

export function Users() {
  const { t } = useTranslation();

  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(true);
  const [createUser, setCreateUser] = useState(false);

  const [selectedUser, setSelectedUser] = useState<User>();

  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);

  const { getUsers, deleteUser } = useUsers();

  useEffect(() => {
    (async () => {
      if (selectedUser == null) {
        const { data } = await getUsers();
        setUsers(data);
        const companies = [];
        data.forEach((user) => companies.push(...(user.companies || [])));
        setCompanies([...new Set(companies)]);
        setLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUser]);

  const sendPasswordReset = (email) => {
    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        setSelectedUser(null);
        emitToast({ title: t('users.resetLinkSent') }, ToastType.Success);
      })
      .catch(() => {
        setSelectedUser(null);
        emitToast({ title: t('utils.toastError') }, ToastType.Error);
      });
  };

  const onDeleteUserClick = async (id: string) => {
    const result = await deleteUser(id);
    if (result.code === 200) {
      emitToast({ title: 'User successfully deleted!' }, ToastType.Success);
      const { data } = await getUsers();
      setUsers(data);
    } else {
      emitToast({ title: t('utils.toastError') }, ToastType.Error);
    }
  };

  return (
    <>
      <Segment basic>
        <Header size="huge">{t('users.title')}</Header>
        <Dimmer inverted active={loading}>
          <Loader />
        </Dimmer>
        <Table basic="very" selectable>
          <Table.Header>
            <Table.Row>
              {/*<Table.HeaderCell style={{ paddingLeft: '.5em' }} singleLine>*/}
              {/*  {t('users.id')}*/}
              {/*</Table.HeaderCell>*/}
              <Table.HeaderCell>{t('users.email')}</Table.HeaderCell>
              <Table.HeaderCell>{t('users.name')}</Table.HeaderCell>
              <Table.HeaderCell>{t('users.roles')}</Table.HeaderCell>
              <Table.HeaderCell>
                {t('users.companies')}
                <Popup
                  wide
                  offset={[-12, 0]}
                  trigger={<Icon style={{ cursor: 'pointer', marginLeft: 6 }} name="filter" />}
                  on="click"
                >
                  <Select
                    size="small"
                    placeholder={t('sessions.facilitator')}
                    options={companies.map((p) => ({ key: p, value: p, text: p }))}
                    onChange={(_, { value: newValue }) => setSelectedCompany(newValue)}
                  />
                </Popup>
                {selectedCompany && (
                  <Icon onClick={() => setSelectedCompany(null)} style={{ cursor: 'pointer' }} name="undo" />
                )}
              </Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
              <Table.HeaderCell textAlign="right" style={{ paddingRight: '.5em' }}>
                <Button size="tiny" positive onClick={() => setCreateUser(true)}>
                  <Icon name="plus" />
                  {t('sessions.create')}
                </Button>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {users
              .filter((user) => !selectedCompany || user.companies.includes(selectedCompany))
              .map((user) => (
                <Table.Row key={user.uid}>
                  {/*<Table.Cell style={{ paddingLeft: '.5em' }}>{user.uid}</Table.Cell>*/}
                  <Table.Cell>{user.email}</Table.Cell>
                  <Table.Cell>{user.name}</Table.Cell>
                  <Table.Cell>
                    {user.roles.map((role) => (
                      <Label style={{ margin: '4px 4px 0 0' }} key={role}>
                        {role}
                      </Label>
                    ))}
                  </Table.Cell>
                  <Table.Cell>
                    {user.companies.map((company) => (
                      <Label style={{ margin: '4px 4px 0 0' }} key={company}>
                        {company}
                      </Label>
                    ))}
                  </Table.Cell>
                  <Table.Cell>
                    <CustomButton
                      size="small"
                      withConfirmation
                      content={t('users.resetConfirmation')}
                      onMakeAction={() => sendPasswordReset(user.email)}
                    >
                      <Icon name="redo" />
                      {t('users.resetButton')}
                    </CustomButton>
                  </Table.Cell>
                  <Table.Cell textAlign="right" style={{ paddingRight: '.5em' }}>
                    <Button icon="edit" size="tiny" onClick={() => setSelectedUser(user)} />
                    <CustomButton
                      withConfirmation
                      icon="delete"
                      negative
                      size="tiny"
                      onMakeAction={() => onDeleteUserClick(user.uid)}
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
          </Table.Body>
        </Table>
      </Segment>
      <CreateUserModal open={createUser} onClose={() => setCreateUser(null)} />
      {selectedUser && <EditUserModal onClose={() => setSelectedUser(null)} user={selectedUser} />}
    </>
  );
}

export default Users;
