import React from 'react';
import { slideWrapper } from '../components/slide';
import styled from 'styled-components';
import { Text } from '../components/text';
import { useAnimationSteps } from '@disco/hooks';
import { Content } from '../components/content';
import { SlideImage } from '../components/slide-image';

const SlideText = styled.div`
  font-size: 60px;
  margin: 0 20px;
`;

export const LeonardoPuzzle2Story = slideWrapper(({ step, getImg, ...props }) => {
  const { text, animationsCount } = step;

  const animationStep = useAnimationSteps(1, true);

  return (
    <>
      <Content left={50} top={30} yNotCenter>
        <SlideImage width="400px" src={getImg('machine-fly')} show={animationStep} />
      </Content>
      <Content left={90} top={20} yNotCenter>
        <SlideImage width="300px" src={getImg('balon-no-color')} show={animationStep} />
      </Content>
      <Content width="80%" yNotCenter bottom={5}>
        <SlideText>
          <Text big alignCenter>
            {text}
          </Text>
        </SlideText>
      </Content>
    </>
  );
});
