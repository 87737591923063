import React from 'react';
import { SlideImage } from '../components/slide-image';
import { Content } from '../components/content';
import { slideWrapper } from '../components/slide';
import { PuzzleItems } from '../components/puzzle-items';
import { RoundLetters, RoundType } from '@disco/data';

export const TeslaPuzzle5Counting = slideWrapper(
  ({ step, getImg, countingResults, countingResultsLoading }) => {
    const { answers } = step;

    return (
      <>
        <Content left={15} top={14} xNotCenter yNotCenter>
          <SlideImage width="250px" src={getImg('lupa')} show={true} />
        </Content>
        <Content left={10} bottom={4} yNotCenter>
          <SlideImage width="400px" src={getImg('pocztowka')} show={true} />
        </Content>
        <PuzzleItems
          {...{ countingResults, countingResultsLoading, getImg, answers, letters: RoundLetters[5] }}
          smallItem
        />
      </>
    );
  },
  { countAnswersTour: RoundType.Tesla }
);
