import React, { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import { useTranslation } from 'react-i18next';
import { Button, Label, Dimmer, Header, Icon, Loader, Segment, Table } from 'semantic-ui-react';
import { COMPANIES_COLLECTION } from '@disco/data';
import { CompanyModal } from '../components/modals/company/company';

export function Companies() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const [selectedCompany, setSelectedCompany] = useState(null);

  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection(COMPANIES_COLLECTION)
      .onSnapshot((snapshot) => {
        const list = [];
        snapshot.forEach((doc) => list.push({ ...doc.data(), id: doc.id }));
        setData(list);
        setLoading(false);
      });
    return unsubscribe;
  }, []);

  return (
    <>
      <Segment basic>
        <Header size="huge">{t('companies.title')}</Header>
        <Dimmer inverted active={loading}>
          <Loader />
        </Dimmer>
        <Table basic="very" selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell style={{ paddingLeft: '.5em' }} singleLine>
                {t('companies.name')}
              </Table.HeaderCell>{' '}
              <Table.HeaderCell style={{ paddingLeft: '.5em' }} singleLine>
                {t('companies.games')}
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="right" style={{ paddingRight: '.5em' }}>
                <Button size="tiny" positive onClick={() => setSelectedCompany({ name: '' })}>
                  <Icon name="plus" />
                  {t('sessions.create')}
                </Button>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data.map(({ name, games }) => (
              <Table.Row key={name}>
                <Table.Cell style={{ paddingLeft: '.5em' }}>{name}</Table.Cell>
                <Table.Cell>
                  {(games || []).map((game) => (
                    <Label style={{ margin: '4px 4px 0 0' }} key={game.id}>
                      {game.id}
                    </Label>
                  ))}
                </Table.Cell>
                <Table.Cell textAlign="right" style={{ paddingRight: '.5em' }}>
                  <Button icon="edit" size="tiny" onClick={() => setSelectedCompany({ name, games })} />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Segment>
      {selectedCompany && (
        <CompanyModal
          selectedCompany={selectedCompany}
          onClose={() => setSelectedCompany(null)}
          setSelectedCompany={setSelectedCompany}
        />
      )}
    </>
  );
}

export default Companies;
