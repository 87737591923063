import React from 'react';
import { Controller } from 'react-hook-form';
import { Form, Input, TextArea } from 'semantic-ui-react';
import styled from 'styled-components';
import { Text } from '../text/text';
import { HFInputProps } from './types';

export const HFInput = (props: HFInputProps) => {
  const {
    name,
    label,
    placeholder,
    defaultValue,
    required = false,
    errors = {},
    control,
    textarea = false,
    width,
    icon,
    disabled = false,
    maxLength,
    transform,
  } = props;

  const onCustomChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    changeFn: (...event: any[]) => void
  ) => {
    if (transform) {
      return changeFn(transform(e));
    }

    return changeFn(e);
  };

  return (
    <Form.Field required={required} error={!!errors[name]} width={width}>
      {label && <label>{label}</label>}
      <Controller
        {...{ name, defaultValue, control }}
        rules={{ required }}
        render={({ field, field: { value, onChange } }) =>
          textarea ? (
            <TextArea
              {...{ ...field, value, onChange: (e) => onCustomChange(e, onChange) }}
              style={{ resize: 'none' }}
              autoComplete={'off'}
              maxLength={maxLength}
            />
          ) : (
            <Input
              fluid
              maxLength={maxLength}
              {...{ ...field, disabled, value, placeholder, icon, onChange: (e) => onCustomChange(e, onChange) }}
              autoComplete={'off'}
            />
          )
        }
      />
      {errors[name]?.message && (
        <ErrorWrapper>
          <Text margin="6px 0 0 0" color="#9f3a38">
            {errors[name]?.message}
          </Text>
        </ErrorWrapper>
      )}
    </Form.Field>
  );
};

const ErrorWrapper = styled.div`
  padding: 4px 8px;
  margin-top: 8px;
  background-color: #fff6f6;
  border: 1px solid #e0b4b4;
  border-radius: 4px; ;
`;
