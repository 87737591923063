export interface User {
  uid: string;
  name: string;
  email: string;
  roles: [];
  companies: string[];
}

export enum Role {
  ADMIN = 'ADMIN',
  DEV = 'DEV',
  PRESENTER = 'PRESENTER',
}
