import React from 'react';
import styled from 'styled-components';

import { Navbar } from './Navbar';
import { Content } from './Content';
import { Footer } from './Footer';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  h1,
  h2 {
    text-align: center;
  }
`;

export const Interface = ({ sessionData, data, content, footer = null }) => {
  const { contextData } = sessionData || {};
  const { stocking } = contextData || {};

  return (
    <Wrapper>
      <Navbar stocking={stocking} shipName={data.shipName} score={data?.overallPoints || '0'} />
      <Content>{content}</Content>
      <Footer>{footer}</Footer>
    </Wrapper>
  );
};
