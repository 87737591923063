import React from 'react';
import { slideWrapper } from '../components/slide';
import { RoundLetters, RoundType } from '@disco/data';
import { SantaPresenterSolution } from '../components/santa-presenter-solution';

export const OfficePuzzle1Solution = slideWrapper(
  ({ step, getImg, countingResults, countingResultsLoading }) => {
    const { answers } = step;

    return (
      <SantaPresenterSolution
        {...{ countingResults, countingResultsLoading, getImg, answers, letters: RoundLetters[3] }}
      />
    );
  },
  { countSantaAnswersTour: RoundType.Office }
);
