import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useCurrentContext } from '../../../utils/use-current-context';
import { useStockingCalculation } from '../../../utils/use-stocking-calculation';
import { Dots } from '../../../components/player-interface/Dots';
import { ScoreTable } from '../../../components/ScoreTable';
import { useRoundScore } from '../../../utils/use-round-score';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  font-size: 1.4rem;

  span {
    font-size: 5rem;
  }
`;

const Box = styled.div`
  min-width: 350px;
  display: flex;
  flex: 1;
  gap: 16px;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  flex-direction: column;
`;

const Boxes = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 24px;
`;

export const Content = ({ data, fetching }) => {
  const { t } = useTranslation();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const context = useCurrentContext(data.sessionId);
  const currentStep = data?.currentStep;

  const { loading, stocking, stockingChange } = useStockingCalculation(data.sessionId, currentStep.id, fetching);

  const { scoreData } = useRoundScore({ ...currentStep, stepsAfterVoting: 1 }, data, false);

  const userVouts = Object.values(scoreData?.overall || {});
  const userCount = userVouts.reduce((acc: number, curr: number) => acc + curr, 0);

  return loading ? (
    <Wrapper>
      <Dots />
    </Wrapper>
  ) : (
    <Wrapper>
      <Boxes>
        <Box>
          <h2>{t(`fishery.roundSummaryTitle`)}</h2>
          <ScoreTable context={context} userCount={userCount} />
        </Box>
        <Box>
          <h1>{t('fishery.fishStockingTitle')}</h1>
          <span>{stocking}</span>
          <h2>
            ({t(`fishery.fishStockingChange`)} {stockingChange > 0 ? '+' : ''}
            {stockingChange})
          </h2>
        </Box>
      </Boxes>
    </Wrapper>
  );
};
