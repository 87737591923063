import React from 'react';
import { Card, Image, List } from 'semantic-ui-react';
import styled from 'styled-components';
import Page from '../components/page';
import { Title } from '../components/title';
import { Text } from '../components/text';
import { PresenterWrapper } from '../components/presenter-wrapper';

const StyledList = styled.div<{ maxWidth: number }>`
  &&& {
    width: 90%;
    max-width: ${(props) => props.maxWidth}px;
    text-align: left;
    display: inline-block;
    margin: 40px auto;

    div {
      font-size: 36px !important;
      line-height: 40px !important;
      @media screen and (max-width: 1000px) {
        font-size: 18px !important;
        line-height: 21px !important;
      }
    }

    i {
      color: ${(props) => props.theme.buttonColor || '#775f9e'};
      font-size: 20px !important;
      @media screen and (max-width: 1000px) {
        font-size: 14px !important;
      }
    }

    li {
      margin-top: 8px;
      margin-bottom: 8px;
    }
  }
`;

const PresentationCard = styled.div<{ maxImageHeigh?: number }>`
  &&& {
    background: rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.5);
    font-weight: bold;

    img {
      min-height: ${(props) => props.maxImageHeigh || 200}px;
      max-height: ${(props) => props.maxImageHeigh || 200}px;
      width: auto !important;
      max-width: 100%;
      margin: auto;

      @media screen and (max-width: 1000px) {
        min-height: ${(props) => props.maxImageHeigh / 2 || 100}px;
        max-height: ${(props) => props.maxImageHeigh / 2 || 100}px;
      }
    }

    .content {
      min-height: 190px;
      border: none;
      @media screen and (max-width: 1000px) {
        min-height: unset;
      }
    }

    .description {
      margin: 16px 0;
      font-size: 24px;
      line-height: 27px;
    }

    @media screen and (max-width: 1000px) {
      margin-right: auto !important;
      margin-left: auto !important;
    }
  }
`;

const BottomBanner = styled.div<{ isChristmas: boolean }>`
  &&& {
    ${(props) => (props.isChristmas ? 'position: absolute; bottom: 0' : '')};
    display: flex;
    flex-direction: row;
    background: rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.5);
    width: 100%;
    align-items: center;
    font-weight: bold;

    .description {
      margin: 16px 0;
      font-size: 24px;
      line-height: 27px;
    }

    img {
      margin: 8px;
      max-height: 70px;
      width: auto;
    }
  }
`;

const Item = styled.li<{ visible: boolean }>`
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
`;

export function PresentationPage({ data, presentation, Plugin }) {
  const {
    color,
    list = [],
    cards = [],
    titleBackground,
    subtitle,
    imageLabels,
    maxCardImageHeigh,
    bannerBottom,
    listItemsVisible,
    listMaxWidth,
    style,
    text,
  } = data.currentStep;
  const isChristmas = style === 'christmas';

  return (
    <PresenterWrapper {...{ data, presentation }}>
      <Page {...{ data, presentation }}>
        <Title background={titleBackground} fontColor={color}>
          {data.currentStep.title}
        </Title>
        <Text
          dangerouslySetInnerHTML={{ __html: subtitle }}
          className={style === 'christmas' ? 'christmas-subtitle' : ''}
        />
        {text && <Text dangerouslySetInnerHTML={{ __html: text }} />}
        {Plugin && <Plugin {...{ data, presentation }} />}
        {!!list.length && (
          <List as={StyledList} maxWidth={listMaxWidth}>
            {list.map((item, index) => (
              <List.Item as={Item} key={item} visible={listItemsVisible === undefined || index < listItemsVisible}>
                <List.Icon verticalAlign="middle" size="small" name="check" />
                <List.Content>
                  <Text dangerouslySetInnerHTML={{ __html: item }} />
                </List.Content>
              </List.Item>
            ))}
          </List>
        )}
        <Card.Group style={{ marginTop: 32 }} stackable centered itemsPerRow={4}>
          {cards.map(({ title, image, text }, index) => (
            <Card as={PresentationCard} key={index} maxImageHeigh={maxCardImageHeigh}>
              {title && <Card.Description>{title}</Card.Description>}
              <Image src={data?.resources[imageLabels[image]]} wrapped ui={false} />
              {text && (
                <Card.Content>
                  {[...(typeof text === 'string' ? [text] : text)].map((t) => (
                    <Card.Description key={t}>
                      <div dangerouslySetInnerHTML={{ __html: t }} />
                    </Card.Description>
                  ))}
                </Card.Content>
              )}
            </Card>
          ))}
        </Card.Group>
        {bannerBottom && (
          <Card as={BottomBanner} isChristmas={isChristmas}>
            <img alt="50" src={data?.resources[imageLabels[bannerBottom.image]]} />
            <Card.Description>
              <div dangerouslySetInnerHTML={{ __html: bannerBottom?.text }} />
            </Card.Description>
          </Card>
        )}
      </Page>
    </PresenterWrapper>
  );
}

export default PresentationPage;
