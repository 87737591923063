import { useEffect, useState } from 'react';
import { useApi } from '../../generic/hooks/use-api';

export const useRoundScore = (currentStep, sessionData, changeScore = true) => {
  const [scoreData, setScoreData] = useState<any>(null);
  const [loading, setLoading] = useState(true);

  const callApi = useApi();

  useEffect(() => {
    (async () => {
      if (!currentStep.id || !currentStep?.stepsAfterVoting) return;

      const response = await callApi('/fishery-game/score/', {
        method: 'post',
        body: {
          sessionId: sessionData.sessionId,
          votingStepId: String(Number(currentStep.id) - Number(currentStep.stepsAfterVoting)),
          changeScore,
        },
      });
      setScoreData(response.data);
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep.id]);

  return { scoreData, loading };
};
