import React from 'react';
import { slideWrapper } from '../components/slide';
import { Content } from '../components/content';
import styled from 'styled-components';
import { Text } from '../components/text';
import { TextBox } from '../components/text-box';
import { useAnimationSteps } from '@disco/hooks';
import { useSantaFinalCounting } from '../hooks/use-santa-final-counting';

export const FinalSolution = slideWrapper(({ step, data, getImg }) => {
  const { sessionId, currentStep } = data;
  const { title, text1, textSuccess1, textSuccess2, textSuccess3, textFail1, textFail2, textFail3 } = step;

  const { isSuccess, groupNameText } = useSantaFinalCounting({
    sessionId,
    votingStepId: currentStep?.stepsAfterVoting ? currentStep.id - currentStep?.stepsAfterVoting : currentStep.id,
  });

  const animationStep = useAnimationSteps(4);

  const finalText = isSuccess
    ? textSuccess1.replace('{{groupName}}', `<b style="color:#e13636">${groupNameText}</b>`)
    : textFail2.replace('{{groupName}}', `<b style="color:#e13636">${groupNameText}</b>`);

  const texts = isSuccess ? [text1, finalText, textSuccess2, textSuccess3] : [text1, textFail1, finalText, textFail3];

  return (
    <>
      <Content yNotCenter top={7} left={50}>
        <TextBox>{title}</TextBox>
      </Content>
      <Content yNotCenter y={140} top={15} left={40} style={{ alignItems: 'baseline' }}>
        {texts.map((text, index) => (
          <TextWrapper key={text} show={animationStep > index}>
            <Text big>{text}</Text>
          </TextWrapper>
        ))}
      </Content>
      <Content xNotCenter left={65} top={50}>
        <SantaImage show src={getImg('santa')} />
        {isSuccess && getImg('santaSaved') && <SantaSaved show={animationStep >= 2} src={getImg('santaSaved')} />}
      </Content>
    </>
  );
});

const SantaImage = styled.img<any>`
  opacity: ${(props) => (props.show ? 1 : 0)};
  transition: opacity 0.7s;
  max-height: 100vh;
  z-index: 90;
`;

const SantaSaved = styled.img<any>`
  position: absolute;
  opacity: ${(props) => (props.show ? 1 : 0)};
  transition: opacity 0.7s;
  max-height: 100vh;
  z-index: 100;
`;

const TextWrapper = styled.div<any>`
  opacity: ${(props) => (props.show ? 1 : 0)};
  transition: opacity 0.7s;
  margin-top: 64px;
`;
