import React, { useEffect, useState } from 'react';
import { ChartWrapper, HeaderWrapper } from '../../../components/reused-components/presenter-chart-wrappers';
import { QuestionRangeCharts } from '../../../../collectors/components/charts/question-range-charts';
import firebase from 'firebase';
import { ACTION_SUBCOLLECTION, SESSION_COLLECTION, STEP_SUBCOLLECTION } from '@disco/data';
import { UserCounter } from '../../../../collectors/components/user-counter';
import { useTranslation } from 'react-i18next';

export const Content = ({ data }) => {
  const { t } = useTranslation();
  const [actions, setActions] = useState([]);

  useEffect(() => {
    return firebase
      .firestore()
      .collection(SESSION_COLLECTION)
      .doc(data.sessionId)
      .collection(STEP_SUBCOLLECTION)
      .doc(String(data.currentStep.id))
      .collection(ACTION_SUBCOLLECTION)
      .onSnapshot((snapshot) => {
        const list = [];
        snapshot.forEach((doc) => list.push(doc.data()));
        setActions(list);
      });
  }, []);

  return (
    <ChartWrapper>
      <HeaderWrapper>
        <h1>{t('fishery.thankYou')}</h1>
        <UserCounter actions={actions} />
      </HeaderWrapper>
      <div style={{ minHeight: '60vh', minWidth: '100%', display: 'grid', marginTop: 32, position: 'relative' }}>
        <QuestionRangeCharts
          style={'whatever'}
          options={getOptionsGrade(t)}
          actions={actions}
          maxText={'max'}
          minText={'min'}
        />
      </div>
    </ChartWrapper>
  );
};

export const getOptionsGrade = (t) => [t('fishery.rate1'), t('fishery.rate2'), t('fishery.rate3'), t('fishery.rate4')];
