import { TFunction } from 'i18next';
import React from 'react';
import Layout from '../components/layout';
import { usePermission } from '@disco/hooks';
import { SemanticICONS } from 'semantic-ui-react/dist/commonjs/generic';

export function panelViewWrapper(View: (...props: any) => JSX.Element, t: TFunction) {
  return () => (
    <Layout
      menuItems={[
        { text: t('sidebar.sessions'), href: '/', icon: 'tasks' },
        { text: t('sidebar.archived'), href: '/archived', icon: 'history' },
        { text: t('sidebar.games'), href: '/games', icon: 'puzzle' },
        ...(usePermission('usersView')
          ? [{ text: t('sidebar.users'), href: '/users', icon: 'users' as SemanticICONS }]
          : []),
        ...(usePermission('companiesView')
          ? [{ text: t('sidebar.companies'), href: '/companies', icon: 'building' as SemanticICONS }]
          : []),
        { text: t('sidebar.settings'), href: '/settings', icon: 'settings' },
      ]}
    >
      <View />
    </Layout>
  );
}
