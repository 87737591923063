import { useCallback, useContext } from 'react';
import { callApi } from '@disco/utils';
import { UserDiscoData, UserDiscoContext } from '@disco/ui';

export const useUsers = () => {
  const { token, email, name, roles, companies } = useContext<UserDiscoData>(UserDiscoContext);

  const getUsers = useCallback(async () => {
    return await callApi('/users', { token, method: 'GET' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateUser = useCallback(async (id, data) => {
    return await callApi('/users/' + id, { token, method: 'PATCH', body: data });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createUser = useCallback(async (data) => {
    return await callApi('/users', { token, method: 'POST', body: data });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteUser = useCallback(async (id) => {
    return await callApi('/users/' + id, { token, method: 'DELETE' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    getUsers,
    updateUser,
    createUser,
    deleteUser,
    currentUserCompanies: companies,
    currentUserRoles: roles,
    currentUserEmail: email,
  };
};
