import React from 'react';
import { Wrapper, Center } from '../../../components/Wrapper';
import { InterfaceWrapper } from '../../../components/InterfaceWrapper';
import { PlayerInterface } from './PlayerInterface';
import { ChartPresenterRadioAnswer } from '../../../components/ChartPresenterRadioAnswer';
import { useTranslation } from 'react-i18next';
import { emotionOptions } from './options';
import styled from 'styled-components';


export const MoodSlide = InterfaceWrapper({
  Interface: PlayerInterface,
  Step: ({ data, ...rest }) => {
    const { t } = useTranslation();

    return (
      <Wrapper>
        <Center>
          <ChartPresenterRadioAnswer data={data} title={t('fishery.introMoodTitle')} options={emotionOptions} />
        </Center>
      </Wrapper>
    );
  },
});
