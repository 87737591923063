import { useEffect, useState } from 'react';
import { useApi } from '../../generic/hooks/use-api';

export const useConferenceVoteCount = (sessionId, stepId) => {
  const [votingResults, setVotingResults] = useState(null);
  const [loading, setLoading] = useState(true);

  const callApi = useApi();

  useEffect(() => {
    (async () => {
      if (!stepId) return;

      const response = await callApi('/fishery-game/conference-count-votes/', {
        method: 'post',
        body: {
          sessionId: sessionId,
          stepId: stepId,
        },
      });
      setVotingResults(response.data);
      setLoading(false);
    })();
  }, [sessionId, stepId, callApi]);

  return { votingResults, loading };
};
