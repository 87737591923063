import React from 'react';
import { slideWrapper } from '../components/slide';
import { Content } from '../components/content';
import styled from 'styled-components';
import { Text } from '../components/text';
import { useAnimationSteps } from '@disco/hooks';
import { Colors } from '../constants';
import { PuzzleItem, PuzzleItemsWrapper } from '../components/puzzle-item';
import { Timer } from '../components/timer';
import { useConfirmation } from '../../generic/hooks/use-confirmation';

const TextImage = styled.img<any>`
  position: absolute;
  left: 2%;
  top: ${(props) => (props.show ? '100px' : '-400px')};
  width: 450px;
  transition: top 1s;
`;

const Box = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0;
`;

const TextWrapper = styled.div`
  margin: 0 40px;
  width: 600px;

  span {
    color: ${Colors.BLUE};
  }
`;

const ItemImage = styled.div`
  img {
    height: 120px;
  }
  position: relative;

  ::after {
    content: '';
    height: 25px;
    width: 25px;
    background-color: ${Colors.BLUE};
    border-radius: 50%;
    border: 3px solid white;
    position: absolute;
    bottom: 10%;
    left: 30%;
  }
`;

export const HypatiaPuzzle2 = slideWrapper(({ step, data, getImg, ...props }) => {
  const { answers } = step;

  const ConfirmationPopup = useConfirmation(1);
  return (
    <>
      <ConfirmationPopup />
      <Timer data={data} presentation />
      <PuzzleItemsWrapper>
        <PuzzleItem getImg={getImg} letter="a" answers={answers} />
        <PuzzleItem getImg={getImg} letter="b" answers={answers} />
        <PuzzleItem getImg={getImg} letter="c" answers={answers} />
      </PuzzleItemsWrapper>
    </>
  );
});
