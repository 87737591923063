import React, { useEffect, useState } from 'react';
import { Card, TextGroup } from '@disco/ui';
import firebase from 'firebase/app';
import {
  QuestionTextStep,
  SessionPublicData,
  SESSION_COLLECTION,
  STEP_SUBCOLLECTION,
  ACTION_SUBCOLLECTION,
  GROUP_COLORS,
  CHRISTMAS_GROUP_COLORS,
} from '@disco/data';
import { useCookies } from '@disco/hooks';
import { useTranslation } from 'react-i18next';
import Page from '../components/page';
import styled from 'styled-components';
import { useApi } from '../../generic/hooks/use-api';
import { CustomHeader } from '../components/custom-header';

export interface QuestionTextProps {
  presentation: boolean;
  fetching: boolean;
  uid: string;
  data: SessionPublicData;
  timer?: boolean;
  seconds?: number;
}

const FlexWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 32px;
`;

const BorderBox = styled.div<{ borderColor: string }>`
  border-radius: 12px;
  border: 2px solid ${(props) => props.borderColor};
  padding: 24px;
  font-size: 28px;
  line-height: 32px;
  font-weight: bold;
  margin: 8px;
  justify-content: center;
  align-items: center;
  display: flex;
`;

export function QuestionText({ uid, data, presentation, fetching = true }: QuestionTextProps) {
  const { t } = useTranslation();
  const { getSessionCookie } = useCookies();

  const callApi = useApi();

  const currentStep = data?.currentStep as QuestionTextStep;

  const [actions, setActions] = useState([]);
  const [optionSubmitted, setOptionSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const postAction = async (submittedText: string) => {
    setSubmitting(true);
    const actionData = { submittedText, groupName: getSessionCookie().groupName };
    const resultAction = await callApi('/steps/action', {
      method: 'POST',
      body: { data: actionData, userId: uid, sessionId: data.sessionId, stepId: data.currentStep.id },
    });
    setOptionSubmitted(true);
    setSubmitting(false);
    console.log('action result', resultAction);
  };

  useEffect(() => {
    setOptionSubmitted(false);
    setSubmitting(false);
  }, [currentStep.id]);

  useEffect(() => {
    if (presentation && fetching) {
      return firebase
        .firestore()
        .collection(SESSION_COLLECTION)
        .doc(data.sessionId)
        .collection(STEP_SUBCOLLECTION)
        .doc(String(data.currentStep.id))
        .collection(ACTION_SUBCOLLECTION)
        .onSnapshot((snapshot) => {
          const list = [];
          snapshot.forEach((doc) => list.push(doc.data()));
          setActions(list);
        });
    }
  }, []);

  return (
    <Page {...{ data, presentation }} alignContent={presentation ? 'top' : 'middle'}>
      {presentation ? (
        <>
          <CustomHeader actions={actions} currentStep={currentStep} prop={'title'} />
          <FlexWrapper>
            {actions.map((action, index) => (
              <BorderBox
                key={action.data.submittedText + index}
                borderColor={
                  currentStep.style === 'christmas'
                    ? CHRISTMAS_GROUP_COLORS[index % CHRISTMAS_GROUP_COLORS.length]
                    : GROUP_COLORS[index % GROUP_COLORS.length]
                }
              >
                {action.data.submittedText}
              </BorderBox>
            ))}
          </FlexWrapper>
        </>
      ) : (
        <Card color={currentStep?.color} title={optionSubmitted ? t('question.submitted') : currentStep?.title}>
          {!optionSubmitted && (
            <TextGroup maxLength={currentStep.maxLength} submitting={submitting} postAction={postAction} />
          )}
        </Card>
      )}
    </Page>
  );
}

export default QuestionText;
