import { ActionData } from '@disco/data';

export enum SortDir {
  ASC = 'ASC',
  DESC = 'DESC',
}

export const getUniqueUserActions = (list): ActionData[] => {
  const users = {};

  list.forEach((action) => {
    users[action.userId] = action;
  });
  return Object.values(users);
};

/**
 * Get most recent user action limited to maxUniqueActions number, duplicates are filtered out
 * @param list all step actions
 * @param maxUniqueActions number of options available to select for a user
 */
export const getUserActions = (list: ActionData[], maxUniqueActions: number): { [key: string]: ActionData[] } => {
  const byUser = groupBy(list, 'userId');

  const usersActions = {};
  for (const userActions of byUser.values()) {
    if (!userActions.length) continue;

    const userSorted = userActions.sort((a, b) => sortObjWithTime(a, b, SortDir.DESC));

    const selectedArr: ActionData[] = [];
    let deselectedArr: ActionData[] = [];

    userSorted.forEach((action) => {
      const deselectOption = action?.data?.deselectedOption;
      const selectOption = action?.data?.selectedOption;
      if (!deselectOption && !selectOption) return;

      if (deselectOption) {
        deselectedArr.push(action);
      }
      if (selectOption) {
        const isDeselectedIdx = deselectedArr.findIndex((deAction) => deAction.data?.deselectedOption === selectOption);

        if (isDeselectedIdx !== -1) deselectedArr = deselectedArr.filter((deAction, idx) => idx !== isDeselectedIdx);
        else selectedArr.push(action);
      }
    });

    //filter out duplicate actions
    const userSelected = [...new Map(selectedArr.map((item) => [item.data.selectedOption, item])).values()];

    // console.log('userActions', userActions);
    // console.log('userSorted', userSorted);
    // console.log('userSelected', userSelected);
    usersActions[userSorted[0].userId] = userSelected.slice(0, maxUniqueActions);
  }

  return usersActions;
};

export const flattenActionData = (actions: ActionData[]) =>
  actions.map(({ data, ...rest }) => ({
    groupName: data.groupName,
    ...(data.selectedOption && { selectedOption: data.selectedOption }),
    ...(data.deselectedOption && { deselectedOption: data.deselectedOption }),
    ...rest,
  }));

export const groupBy = <T, K extends keyof T>(value: T[], key: K) =>
  value.reduce((acc, curr) => {
    if (acc.get(curr[key])) return acc;
    acc.set(
      curr[key],
      value.filter((elem) => elem[key] === curr[key])
    );
    return acc;
  }, new Map<T[K], T[]>());

export const sortObjWithTime = (a: any, b: any, sortDir: SortDir) => {
  const dateA = a.time.toDate().getTime();
  const dateB = b.time.toDate().getTime();
  if (sortDir === SortDir.DESC) return dateA < dateB ? 1 : -1;
  else return dateA < dateB ? -1 : 1;
};
