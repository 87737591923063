import React, { useEffect, useState, useContext, useMemo } from 'react';
import { UserDiscoData, UserDiscoContext } from '@disco/ui';
import firebase from 'firebase/app';
import { useTranslation } from 'react-i18next';
import { Button, Dimmer, Header, Icon, Label, Loader, Segment, Table, Popup, Select } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import { SessionData } from '../models/session';
import { DeleteSessionModal } from '../components/modals/session/delete-session';
import { CreateSessionModal } from '../components/modals/session/create-session';
import { PreviewSessionModal } from '../components/modals/session/preview-session';
import { EditSessionModal } from '../components/modals/session/edit-session';
import moment from 'moment';
import { SESSION_COLLECTION, SessionStatus, GAMES_COLLECTION } from '@disco/data';
import styled from 'styled-components';
import { useSessions } from '../api/use-sessions';

type ModalType = 'delete' | 'create' | 'edit' | 'preview';

type Order = 'asc' | 'desc';

const listLimit = 10;

export function Sessions({ archivedView = false }) {
  const { t } = useTranslation();

  const { companies } = useContext<UserDiscoData>(UserDiscoContext);

  const { getPresenters } = useSessions();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<SessionData[]>([]);
  const [games, setGames] = useState([]);

  useEffect(() => {
    const gamesRef = firebase
      .firestore()
      .collection(GAMES_COLLECTION)
      .where('companies', 'array-contains-any', companies);

    const unsubscribe = gamesRef.onSnapshot((snapshot) => {
      const list = [];
      snapshot.forEach((doc) => list.push(doc.id));
      setGames(list);
    });
    return unsubscribe;
  }, []);

  const history = useHistory();

  const [presenters, setPresenters] = useState([]);

  const [modalType, setModalType] = useState<ModalType>();
  const [selectedSession, setSelectedSession] = useState<SessionData | null>();

  const [createSession, setCreateSession] = useState(false);

  const [lastDoc, setLastDoc] = useState(null);
  const [noMoreDocs, setNoMoreDocs] = useState(false);

  const [docOrder, setDocOrder] = useState<Order>(archivedView ? 'desc' : 'asc');
  const [selectedTrainer, setSelectedTrainer] = useState(null);

  const userSessionsRef = firebase
    .firestore()
    .collection(SESSION_COLLECTION)
    .where('status', '==', archivedView ? SessionStatus.ARCHIVED : SessionStatus.OPEN)
    .where('companies', 'array-contains-any', companies);

  const sessionsRef = useMemo(
    () => (selectedTrainer ? userSessionsRef.where('facilitator', '==', selectedTrainer) : userSessionsRef),
    [selectedTrainer]
  );

  useEffect(() => {
    return sessionsRef
      .orderBy('plannedDate', docOrder)
      .limit(listLimit)
      .onSnapshot((snapshot) => {
        setLastDoc(snapshot.docs[snapshot.docs.length - 1]);
        const list = [];
        snapshot.forEach((doc) => {
          list.push({ ...doc.data(), id: doc.id });
        });
        setData(list);
        setLoading(false);
      });
  }, [docOrder, sessionsRef]);

  useEffect(() => {
    (async () => {
      const presenters = await getPresenters();
      setPresenters(presenters?.data.map((u) => u.name || u.email));
    })();
  }, []);

  const getNext = () =>
    sessionsRef
      .orderBy('plannedDate', docOrder)
      .startAfter(lastDoc)
      .limit(listLimit)
      .onSnapshot((snapshot) => {
        if (snapshot.empty) {
          setNoMoreDocs(true);
        } else {
          setLastDoc(snapshot.docs[snapshot.docs.length - 1]);
          const list = [];
          snapshot.forEach((doc) => {
            list.push({ ...doc.data(), id: doc.id });
          });
          setData((data) => [...data, ...list]);
        }
        setLoading(false);
      });

  const onSelectRow = (id) => {
    history.push('/sessions/' + id);
  };

  const onModalAction = (id: string, type: ModalType) => {
    setSelectedSession(data.find((ele) => ele.id === id));
    setModalType(type);
  };

  const onClose = () => setModalType(null);

  const today = moment();
  const tomorrow = moment().add(1, 'day');

  return (
    <>
      <Segment basic>
        <Header size="huge">{archivedView ? t('sessions.historyTitle') : t('sessions.title')}</Header>
        <Dimmer inverted active={loading}>
          <Loader />
        </Dimmer>
        <StyledTable>
          <Table selectable basic="very">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  {t('sessions.plannedDate')}
                  <Icon
                    style={{ cursor: 'pointer', marginLeft: 6 }}
                    onClick={() => setDocOrder(docOrder === 'asc' ? 'desc' : 'asc')}
                    name={docOrder === 'asc' ? 'sort content ascending' : 'sort content descending'}
                  />
                </Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell style={{ paddingLeft: '.5em' }} singleLine>
                  {t('sessions.name')}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  {t('sessions.facilitator')}
                  <Popup
                    wide
                    offset={[-12, 0]}
                    trigger={<Icon style={{ cursor: 'pointer', marginLeft: 6 }} name="filter" />}
                    on="click"
                  >
                    <Select
                      size="small"
                      placeholder={t('sessions.facilitator')}
                      options={presenters.map((p) => ({ key: p, value: p, text: p }))}
                      onChange={(_, { value: newValue }) => setSelectedTrainer(newValue)}
                    />
                  </Popup>
                  {selectedTrainer && (
                    <Icon onClick={() => setSelectedTrainer(null)} style={{ cursor: 'pointer' }} name="undo" />
                  )}
                </Table.HeaderCell>
                <Table.HeaderCell>{t('sessions.sessionSize')}</Table.HeaderCell>
                <Table.HeaderCell>{t('sessions.type')}</Table.HeaderCell>
                <Table.HeaderCell textAlign="right" style={{ paddingRight: '.5em' }}>
                  {!archivedView && (
                    <Button style={{ width: 105 }} size="tiny" positive onClick={() => setCreateSession(true)}>
                      <Icon name="plus" />
                      {t('sessions.create')}
                    </Button>
                  )}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {data.map(({ plannedDate, name, id, participants, gameId, facilitator }) => (
                <Table.Row key={id} style={{ cursor: 'pointer' }} onClick={() => onSelectRow(id)}>
                  <Table.Cell style={{ paddingLeft: '.5em' }}>
                    {moment(plannedDate).format('DD.MM.yyyy, HH:mm')}
                  </Table.Cell>
                  <Table.Cell style={{ paddingLeft: '.5em' }}>
                    {moment(plannedDate).isSame(today, 'day') ? (
                      <Label color="blue">{t('sessions.today')}</Label>
                    ) : moment(plannedDate).isSame(tomorrow, 'day') ? (
                      <Label color="blue">{t('sessions.tomorrow')}</Label>
                    ) : null}
                  </Table.Cell>
                  <Table.Cell>{name}</Table.Cell>
                  <Table.Cell>{facilitator || '-'}</Table.Cell>
                  <Table.Cell>{participants}</Table.Cell>
                  <Table.Cell>{gameId}</Table.Cell>
                  <Table.Cell textAlign="right" style={{ paddingRight: '.5em' }}>
                    <SessionListButtons
                      archivedView={archivedView}
                      onPreviewSession={(e) => {
                        e.stopPropagation();
                        onModalAction(id, 'preview');
                      }}
                      onEditSession={(e) => {
                        e.stopPropagation();
                        if (!archivedView) onModalAction(id, 'edit');
                      }}
                      onDeleteSession={(e) => {
                        e.stopPropagation();
                        onModalAction(id, 'delete');
                      }}
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </StyledTable>
        <Button style={{ marginTop: 10 }} disabled={noMoreDocs} onClick={getNext}>
          {t('sessions.loadMore')}
        </Button>
      </Segment>
      {!!createSession && (
        <CreateSessionModal games={games} presenters={presenters} open onClose={() => setCreateSession(false)} />
      )}
      {!!selectedSession && modalType === 'delete' && (
        <DeleteSessionModal
          open
          {...{ sessionCode: selectedSession.sessionCode, name: selectedSession.name, id: selectedSession.id, onClose }}
        />
      )}
      {!!selectedSession && modalType === 'edit' && (
        <EditSessionModal games={games} presenters={presenters} open {...{ selectedSession, onClose }} />
      )}
      {!!selectedSession && modalType === 'preview' && <PreviewSessionModal open {...{ selectedSession, onClose }} />}
    </>
  );
}

export default Sessions;

const SessionListButtons = ({ onPreviewSession, onEditSession, onDeleteSession, archivedView = false }) => {
  return (
    <div>
      <Button icon="eye" size="tiny" onClick={onPreviewSession} />
      {!archivedView && <Button icon="edit" size="tiny" onClick={onEditSession} />}
      <Button icon="delete" size="tiny" negative onClick={onDeleteSession} />
    </div>
  );
};

const StyledTable = styled.div`
  overflow: auto;
`;
