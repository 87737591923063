import React from 'react';

export interface UserDiscoData {
  uid?: string;
  token?: string;
  name?: string;
  email?: string;
  companies?: string[];
  roles?: string[];
}

export const UserDiscoContext = React.createContext<UserDiscoData>({});
