import styled from 'styled-components';

export const Content = styled.div<any>`
  display: flex;
  z-index: ${props => props.zIndex};
  flex-direction: column;
  align-items: ${(props) => props.align || 'center'};
  position: absolute;
  width: ${(props) => props.width};
  top: ${(props) => props?.top && `${props?.top}%`};
  bottom: ${(props) => props?.bottom && `${props?.bottom}%`};
  left: ${(props) => (props.left || 50) + '%'};
  transform: translate(
    calc(${(props) => (props.xNotCenter ? '0px' : '-50%')} + ${(props) => (props.x || 0) + 'px'}),
    calc(${(props) => (props.yNotCenter ? '0px' : '-50%')} + ${(props) => (props.y || 0) + 'px'})
  );
`;
