import React from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, Divider } from 'semantic-ui-react';
import { RoundSettings } from './RoundWrapper';

export const RoundBlock = ({ currentRound, currentSlide, config, onChangeValue }) => {
  const { t } = useTranslation();

  return (
    <RoundSettings>
      <h3>
        {t('fisheryAdmin.roundNumber')}: {currentRound}
      </h3>
      <div>{t('fisheryAdmin.roundEvents')}</div>
      <Checkbox
        onChange={(e, data) => onChangeValue('roundStocking', data.checked)}
        checked={config.roundStocking}
        disabled={currentSlide}
        toggle
        label={t('fisheryAdmin.roundStocking')}
      />
      <Checkbox
        onChange={(e, data) => onChangeValue('roundConference', data.checked)}
        checked={config.roundConference}
        disabled={currentSlide}
        toggle
        label={t('fisheryAdmin.roundConference')}
      />
      <Checkbox
        onChange={(e, data) => onChangeValue('roundReferendum', data.checked)}
        checked={config.roundReferendum}
        disabled={currentSlide}
        toggle
        label={t('fisheryAdmin.roundReferendum')}
      />
      <Checkbox
        onChange={(e, data) => onChangeValue('roundHowAreYou', data.checked)}
        checked={config.roundHowAreYou}
        disabled={currentSlide}
        toggle
        label={t('fisheryAdmin.roundHowAreYou')}
      />
      <Divider />
      <div>{t('fisheryAdmin.settings')} </div>
      <Checkbox disabled checked={config['settingsDynamit']} toggle label={t('fisheryAdmin.settingsDynamit')} />
      <Checkbox
        onChange={(e, data) => onChangeValue('settingsRedPunishment', data.checked)}
        checked={config['settingsRedPunishment']}
        toggle
        label={t('fisheryAdmin.settingsRedPunishment')}
      />
      <Checkbox
        onChange={(e, data) => onChangeValue('settingsBlueTakesAll', data.checked)}
        checked={config['settingsBlueTakesAll']}
        toggle
        label={t('fisheryAdmin.settingsBlueTakesAll')}
      />
      <Divider />
      <div>{t('fisheryAdmin.strategies')}</div>
      <Checkbox
        disabled
        onChange={(e, data) => onChangeValue('strategyBlue', data.checked)}
        checked={config['strategyBlue']}
        toggle
        label={t('fisheryAdmin.strategyBlue')}
      />
      <Checkbox
        disabled
        onChange={(e, data) => onChangeValue('strategyYellow', data.checked)}
        checked={config['strategyYellow']}
        toggle
        label={t('fisheryAdmin.strategyYellow')}
      />
      <Checkbox
        disabled
        onChange={(e, data) => onChangeValue('strategyOrange', data.checked)}
        checked={config['strategyOrange']}
        toggle
        label={t('fisheryAdmin.strategyOrange')}
      />
    </RoundSettings>
  );
};
