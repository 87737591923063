import { QuestionRadioChart } from './components/charts/question-radio';
import { SelectGroupChart } from './components/charts/select-group';
import PresentationPage from './presentation/presentation-page';
import PresentationPageTimer from './presentation/presentation-page-timer';
import QuestionRadio from './question/question-radio';
import SelectGroup from './select-group/select-group';
import { PresentVoting } from './present-voting/present-voting';
import { PresentScoreTable } from './present-score-table/present-score-table';
import { QuestionText } from './question/question-text';
import { QuestionTextTable } from './components/charts/question-text';
import QuestionRange from './question/question-range';
import { CollectorsStepType } from './types';

export const CollectorsStep = {
  [CollectorsStepType.QUESTION_RADIO]: QuestionRadio,
  [CollectorsStepType.SELECT_GROUP]: SelectGroup,
  [CollectorsStepType.PRESENT]: PresentationPage,
  [CollectorsStepType.PRESENT_TIMER]: PresentationPageTimer,
  [CollectorsStepType.PRESENT_VOTING]: PresentVoting,
  [CollectorsStepType.PRESENT_SCORE_TABLE]: PresentScoreTable,
  [CollectorsStepType.QUESTION_TEXT]: QuestionText,
  [CollectorsStepType.QUESTION_RANGE]: QuestionRange,
};

export const CHART_MAP = {
  [CollectorsStepType.SELECT_GROUP]: SelectGroupChart,
  [CollectorsStepType.QUESTION_RADIO]: QuestionRadioChart,
  [CollectorsStepType.QUESTION_TEXT]: QuestionTextTable,
};
