import { SessionDurationProps } from '@disco/data';

export interface SessionData {
  id?: string;
  name?: string;
  plannedDate: string;
  meetingType: string;
  meetingLink: string;
  sessionCode: string;
  createdAt?: { seconds: number; toDate: () => Date };
  open?: boolean;
  groups?: string[];
  gameId?: string;
  status?: string;
  participants?: string;
  currentStepId?: number;
  facilitator?: string;
  notes?: string;
  trainingObjective?: string;
  duration?: SessionDurationProps;
}

export interface SessionStepData {
  id?: string;
  orderId?: number;
  type?: string;
  notes?: string;
  scoring?: any;
  stepsToSolution?: any;
  itemsBot?: any;
  maxSelections?: number;
  answers?: number;
  blockNextStepKey?: boolean;
  blockPrevStepKey?: boolean;
  [key: string]: any;
}

export const byOrderId = (a: SessionStepData, b: SessionStepData) =>
  a.orderId > b.orderId ? 1 : a.orderId < b.orderId ? -1 : 0;

export enum SessionDurationAction {
  START = 'START',
  END = 'END',
}
