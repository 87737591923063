import React from 'react';
import { slideWrapper } from '../components/slide';
import { TextBox } from '../components/text-box';
import { Content } from '../components/content';
import { useAnimationSteps } from '@disco/hooks';
import styled from 'styled-components';
import { Rule } from '../components/rule';

const CharacterImage = styled.img<any>`
  opacity: ${(props) => (props.show ? 1 : 0)};
  transition: opacity 0.7s;
  height: 55vh;
`;

const Wrapper = styled.div`
  display: flex;
`;

export const SantaCharacterDescription = slideWrapper(({ step, getImg, resources, ...props }) => {
  const { title, text1, text2, text3, text4, text5 } = step;

  const texts = [text1, text2, text3, text4, text5].filter((r) => r);
  const animationStep = useAnimationSteps(texts.length);

  const goat = getImg('goat');
  const rudolf = getImg('rudolf');
  const krampus = getImg('krampus');
  const mari = getImg('mari');

  return (
    <>
      <Content yNotCenter top={7} left={50}>
        <TextBox>{title}</TextBox>
      </Content>
      <Content yNotCenter y={140} top={15} left={50}>
        <Wrapper>
          {goat && <CharacterImage show src={goat} />}
          {rudolf && <CharacterImage show src={rudolf} />}
          {krampus && <CharacterImage show src={krampus} />}
          {mari && <CharacterImage show src={mari} />}
          <div>
            {texts.map((text, index) => (
              <Rule width={'70vw'} key={index} show={animationStep > index} text={text} />
            ))}
          </div>
        </Wrapper>
      </Content>
    </>
  );
});
