import React from 'react';

import { Wrapper } from '../../../components/Wrapper';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Header = styled.h1`
  text-align: center;
`;

export const OutroSlide = ({ data }) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Header>{t('fishery.fishingEnd')}</Header>
    </Wrapper>
  );
};
