import React from 'react';
import styled from 'styled-components';
import { Colors } from '../constants';
import { Text } from './text';

const LabelWrapper = styled.div`
  position: relative;
  width: 250px;
`;

const HorizontalLine = styled.div<any>`
  height: 2px;
  width: ${(props) => props.width}px;
  background-color: ${Colors.TEXT};
  position: absolute;
  bottom: 50%;

  ${(props) =>
    props.right
      ? `
  right: 0;
  transform: translate(calc(100% + 1px), calc(-50% - 8px));
  `
      : `
  left: 0;
  transform: translate(calc(-100% - 1px), calc(-50% - 8px));
  `}
`;

const Point = styled.div<any>`
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${Colors.TEXT};
  bottom: 50%;
  ${(props) =>
    props.right
      ? `
  right: 0;
  transform: translate(100%, -50%);
  `
      : `
  left: 0;
  transform: translate(-100%, -50%);
  `}
`;

const PointingLine = styled.div<any>`
  height: 2px;
  width: ${(props) => props.width}px;
  background-color: ${Colors.TEXT};
  position: absolute;
  bottom: 50%;
  outline: 1px solid transparent;
  ${(props) =>
    props.right
      ? `
  right: 0;
  transform-origin: 0% 0%;
  transform: translate(calc(100% + ${props.translate + 'px'}), calc(-50% - 8px)) rotate(30deg);
  `
      : `
  left: 0;
  transform-origin: 100% 0%;
  transform: translate(calc(-100% - ${props.translate + 'px'}), calc(-50% - 8px)) rotate(-30deg);
  `}
`;

const TextWrapper = styled.div`
  margin-right: 16px;
`;

export const TextLabel = ({ text, xLength, yLength, right = false }) => {
  return (
    <LabelWrapper>
      <TextWrapper>
        <Text alignCenter>{text}</Text>
      </TextWrapper>
      <Point right={right} />
      <PointingLine right={right} translate={xLength} width={yLength} />
      <HorizontalLine right={right} width={xLength} />
    </LabelWrapper>
  );
};
