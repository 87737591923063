import { GroupData } from '@disco/data';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BarChart, XAxis, YAxis, Bar, ResponsiveContainer } from 'recharts';
import styled from 'styled-components';
import { size } from './presentation/constants';

interface ScoreChartProps {
  data: any[];
  groups: GroupData[];
}

const Label = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: bold;
  height: 100%;
  font-size: 30px;
`;

const LABEL_HEIGHT = 100;

const customizedAxisTicksScore = (t) => ({ x, y, payload }) => {
  return (
    <g transform={`translate(${Number(x)},${Number(y - LABEL_HEIGHT / 2)})`}>
      <foreignObject x={100} y={0} dy={16} width={200} height={LABEL_HEIGHT}>
        <Label>
          <b>
            {payload.value} {t('steps.points')}
          </b>
        </Label>
      </foreignObject>
    </g>
  );
};

const Circle = styled.span<{ color?: string }>`
  margin-right: 16px;
  font-weight: bold;
  padding: 10px;
  background-color: ${(props) => props.color};
  border-radius: 30px;
`;

const CustomizedAxisTicksGroupName = ({ x, y, index, payload }) => {
  return (
    <g transform={`translate(${Number(x - 400)},${Number(y - LABEL_HEIGHT / 2 || 0)})`}>
      <foreignObject x={100} y={0} dy={16} width={400} height={LABEL_HEIGHT}>
        <Label>
          <Circle>{index + 1}</Circle> {payload.value}
        </Label>
      </foreignObject>
    </g>
  );
};

export const ScoreChart = ({ data, groups = [] }: ScoreChartProps) => {
  const { t } = useTranslation();
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={400}
        height={300}
        margin={{
          top: 5,
          right: 250,
          left: 250,
          bottom: 5,
        }}
        data={data}
        layout="vertical"
      >
        <XAxis tickLine={false} hide axisLine={false} type="number" domain={[0, (dataMax) => dataMax]} />
        <YAxis
          tickLine={false}
          yAxisId="left"
          axisLine={false}
          dataKey="name"
          type="category"
          tick={CustomizedAxisTicksGroupName}
        />
        <YAxis
          orientation="right"
          tickLine={false}
          axisLine={false}
          dataKey="points"
          type="category"
          tick={customizedAxisTicksScore(t)}
        />
        {groups.map((group) => (
          <Bar stackId="s1" maxBarSize={size.BAR_MAX_WIDTH} key={group.name} dataKey={group.name} fill={group.color} />
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};
