import React, { useState } from 'react';
import styled from 'styled-components';
import { playerInterfaceWrapper } from './player-interface';
import { useTranslation } from 'react-i18next';
import { useCookies } from '@disco/hooks';
import { Text } from '../components/text';
import { Content } from '../components/content';

const ContentWrapper = styled.div`
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

export const PresentScore = playerInterfaceWrapper(({ step, data, uid, getImg, inventoryItems }) => {
  const { t } = useTranslation();

  const { getSessionCookie } = useCookies();
  const { groupName } = getSessionCookie();
  const { groupScores = [], maxHours } = data;

  const score = groupScores.find((group) => group.groupName === groupName);
  return (
    <Content top={40}>
      <ContentWrapper>
        <Text>{t('chronos.yourScore')}</Text>
        <Text isBold>{`${maxHours - score?.score}h`}</Text>
      </ContentWrapper>
    </Content>
  );
});
