import React from 'react';
import { slideWrapper } from '../components/slide';
import { RoundLetters, RoundType } from '@disco/data';
import { PuzzleItems } from '../components/puzzle-items';

import { Content } from '../components/content';
import { SlideImage } from '../components/slide-image';

export const HypatiaPuzzle3Counting = slideWrapper(
  ({ step, getImg, countingResults, countingResultsLoading }) => {
    const { answers } = step;

    return (
      <>
        <Content bottom={10} left={-10} yNotCenter xNotCenter>
          <SlideImage width="400px" src={getImg('boat')} show />
        </Content>

        <Content bottom={0} left={-2} yNotCenter xNotCenter>
          <SlideImage width="400px" src={getImg('boatguys')} show />
        </Content>
        <PuzzleItems
          smallItem
          {...{ countingResults, countingResultsLoading, getImg, answers, letters: RoundLetters[3] }}
        />
      </>
    );
  },
  { countAnswersTour: RoundType.Hypatia }
);
