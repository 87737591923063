import { ApiResponse } from '@disco/data';

export const callApi = async (url, options: any) => {
  const headers = {
    'Content-Type': 'application/json',
    ...(typeof options?.token === 'string' && { Authorization: 'Bearer ' + options.token }),
  };
  const parsedUrl = 'https://europe-west1-disco-ament.cloudfunctions.net/api' + url;

  const res = await fetch(parsedUrl, {
    headers,
    ...options,
    ...(options.body && { body: JSON.stringify(options.body) }),
  });
  return (await res.json()) as ApiResponse;
};
