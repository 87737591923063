import React from 'react';
import { slideWrapper } from '../components/slide';
import { Content } from '../components/content';
import styled from 'styled-components';
import { Text } from '../components/text';
import { AnimFull } from './puzzle-3-story-post-office';
import { ConditionalSoundButton } from '../components/sound-button';

const Image = styled.img`
  width: 100vw;
  height: auto;
`;

const SlideText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-size: 60px;
  margin: 32px 20px;
  z-index: 999;
`;

const Mari = styled.img`
  position: absolute;
  width: 500px;
  right: 15%;
  top: 15%;
`;

const NotesAnim = styled.img`
  width: 100vw;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
`;

export const ArtHousePuzzle8Story = slideWrapper(({ step, fetching, resources, getImg, ...props }) => {
  const { text1, text2 } = step;

  return (
    <>
      {fetching && <ConditionalSoundButton getImg={getImg} />}
      <Content width="90%" yNotCenter top={0} left={0}>
        <Image src={getImg('image')} />
        <AnimFull src={getImg('notesMariAnim')} />
        <NotesAnim src={getImg('notesAnim')} />
        <Mari src={getImg('mari')} />
        <SlideText>
          <Text medium>{text1}</Text>
          <Text medium>{text2}</Text>
        </SlideText>
      </Content>
    </>
  );
});
