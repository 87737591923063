import { useLocation } from 'react-router-dom';

export const usePresentationCheck = () => {
  const location = useLocation();

  const checkIfPresentationPath = () => {
    const split = location.pathname.split('/');
    return split.length && split[split.length - 1] === 'present';
  };

  return { actualPresentationScreen: checkIfPresentationPath() };
};
