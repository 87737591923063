import { useEffect, useState } from 'react';
import React from 'react';
import { useGameSound } from '../hooks/use-sound-game';
import {usePresentationCheck} from "../hooks/use-presentation-check";

export const SoundButton = ({ getImg, soundKey }) => {
  const SoundButton = useGameSound(getImg(soundKey));
  return <SoundButton />;
};

export const ConditionalSoundButton = ({ getImg, soundKey = 'lector' }) => {
  const { actualPresentationScreen } = usePresentationCheck();

  const [render, setRender] = useState(false);

  useEffect(() => {
    if (!actualPresentationScreen) return

    const timeout = setTimeout(() => setRender(true), 100);
    return () => clearTimeout(timeout);
  }, []);

  return render && actualPresentationScreen ? <SoundButton getImg={getImg} soundKey={soundKey} /> : null;
};
