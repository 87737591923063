import React from 'react';
import { slideWrapper } from '../components/slide';
import { Timer } from '../components/timer';
import { PuzzleItem, PuzzleItemsWrapper } from '../components/puzzle-item';
import { Content } from '../components/content';

export const ArtHousePuzzle8 = slideWrapper(({ data, step, getImg }) => {
  const { answers } = step;

  return (
    <>
      <Timer data={data} presentation />
      <Content yNotCenter top={14} left={7}>
        <img style={{ transform: 'scaleX(-1)' }} width="180px" src={getImg('mari')} />
      </Content>
      <Content yNotCenter top={20} left={15}>
        <img width="400px" src={getImg('man')} />
      </Content>
      <PuzzleItemsWrapper>
        <PuzzleItem getImg={getImg} letterWrapper="t2" letter="a" answers={answers} />
        <PuzzleItem getImg={getImg} letterWrapper="t3" letter="b" answers={answers} />
        <PuzzleItem getImg={getImg} letterWrapper="t4" letter="c" answers={answers} />
      </PuzzleItemsWrapper>
    </>
  );
});
