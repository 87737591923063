import { useEffect, useState } from 'react';
import firebase from 'firebase/app';

import { ACTION_SUBCOLLECTION, SESSION_COLLECTION, STEP_SUBCOLLECTION } from '@disco/data';

export const usePresenterActions = (data, offset = 0) => {
  const [loading, setLoading] = useState(true);
  const [actions, setActions] = useState([]);

  useEffect(() => {
    return firebase
      .firestore()
      .collection(SESSION_COLLECTION)
      .doc(data.sessionId)
      .collection(STEP_SUBCOLLECTION)
      .doc(String(Number(data.currentStep.id) - offset))
      .collection(ACTION_SUBCOLLECTION)
      .onSnapshot((snapshot) => {
        const list = [];
        snapshot.forEach((doc) => list.push(doc.data()));
        setActions(list);
        setLoading(false);
      });
  }, [data.currentStep.id, data.sessionId, offset]);

  return { actions, loading };
};
